import {
  DetailsList, IconButton, Link,
  SelectionMode, Stack,
  useTheme
} from "@fluentui/react";
import { format } from "date-fns";
import { de } from "date-fns/locale";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import Swal from "sweetalert2";
import axiosJWT from "../assets/axiosjwt";
import { Icons } from "../assets/icons";
import { addWindow } from "../assets/windows";
import {
  PushDown,
  QueryProgressIndicator
} from "../components/misc";

const ListReceipts: React.FC<{ query?: any }> = ({ query }) => {
  const receipts = query
  const [list, setList] = useState<any>({
    items: [],
    isSorted: {},
  });
  const clear = () => setList({ ...list, items: receipts?.data });
  const setItems = (items: Array<any>) => setList({ ...list, items: items });
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme()
  const queryClient = useQueryClient()

  useEffect(() => {
    if (receipts?.isSuccess) setItems(receipts?.data);
    // eslint-disable-next-line
  }, [receipts?.isSuccess, receipts?.data?.length]);

  const sortByPublicKey = () => {
    let items = list.items

    if (list.isSorted.byPublicKeyUP) {
      items = items.sort((a: any, b: any) => {
        if (parseInt(a.publicKey.slice(a.publicKey.length - 4, a.publicKey.length))
          <
          parseInt(b.publicKey.slice(b.publicKey.length - 4, b.publicKey.length))) return -1
        return 1
      })
    } else {
      items = items.sort((a: any, b: any) => {
        if (parseInt(a.publicKey.slice(a.publicKey.length - 4, a.publicKey.length))
          <
          parseInt(b.publicKey.slice(b.publicKey.length - 4, b.publicKey.length))) return 1
        return -1
      })
    }

    setList({
      items: items, isSorted: {
        byCreatedAtUP: false,
        byCreatedAtDOWN: false,
        byPublicKeyUP: !list.isSorted.byPublicKeyUP,
        byPublicKeyDOWN: list.isSorted.byPublicKeyUP,
        byInvoiceUP: false,
        byInvoiceDOWN: false,
        byEmployerUP: false,
        byEmployerDOWN: false,
        byJobUP: false,
        byJobDOWN: false,
        byAmountUP: false,
        byAmountDOWN: false,
        byEmployeeUP: false,
        byEmployeeDOWN: false,
      }
    })
  }

  const sortByEmployer = () => {
    let items = list.items

    if (list.isSorted.byEmployerUP) {
      items = items.sort((a: any, b: any) => {
        if (parseInt(a.job.employer.publicKey.slice(a.job.employer.publicKey.length - 4, a.job.employer.publicKey.length))
          <
          parseInt(b.job.employer.publicKey.slice(b.job.employer.publicKey.length - 4, b.job.employer.publicKey.length))) return -1
        return 1
      })
    } else {
      items = items.sort((a: any, b: any) => {
        if (parseInt(a.job.employer.publicKey.slice(a.job.employer.publicKey.length - 4, a.job.employer.publicKey.length))
          <
          parseInt(b.job.employer.publicKey.slice(b.job.employer.publicKey.length - 4, b.job.employer.publicKey.length))) return 1
        return -1
      })
    }

    setList({
      items: items, isSorted: {
        byCreatedAtUP: false,
        byCreatedAtDOWN: false,
        byPublicKeyUP: false,
        byPublicKeyDOWN: false,
        byEmployerUP: !list.isSorted.byEmployerUP,
        byEmployerDOWN: list.isSorted.byEmployerUP,
        byInvoiceUP: false,
        byInvoiceDOWN: false,
        byJobUP: false,
        byJobDOWN: false,
        byAmountUP: false,
        byAmountDOWN: false,
        byEmployeeUP: false,
        byEmployeeDOWN: false,
      }
    })
  }

  const sortByEmployee = () => {
    let items = list.items

    if (list.isSorted.byEmployeeUP) {
      items = items.sort((a: any, b: any) => {
        if (parseInt(a.employee.publicKey.slice(a.employee.publicKey.length - 4, a.employee.publicKey.length))
          <
          parseInt(b.employee.publicKey.slice(b.employee.publicKey.length - 4, b.employee.publicKey.length))) return -1
        return 1
      })
    } else {
      items = items.sort((a: any, b: any) => {
        if (parseInt(a.employee.publicKey.slice(a.employee.publicKey.length - 4, a.employee.publicKey.length))
          <
          parseInt(b.employee.publicKey.slice(b.employee.publicKey.length - 4, b.employee.publicKey.length))) return 1
        return -1
      })
    }

    setList({
      items: items, isSorted: {
        byCreatedAtUP: false,
        byCreatedAtDOWN: false,
        byPublicKeyUP: false,
        byPublicKeyDOWN: false,
        byEmployerUP: false,
        byEmployerDOWN: false,
        byEmployeeUP: !list.isSorted.byEmployeeUP,
        byEmployeeDOWN: list.isSorted.byEmployeeUP,
        byInvoiceUP: false,
        byInvoiceDOWN: false,
        byJobUP: false,
        byJobDOWN: false,
        byAmountUP: false,
        byAmountDOWN: false,
      }
    })
  }

  const sortByJob = () => {
    let items = list.items

    if (list.isSorted.byJobUP) {
      items = items.sort((a: any, b: any) => {
        if (parseInt(a.job.publicKey.slice(a.job.publicKey.length - 4, a.job.publicKey.length))
          <
          parseInt(b.job.publicKey.slice(b.job.publicKey.length - 4, b.job.publicKey.length))) return -1
        return 1
      })
    } else {
      items = items.sort((a: any, b: any) => {
        if (parseInt(a.job.publicKey.slice(a.job.publicKey.length - 4, a.job.publicKey.length))
          <
          parseInt(b.job.publicKey.slice(b.job.publicKey.length - 4, b.job.publicKey.length))) return 1
        return -1
      })
    }

    setList({
      items: items, isSorted: {
        byCreatedAtUP: false,
        byCreatedAtDOWN: false,
        byPublicKeyUP: false,
        byPublicKeyDOWN: false,
        byJobUP: !list.isSorted.byJobUP,
        byJobDOWN: list.isSorted.byJobUP,
        byEmployerUP: false,
        byEmployerDOWN: false,
        byInvoiceUP: false,
        byInvoiceDOWN: false,
        byAmountUP: false,
        byAmountDOWN: false,
        byEmployeeUP: false,
        byEmployeeDOWN: false,
      }
    })
  }

  const sortByCreatedAt = () => {
    let items = list.items

    if (list.isSorted.byCreatedAtUP) {
      items = items.sort((a: any, b: any) => {
        if (new Date(a.createdAt).getTime() < new Date(b.createdAt).getTime()) return -1
        return 1
      })
    } else {
      items = items.sort((a: any, b: any) => {
        if (new Date(a.createdAt).getTime() < new Date(b.createdAt).getTime()) return 1
        return -1
      })
    }

    setList({
      items: items, isSorted: {
        byCreatedAtUP: !list.isSorted.byCreatedAtUP,
        byCreatedAtDOWN: list.isSorted.byCreatedAtUP,
        byPublicKey: false,
        byInvoiceUP: false,
        byEmployerUP: false,
        byEmployerDOWN: false,
        byInvoiceDOWN: false,
        byJobUP: false,
        byJobDOWN: false,
        byAmountUP: false,
        byAmountDOWN: false,
        byEmployeeUP: false,
        byEmployeeDOWN: false,
      }
    })
  }

  const sortByInvoice = () => {
    let items = list.items

    if (list.isSorted.byInvoiceUP) {
      items = items.sort((a: any, b: any) => {
        if (a.invoice.publicKey.length < 12) a.invoice.publicKey = "00000"
        if (b.invoice.publicKey < 12) b.invoice.publicKey = "00000"
        if (parseInt(a.invoice.publicKey.slice(a.invoice.publicKey.length - 4, a.invoice.publicKey.length))
          <
          parseInt(b.invoice.publicKey.slice(b.invoice.publicKey.length - 4, b.invoice.publicKey.length))) return -1
        return 1
      })
    } else {
      items = items.sort((a: any, b: any) => {
        if (a.invoice.publicKey.length < 12) a.invoice.publicKey = "00000"
        if (b.invoice.publicKey < 12) b.invoice.publicKey = "00000"
        if (parseInt(a.invoice.publicKey.slice(a.invoice.publicKey.length - 4, a.invoice.publicKey.length))
          <
          parseInt(b.invoice.publicKey.slice(b.invoice.publicKey.length - 4, b.invoice.publicKey.length))) return 1
        return -1
      })
    }

    setList({
      items: items, isSorted: {
        byCreatedAtUP: false,
        byCreatedAtDOWN: false,
        byEmployerUP: false,
        byEmployerDOWN: false,
        byAmountUP: false,
        byAmountDOWN: false,
        byPublicKeyUP: false,
        byPublicKeyDOWN: false,
        byInvoiceUP: !list.isSorted.byInvoiceUP,
        byInvoiceDOWN: list.isSorted.byInvoiceUP,
        byJobUP: false,
        byJobDOWN: false,
        byEmployeeUP: false,
        byEmployeeDOWN: false,
      }
    })
  }

  const sortByAmount = () => {
    let items = list.items

    if (list.isSorted.byAmountUP) {
      items = items.sort((a: any, b: any) => {
        if (a.amount < b.amount) return -1
        return 1
      })
    } else {
      items = items.sort((a: any, b: any) => {
        if (a.amount < b.amount) return 1
        return -1
      })
    }

    setList({
      items: items, isSorted: {
        byCreatedAtUP: false,
        byCreatedAtDOWN: false,
        byAmountUP: !list.isSorted.byAmountUP,
        byAmountDOWN: list.isSorted.byAmountUP,
        byPublicKeyUP: false,
        byPublicKeyDOWN: false,
        byInvoiceUP: false,
        byInvoiceDOWN: false,
        byEmployerUP: false,
        byEmployerDOWN: false,
        byJobUP: false,
        byJobDOWN: false,
        byEmployeeUP: false,
        byEmployeeDOWN: false,
      }
    })
  }

  return (
    <Stack>
      <PushDown by={100} />

      <QueryProgressIndicator  query={receipts} />

      {receipts?.isSuccess && receipts?.data?.length > 0 && (
        <Stack>
          <DetailsList
            columns={[
              {
                isResizable: true,
                key: "date",
                onColumnClick(ev, column) {
                  sortByCreatedAt()
                },
                minWidth: 0,
                maxWidth: 50,
                name: ((): string => {
                  if (list.isSorted.byCreatedAtUP) {
                    return "erstellt am | oben"
                  } else if (list.isSorted.byCreatedAtDOWN) {
                    return "erstellt am | unten"
                  } else {
                    return "erstellt am"
                  }
                })(),
                styles: { root: { borderRadius: "16px 16px 0px 0px", background: list.isSorted.byCreatedAtUP || list.isSorted.byCreatedAtDOWN ? theme.palette.themePrimary : undefined } },
                onRender: (item: any) => {
                  return (
                    format(new Date(item?.createdAt), "dd.MM.yy", { locale: de })
                  );
                },
              },
              {
                isResizable: true,
                key: "publicKey",
                minWidth: 0,
                maxWidth: 50,
                name: ((): string => {
                  if (list.isSorted.byPublicKeyUP) {
                    return "Schlüssel | oben"
                  } else if (list.isSorted.byPublicKeyDOWN) {
                    return "Schlüssel | unten"
                  } else {
                    return "Schlüssel"
                  }
                })(),
                onColumnClick(ev, column) {
                  sortByPublicKey()
                },
                onRender: (item: any) => item?.publicKey,
                styles: { root: { borderRadius: "16px 16px 0px 0px", background: list.isSorted.byPublicKeyUP || list.isSorted.byPublicKeyDOWN ? theme.palette.themePrimary : undefined } }
              },
              {
                isResizable: true,
                key: "invoice",
                minWidth: 0,
                maxWidth: 50,
                name: ((): string => {
                  if (list.isSorted.byInvoiceUP) {
                    return "Rechnung | oben"
                  } else if (list.isSorted.byInvoiceDOWN) {
                    return "Rechnung | unten"
                  } else {
                    return "Rechnung"
                  }
                })(),
                onColumnClick(ev, column) {
                  sortByInvoice()
                },
                styles: { root: { borderRadius: "16px 16px 0px 0px", background: list.isSorted.byInvoiceUP || list.isSorted.byInvoiceDOWN ? theme.palette.themePrimary : undefined } },
                onRender: (item: any) => {
                  return (
                    <Link
                      onClick={() =>
                        addWindow.Invoice.view(item?.invoice?.invoiceId)
                      }
                    >
                      {item?.invoice?.publicKey}
                    </Link>
                  );
                },
              },
              {
                key: "employer",
                isResizable: true,
                minWidth: 0,
                maxWidth: 250,
                name: ((): string => {
                  if (list.isSorted.byEmployerUP) {
                    return "Auftraggeber*in | oben"
                  } else if (list.isSorted.byEmployerDOWN) {
                    return "Auftraggeber*in | unten"
                  } else {
                    return "Auftraggeber*in"
                  }
                })(),
                onColumnClick(ev, column) {
                  sortByEmployer()
                },
                styles: { root: { borderRadius: "16px 16px 0px 0px", background: list.isSorted.byEmployerUP || list.isSorted.byEmployerDOWN ? theme.palette.themePrimary : undefined } },
                onRender: (item: any) => {
                  if (item?.job?.employer?.type === "publicEmployer") {
                    return (
                      <Link
                        onClick={() =>
                          addWindow.Profile.view(item?.job?.employer?.id)
                        }
                      >
                        {item?.job?.employer?.description}
                      </Link>
                    );
                  }
                  if (item?.job?.employer?.type !== "publicEmployer") {
                    return (
                      <Link
                        onClick={() =>
                          addWindow.Profile.view(item?.job?.employer?.id)
                        }
                      >
                        {item?.job?.employer?.firstName +
                          " " +
                          item?.job?.employer?.lastName}
                      </Link>
                    );
                  }
                },
              },
              {
                key: "job",
                minWidth: 0,
                isResizable: true,
                onColumnClick(ev, column) {
                  sortByJob()
                },
                name: ((): string => {
                  if (list.isSorted.byJobUP) {
                    return "Job | oben"
                  } else if (list.isSorted.byJobDOWN) {
                    return "Job | unten"
                  } else {
                    return "Job"
                  }
                })(),
                styles: { root: { borderRadius: "16px 16px 0px 0px", background: list.isSorted.byJobUP || list.isSorted.byJobDOWN ? theme.palette.themePrimary : undefined } },
                onRender: (item: any) => {
                  return (
                    <Link onClick={() => addWindow.Job.view(item?.job?.id)}>
                      {item?.job?.title}
                    </Link>
                  );
                },
              },
              {
                isResizable: true,
                key: "employee",
                minWidth: 0,
                maxWidth: 250,
                onColumnClick(ev, column) {
                  sortByEmployee()
                },
                name: ((): string => {
                  if (list.isSorted.byEmployeeUP) {
                    return "Jugendliche*r | oben"
                  } else if (list.isSorted.byEmployeeDOWN) {
                    return "Jugendliche*r | unten"
                  } else {
                    return "Jugendliche*r"
                  }
                })(),
                styles: { root: { borderRadius: "16px 16px 0px 0px", background: list.isSorted.byEmployeeUP || list.isSorted.byEmployeeDOWN ? theme.palette.themePrimary : undefined } },
                onRender: (item: any) => {
                  return (
                    <Link
                      onClick={() => addWindow.Profile.view(item?.employee?.id)}
                    >
                      {item?.employee?.firstName + " " + item?.employee?.lastName}
                    </Link>
                  );
                },
              },
              {
                key: "amount",
                minWidth: 0,
                maxWidth: 50,
                name: ((): string => {
                  if (list.isSorted.byAmountUP) {
                    return "Betrag | oben"
                  } else if (list.isSorted.byAmountDOWN) {
                    return "Betrag | unten"
                  } else {
                    return "Betrag"
                  }
                })(),
                styles: { root: { borderRadius: "16px 16px 0px 0px", background: list.isSorted.byAmountUP || list.isSorted.byAmountDOWN ? theme.palette.themePrimary : undefined } },
                onColumnClick(ev, column) {
                  sortByAmount()
                },
                onRender: (item: any) => "CHF " + item?.amount / 100,
              },
              {
                key: "delete",
                minWidth: 0,
                name: "Stornieren",
                onRender: (item: any) => {
                  return (
                    <IconButton
                      iconProps={{ iconName: Icons.Delete }}
                      onClick={async () => {
                        Swal.fire({
                          title: "Sicher?",
                          text: "Damit werden die in dieser Quittung enthaltenen Arbeitseinsätze als NICHT AUSBEZAHLT markiert.",
                          showCancelButton: true,
                          cancelButtonText: "Abbrechen",
                          confirmButtonText: "Ja, stornieren",
                          buttonsStyling: false
                        }).then(async (event: any) => {
                          if (event.isConfirmed) {
                            await axiosJWT.post(
                              process.env.REACT_APP_SERVER +
                              "/api/a/deleteReceiptById/",
                              { data: { receiptId: item?.id } }
                            );

                            queryClient.invalidateQueries("receipts")
                            queryClient.invalidateQueries("workLogs")
                          }
                        })
                      }}
                      styles={{
                        icon: { fontSize: 15 },
                        iconHovered: { color: "#FFD23F" },
                        root: { borderRadius: 16, height: 20, width: 20 },
                      }}
                    />
                  );
                },
              },
            ]}
            isHeaderVisible={true}
            items={list.items}
            onItemInvoked={async (item: any) => {
              setIsLoading(true);
              let res = await axiosJWT.get(
                process.env.REACT_APP_SERVER +
                "/api/a/get-receipt-by-id/" +
                item?.id
              );

              const link: any = res.data;

              setIsLoading(false);

              window.open(link, "blank");
            }}
            selectionMode={SelectionMode.none}
            styles={{ root: { cursor: "pointer", borderRadius: "0px 0px 16px 16px" } }}
          />
        </Stack>
      )}
    </Stack>
  );
};

export default ListReceipts;
