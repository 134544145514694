import {
  DefaultButton,
  Dropdown,
  Link,
  Modal,
  Stack,
  Text,
  TextField,
  Toggle
} from "@fluentui/react";
import { format, intervalToDuration, set } from "date-fns";
import { useEffect, useRef, useState } from "react";
import { useStaff } from "../../assets/queries";
import { myColumnRight, myTextField, myTokens } from "../../assets/styles";
import { UploadDocument } from "../../views/Document";
import { PushDown } from "../misc";
import {
  CreateAddress,
  CreateBirthdate,
  CreateDescription,
  CreateEmail,
  CreateFirstName,
  CreateGender,
  CreateLandline,
  CreateLastName,
  CreateMobile,
  CreateWebsite
} from "./Fields";
import { FieldDateCreate, FormColumnLeft } from "./Parts";

const Create: React.FC<{
  form: any;
  formErrors: any;
  onChange: (updatedFields: any) => void;
  type: string;
  independent?: boolean;
}> = ({ form, formErrors, onChange, type, independent = false }) => {
  switch (type) {
    case "publicEmployer":
      return (
        <PublicEmployer
          form={form}
          formErrors={formErrors}
          onChange={onChange}
        />
      );
    case "privateEmployer":
      return (
        <PrivateEmployer
          form={form}
          formErrors={formErrors}
          onChange={onChange}
        />
      );
    case "employee":
      return (
        <Employee form={form} formErrors={formErrors} onChange={onChange} />
      );
    case "workLog":
      return (
        <WorkLog form={form} formErrors={formErrors} onChange={onChange} />
      );
    case "contact":
      return (
        <Contact form={form} formErrors={formErrors} onChange={onChange} />
      );
    case "note":
      return (
        <Note
          form={form}
          formErrors={formErrors}
          onChange={onChange}
          independent={independent}
        />
      );
    case "assignment":
      return (
        <Assignment form={form} formErrors={formErrors} onChange={onChange} />
      );

    default:
      return <Stack></Stack>;
  }
};

export default Create;

const PublicEmployer: React.FC<{
  form: any;
  formErrors: any;
  onChange: any;
}> = ({ form, formErrors, onChange }) => {
  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <FormColumnLeft label="Auftraggeber*in" />

        <Stack styles={myColumnRight}>
          <CreateDescription
            formErrors={formErrors}
            onChange={onChange}
            placeholder="z.B. Bäckerei Feinbrot"
          />
        </Stack>
      </Stack>

      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <FormColumnLeft />

        <Stack styles={myColumnRight}>
          <CreateWebsite
            formErrors={formErrors}
            onChange={onChange}
            placeholder="z.B. feinbrot.ch"
          />
        </Stack>
      </Stack>

      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <FormColumnLeft />

        <Stack styles={myColumnRight}>
          <CreateAddress formErrors={formErrors} onChange={onChange} />
        </Stack>
      </Stack>

      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <FormColumnLeft />

        <Stack styles={myColumnRight}>
          <CreateEmail formErrors={formErrors} onChange={onChange} />
        </Stack>
      </Stack>

      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <FormColumnLeft />

        <Stack horizontal styles={myColumnRight} tokens={{ childrenGap: 10 }}>
          <CreateLandline formErrors={formErrors} onChange={onChange} />
          <CreateMobile formErrors={formErrors} isPrimary onChange={onChange} />
        </Stack>
      </Stack>
    </Stack>
  );
};

const PrivateEmployer: React.FC<{
  form: any;
  formErrors: any;
  onChange: any;
}> = ({ form, formErrors, onChange }) => {
  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <FormColumnLeft label="Auftraggeber*in" />

        <Stack styles={myColumnRight}>
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <CreateFirstName formErrors={formErrors} onChange={onChange} />

            <CreateLastName formErrors={formErrors} onChange={onChange} />
          </Stack>

          <CreateAddress formErrors={formErrors} onChange={onChange} />
        </Stack>
      </Stack>

      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <FormColumnLeft />

        <Stack styles={myColumnRight}>
          <CreateEmail formErrors={formErrors} onChange={onChange} />
        </Stack>
      </Stack>

      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <FormColumnLeft />

        <Stack horizontal styles={myColumnRight} tokens={{ childrenGap: 10 }}>
          <CreateLandline formErrors={formErrors} onChange={onChange} />
          <CreateMobile formErrors={formErrors} isPrimary onChange={onChange} />
        </Stack>
      </Stack>
    </Stack>
  );
};

const Employee: React.FC<{ form: any; formErrors: any; onChange: any }> = ({
  form,
  formErrors,
  onChange,
}) => {
  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <FormColumnLeft label="Jugendliche*r" />

        <Stack horizontal styles={myColumnRight} tokens={{ childrenGap: 10 }}>
          <CreateBirthdate
            form={form}
            formErrors={formErrors}
            onChange={onChange}
          />

          <CreateGender
            form={form}
            formErrors={formErrors}
            onChange={onChange}
          />
        </Stack>
      </Stack>

      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <FormColumnLeft />

        <Stack styles={myColumnRight}>
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <CreateFirstName formErrors={formErrors} onChange={onChange} />

            <CreateLastName formErrors={formErrors} onChange={onChange} />
          </Stack>

          <CreateAddress formErrors={formErrors} onChange={onChange} />
        </Stack>
      </Stack>

      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <FormColumnLeft />

        <Stack styles={myColumnRight}>
          <CreateEmail formErrors={formErrors} onChange={onChange} />
        </Stack>
      </Stack>

      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <FormColumnLeft />

        <Stack horizontal styles={myColumnRight} tokens={{ childrenGap: 10 }}>
          <CreateLandline formErrors={formErrors} onChange={onChange} />
          <CreateMobile formErrors={formErrors} isPrimary onChange={onChange} />
        </Stack>
      </Stack>
    </Stack>
  );
};

const WorkLog: React.FC<{ form: any; formErrors: any; onChange: any }> = ({
  form,
  formErrors,
  onChange,
}) => {
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(15);
  const [timeframe, setTimeframe] = useState<any>({
    start: undefined,
    end: undefined,
  });

  const updateMinutes = () => {
    let amountOfMinutes = hours * 60 + minutes;
    onChange({
      minutes: amountOfMinutes.toString(),
    });
    if (timeframe.start && timeframe.end) {
      // let coeff = 1000 * 60 * 15;
      // timeframe.start = new Date(
      //   Math.ceil(timeframe.start.getTime() / coeff) * coeff
      // );
      // timeframe.end = new Date(
      //   Math.ceil(timeframe.end.getTime() / coeff) * coeff
      // );
      onChange({
        minutes: amountOfMinutes.toString(),
        startTime: format(new Date(timeframe.start), "HH:mm:00.000"),
        endTime: format(new Date(timeframe.end), "HH:mm:00.000"),
      });
    }
  };

  useEffect(() => {
    updateMinutes();
    // eslint-disable-next-line
  }, [hours, minutes]);

  useEffect(() => {
    if (timeframe.start && timeframe.end) {
      let coeff = 1000 * 60 * 15;
      if (new Date(timeframe.start).getMinutes() !== 0 &&
        new Date(timeframe.start).getMinutes() !== 15 &&
        new Date(timeframe.start).getMinutes() !== 30 &&
        new Date(timeframe.start).getMinutes() !== 45) {
        timeframe.start = new Date(
          Math.ceil(timeframe.start.getTime() / coeff) * coeff
        );
      }
      if (new Date(timeframe.end).getMinutes() !== 0 &&
        new Date(timeframe.end).getMinutes() !== 15 &&
        new Date(timeframe.end).getMinutes() !== 30 &&
        new Date(timeframe.end).getMinutes() !== 45) {
        timeframe.end = new Date(
          Math.ceil(timeframe.end.getTime() / coeff) * coeff
        );
      }
      setHours(
        intervalToDuration({
          start: new Date(timeframe.start),
          end: new Date(timeframe.end),
        }).hours!
      );
      setMinutes(
        intervalToDuration({
          start: new Date(timeframe.start),
          end: new Date(timeframe.end),
        }).minutes!
      );
    }
    // eslint-disable-next-line
  }, [timeframe]);

  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <FormColumnLeft label="Arbeitseinsatz" />

        <Stack horizontal styles={myColumnRight} tokens={{ childrenGap: 10 }}>
          <FieldDateCreate
            field="date"
            form={form}
            label="vom"
            onFieldChange={(value: any) =>
              onChange({
                date: set(new Date(value!), {
                  date: new Date(value!).getDate() + 1,
                }),
              })
            }
            validationErrors={formErrors?.date}
          />
        </Stack>
      </Stack>

      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <FormColumnLeft />

        <Stack horizontal styles={myColumnRight} tokens={{ childrenGap: 10 }}>
          <label htmlFor="startTime">von</label>
          <input
            id="startTime"
            onChange={(e) => {
              let date = set(new Date(), {
                hours: parseInt(e.target.value.slice(0, 2)),
                minutes: parseInt(e.target.value.slice(3, 5)),
              });
              setTimeframe({ ...timeframe, start: date });
            }}
            type="time"
          />

          <label htmlFor="startTime">bis</label>
          <input
            id="endTime"
            onChange={(e) => {
              let date = set(new Date(), {
                hours: parseInt(e.target.value.slice(0, 2)),
                minutes: parseInt(e.target.value.slice(3, 5)),
              });
              setTimeframe({ ...timeframe, end: date });
            }}
            type="time"
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

const Contact: React.FC<{
  form: any;
  formErrors: any;
  onChange: (updatedFields: any) => void;
}> = ({ form, formErrors, onChange }) => {
  return (
    <Stack tokens={myTokens}>
      <Stack horizontal tokens={myTokens}>
        <Stack grow>
          <CreateDescription formErrors={formErrors} onChange={onChange} />

          <Text variant="xSmall">
            *in welcher Beziehung steht - oder - in welcher Situation eignet
            sich dieser Kontakt?
          </Text>
        </Stack>
      </Stack>

      <Stack horizontal tokens={myTokens}>
        <CreateFirstName formErrors={formErrors} onChange={onChange} />

        <CreateLastName formErrors={formErrors} onChange={onChange} />
      </Stack>

      <CreateAddress formErrors={formErrors} onChange={onChange} />

      <Stack horizontal tokens={myTokens}>
        <Stack grow styles={myTextField}>
          <CreateEmail formErrors={formErrors} onChange={onChange} />
        </Stack>
      </Stack>

      <Stack horizontal tokens={myTokens}>
        <Stack grow styles={myTextField}>
          <CreateLandline formErrors={formErrors} onChange={onChange} />
        </Stack>

        <Stack grow styles={myTextField}>
          <CreateMobile formErrors={formErrors} onChange={onChange} />
        </Stack>
      </Stack>
    </Stack>
  );
};

const Note: React.FC<{
  form: any;
  formErrors: any;
  onChange: (updatedFields: any) => void;
  independent: boolean;
}> = ({ form, formErrors, onChange, independent }) => {
  // styles
  const stackTokensGeneral = { childrenGap: 10 };
  const textFieldStyle = { root: { width: "100%" } };
  const [type, setType] = useState<any>("");
  const staff = useStaff();
  const [selectedStaff, setSelectedStaff] = useState<any>([]);
  const [options, setOptions] = useState<any>([]);
  const [file, setFile] = useState();
  const uploader = useRef<any>();
  const [title, setTitle] = useState("")

  const staffOptions = staff.data?.map((item: any) => {
    return {
      key: item?.id,
      text: item?.firstName + " " + item?.lastName,
    };
  });

  useEffect(() => {
    onChange({
      confirmationsNeeded: selectedStaff,
    });
    // eslint-disable-next-line
  }, [selectedStaff.length]);

  useEffect(() => {
    if (!independent) {
      setOptions([
        {
          key: "default",
          text: "Normal",
        },
        {
          key: "critical",
          text: "Kritisch",
        },
        {
          key: "danger",
          text: "Alarm",
        },
        {
          key: "todo",
          text: "Zu erledigen",
        },
        {
          key: "ticket",
          text: "Support",
        },
      ]);
    } else {
      setOptions([
        {
          key: "default",
          text: "Normal",
        },
        {
          key: "todo",
          text: "Zu erledigen",
        },
        {
          key: "ticket",
          text: "Support",
        },
      ]);
    }
    // eslint-disable-next-line
  }, []);

  const [showUploadDocument, setShowUploadDocument] = useState(false)

  return (
    <Stack tokens={stackTokensGeneral}>
      <Modal isOpen={showUploadDocument} onDismiss={() => setShowUploadDocument(false)} styles={{ main: { borderRadius: 16, height: "45vh", width: "50vw" } }}>
        <UploadDocument modelId={title} modelType="note" windowId={"uploadDocument_" + title} />
      </Modal>
      
      <Stack horizontal tokens={stackTokensGeneral}>
        <Stack grow>
          <Dropdown
            onChange={(event, newValue) => {
              setType(newValue!.key);
              onChange({ type: newValue!.key });
            }}
            options={options}
            placeholder="Notiz-Typ"
          />
        </Stack>

        <Stack>
          <DefaultButton disabled={!title} primary onClick={() => {
            setShowUploadDocument(true)
            // addWindow.Document.upload(title, "note")
          }
          }>Datei anhängen</DefaultButton>
        </Stack>
      </Stack>

      <Stack tokens={stackTokensGeneral}>
        {type === "default" && (
          <Text style={{ fontSize: 12 }}>
            Eine normale Notiz
            <ul>
              <li>
                wird dem Eintrag zugeordnet, von dem aus sie ausgelöst worden
                ist.
              </li>
              <li>
                ist für alle Teammitglieder der Jugend-Job-Börse einseh- und
                kommentierbar.
              </li>
            </ul>
          </Text>
        )}

        {type === "critical" && (
          <Text style={{ fontSize: 12 }}>
            Eine kritische Notiz
            <ul>
              <li>
                wird dem Eintrag zugeordnet, von dem aus sie ausgelöst worden
                ist.
              </li>
              <li>
                muss gelesen werden, bevor der entsprechende Eintrag angezeigt
                wird
              </li>
              <li>
                ist für alle Teammitglieder der Jugend-Job-Börse einseh- und
                kommentierbar.
              </li>
            </ul>
          </Text>
        )}

        {type === "danger" && (
          <Text style={{ fontSize: 12 }}>
            Eine alarmierende Notiz
            <ul>
              <li>
                wird dem Eintrag zugeordnet, von dem aus sie ausgelöst worden
                ist.
              </li>
              <li>führt zur Sperrung des entsprechenden Eintrags</li>
              <li>
                ist für alle Teammitglieder der Jugend-Job-Börse einseh- und
                kommentierbar.
              </li>
            </ul>
          </Text>
        )}

        {type === "todo" && (
          <Text style={{ fontSize: 12 }}>
            Eine zu erledigende Notiz
            <ul>
              <li>
                wird dem Eintrag zugeordnet, von dem aus sie ausgelöst worden
                ist.
              </li>
              <li>
                kann einem oder mehreren Teammitgliedern der Jugend-Job-Börse
                zur Erledigung zugeordnet werden
              </li>
              <li>
                gilt als erledigt, wenn alle zugeordneten Teammitglieder diese
                entsprechend markiert haben
              </li>
            </ul>
          </Text>
        )}

        {type === "ticket" && (
          <Text style={{ fontSize: 12 }}>
            Eine Support-Notiz
            <ul>
              <li>
                wird dem Eintrag zugeordnet, von dem aus sie ausgelöst worden
                ist.
              </li>
              <li>schickt den Notiz-Text per SMS an den jobfairy-Support</li>
              <li>
                gilt als erledigt, wenn der jobfairy-Support diese entsprechend
                markiert
              </li>
            </ul>
          </Text>
        )}
      </Stack>

      {type === "todo" && (
        <Stack tokens={stackTokensGeneral}>
          <Stack>
            <Dropdown
              multiSelect
              onChange={(event, newValue: any) => {
                if (newValue.selected) {
                  setSelectedStaff([...selectedStaff, newValue!.key]);
                } else {
                  let newSelection = selectedStaff.filter((item: any) => {
                    return item !== newValue.key;
                  });
                  setSelectedStaff(newSelection);
                }
              }}
              options={staffOptions}
              placeholder="zu erledigen durch"
            />
          </Stack>
        </Stack>
      )}

      <input
        id="file"
        onChange={async (e: any) => {
          setFile(e.target.files[0]);
        }}
        ref={uploader}
        style={{ display: "none" }}
        type="file"
      />

      <Stack horizontal tokens={stackTokensGeneral}>
        <Stack grow>
          <TextField
            autoAdjustHeight
            errorMessage={formErrors?.title}
            label="Titel"
            required
            onChange={(event, newValue) => {
              onChange({ title: newValue! })
              setTitle(newValue!)
            }}
            resizable={false}
            styles={textFieldStyle}
          />
        </Stack>

        <Stack>
          <Toggle
            label="Hervorheben"
            onChange={(event: any, checked: any) => {
              onChange({ showInDossier: checked });
            }}
          />
        </Stack>
      </Stack>

      <Stack tokens={stackTokensGeneral}>
        <Stack>
          <TextField
            autoAdjustHeight
            errorMessage={formErrors?.text}
            label="Notiz"
            multiline
            rows={10}
            onChange={(event, newValue) => onChange({ text: newValue! })}
            resizable={false}
            required
            styles={textFieldStyle}
          />
        </Stack>
      </Stack>

      <Link href="https://www.markdownguide.org/cheat-sheet" target="blank">
        Markdown-Anleitung
      </Link>
    </Stack >
  );
};

const Assignment: React.FC<{
  form: any;
  formErrors: any;
  onChange: (updatedFields: any) => void;
}> = ({ form, formErrors, onChange }) => {
  const [details, setDetails] = useState<any>({
    jobId: "",
    employerId: "",
    employeeId: "",
    date: new Date(),
    startTime: format(new Date(), "yyy-MM-dd") + "T06:00:00.000+01:00",
    endTime: format(new Date(), "yyy-MM-dd") + "T06:15:00.000+01:00",
    minutes: 15,
  });

  return (
    <Stack tokens={myTokens}>
      <Stack tokens={myTokens}>
        <FieldDateCreate
          field="date"
          form={form}
          label=""
          onFieldChange={(value) => {
            onChange({ date: value });
          }}
          validationErrors={formErrors?.date}
        />

        <PushDown by={20} />

        <Stack horizontal tokens={myTokens}>
          <label htmlFor="startTime">von</label>
          <input
            id="startTime"
            onChange={(e) => {
              let date = set(new Date(), {
                hours: parseInt(e.target.value.slice(0, 2)),
                minutes: parseInt(e.target.value.slice(3, 5)),
              });
              onChange({
                startTime: format(new Date(date), "HH:mm:00.000"),
              });
            }}
            style={{ width: "50%" }}
            type="time"
          />

          <label htmlFor="startTime">bis</label>
          <input
            id="endTime"
            onChange={(e) => {
              let date = set(new Date(), {
                hours: parseInt(e.target.value.slice(0, 2)),
                minutes: parseInt(e.target.value.slice(3, 5)),
              });
              onChange({
                endTime: format(new Date(date), "HH:mm:00.000"),
              });
            }}
            style={{ width: "50%" }}
            type="time"
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
