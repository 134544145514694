import { Label, Link, Stack, Text } from "@fluentui/react";
import { useEffect, useState } from "react";
import { returnErrorsToReset, returnServerError } from "../assets/functions";
import { useCreateAssignment } from "../assets/mutations";
import { useEmployeeById, useJobById } from "../assets/queries";
import { myColumnRight, myStack } from "../assets/styles";
import { addWindow, closeWindow } from "../assets/windows";
import Create from "../components/forms/Create";
import { FormColumnLeft } from "../components/forms/Parts";
import { MutationProgressIndicator, PushDown } from "../components/misc";
import { ViewContent, ViewFooter } from "./Parts";

const ViewCreateAssignment: React.FC<{
  employeeId: string;
  jobId: string;
  windowId: string;
}> = ({ employeeId, jobId, windowId }) => {
  const job = useJobById(jobId);
  const employee = useEmployeeById(employeeId);
  const [form, setForm] = useState<any>({});
  const [formErrors, setFormErrors] = useState<any>();
  const mutation = useCreateAssignment(employeeId, jobId);

  const change = (updatedFields: any, oldForm: any = form) => {
    setForm({ ...oldForm!, ...updatedFields });
    setFormErrors({
      ...formErrors,
      formErrors: {
        ...formErrors?.formErrors,
        ...returnErrorsToReset(updatedFields),
      },
    });
  };

  useEffect(() => {
    if (mutation.isSuccess) closeWindow(windowId);
    if (mutation.isError) setFormErrors(returnServerError(mutation.error));
  }, [mutation.error, mutation.isError, mutation.isSuccess, windowId]);

  return (
    <Stack>
      <ViewContent>
        <MutationProgressIndicator mutationQuery={mutation} />

        <Create
          form={form}
          formErrors={formErrors?.formErrors}
          onChange={(updatedFields: any) => change(updatedFields, form)}
          type="assignment"
        />

        <PushDown by={20} />

        <Stack grow horizontal styles={myStack}>
          <FormColumnLeft label="Zugehörigkeit" />

          <Stack grow horizontal styles={myColumnRight}>
            <Text styles={myStack}>
              <Label>Job</Label>
              <Link onClick={() => addWindow.Job.view(job.data?.id)}>
                {job.data?.title}
              </Link>
            </Text>

            <Text styles={myStack}>
              <Label>Jugendliche*r</Label>
              <Link onClick={() => addWindow.Profile.view(employee.data?.id)}>
                {employee.data?.firstName + " " + employee.data?.lastName}
              </Link>
            </Text>
          </Stack>
        </Stack>
      </ViewContent>

      <ViewFooter
        onCreate={() => mutation.mutate(form)}
        mutationQuery={mutation}
      />
    </Stack>
  );
};

export default ViewCreateAssignment;
