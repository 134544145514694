import { IDatePickerStrings } from "@fluentui/react";

export const dateStrings: IDatePickerStrings = {
  months: [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember",
  ],

  shortMonths: [
    "Jan",
    "Feb",
    "Mär",
    "Apr",
    "Mai",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Okt",
    "Nov",
    "Dez",
  ],

  days: [
    "Sonntag",
    "Montag",
    "Dienstag",
    "Mittwoch",
    "Donnerstag",
    "Freitag",
    "Samstag",
  ],

  shortDays: ["S", "M", "D", "M", "D", "F", "S"],

  goToToday: "Heute",
  prevMonthAriaLabel: "Vorheriger Monat",
  nextMonthAriaLabel: "Nächster Monat",
  prevYearAriaLabel: "Vorheriges Jahr",
  nextYearAriaLabel: "Nächstes Jahr",
  closeButtonAriaLabel: "Schliessen",
  monthPickerHeaderAriaLabel: "{0}, auswählen, um Jahr zu ändern",
  yearPickerHeaderAriaLabel: "{0}, auswählen, um Monat zu ändern",
};
