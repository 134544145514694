import Swal from "sweetalert2";
import axiosJWT from "./axiosjwt";
const server = process.env.REACT_APP_SERVER;
const jwt = localStorage.jwt;

export const authCheckPassword = async (credentials: any) => {
  try {
    const res: any = await axiosJWT.post(server + "/api/a/login", {
      data: credentials,
    });

    const { jwt, userId, refreshToken } = res.data;
    return { jwt, userId, refreshToken };
  } catch (error: any) {
    if (error.response.status === 403) {
      Swal.fire({
        buttonsStyling: false,
        title: "403",
        text: "Berechtigung fehlt",
      });
    } else {
      Swal.fire({
        buttonsStyling: false,
        title: error?.response?.status,
        text: error?.response?.data?.data?.message || "Unbekannter Fehler",
      });
    }
    return {
      jwt: undefined,
      userId: undefined,
      refreshToken: undefined
    };
  }
};

export const authSendToken = async (credentials: any) => {
  try {
    await axiosJWT.post(server + "/api/a/send-token", { data: credentials });
    return true;
  } catch (error: any) {
    if (error.response.status === 403) {
      Swal.fire({
        buttonsStyling: false,
        title: "403",
        text: "Berechtigung fehlt",
      });
    } else {
      Swal.fire({
        buttonsStyling: false,
        title: error?.response?.status,
        text: error?.response?.data?.data?.message || "Unbekannter Fehler",
      });
    }
    return false;
  }
};

export const authCheckToken = async (credentials: any) => {
  try {
    const res: any = await axiosJWT.post(server + "/api/a/check-token", {
      data: credentials,
    });
    const { jwt, userId, refreshToken } = res.data;
    return { jwt, userId, refreshToken };
  } catch (error: any) {
    if (error.response.status === 403) {
      Swal.fire({
        buttonsStyling: false,
        title: "403",
        text: "Berechtigung fehlt",
      });
    } else {
      Swal.fire({
        buttonsStyling: false,
        title: error?.response?.status,
        text: error?.response?.data?.data?.message || "Unbekannter Fehler",
      });
    }
    return {
      jwt: undefined,
      userId: undefined,
      refreshToken: undefined
    };
  }
};

export const returnSearchResult = async (
  items: any,
  triggerExport: boolean
) => {
  try {
    let res: any = await axiosJWT.post(
      process.env.REACT_APP_SERVER + "/api/a/search",
      { data: { items: items, triggerExport: triggerExport } });

    if (triggerExport) {
      setTimeout(() => {
        return window.open(process.env.REACT_APP_SERVER + "/" + res.data);
      }, 3000);
    }

    if (!triggerExport) {
      return { searchResults: res.data.searchResults, meta: res.data.meta };
    }
  } catch (error: any) {
    if (error.response.status === 403) {
      Swal.fire({
        buttonsStyling: false,
        title: "403",
        text: "Berechtigung fehlt",
      });
    } else {
      Swal.fire({
        buttonsStyling: false,
        title: error?.response?.status,
        text: error?.response?.data?.data?.message || "Unbekannter Fehler",
      });
    }
  }
};
