import { PartialTheme } from "@fluentui/react/lib/Theme";

export const light: PartialTheme = {
  palette: {
    themePrimary: "#FFD23F",
    themeLighterAlt: "#fffdf5",
    themeLighter: "#fff7d6",
    themeLight: "#fff0b3",
    themeTertiary: "#ffe066",
    themeSecondary: "#ffd21f",
    themeDarkAlt: "#e6b800",
    themeDark: "#c29b00",
    themeDarker: "#8f7200",
    neutralLighterAlt: "#f3f3f3",
    neutralLighter: "#efefef",
    neutralLight: "#e5e5e5",
    neutralQuaternaryAlt: "#d6d6d6",
    neutralQuaternary: "#cccccc",
    neutralTertiaryAlt: "#c4c4c4",
    neutralTertiary: "#b3b3b3",
    neutralSecondary: "#9a9a9a",
    neutralPrimaryAlt: "#818181",
    neutralPrimary: "#1f1f1f",
    neutralDark: "#505050",
    black: "#373737",
    white: "#ffffff",
  },
};

export const dark: PartialTheme = {
  palette: {
    themePrimary: "#FFD23F",
    themeLighterAlt: "#fffdf5",
    themeLighter: "#fff7d6",
    themeLight: "#fff0b3",
    themeTertiary: "#ffe066",
    themeSecondary: "#ffd21f",
    themeDarkAlt: "#e6b800",
    themeDark: "#c29b00",
    themeDarker: "#8f7200",
    neutralLighterAlt: "#1e1e1e",
    neutralLighter: "#1d1d1d",
    neutralLight: "#1c1c1c",
    neutralQuaternaryAlt: "#1a1a1a",
    neutralQuaternary: "#191919",
    neutralTertiaryAlt: "#181818",
    neutralTertiary: "#fcfcfc",
    neutralSecondary: "#fcfcfc",
    neutralPrimaryAlt: "#fdfdfd",
    neutralPrimary: "#fafafa",
    neutralDark: "#fefefe",
    black: "#fefefe",
    white: "#1f1f1f",
  },
};
