import { DetailsList, IconButton, SelectionMode, Stack } from "@fluentui/react";
import { formatDistance } from "date-fns";
import { de } from "date-fns/locale";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import Swal from "sweetalert2";
import axiosJWT from "../assets/axiosjwt";
import { Icons } from "../assets/icons";
import { useUnconfirmedRegistrations } from "../assets/queries";
import { addWindow } from "../assets/windows";
import {
    PushDown,
    QueryProgressIndicator
} from "../components/misc";

const ListUnconfirmedRegistrations: React.FC = () => {
    const unconfirmedRegistrations = useUnconfirmedRegistrations();
    const [list, setList] = useState<any>({
        items: [],
        isSorted: {},
    });
    const clear = () => setList({ ...list, items: unconfirmedRegistrations.data });
    const setItems = (items: Array<any>) => setList({ ...list, items: items });
    const queryClient = useQueryClient()

    useEffect(() => {
        if (unconfirmedRegistrations.isSuccess) setItems(unconfirmedRegistrations.data);
        // eslint-disable-next-line
    }, [unconfirmedRegistrations.isSuccess, unconfirmedRegistrations.data]);

    return (
        <Stack>
            {unconfirmedRegistrations.isSuccess && unconfirmedRegistrations.data.length < 1 && <PushDown by={40} />}

            <QueryProgressIndicator query={unconfirmedRegistrations} />

            {unconfirmedRegistrations.isSuccess && unconfirmedRegistrations.data.length > 0 && (
                <Stack>
                    <DetailsList
                        columns={[
                            {
                                isResizable: true,
                                key: "type",
                                minWidth: 100,
                                maxWidth: 100,
                                name: "Typ",
                                onRender: (item: any) => {
                                    if (item.type === "employee") return "Jugendliche*r"
                                    if (item.type === "publicEmployer") return "AG Öffentlich"
                                    if (item.type === "privateEmployer") return "AG Privat"
                                },
                            },
                            {
                                isResizable: true,
                                key: "mobilephone",
                                minWidth: 100,
                                maxWidth: 100,
                                name: "Mobilnummer",
                                onRender: (item: any) => {
                                    return item.mobilephone ? item.mobilephone : "k.A."
                                },
                            },
                            {
                                isResizable: true,
                                key: "createdAt",
                                minWidth: 125,
                                maxWidth: 125,
                                name: "registriert",
                                onRender: (item: any) => {
                                    return formatDistance(new Date(item.createdAt), new Date(), { locale: de, addSuffix: true })
                                },
                            },
                            {
                                isResizable: true,
                                key: "jobfair",
                                minWidth: 100,
                                maxWidth: 100,
                                name: "Standort",
                                onRender: (item: any) => {
                                    return item.jobfair?.text
                                },
                            },
                            {
                                isResizable: true,
                                key: "name",
                                minWidth: 150,
                                maxWidth: 150,
                                name: "Vor/-Nachname",
                                onRender: (item: any) => {
                                    if (item.type !== "publicEmployer" && item.firstName && item.lastName) return item.firstName + " " + item.lastName
                                    if (item.type === "publicEmployer" && item.description) return item.description
                                },
                            },
                            {
                                isResizable: true,
                                key: "address",
                                minWidth: 150,
                                maxWidth: 150,
                                name: "Adresse",
                                onRender: (item: any) => {
                                    return item.place_id ? item.place_id : "k.A."
                                },
                            },
                            // {
                            //     isResizable: true,
                            //     key: "landline",
                            //     minWidth: 125,
                            //     maxWidth: 125,
                            //     name: "Festnetznummer",
                            //     onRender: (item: any) => {
                            //         return item.landline ? item.landline : "k.A."
                            //     },
                            // },
                            // {
                            //     isResizable: true,
                            //     key: "email",
                            //     minWidth: 150,
                            //     maxWidth: 150,
                            //     name: "E-Mail",
                            //     onRender: (item: any) => {
                            //         return item.email ? item.email : "k.A."
                            //     },
                            // },
                            // {
                            //     isResizable: true,
                            //     key: "iban",
                            //     minWidth: 100,
                            //     maxWidth: 100,
                            //     name: "IBAN",
                            //     onRender: (item: any) => {
                            //         if (item.type !== "publicEmployer") return item.iban ? item.iban : "k.A."
                            //         return "n.n."
                            //     },
                            // },
                            // {
                            //     isResizable: true,
                            //     key: "website",
                            //     minWidth: 75,
                            //     maxWidth: 75,
                            //     name: "Webseite",
                            //     onRender: (item: any) => {
                            //         if (item.type === "publicEmployer") return item.website ? item.website : "k.A."
                            //         return "n.n."
                            //     },
                            // },
                            {
                                isResizable: true,
                                key: "status",
                                minWidth: 0,
                                name: "Status",
                                onRender: (item: any) => {
                                    if (item.isConfirmed) return (item?.confirmedAt ? "am " + item?.confirmedAt : "irgendwann") + " von " + (item?.confirmedBy ? item?.confirmedBy : "Anonym ") + "bestätigt"
                                    if (item.isRejected) return (item?.rejectedAt ? "am " + item?.rejectedAt : "irgendwann") + " von " + (item?.rejectedBy ? item?.rejectedBy : "Anonym ") + "abgelehnt"
                                    return "offen"
                                },
                            },
                            {
                                isResizable: true,
                                key: "confirm",
                                minWidth: 100,
                                maxWidth: 100,
                                name: "Bestätigen",
                                onRender: (item: any) => {
                                    if (!item.isConfirmed && !item.isRejected) {
                                        return (
                                            <Stack horizontal>
                                                <IconButton iconProps={{ iconName: Icons.Check }} styles={{ icon: { fontSize: 20 }, root: { height: 15 } }} onClick={() => {
                                                    Swal.fire({
                                                        buttonsStyling: false,
                                                        title: "Sicher?",
                                                        html: "<p>Willst du diese Registrierungsanfrage bestätigen?</p>" +
                                                            "<small>" +
                                                            (item.type === "employee" ? "<b>Jugendliche*r</b>" : item.type === "publicEmployer" ? "<b>AG Öffentlich</>" : "<b>AG Privat</b>") +
                                                            "<br/>" +
                                                            "Name: " + (item.type === "publicEmployer" ? item.description : item.firstName + " " + item.lastName) +
                                                            "<br/>" +
                                                            "Adresse: " + (item.address ? item.address : "k.A.") +
                                                            "</small>",
                                                        cancelButtonText: "Abbrechen",
                                                        showCancelButton: true,
                                                        confirmButtonText: "Ja, bestätigen"
                                                    }).then(async (event: any) => {
                                                        if (event.isConfirmed) {
                                                            try {
                                                                await axiosJWT.post(process.env.REACT_APP_SERVER + "/api/a/confirmRegistration", {
                                                                    data: { id: item.id }
                                                                })
                                                                queryClient.invalidateQueries("unconfirmedRegistrations")
                                                            } catch (error) {
                                                                Swal.fire({
                                                                    buttonsStyling: false,
                                                                    title: "Fehler",
                                                                    text: "Registrierung kann nicht bestätigt werden. Support benachrichtigen."
                                                                })
                                                            }
                                                        }
                                                    })
                                                }} />
                                                <IconButton iconProps={{ iconName: Icons.Cancel }} styles={{ icon: { fontSize: 20 }, root: { height: 15 } }} onClick={() => {
                                                    Swal.fire({
                                                        buttonsStyling: false,
                                                        title: "Sicher?",
                                                        html: "<p>Willst du diese Registrierungsanfrage ablehnen?" +
                                                            "</p><small>Der/dem Benutzer*in wird untenstehender Grund in der App angezeigt.</small>",
                                                        cancelButtonText: "Abbrechen",
                                                        showCancelButton: true,
                                                        confirmButtonText: "Ja, ablehnen",
                                                        input: "textarea",
                                                    }).then(async (event: any) => {
                                                        if (event.isConfirmed) {
                                                            try {
                                                                await axiosJWT.post(process.env.REACT_APP_SERVER + "/api/a/rejectRegistration", {
                                                                    data: { id: item.id, reason: event.value }
                                                                })
                                                                queryClient.invalidateQueries("unconfirmedRegistrations")
                                                            } catch (error) {
                                                                Swal.fire({
                                                                    buttonsStyling: false,
                                                                    title: "Fehler",
                                                                    text: "Registrierung kann nicht abgelehnt werden. Support benachrichtigen."
                                                                })
                                                            }
                                                        }
                                                    })
                                                }} />
                                            </Stack>
                                        )
                                    }
                                    return "bereits bearbeitet"
                                },
                            },
                        ]}
                        isHeaderVisible={true}
                        items={list.items}
                        onItemInvoked={(item) => addWindow.Note.view(item?.id)}
                        selectionMode={SelectionMode.none}
                        styles={{ root: { cursor: "pointer", borderRadius: "0px 0px 16px 16px" } }}
                    />
                </Stack>
            )}
        </Stack>
    );
};

export default ListUnconfirmedRegistrations;
