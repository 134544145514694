import { DefaultButton, IconButton, Link, Text } from "@fluentui/react";
import { format, formatDistance } from "date-fns";
import { de } from "date-fns/locale";
import Markdown from "markdown-to-jsx";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import axiosJWT from "../assets/axiosjwt";
import { returnReadableDuration } from "../assets/functions";
import { Icons } from "../assets/icons";
import { useDeleteDocument } from "../assets/mutations";
import { useMyProfile } from "../assets/queries";
import { addWindow } from "../assets/windows";
import { Tile } from "./Parts";

const Recent: React.FC<{
  isMockUp?: boolean;
  query?: any;
  type: string;
  whoAmI?: any;
}> = ({ isMockUp, query, type, whoAmI }) => {
  const [title, setTitle] = useState("");
  const [actions, setActions] = useState<any>([]);
  const [columns, setColumns] = useState<any>([]);
  const [windowType, setWindowType] = useState<any>([]);
  const myProfile = useMyProfile();
  const deleteDocument = useDeleteDocument();

  useEffect(() => {
    switch (type) {
      case "publicEmployer":
        setActions([
          {
            iconProps: { iconName: Icons.Create },
            onClick: () => !isMockUp && addWindow.Profile.create(type),
          },
        ]);
        setColumns([
          {
            fieldName: "description",
            key: "description",
            minWidth: 0,
            name: "Bezeichnung",
          },
          {
            key: "postal_code",
            minWidth: 0,
            name: "PLZ",
            onRender: (item: any) => {
              return item?.contacts[0].postal_code;
            },
          },
          {
            key: "locality",
            minWidth: 0,
            name: "Ort",
            onRender: (item: any) => {
              return item?.contacts[0].locality;
            },
          },
        ]);
        setTitle("AG Öffentlich");
        setWindowType("profile");
        break;
      case "privateEmployer":
        setActions([
          {
            iconProps: { iconName: Icons.Create },
            onClick: () => !isMockUp && addWindow.Profile.create(type),
          },
        ]);
        setColumns([
          {
            fieldName: "firstName",
            key: "firstName",
            minWidth: 0,
            name: "Vorname",
          },
          {
            fieldName: "lastName",
            key: "lastName",
            minWidth: 0,
            name: "Nachname",
          },
          {
            key: "postal_code",
            minWidth: 0,
            name: "PLZ",
            onRender: (item: any) => {
              return item?.contacts[0].postal_code;
            },
          },
          {
            key: "locality",
            minWidth: 0,
            name: "Ort",
            onRender: (item: any) => {
              return item?.contacts[0].locality;
            },
          },
        ]);
        setTitle("AG Privat");
        setWindowType("profile");
        break;
      case "job":
        setActions([]);
        setColumns([
          {
            fieldName: "title",
            key: "title",
            minWidth: 0,
            name: "Titel",
          },
          {
            key: "employer",
            minWidth: 0,
            name: "Auftraggeber*in",
            onRender: (item: any) => {
              if (item?.employer?.description) {
                return (
                  <Link
                    onClick={() => addWindow.Profile.view(item?.employer?.id)}
                  >
                    {item?.employer?.description}
                  </Link>
                );
              }
              return (
                <Link onClick={() => addWindow.Profile.view(item?.employer?.id)}>
                  {item?.employer?.firstName + " " + item?.employer?.lastName}
                </Link>
              );
            },
          },
          {
            fieldName: "jobfair",
            key: "jobfair",
            minWidth: 0,
            name: "zuständig",
            onRender: (item: any) => {
              return item?.jobfairs.map((jf: any) => {
                return jf.text + " "
              })
            }
          },
        ]);
        setTitle("Jobs");
        setWindowType("job");
        break;
      case "employee":
        setActions([
          {
            iconProps: { iconName: Icons.Create },
            onClick: () => !isMockUp && addWindow.Profile.create(type),
          },
        ]);
        setColumns([
          {
            fieldName: "firstName",
            key: "firstName",
            minWidth: 0,
            name: "Vorname",
          },
          {
            fieldName: "lastName",
            key: "lastName",
            minWidth: 0,
            name: "Nachname",
          },
          {
            key: "postal_code",
            minWidth: 0,
            name: "PLZ",
            onRender: (item: any) => {
              return item?.contacts[0]?.postal_code;
            },
          },
          {
            key: "locality",
            minWidth: 0,
            name: "Ort",
            onRender: (item: any) => {
              return item?.contacts[0]?.locality;
            },
          },
        ]);
        setTitle("Jugendliche");
        setWindowType("profile");
        break;
      case "lastOpened":
        setActions([]);
        // setColumns([
        //   {
        //     fieldName: "description",
        //     key: "description",
        //     minWidth: 0,
        //     name: "Beschreibung",
        //   },
        // ]);
        // setWindowType("lastOpened")
        setTitle("zuletzt aufgerufen");
        break;
      case "workLog":
        setActions([]);
        setColumns([
          {
            key: "date",
            minWidth: 0,
            name: "Datum",
            onRender: (item: any) => {
              return format(new Date(item?.date), "dd.MM.yyyy");
            },
          },
          {
            key: "name",
            minWidth: 0,
            name: "Jugendliche*r",
            onRender: (item: any) => {
              return item?.employee?.firstName + " " + item?.employee?.lastName;
            },
          },
          {
            fieldName: "duration",
            isResizable: true,
            key: "duration",
            minWidth: 0,
            name: "Dauer",
            onRender: (item: any) => returnReadableDuration(item?.minutes),
          },
          {
            key: "title",
            minWidth: 0,
            name: "Job",
            onRender: (item: any) => {
              return item?.job?.title;
            },
          },
        ]);
        setTitle("Arbeitseinsätze");
        setWindowType("workLog");
        break;
      case "invoice":
        setActions([]);
        setColumns([
          {
            key: "amount",
            minWidth: 0,
            maxWidth: 40,
            name: "Betrag",
            onRender: (item: any) => "CHF " + item?.smallinvoice_invoice_data?.item?.total,
          },
          {
            key: "status",
            minWidth: 0,

            name: "Status",
            onRender: (item: any) => {
              if (item?.smallinvoice_invoice_data?.item?.status === "P")
                return (
                  <Text style={{ color: "green", fontSize: 12 }}>
                    bezahlt
                  </Text>
                );
              if (item?.smallinvoice_invoice_data?.item?.status === "C")
                return (
                  <Text style={{ color: "red", fontSize: 12 }}>
                    storniert
                  </Text>
                );
              if (item?.smallinvoice_invoice_data?.item?.status === "S")
                return (
                  <Text style={{ color: "orange", fontSize: 12 }}>
                    gesendet
                  </Text>
                );
              if (item?.smallinvoice_invoice_data?.item?.status === "D")
                return (
                  <Text style={{ color: "red", fontSize: 12 }}>
                    gelöscht
                  </Text>
                );
              if (item?.smallinvoice_invoice_data?.item?.status === "DR")
                return (
                  <Text style={{ fontSize: 12 }}>
                    Entwurf
                  </Text>
                );
              return (
                <Text style={{ color: "orange", fontSize: 12 }}>
                  Status unklar
                </Text>
              );
            },
          },
          {
            key: "employer",
            minWidth: 0,

            name: "Auftraggeber*in",
            onRender: (item: any) => {
              if (item?.employer?.type === "publicEmployer") {
                return (
                  <Link
                    onClick={() => addWindow.Profile.view(item?.employer?.id)}
                  >
                    {item?.employer?.description}
                  </Link>
                );
              }
              if (item?.employer?.type !== "publicEmployer") {
                return (
                  <Link
                    onClick={() => addWindow.Profile.view(item?.employer?.id)}
                  >
                    {item?.employer?.firstName + " " + item?.employer?.lastName}
                  </Link>
                );
              }
            },
          },
          {
            key: "employee",
            minWidth: 0,

            name: "Jugendliche*r",
            onRender: (item: any) => {
              return (
                <Link onClick={() => addWindow.Profile.view(item?.employee?.id)}>
                  {item?.employee?.firstName + " " + item?.employee?.lastName}
                </Link>
              );
            },
          },
          {
            key: "job",
            minWidth: 0,

            name: "Job",
            onRender: (item: any) => {
              return (
                <Link onClick={() => addWindow.Job.view(item?.job?.id)}>
                  {item?.job?.title}
                </Link>
              );
            },
          },
        ]);
        setTitle("Rechnungen");
        setWindowType("invoice");
        break;
      case "personalNote":
        setActions([
          {
            key: "create",
            iconProps: { iconName: Icons.Create },
            onClick: () =>
              addWindow.Note.add(
                myProfile.data?.id,
                myProfile.data?.id,
                "profile",
                true
              ),
          },
        ]);
        setColumns([
          {
            key: "type",
            minWidth: 0,
            name: "Typ",
            onRender: (item: any) => {
              switch (item?.type) {
                case "default":
                  return (
                    <Text style={{ fontSize: 12 }}>normal</Text>
                  );
                case "critical":
                  return (
                    <Text style={{ color: "orange", fontSize: 12 }}>
                      kritisch
                    </Text>
                  );
                case "danger":
                  return (
                    <Text style={{ color: "red", fontSize: 12 }}>
                      alarmierend
                    </Text>
                  );
                case "todo":
                  return (
                    <Text style={{ color: "green", fontSize: 12 }}>
                      zu erledigen
                    </Text>
                  );
                case "ticket":
                  return (
                    <Text style={{ color: "purple", fontSize: 12 }}>
                      Support
                    </Text>
                  );

                default:
                  break;
              }
            },
          },
          {
            fieldName: "title",
            key: "title",
            minWidth: 0,
            name: "Titel",
          },
          {
            fieldName: "text",
            key: "text",
            minWidth: 300,
            name: "Auszug",
          },

        ]);
        setTitle("Eigene Notizen");
        setWindowType("note");
        break;
      case "todoNotes":
        setColumns([
          {
            key: "title",
            minWidth: 0,
            name: "Titel",
            onRender: (item: any) => <Text>{item?.title}</Text>,
          },
          {
            key: "text",
            minWidth: 0,
            name: "Auszug",
            onRender: (item: any) => <Markdown>{item?.text}</Markdown>,
          },
          // {
          //   key: "createdByProfile",
          //   minWidth: 0,
          //   name: "erstellt von",
          //   onRender: (item: any) => {
          //     return (
          //       <Link
          //         onClick={() => {
          //           Swal.fire({
          //             buttonsStyling: false,
          //             title: "Noch nicht verfügbar",
          //             text:
          //               "Diese Funktion ist in Planung." ||
          //               "Unbekannter Fehler",
          //           });
          //         }}
          //       >
          //         {item?.createdByProfile?.firstName +
          //           " " +
          //           item?.createdByProfile?.lastName}
          //       </Link>
          //     );
          //   },
          // },
          {
            key: "done",
            minWidth: 0,
            name: "erledigt?",
            onRender: (item: any) => {
              if (item?.isDone) return <Text style={{ color: "green" }}>erledigt</Text>
            },
          },
        ]);
        setTitle("Zu erledigen");
        setWindowType("note");
        break;
      case "internalDocuments":
        setColumns([
          {
            fieldName: "name",
            key: "name",
            minWidth: 0,
            name: "Name",
          },
          {
            isResizable: true,
            key: "file",
            minWidth: 0,
            name: "Datei",
            onRender: (item: any) => {
              return (
                <Link href={item?.file?.url} target="_blank">
                  Öffnen
                </Link>
              );
            },
          },
          {
            isResizable: true,
            key: "delete",
            minWidth: 0,
            name: "Löschen",
            onRender: (item: any) => {
              return (
                <DefaultButton
                  styles={{ root: { borderRadius: 16 } }}
                  onClick={async () => {
                    Swal.fire({
                      buttonsStyling: false,
                      confirmButtonText: "Löschen",
                      focusCancel: true,
                      cancelButtonText: "Abbrechen",
                      showCancelButton: true,
                      title: "Achtung",
                      html:
                        "Das Dokument kann NICHT mehr wiederhergestellt werden.",
                      reverseButtons: true,
                    }).then(async (event: any) => {
                      if (event.isConfirmed) await deleteDocument.mutateAsync(item?.id)
                    });
                  }}
                >
                  Löschen
                </DefaultButton>
              );
            },
          },
        ]);
        setTitle("Interne Dokumente");
        setWindowType("document");
        break;
      case "unassignedJobs":
        setColumns([
          {
            fieldName: "since",
            key: "since",
            minWidth: 0,
            name: "seit",
            onRender: (item: any) => {
              return formatDistance(new Date(item?.createdAt), new Date(), {
                locale: de
              })
            }
          },
          {
            fieldName: "title",
            key: "title",
            minWidth: 0,
            name: "Titel",
          },
          {
            key: "employer",
            minWidth: 0,
            name: "Auftraggeber*in",
            onRender: (item: any) => {
              if (item?.employer?.description) {
                return (
                  <Link
                    onClick={() => addWindow.Profile.view(item?.employer?.id)}
                  >
                    {item?.employer?.description}
                  </Link>
                );
              }
              return (
                <Link onClick={() => addWindow.Profile.view(item?.employer?.id)}>
                  {item?.employer?.firstName + " " + item?.employer?.lastName}
                </Link>
              );
            },
          },
          {
            fieldName: "jobfair",
            key: "jobfair",
            minWidth: 0,
            name: "zuständig",
            onRender: (item: any) => {
              return item?.jobfairs.map((jf: any) => {
                return jf.text + " "
              })
            }
          },
        ]);
        setTitle("nicht zugeteilte Jobs");
        setWindowType("job");
        break;
      case "receipt":
        setColumns([
          {
            key: "paidDate",
            minWidth: 0,
            name: "Datum",
            onRender: (item: any) => {
              return format(new Date(item?.paidDate), "dd. MMMM yyy", { locale: de })
            }
          },
          {
            key: "amount",
            minWidth: 0,
            name: "Betrag",
            onRender: (item: any) => {
              return item?.amount / 100 + " CHF"
            }
          },
          {
            key: "employee",
            minWidth: 0,
            name: "Jugendliche*r",
            onRender: (item: any) => {
              return (
                <Link onClick={() => addWindow.Profile.view(item?.employee.id)}>{item?.employee.firstName + " " + item?.employee.lastName}</Link>
              )
            }
          },
          {
            key: "generate",
            minWidth: 0,
            name: "",
            onRender: (item: any) => {
              return <IconButton styles={{ root: { borderRadius: 16, } }} iconProps={{ iconName: "Download" }} onClick={() => getReceiptAsPDF(item)} />
            }
          },
        ]);
        setTitle("Quittungen");
        setWindowType("receipt");
        break;
      case "tags":
        setColumns([
          {
            key: "name",
            minWidth: 1500,
            maxWidth: 1500,
            name: "Bezeichnung",
            onRender: (item: any) => {
              return item?.name
            }
          },
        ]);
        setTitle("Tags");
        setWindowType("tag");
        break;

      default:
        break;
    }
  }, []);

  const getReceiptAsPDF = async (item: any) => {
    let res = await axiosJWT.get(
      process.env.REACT_APP_SERVER +
      "/api/a/get-receipt-by-id/" +
      item?.id
    );

    const link: any = res.data;

    window.open(link, "blank");
  }

  return (
    <Tile
      isMockUp={isMockUp}
      actions={actions}
      columns={columns}
      onItemInvoked={(selectedItem: any) => {
        let lO = JSON.parse(localStorage.lastOpened)
        switch (windowType) {
          case "profile":
            for (let i = 0; i < lO.length; i++) {
              const entry = lO[i];
              if (entry.modelType === "profile" && entry.modelId == selectedItem.id) return addWindow.Profile.view(selectedItem.id)
            }
            localStorage.lastOpened = JSON.stringify([...JSON.parse(localStorage.lastOpened), {
              description: selectedItem?.description,
              modelId: selectedItem?.id,
              modelType: "profile"
            }])
            return addWindow.Profile.view(selectedItem.id);
          case "job":
            for (let i = 0; i < lO.length; i++) {
              const entry = lO[i];
              if (entry.modelType === "job" && entry.modelId == selectedItem.id) return addWindow.Job.view(selectedItem.id)
            }
            localStorage.lastOpened = JSON.stringify([...JSON.parse(localStorage.lastOpened), {
              description: selectedItem?.description,
              modelId: selectedItem?.id,
              modelType: "job"
            }])
            return addWindow.Job.view(selectedItem.id);
          case "workLog":
            for (let i = 0; i < lO.length; i++) {
              const entry = lO[i];
              if (entry.modelType === "workLog" && entry.modelId == selectedItem.id) return addWindow.WorkLog.view(selectedItem.id)
            }
            localStorage.lastOpened = JSON.stringify([...JSON.parse(localStorage.lastOpened), {
              description: selectedItem?.description,
              modelId: selectedItem?.id,
              modelType: "workLog"
            }])
            return addWindow.WorkLog.view(selectedItem.id);
          case "invoice":
            for (let i = 0; i < lO.length; i++) {
              const entry = lO[i];
              if (entry.modelType === "invoice" && entry.modelId == selectedItem.id) return addWindow.Invoice.view(selectedItem?.invoiceId)
            }
            localStorage.lastOpened = JSON.stringify([...JSON.parse(localStorage.lastOpened), {
              description: selectedItem?.description,
              modelId: selectedItem?.id,
              modelType: "invoice"
            }])
            return addWindow.Invoice.view(selectedItem?.invoiceId);
          case "note":
            for (let i = 0; i < lO.length; i++) {
              const entry = lO[i];
              if (entry.modelType === "note" && entry.modelId == selectedItem.id) return addWindow.Note.view(selectedItem.id)
            }
            localStorage.lastOpened = JSON.stringify([...JSON.parse(localStorage.lastOpened), {
              description: selectedItem?.description,
              modelId: selectedItem?.id,
              modelType: "note"
            }])
            return addWindow.Note.view(selectedItem.id);
          case "document":
            for (let i = 0; i < lO.length; i++) {
              const entry = lO[i];
              if (entry.modelType === "document" && entry.modelId == selectedItem.id) return addWindow.Document.view(selectedItem.id)
            }
            localStorage.lastOpened = JSON.stringify([...JSON.parse(localStorage.lastOpened), {
              description: selectedItem?.description,
              modelId: selectedItem?.id,
              modelType: "document"
            }])
            return addWindow.Document.view(selectedItem.id);
          case "receipt":
            for (let i = 0; i < lO.length; i++) {
              const entry = lO[i];
              if (entry.modelType === "receipt" && entry.modelId == selectedItem.id) return getReceiptAsPDF(selectedItem)
            }
            localStorage.lastOpened = JSON.stringify([...JSON.parse(localStorage.lastOpened), {
              description: selectedItem?.description,
              modelId: selectedItem?.id,
              modelType: "receipt"
            }])
            return getReceiptAsPDF(selectedItem)
          case "tag":
            return addWindow.Search(selectedItem?.name)
          case "lastOpened":
            if (selectedItem?.modelType === "profile") {
              return addWindow.Profile.view(selectedItem?.modelId)
            }
            if (selectedItem?.modelType === "job") {
              return addWindow.Job.view(selectedItem?.modelId)
            }
            if (selectedItem?.modelType === "workLog") {
              return addWindow.WorkLog.view(selectedItem?.modelId)
            }
            if (selectedItem?.modelType === "invoice") {
              return addWindow.Invoice.view(selectedItem?.modelId)
            }
            if (selectedItem?.modelType === "note") {
              return addWindow.Note.view(selectedItem?.modelId)
            }
            if (selectedItem?.modelType === "document") {
              return addWindow.Document.view(selectedItem?.modelId)
            }
            if (selectedItem?.modelType === "receipt") {
              return getReceiptAsPDF(selectedItem)
            }
            return false
          default:
            break;
        }
      }}
      title={title}
      query={query}
      whoAmI={whoAmI}
    />
  );
};

export default Recent;
