import { css } from "@emotion/css";
import {
  DefaultButton,
  IBasePicker,
  IBasePickerSuggestionsProps,
  ITag,
  Label,
  Stack,
  TagPicker,
  TextField,
  useTheme
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import Dropzone from 'dropzone';
import { useCallback, useEffect, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import Swal from "sweetalert2";
import axiosJWT from "../assets/axiosjwt";
import {
  useCreateNewTagDocument,
  useUpdateDocument,
  useUpdateTagsByDocument
} from "../assets/mutations";
import { useTags, useTagsByDocument } from "../assets/queries";
import { myTokens } from "../assets/styles";
import { closeWindow } from "../assets/windows";
import { MutationProgressIndicator, PushDown } from "../components/misc";
import { queryClient } from "../imports";
import { ViewContent } from "./Parts";
import './styles.css';

export const UploadDocument: React.FC<{
  modelId: string;
  modelType: string;
  windowId: string;
}> = ({ modelId, modelType, windowId }) => {
  const uploader = useRef<any>();
  const [file, setFile] = useState<any>();
  const [documentName, setDocumentName] = useState<any>();
  const [isLoaded, setIsLoaded] = useState(true);
  const theme = useTheme()

  useEffect(() => {
    if (file?.name) setDocumentName(file.name);
  }, [file]);

  useEffect(() => {
    if (document.getElementById("my-form")) {
      let myDropzone = new Dropzone("#my-form", {
        paramName: "file",
        maxFilesize: 10,
        dictDefaultMessage: "Fuck",
      });

      myDropzone.on("addedfile", async (file) => {
        setFile(file)
      });
    }
  }, [])

  return (
    <Stack>
      <ViewContent>
        <Stack styles={{ root: { background: theme.palette.white, padding: 20, borderRadius: 16 } }}>
          {!file && <form action="/" onSubmit={(e) => e.preventDefault()} id="my-form" className="dropzone" style={{ border: "3px dotted lightgrey", height: 300 }}>
            <p className="dragndrop-text">
              Datei reinziehen oder klicken
            </p>
            <input
              id="file"
              onChange={async (e: any) => {
                setFile(e.target.files[0]);
              }}
              ref={uploader}
              style={{ display: "none" }}
              type="file"
            />
          </form>}

          <Stack tokens={myTokens}>
            {modelType !== "internal" && <PushDown by={20} />}

            {file && (
              <Stack>
                <Label>Name des Dokumentes überschreiben</Label>
                <TextField
                  onChange={(event, newValue) => setDocumentName(newValue)}
                  placeholder={file?.name}
                />
              </Stack>
            )}

            <Stack horizontal>
              {file && (
                <DefaultButton
                  styles={{ root: { borderRadius: 16 } }}
                  disabled={!isLoaded}
                  onClick={async () => {
                    try {
                      setIsLoaded(false);

                      const res: any = await axiosJWT.post(
                        process.env.REACT_APP_SERVER + "/api/a/prepare-document",
                        {
                          data: {
                            name: documentName,
                            modelId: modelId,
                            modelType: modelType,
                          },
                        },
                      );

                      let form = new FormData();
                      form.append("files", file);
                      form.append("ref", "api::document.document");
                      form.append("refId", res.data);
                      form.append("field", "file");

                      const upload: any = await axiosJWT.post(
                        process.env.REACT_APP_SERVER + "/api/upload",
                        form,
                      );

                      await axiosJWT.post(
                        process.env.REACT_APP_SERVER + "/api/a/post-file-id",
                        {
                          data: {
                            documentId: res.data,
                            fileId: upload.data[0].id
                          },
                        },
                      );

                      await queryClient.invalidateQueries("documents")
                      await queryClient.invalidateQueries("internalDocuments")

                      setIsLoaded(true);
                      closeWindow(windowId);
                    } catch (error) {
                      console.log(error);
                      Swal.fire({
                        buttonsStyling: false,
                        title: "Fehler",
                        text: "Wende dich an den Support.",
                      });
                    }
                  }}
                >
                  {isLoaded && "Dokument hochladen"}
                  {!isLoaded && "Bitte warten ..."}
                </DefaultButton>
              )}
            </Stack>
          </Stack>
        </Stack>
      </ViewContent>
    </Stack >
  );
};

export const ViewDocument: React.FC<{
  documentId: string;
  windowId: string;
}> = ({ documentId, windowId }) => {
  const tags = useTags();
  const updateDocument = useUpdateDocument(documentId);
  const updateTags = useUpdateTagsByDocument(documentId);
  const createTag = useCreateNewTagDocument(documentId);
  const queryClient = useQueryClient();
  const documentTags = useTagsByDocument(documentId);

  const filterSelectedTags: any = (
    filterText: string,
    tagList: ITag[]
  ): ITag[] => {
    let results = [];

    if (filterText) {
      results = tags.data?.filter(
        (tag: any) =>
          tag.name.toLowerCase().indexOf(filterText.toLowerCase()) === 0
      );
    }

    return filterText
      ? results.length === 0
        ? [
          {
            key: "new",
            name: filterText + " erstellen",
            realName: filterText,
          },
        ]
        : tags.data?.filter(
          (tag: any) =>
            tag.name.toLowerCase().indexOf(filterText.toLowerCase()) === 0
        )
      : [];
  };

  const picker = useRef<IBasePicker<ITag>>(null);

  const listContainsTagList = (tag: ITag, tagList?: ITag[]) => {
    if (!tagList || !tagList.length || tagList.length === 0) {
      return false;
    }
    return tagList.some((compareTag) => compareTag.key === tag.key);
  };

  const onItemSelected: any = useCallback((item: any): ITag | null => {
    if (item.key === "new") {
      createTag.mutate({ name: item?.realName });
      queryClient.invalidateQueries("documents");
      item.name = item?.realName;
      return item;
    }

    if (picker.current && listContainsTagList(item, picker.current.items)) {
      return null;
    }
    return item;
  }, []);

  const getTextFromItem = (item: any) => item?.name;

  const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
    suggestionsHeaderText: "Vorschläge",
    noResultsFoundText: "Keine Tags gefunden",
  };

  const [tagPicker, { toggle: toggleIsTagPickerVisible }] = useBoolean(false);

  return (
    <Stack>
      <ViewContent>
        <MutationProgressIndicator
          mutationQuery={updateDocument}
          
        />

        <TagPicker
          className={css`
            .ms-BasePicker-text {
              border: 0 !important;
            }
            border-bottom: 3px solid lightgrey !important;
          `}
          componentRef={picker}
          onResolveSuggestions={filterSelectedTags}
          onItemSelected={onItemSelected}
          getTextFromItem={getTextFromItem}
          pickerSuggestionsProps={pickerSuggestionsProps}
          disabled={tagPicker}
          onChange={(items) => {
            updateTags.mutateAsync({ tags: items });
          }}
          defaultSelectedItems={documentTags.data}
        />
      </ViewContent>
    </Stack>
  );
};
