import { Image, ImageFit } from "@fluentui/react";

export interface IIcons {
  Account: string;
  Accounting: string;
  Actions: string;
  Activate: string;
  App: string;
  Archive: string;
  Assignment: string;
  Blocked: string;
  Bug: string;
  Camera: string;
  Cancel: string;
  Check: string;
  Comment: string;
  ConnectionError: string;
  Contact: string;
  Create: string;
  Credit: string;
  Delete: string;
  Documents: string;
  Download: string;
  Edit: string;
  EditForm: string;
  Employee: string;
  Employer: string;
  Export: string;
  File: string;
  Filter: string;
  Gender: {
    F: {
      dark: any;
      default: any;
    };
    M: {
      dark: any;
      default: any;
    };
    unknown: string;
  };
  GoTo: string;
  Help: string;
  History: string;
  Important: string;
  Invoice: string;
  Job: string;
  Jobfair: string;
  License: string;
  Link: string;
  Log: string;
  Lock: string;
  Maximize: string;
  Menu: string;
  Note: string;
  Organisation: string;
  PrivateEmployer: string;
  Profile: string;
  ProfileSettings: string;
  PublicEmployer: string;
  Rating: string;
  Receipt: string;
  Reload: string;
  Read: string;
  Save: string;
  Search: string;
  Settings: string;
  SignOut: string;
  Staff: string;
  Unlock: string;
  Task: string;
  Warning: string;
  WorkLog: string;
  Statistics: string;
}

export const Icons: IIcons = {
  Account: "AccountBrowser",
  Statistics: "Calculator",
  Accounting: "Bank",
  Actions: "AccountActivity",
  Activate: "Rocket",
  Assignment: "DateTime",
  App: "CellPhone",
  Archive: "Archive",
  Blocked: "Blocked",
  Bug: "Bug",
  Camera: "Camera",
  Cancel: "ErrorBadge",
  Check: "CompletedSolid",
  Comment: "Comment",
  ConnectionError: "StatusCircleBlock",
  Contact: "Contact",
  Create: "Add",
  Credit: "Money",
  Delete: "Delete",
  Documents: "Documentation",
  Download: "CloudDownload",
  Edit: "Edit",
  EditForm: "EditContact",
  Employee: "Family",
  Employer: "AccountManagement",
  Export: "Export",
  File: "KnowledgeArticle",
  Filter: "Filter",
  Gender: {
    F: {
      dark: (
        <Image
          imageFit={ImageFit.contain}
          src="https://img.icons8.com/fluent-systems-regular/16/ffffff/F.png"
          styles={{ root: { height: 16, width: 16 } }}
        />
      ),
      default: (
        <Image
          imageFit={ImageFit.contain}
          src="https://img.icons8.com/fluent-systems-regular/16/000000/F.png"
          styles={{ root: { height: 16, width: 16 } }}
        />
      ),
    },
    M: {
      dark: (
        <Image
          imageFit={ImageFit.contain}
          src="https://img.icons8.com/fluent-systems-regular/16/ffffff/M.png"
          styles={{ root: { height: 16, width: 16 } }}
        />
      ),
      default: (
        <Image
          imageFit={ImageFit.contain}
          src="https://img.icons8.com/fluent-systems-regular/16/000000/M.png"
          styles={{ root: { height: 16, width: 16 } }}
        />
      ),
    },
    unknown: "Help",
  },
  GoTo: "OpenInNewWindow",
  Help: "Help",
  History: "Timeline",
  Important: "Important",
  Invoice: "Money",
  Job: "Work",
  Jobfair: "POI",
  License: "Certificate",
  Link: "Link",
  Log: "ServerProcesses",
  Lock: "Lock",
  Maximize: "ScaleUp",
  Menu: "CollapseMenu",
  Note: "DietPlanNotebook",
  Organisation: "Org",
  PrivateEmployer: "Contact",
  Profile: "Contact",
  ProfileSettings: "PlayerSettings",
  PublicEmployer: "WorkForceManagement",
  Rating: "FavoriteStar",
  Receipt: "InsertSignatureLine",
  Reload: "DatabaseSync",
  Read: "Glasses",
  Save: "CheckMark",
  Search: "Search",
  Settings: "Settings",
  SignOut: "SignOut",
  Staff: "Group",
  Unlock: "Unlock",
  Task: "WorkItem",
  Warning: "Warning",
  WorkLog: "StopWatch",
};
