import { DetailsList, SelectionMode, Stack } from "@fluentui/react";
import { useEffect, useState } from "react";
import { useJobsByEmployer } from "../assets/queries";
import { addWindow } from "../assets/windows";
import {
  PullUp,
  QueryProgressIndicator
} from "../components/misc";

const ListJobsByEmployer: React.FC<{
  employerId: string;
}> = ({ employerId }) => {
  const jobs = useJobsByEmployer(employerId);
  const [list, setList] = useState<any>({});
  const clear = () => setList({ ...list, items: jobs.data });
  const setItems = (items: Array<any>) => setList({ ...list, items: items });
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (jobs.data?.length > 0) setIsReady(true);
    if (jobs.isSuccess) setItems(jobs.data);
    // eslint-disable-next-line
  }, [jobs.isSuccess, jobs.data]);

  return (
    <Stack>
      <QueryProgressIndicator  query={jobs} />
      {isReady && (
        <Stack>
          <PullUp by={20} />

          <DetailsList
            columns={[
              {
                fieldName: "title",
                key: "title",
                minWidth: 0,
                name: "Titel",
              },
              {
                fieldName: "jobfair",
                key: "jobfair",
                minWidth: 0,
                name: "zuständig",
                onRender: (item: any) => {
                  return item?.jobfairs.map((jf: any) => {
                    return jf.text + " "
                  })
                }
              },
              // {
              //   key: "nextAssignment",
              //   minWidth: 0,
              //   name: "Nächster Termin",
              //   onRender: () => "BETA"
              // },
            ]}
            isHeaderVisible={true}
            items={list.items}
            onItemInvoked={(item) => addWindow.Job.view(item!.id)}
            selectionMode={SelectionMode.none}
            styles={{ root: { cursor: "pointer", borderRadius: "0px 0px 16px 16px" } }}
          />
        </Stack>
      )}
    </Stack>
  );
};

export default ListJobsByEmployer;
