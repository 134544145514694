import { DefaultButton, Stack } from "@fluentui/react";
import axiosJWT from "../assets/axiosjwt";
import { ViewContent } from "./Parts";

const ViewCreateExport: React.FC<{
  windowId: string;
}> = ({ windowId }) => {
  return (
    <Stack>
      <ViewContent>
        {/* <Stack>
          <DefaultButton
            disabled
            onClick={async () => {
              const res: any = await axiosJWT.post(
                process.env.REACT_APP_SERVER + "/api/a/export",
                { data: { type: "publicEmployers" } },
              );

              window.open(process.env.REACT_APP_SERVER + "/" + res.data);
            }}
          >
            Öffentliche Auftraggeber*innen
          </DefaultButton>
        </Stack> */}

        {/* <Stack>
          <DefaultButton
            disabled
            onClick={async () => {
              const res: any = await axiosJWT.post(
                process.env.REACT_APP_SERVER + "/api/a/export",
                { data: { type: "privateEmployers" } },
              );

              window.open(process.env.REACT_APP_SERVER + "/" + res.data);
            }}
          >
            Private Auftraggeber*innen
          </DefaultButton>
        </Stack> */}

        {/* <Stack>
          <DefaultButton
            onClick={async () => {
              const res: any = await axiosJWT.post(
                process.env.REACT_APP_SERVER + "/api/a/export",
                { data: { type: "norman" } },
              );

              window.open(process.env.REACT_APP_SERVER + "/" + res.data);
            }}
          >
            Export Köniz gemäss Norman
          </DefaultButton>
        </Stack> */}

        <Stack>
          <DefaultButton
            styles={{ root: { borderRadius: 16 } }}
            onClick={async () => {
              const res: any = await axiosJWT.post(
                process.env.REACT_APP_SERVER + "/api/a/export",
                { data: { type: "employees" } }
              );

              window.open(process.env.REACT_APP_SERVER + "/" + res.data);
            }}
          >
            Jugendliche
          </DefaultButton>
        </Stack>

        {/* <Stack>
          <DefaultButton
            disabled
            onClick={async () => {
              const res: any = await axiosJWT.post(
                process.env.REACT_APP_SERVER + "/api/a/export",
                { data: { type: "jobs" } },
              );

              window.open(process.env.REACT_APP_SERVER + "/" + res.data);
            }}
          >
            Jobs
          </DefaultButton>
        </Stack> */}

        {/* <Stack>
          <DefaultButton
            styles={{ root: { borderRadius: 16 } }}
            onClick={async () => {
              const res: any = await axiosJWT.post(
                process.env.REACT_APP_SERVER + "/api/a/export",
                { data: { type: "workLogs" } }
              );

              window.open(process.env.REACT_APP_SERVER + "/" + res.data);
            }}
          >
            Arbeitseinsätze
          </DefaultButton>
        </Stack> */}

        <Stack>
          <DefaultButton
            styles={{ root: { borderRadius: 16 } }}
            onClick={async () => {
              const res: any = await axiosJWT.post(
                process.env.REACT_APP_SERVER + "/api/a/export",
                { data: { type: "report" } }
              );

              window.open(process.env.REACT_APP_SERVER + "/" + res.data);
            }}
          >
            Report 2022
          </DefaultButton>
        </Stack>

        {/* <Stack>
          <DefaultButton
            disabled
            onClick={async () => {
              const res: any = await axiosJWT.post(
                process.env.REACT_APP_SERVER + "/api/a/export",
                { data: { type: "invoices" } },
              );

              window.open(process.env.REACT_APP_SERVER + "/" + res.data);
            }}
          >
            Rechnungen
          </DefaultButton>
        </Stack> */}
      </ViewContent>
    </Stack>
  );
};

export default ViewCreateExport;
