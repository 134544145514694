import { css } from "@emotion/css";
import {
  DefaultButton,
  IconButton,
  Label,
  Link,
  Modal,
  Pivot,
  PivotItem,
  Position,
  SpinButton,
  Stack,
  Text,
  TextField,
  Toggle,
  useTheme,
} from "@fluentui/react";
import Markdown from "markdown-to-jsx";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { returnErrorsToReset, returnServerError } from "../assets/functions";
import { Icons } from "../assets/icons";
import {
  useAddJob,
  useArchiveJob,
  useDeleteJob,
  useUpdateJob,
} from "../assets/mutations";
import {
  useCategories,
  useFrequencies,
  useInvoicesByJob,
  useJobById,
  useJobfairs,
  useMyProfile,
  useProfile,
  useReceiptsByJob,
  useWorkLogsByJob,
} from "../assets/queries";
import { useClientStore } from "../assets/stores";
import { myStack, myTextField, myTokens } from "../assets/styles";
import { addWindow, closeWindow } from "../assets/windows";
import Add from "../components/forms/Add";
import {
  FieldDateUpdate,
  FieldDropdownUpdate,
  FieldDropdownUpdateMultiSelect,
  FieldStringUpdate,
} from "../components/forms/Parts";
import {
  InternalMeta,
  MutationProgressIndicator,
  PullUp,
  PushDown,
  QueryProgressIndicator,
} from "../components/misc";
import ListEmployeesByJob from "../lists/ListEmployeesByJob";
import ListInvoices from "../lists/ListInvoices";
import ListNotesByType from "../lists/ListNotesByType";
import ListReceipts from "../lists/ListReceipts";
import ListWorkLogs from "../lists/ListWorkLogs";
import PivotSubMenu, { ViewContent, ViewFooter } from "./Parts";
import ViewLinkEmployee from "./ViewLinkEmployee";
const { getState, setState } = useClientStore;

export const ViewAddJob: React.FC<{
  profileId: string;
  windowId: string;
}> = ({ profileId, windowId }) => {
  const [form, setForm] = useState<any>({});
  const [formErrors, setFormErrors] = useState<any>({});
  const employer = useProfile(profileId);
  const mutation = useAddJob(profileId);

  const change = (updatedFields: any, oldForm: any = form) => {
    setForm({ ...oldForm!, ...updatedFields });
    setFormErrors({
      ...formErrors,
      formErrors: {
        ...formErrors?.formErrors,
        ...returnErrorsToReset(updatedFields),
      },
    });
  };

  useEffect(() => {
    if (mutation.isSuccess) closeWindow(windowId);
    if (mutation.isError) setFormErrors(returnServerError(mutation.error));
  }, [mutation.error, mutation.isError, mutation.isSuccess, windowId]);

  const theme = useTheme();

  return (
    <Stack styles={{ root: { padding: 20 } }}>
      <Stack
        styles={{
          root: {
            padding: 20,
            background: theme.palette.white,
            borderRadius: 16,
          },
        }}
      >
        <ViewContent>
          <MutationProgressIndicator mutationQuery={mutation} />

          <QueryProgressIndicator query={employer} />

          <Add
            employer={employer}
            form={form}
            formErrors={formErrors?.formErrors}
            onChange={(updatedFields: any) => change(updatedFields, form)}
            type="job"
          />
        </ViewContent>

        <ViewFooter
          onCreate={() =>
            mutation.mutate({
              ...form,
              categories: JSON.parse(localStorage.preferredCategories),
            })
          }
          mutationQuery={mutation}
        />
      </Stack>
    </Stack>
  );
};

export const ViewJob: React.FC<{ jobId: string; windowId: string }> = ({
  jobId,
  windowId,
}) => {
  const isAdmin = true;
  const mutation = useUpdateJob(jobId);
  const query = useJobById(jobId);
  const profile = useMyProfile();
  const deleteJob = useDeleteJob(jobId, windowId);
  const archiveJob = useArchiveJob(jobId, windowId);
  const theme = useTheme();
  const receipts = useReceiptsByJob(jobId);
  const invoices = useInvoicesByJob(jobId);
  const workLogs = useWorkLogsByJob(jobId);
  const [showLinkEmployee, setShowLinkEmployee] = useState(false);

  useEffect(() => {
    let newWindows = getState().openWindows.filter((window: any) => {
      if (window.window.id === "viewJob" + jobId) {
        window.window.title = query.data?.publicKey;
      }
      return true;
    });

    setState({ openWindows: newWindows });
  }, [query.isSuccess]);

  let actions: any = [
    {
      key: "delete",
      iconProps: { iconName: Icons.Delete },
      onClick: async () => {
        Swal.fire({
          buttonsStyling: false,
          confirmButtonText: "Löschen",
          focusCancel: true,
          cancelButtonText: "Abbrechen",
          showCancelButton: true,
          title: "Achtung",
          html: "Der Job kann NICHT mehr wiederhergestellt werden.",
          reverseButtons: true,
        }).then(async (event: any) => {
          if (event.isConfirmed) deleteJob.mutateAsync();
        });
      },
      text: "Löschen",
    },
    {
      disabled: query.data?.isArchived,
      key: "archive",
      iconProps: { iconName: Icons.Archive },
      onClick: async () => {
        Swal.fire({
          buttonsStyling: false,
          confirmButtonText: "Archivieren",
          focusCancel: true,
          cancelButtonText: "Abbrechen",
          showCancelButton: true,
          title: "Achtung",
          html: "Der Job kann NICHT mehr aktiviert werden.",
          reverseButtons: true,
        }).then(async (event: any) => {
          if (event.isConfirmed) archiveJob.mutateAsync();
        });
      },
      text: "Archivieren",
    },
  ];

  return (
    <Stack>
      <ViewContent>
        <Modal
          onDismiss={() => setShowLinkEmployee(false)}
          isOpen={showLinkEmployee}
          styles={{ main: { borderRadius: 16, height: "50vh", width: "50vw" } }}
        >
          <ViewLinkEmployee
            jobId={jobId}
            windowId={"linkEmployee_" + jobId}
            setShowLinkEmployee={setShowLinkEmployee}
          />
        </Modal>

        <QueryProgressIndicator query={query} />
        <MutationProgressIndicator mutationQuery={deleteJob} />

        <PullUp by={20} />

        {query.data && (
          <Pivot
            overflowBehavior="menu"
            styles={{
              root: {
                background: theme.palette.white,
                zIndex: 10,
                left: 0,
                borderRadius: "16px 16px 0px 0px",
                width: "100%",
              },
            }}
          >
            <PivotItem headerText="Dossier" itemIcon={Icons.File}>
              <PivotSubMenu>
                <Stack grow />
                <Stack slot="end">
                  <DefaultButton
                    styles={{ root: { borderRadius: 16 } }}
                    primary
                    menuProps={{ items: actions }}
                  >
                    Aktionen
                  </DefaultButton>
                </Stack>
              </PivotSubMenu>

              <File isAdmin={isAdmin} mutation={mutation} query={query} />
            </PivotItem>

            <PivotItem headerText="Jugendliche" itemIcon={Icons.Employee}>
              <PivotSubMenu>
                <DefaultButton
                  disabled={query.data?.isArchived}
                  styles={{ root: { borderRadius: 16 } }}
                  iconProps={{ iconName: Icons.Link }}
                  // onClick={() => addWindow.Employee.assign(jobId)}
                  onClick={() => setShowLinkEmployee(true)}
                  text="Jugendliche*r zuweisen"
                />
              </PivotSubMenu>

              <ListEmployeesByJob jobId={jobId} isArchived={query.data?.isArchived} />
            </PivotItem>

            <PivotItem headerText="Arbeitseinsätze" itemIcon={Icons.WorkLog}>
              <ListWorkLogs query={workLogs} isArchived={query.data?.isArchived} />
            </PivotItem>

            <PivotItem headerText="Buchhaltung" itemIcon={Icons.Accounting}>
              <Text
                style={{
                  background: theme.palette.white,
                  padding: 20,
                  display: "block",
                }}
                variant="xLarge"
              >
                Rechnungen
              </Text>

              <ListInvoices query={invoices} />

              <Text
                style={{
                  background: theme.palette.white,
                  padding: 20,
                  display: "block",
                }}
                variant="xLarge"
              >
                Quittungen
              </Text>

              <PullUp by={120} />

              <ListReceipts query={receipts} />
            </PivotItem>

            <PivotItem headerText="Notizen" itemIcon={Icons.Note}>
              <PivotSubMenu>
                <DefaultButton
                  styles={{ root: { borderRadius: 16 } }}
                  iconProps={{ iconName: Icons.Note }}
                  onClick={() =>
                    addWindow.Note.add(profile.data?.id, jobId, "job", true)
                  }
                  text="Notiz hinzufügen"
                />
              </PivotSubMenu>

              <PushDown by={20} />

              <ListNotesByType modelId={jobId} modelType="job" />
            </PivotItem>

            <PivotItem headerText="" itemIcon={Icons.Warning}>
              <Stack tokens={{ childrenGap: 10 }}>
                <InternalMeta query={query} />
              </Stack>
            </PivotItem>
          </Pivot>
        )}
      </ViewContent>
    </Stack>
  );
};

export const File: React.FC<{
  isAdmin: boolean;
  mutation: any;
  query: any;
}> = ({ isAdmin, mutation, query }) => {
  const [isEditModeMinAge, setIsEditModeMinAge] = useState(false);
  const [isEditModeAmountOfAssignments, setIsEditModeAmountOfAssignments] =
    useState(false);
  const [isEditModeAmountOfHours, setIsEditModeAmountOfHours] = useState(false);
  const [isEditModeDescription, setIsEditModeDescription] = useState(false);
  const frequencies = useFrequencies();
  const categories = useCategories();
  const jobfairs = useJobfairs();
  const [description, setDescription] = useState("");
  const theme = useTheme();

  useEffect(() => {
    setDescription(query.data?.description);
  }, [query.isSuccess]);

  // styles
  const styles: any = {
    field: {
      padding: 0,
    },
    fieldGroup: {
      background: "transparent",
      border: 0,
      borderBottom: "3px solid lightgrey",
    },
    root: {
      width: "100%",
    },
  };

  return (
    <Stack
      tokens={myTokens}
      styles={{
        root: {
          background: theme.palette.white,
          padding: 20,
          borderRadius: "0px 0px 16px 16px",
        },
      }}
    >
      <MutationProgressIndicator mutationQuery={mutation} />

      <QueryProgressIndicator query={query} />

      <Stack grow horizontal styles={myStack}>
        <Stack grow styles={myStack}>
          <Label>Auftraggeber*in</Label>
          <Link
            onClick={() => {
              addWindow.Profile.view(query.data?.employer.id);
            }}
            styles={myTextField}
          >
            {query.data?.employer?.description ||
              query.data?.employer?.firstName +
                " " +
                query.data?.employer?.lastName}
          </Link>
        </Stack>
      </Stack>

      <Stack tokens={myTokens}>
        <Stack horizontal tokens={myTokens}>
          <Stack grow styles={myStack}>
            <FieldStringUpdate
              allowMutation={!query.data?.isArchived}
              field="title"
              label="Titel"
              mutation={(payload: any) => mutation.mutate(payload)}
              query={query}
            />
          </Stack>

          <Stack styles={myStack}>
            <FieldDropdownUpdateMultiSelect
              allowMutation={!query.data?.isArchived}
              field="jobfairs"
              label="Job-Börse(n)"
              mutation={(payload: any) => mutation.mutate(payload)}
              options={jobfairs?.data}
              query={query}
              selectedKeys={query.data?.jobfairs}
            />
          </Stack>
        </Stack>

        <Stack
          styles={{
            root: {
              background: theme.palette.neutralLighter,
              marginRight: 30,
              padding: 10,
            },
          }}
        >
          <Toggle
            disabled={query.data?.isArchived}
            checked={query.data?.provideEstimatedInfos}
            inlineLabel
            label="Einsatzangaben anzeigen"
            onClick={() => {
              mutation.mutate({
                provideEstimatedInfos: !query.data.provideEstimatedInfos,
              });
            }}
          />

          {query.data?.provideEstimatedInfos && (
            <Stack horizontal tokens={myTokens}>
              <Stack horizontal styles={{ root: { width: "25%" } }}>
                <SpinButton
                  className={css`
                    .ms-spinButton-input {
                      border: 0px;
                      border-bottom: 4px solid lightgrey;
                    }
                  `}
                  disabled={!isEditModeAmountOfAssignments}
                  label="Anzahl Einsätze"
                  labelPosition={Position.top}
                  onChange={(event, value: any) => {
                    mutation.mutate({ amountOfAssignments: value });
                  }}
                  styles={styles}
                  value={query?.data?.amountOfAssignments}
                />
                {!isEditModeAmountOfAssignments && (
                  <IconButton
                    styles={{ root: { borderRadius: 16 } }}
                    disabled={false}
                    iconProps={{ iconName: Icons.Lock }}
                    onClick={() => {
                      setIsEditModeAmountOfAssignments(
                        !isEditModeAmountOfAssignments
                      );
                    }}
                    toggle
                  />
                )}
                {isEditModeAmountOfAssignments && (
                  <IconButton
                    styles={{ root: { borderRadius: 16 } }}
                    disabled={false}
                    iconProps={{ iconName: Icons.Cancel }}
                    onClick={() => {
                      setIsEditModeAmountOfAssignments(
                        !isEditModeAmountOfAssignments
                      );
                    }}
                    toggle
                  />
                )}
                {isEditModeAmountOfAssignments && (
                  <IconButton
                    styles={{ root: { borderRadius: 16 } }}
                    disabled={false}
                    iconProps={{ iconName: Icons.Unlock }}
                    onClick={() => {
                      setIsEditModeAmountOfAssignments(
                        !isEditModeAmountOfAssignments
                      );
                    }}
                    toggle
                  />
                )}
              </Stack>

              <Stack horizontal styles={{ root: { width: "24%" } }}>
                <SpinButton
                  className={css`
                    .ms-spinButton-input {
                      border: 0px;
                      border-bottom: 4px solid lightgrey;
                    }
                  `}
                  disabled={!isEditModeAmountOfHours}
                  label="à Stunden"
                  labelPosition={Position.top}
                  onChange={(event, value: any) => {
                    mutation.mutate({ amountOfHoursPerAssignment: value });
                  }}
                  styles={styles}
                  value={query?.data?.amountOfHoursPerAssignment}
                />
                {!isEditModeAmountOfHours && (
                  <IconButton
                    styles={{ root: { borderRadius: 16 } }}
                    disabled={false}
                    iconProps={{ iconName: Icons.Lock }}
                    onClick={() => {
                      setIsEditModeAmountOfHours(!isEditModeAmountOfHours);
                    }}
                    toggle
                  />
                )}
                {isEditModeAmountOfHours && (
                  <IconButton
                    styles={{ root: { borderRadius: 16 } }}
                    disabled={false}
                    iconProps={{ iconName: Icons.Cancel }}
                    onClick={() => {
                      setIsEditModeAmountOfHours(!isEditModeAmountOfHours);
                    }}
                    toggle
                  />
                )}
                {isEditModeAmountOfHours && (
                  <IconButton
                    styles={{ root: { borderRadius: 16 } }}
                    disabled={false}
                    iconProps={{ iconName: Icons.Unlock }}
                    onClick={() => {
                      setIsEditModeAmountOfHours(!isEditModeAmountOfHours);
                    }}
                    toggle
                  />
                )}
              </Stack>

              <Stack horizontal grow styles={{ root: { width: "100%" } }}>
                <Stack grow horizontal styles={{ root: { width: "25%" } }}>
                  <FieldDateUpdate
                    allowMutation={isAdmin}
                    field="startDate"
                    label="erster Einsatz"
                    mutation={(payload: any) => mutation.mutate(payload)}
                    query={query}
                  />
                </Stack>

                <Stack grow horizontal styles={{ root: { width: "25%" } }}>
                  <FieldDateUpdate
                    allowMutation={isAdmin}
                    field="endDate"
                    label="letzter Einsatz"
                    mutation={(payload: any) => mutation.mutate(payload)}
                    query={query}
                  />
                </Stack>
              </Stack>
            </Stack>
          )}
        </Stack>

        <Stack horizontal tokens={myTokens}>
          <Stack horizontal styles={{ root: { width: "24%" } }}>
            <SpinButton
              className={css`
                .ms-spinButton-input {
                  border: 0px;
                  border-bottom: 4px solid lightgrey;
                }
              `}
              disabled={!isEditModeMinAge}
              label="Mindestalter"
              labelPosition={Position.top}
              min={13}
              onChange={(event, value: any) => {
                mutation.mutate({ minAge: value });
              }}
              styles={styles}
              value={query?.data?.minAge}
            />
            {!isEditModeMinAge && (
              <IconButton
                styles={{ root: { borderRadius: 16 } }}
                disabled={query.data?.isArchived}
                iconProps={{ iconName: Icons.Lock }}
                onClick={() => {
                  setIsEditModeMinAge(!isEditModeMinAge);
                }}
                toggle
              />
            )}
            {isEditModeMinAge && (
              <IconButton
                styles={{ root: { borderRadius: 16 } }}
                disabled={false}
                iconProps={{ iconName: Icons.Cancel }}
                onClick={() => {
                  setIsEditModeMinAge(!isEditModeMinAge);
                }}
                toggle
              />
            )}
            {isEditModeMinAge && (
              <IconButton
                styles={{ root: { borderRadius: 16 } }}
                disabled={false}
                iconProps={{ iconName: Icons.Unlock }}
                onClick={() => {
                  setIsEditModeMinAge(!isEditModeMinAge);
                }}
                toggle
              />
            )}
          </Stack>

          <Stack styles={{ root: { width: "25%" } }}>
            <FieldDropdownUpdate
              allowMutation={!query.data?.isArchived}
              field="frequency"
              label="Regelmässigkeit"
              mutation={(payload: any) => mutation.mutate(payload)}
              options={frequencies?.data}
              query={query}
              selectedKey={query.data?.frequency?.key}
            />
          </Stack>

          <Stack styles={{ root: { width: "48.5%", marginRight: 6 } }}>
            <FieldDropdownUpdateMultiSelect
              allowMutation={!query.data?.isArchived}
              field="categories"
              label="Rubrik(en)"
              mutation={(payload: any) => mutation.mutate(payload)}
              options={categories?.data}
              query={query}
              selectedKeys={query.data?.categories}
            />
          </Stack>
        </Stack>

        <Stack horizontal tokens={myTokens}>
          <Stack grow horizontal styles={myStack}>
            <TextField
              autoAdjustHeight
              disabled={!isEditModeDescription}
              label="Ausschreibung bearbeiten"
              multiline
              onChange={(event, newValue) => setDescription(newValue!)}
              resizable={false}
              required
              rows={10}
              styles={{
                field: {
                  padding: 5,
                },
                fieldGroup: {
                  background: "transparent",
                  border: 0,
                  borderBottom: "3px solid lightgrey",
                },
                root: {
                  width: "100%",
                },
              }}
              value={description}
            />
            {!isEditModeDescription && (
              <IconButton
                disabled={query.data?.isArchived}
                styles={{ root: { borderRadius: 16 } }}
                iconProps={{ iconName: Icons.Lock }}
                onClick={() => setIsEditModeDescription(true)}
                toggle
              />
            )}
            {isEditModeDescription && (
              <IconButton
                styles={{ root: { borderRadius: 16 } }}
                iconProps={{ iconName: Icons.Cancel }}
                onClick={() => setIsEditModeDescription(false)}
                toggle
              />
            )}
            {isEditModeDescription && (
              <IconButton
                styles={{ root: { borderRadius: 16 } }}
                iconProps={{ iconName: Icons.Unlock }}
                onClick={() => {
                  setIsEditModeDescription(false);
                  if (!description) {
                    return setDescription(query.data?.description);
                  }
                  mutation.mutate({ description: description });
                }}
                toggle
              />
            )}
          </Stack>
        </Stack>

        <Text variant="medium">
          Verwende Markdown, um den Text zu formatieren.
        </Text>

        <Link href="https://www.markdownguide.org/cheat-sheet" target="blank">
          Überblick der wichtigsten Markdown-Befehle
        </Link>

        <Stack
          style={{
            background: theme.palette.neutralLighterAlt,
            marginRight: 32,
            padding: 5,
          }}
        >
          <Markdown>{query.data?.description}</Markdown>
        </Stack>
      </Stack>
    </Stack>
  );
};
