import { Stack, useTheme } from "@fluentui/react";
import { useEffect, useState } from "react";
import { changeForm, returnPartOfString } from "../assets/functions";
import { useUserCreate } from "../assets/mutations";
import FormCreateUser from "../components/forms/FormCreateUser";
import { MutationProgressIndicator } from "../components/misc";
import { ViewContent, ViewFooter } from "./Parts";

export const ViewCreateUser: React.FC<{ windowId: string }> = ({
  windowId,
}) => {
  const [form, setForm] = useState<any>();
  const createUser = useUserCreate(windowId, form, setForm);
  const theme = useTheme()

  useEffect(() => {
    if (form?.firstName && form?.lastName) {
      let username =
        returnPartOfString(1, form?.firstName) +
        "." +
        returnPartOfString(1, form?.lastName);
      changeForm(
        { email: username + "@jobfairy.ch", username: username },
        form,
        setForm
      );
    }
    // eslint-disable-next-line
  }, [form?.firstName, form?.lastName]);

  return (
    <Stack styles={{ root: { padding: 20, borderRadius: 16 } }}>
      <Stack styles={{ root: { background: theme.palette.white, padding: 20, borderRadius: 16 } }}>
        <ViewContent>
          <MutationProgressIndicator mutationQuery={createUser} />

          <FormCreateUser
            form={form}
            onChange={(updatedFields: any) =>
              changeForm(updatedFields, form, setForm)
            }
          />
        </ViewContent>

        <ViewFooter
          onCreate={() => createUser.mutate(form)}
          mutationQuery={createUser}
        />
      </Stack>
    </Stack >
  );
};

export default ViewCreateUser;
