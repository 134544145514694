import { State } from "zustand";
import createVanilla from "zustand/vanilla";

interface IDialogProps {
  onConfirm?: any;
  hide: boolean;
}

export type TDialogs = "confirmDeletion" | "signOut";

interface IDialogs {
  confirmDeletion: IDialogProps;
  signOut: IDialogProps;
}

interface IClientStore extends State {
  darkmode: boolean;
  editMode: boolean;
  currentlyFocusedWindowId: string | null;
  dialogs: IDialogs;
  openWindows: Array<any>;
  windowOrder: Array<any>;
  lastClickedWindowId: string;
  searchIsActiveTab: boolean;
}

export const useClientStore = createVanilla<IClientStore>(() => ({
  darkmode: false,
  editMode: false,
  currentlyFocusedWindowId: "tiles",
  dialogs: {
    confirmDeletion: {
      hide: true,
    },
    signOut: {
      hide: true,
    },
  },
  openWindows: [],
  windowOrder: [],
  lastClickedWindowId: "",
  searchIsActiveTab: false
}));
