import { css } from "@emotion/css";
import {
  DefaultButton,
  Pivot,
  PivotItem,
  SpinButton,
  Stack,
  Text,
  Toggle,
  useTheme
} from "@fluentui/react";
import axios from "axios";
import Markdown from "markdown-to-jsx";
import { useEffect, useState } from "react";
import create from "zustand";
import { Icons } from "../assets/icons";
import { useUpdateMyProfile, useUpdateMyUser } from "../assets/mutations";
import { useJobfairs, useMyProfile, useMyUser } from "../assets/queries";
import { useClientStore } from "../assets/stores";
import { addWindow } from "../assets/windows";
import {
  FieldDropdownUpdate,
  FieldStringUpdate,
  FieldToggleUpdate
} from "../components/forms/Parts";
import {
  MutationProgressIndicator,
  PushDown,
  QueryProgressIndicator
} from "../components/misc";
import ListStaff from "../lists/ListStaff";
import PivotSubMenu, { ViewContent } from "./Parts";

const ViewMyAccount: React.FC<{
  windowId: string;
}> = ({ windowId }) => {
  const [update, setUpdate] = useState<any>({ version: "", content: "" })
  const theme = useTheme()

  const getUpdate = async () => {
    const res: any = await axios.get(process.env.REACT_APP_SERVER + "/api/update")
    setUpdate(res.data.data.attributes)
  }

  useEffect(() => {
    getUpdate()
  }, [])

  return (
    <Stack>
      <ViewContent>
        <Pivot overflowBehavior="menu" styles={{
          root: {
            background: theme.palette.white, zIndex: 10, left: 0,
            // position: "fixed",
            // top: 45,
            borderRadius: "16px 16px 0px 0px",
            width: "100%",
          }
        }}>
          <PivotItem
            headerText="Profil"
            itemIcon={Icons.Profile}
            itemKey="myProfile"
          >
            <MyProfile />
          </PivotItem>

          <PivotItem
            headerText="Einstellungen"
            itemIcon={Icons.Settings}
            itemKey="mySetting"
          >
            <MySetting />
          </PivotItem>

          <PivotItem headerText="Team" itemIcon={Icons.Staff} itemKey="staff">
            <PivotSubMenu>
              <DefaultButton
                styles={{ root: { borderRadius: 16 } }}
                iconProps={{ iconName: Icons.Staff }}
                onClick={() => addWindow.Organisation.create.user()}
                text="Zu Team hinzufügen"
              />
            </PivotSubMenu>

            <PushDown by={100} />

            <ListStaff />
          </PivotItem>

          <PivotItem headerText="Updates" itemIcon={Icons.Bug} itemKey="updates">
            <Stack styles={{ root: { background: theme.palette.white, padding: 20, borderRadius: "0px 0px 16px 16px" } }}>
              <Markdown>
                {update.content}
              </Markdown>
            </Stack>
          </PivotItem>
        </Pivot>
      </ViewContent>
    </Stack>
  );
};

export default ViewMyAccount;

const MyProfile: React.FC<{ id?: string }> = ({ id }) => {
  const isAdmin = true;
  const isJobfairAdmin = true;
  const allowMutation = isAdmin || isJobfairAdmin;

  const myUser = useMyUser();
  const updateMyUser = useUpdateMyUser();
  const myProfile = useMyProfile();
  const updateMyProfile = useUpdateMyProfile();
  const jobfairs = useJobfairs();

  // styles
  const stackStyles = { root: { maxWidth: "50%", width: "100%" } };
  const theme = useTheme()

  return (
    <Stack tokens={{ childrenGap: 10 }} styles={{ root: { background: theme.palette.white, padding: 20, borderRadius: "0px 0px 16px 16px" } }}>
      <MutationProgressIndicator mutationQuery={updateMyUser}  />

      <MutationProgressIndicator mutationQuery={updateMyProfile}  />

      <QueryProgressIndicator  query={myUser} />

      <Stack horizontal>
        <Stack grow styles={stackStyles}>
          <FieldStringUpdate
            allowMutation={allowMutation}
            field="firstName"
            label="Vorname"
            mutation={(payload: any) => updateMyProfile.mutate(payload)}
            query={myProfile}
          />
        </Stack>

        <Stack grow styles={stackStyles}>
          <FieldStringUpdate
            allowMutation={allowMutation}
            field="lastName"
            label="Nachname"
            mutation={(payload: any) => updateMyProfile.mutate(payload)}
            query={myProfile}
          />
        </Stack>
      </Stack>

      <Stack horizontal>
        <Stack styles={stackStyles}>
          <FieldStringUpdate
            allowMutation={allowMutation}
            field="position"
            label="Position"
            mutation={(payload: any) => updateMyProfile.mutate(payload)}
            query={myProfile}
          />
        </Stack>

        <Stack styles={stackStyles}>
          {jobfairs.data && (
            <FieldDropdownUpdate
              allowMutation={isAdmin}
              field="jobfair"
              label="Jobbörse"
              mutation={(payload: any) => {
                updateMyProfile.mutate(payload);
              }}
              options={jobfairs.data}
              query={jobfairs}
              selectedKey={myProfile.data?.jobfair?.key}
            />
          )}
        </Stack>
      </Stack>

      <Stack horizontal>
        <Stack grow styles={stackStyles}>
          <FieldStringUpdate
            allowMutation={allowMutation}
            field="username"
            label="Benutzername"
            mutation={(payload: any) => updateMyUser.mutate(payload)}
            query={myUser}
          />
        </Stack>

        <Stack grow styles={stackStyles}>
          <FieldStringUpdate
            allowMutation={allowMutation}
            field="email"
            label="E-Mail"
            mutation={(payload: any) => updateMyUser.mutate({ ...payload, ignoreEmail: true })}
            query={myUser}
          />
        </Stack>
      </Stack>

      <Stack horizontal>
        <Stack grow styles={stackStyles}>
          <FieldStringUpdate
            allowMutation={allowMutation}
            field="mobilephone"
            label="Mobilnummer"
            isMobilephone
            mutation={(payload: any) => updateMyUser.mutate(payload)}
            query={myUser}
          />
        </Stack>

        <Stack grow styles={stackStyles}>
          <FieldStringUpdate
            allowMutation={allowMutation}
            field="password"
            isPassword
            label="Passwort"
            mutation={(payload: any) => updateMyUser.mutate(payload)}
            query={myUser}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

const MySetting: React.FC<{ id?: string }> = ({ id }) => {
  const myUser = useMyUser();
  const updateMyUser = useUpdateMyUser();
  const { getState, setState } = useClientStore;
  const useStore = create(useClientStore);
  const editMode = useStore((store: any) => store.editMode);
  const darkmode = useStore((store: any) => store.darkmode)

  const [spinButtonClass, setSpinButtonClass] = useState("");
  useEffect(() => {
    const inputs = document.getElementsByTagName("input");
    if (inputs.length < 1) return;
    setSpinButtonClass(inputs[0].parentElement?.classList[0]!);
  });

  const theme = useTheme()

  return (
    <Stack tokens={{ childrenGap: 10 }} styles={{ root: { background: theme.palette.white, padding: 20, borderRadius: "0px 0px 16px 16px" } }}>
      <MutationProgressIndicator mutationQuery={updateMyUser}  />

      <QueryProgressIndicator  query={myUser} />

      {myUser.isSuccess && (
        <Stack tokens={{ childrenGap: 10 }}>
          <Stack horizontal>
            <Stack grow>
              <Text variant="medium">Abmeldung nach Minuten</Text>
            </Stack>

            <Stack horizontal>
              <SpinButton
                className={css`
                  .${spinButtonClass} {
                    border-bottom: 3px solid lightgrey;
                  }
                  .${spinButtonClass}::after {
                    border: 0 !important;
                  }
                `}
                max={24 * 60}
                min={1}
                onChange={async (event: any, newValue: any) => {
                  updateMyUser.mutate({
                    setting: {
                      ...myUser.data?.setting,
                      logMeOutAfterMinutes: parseInt(newValue),
                    },
                  });
                }}
                value={myUser?.data?.setting?.logMeOutAfterMinutes}
              />
            </Stack>
          </Stack>

          <small style={{ color: "lightgrey" }}>
            Erst nach erneuter Anmeldung aktiv
          </small>

          <PushDown by={10} />

          <Stack horizontal>
            <Stack grow>
              <Text variant="medium">Darkmode</Text>
            </Stack>

            <Stack horizontal>
              <Toggle
                checked={darkmode}
                onClick={() => {
                  updateMyUser.mutate({
                    setting: {
                      ...myUser.data?.setting,
                      darkmode: !darkmode
                    }
                  })
                  setState({
                    ...getState(),
                    darkmode: !getState().darkmode,
                  })
                }}
              />
            </Stack>
          </Stack>

          <PushDown by={10} />

          <Stack horizontal>
            <Stack grow>
              <Text variant="medium">Layout anpassen</Text>
            </Stack>

            <Stack horizontal>
              <Toggle
                checked={editMode}
                // disabled
                onClick={() => {
                  updateMyUser.mutate({
                    setting: {
                      ...myUser.data?.setting,
                      editMode: !editMode
                    }
                  })
                  setState({
                    ...getState(),
                    editMode: !getState().editMode,
                  })
                }
                }
              />
            </Stack>
          </Stack>

          <PushDown by={10} />

          <Stack horizontal>
            <Stack grow>
              <Text variant="medium">Vor Schliessen warnen</Text>
            </Stack>

            <FieldToggleUpdate
              checked={myUser.data?.setting?.preventUnintendedReloadByUser}
              mutation={updateMyUser}
              onChange={(event: any, checked: any) => {
                updateMyUser.mutate({
                  setting: {
                    ...myUser.data?.setting,
                    preventUnintendedReloadByUser: checked,
                  },
                });
              }}
              query={myUser}
            />
          </Stack>

          <small style={{ color: "lightgrey" }}>
            Erst nach erneutem Laden der Plattform aktiv
          </small>
        </Stack>
      )}
    </Stack>
  );
};
