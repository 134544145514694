import {
    Checkbox,
    Stack
} from "@fluentui/react";
import { useUpdateProfile } from "../assets/mutations";
import { useCategories, useProfile } from "../assets/queries";
import { ViewContent } from "./Parts";

const ViewCategories: React.FC<{
    profileId: string;
    windowId: string;
}> = ({ profileId, windowId }) => {
    const profile = useProfile(profileId)
    const categories = useCategories()
    const updateProfile = useUpdateProfile(profileId)

    return (
        <Stack>
            <ViewContent>
                {categories.data?.map((category: any) => {
                    let checked = false
                    profile.data?.preferredCategories.forEach((c: any) => {
                        if (c.text === category.text) checked = true
                    })
                    return (
                        <Checkbox key={category.text} label={category.text} checked={checked} onChange={(event: any, checked: any) => {
                            let newCategories = categories.data?.filter((templateCategory: any) => {
                                if (templateCategory.text === category.text) return checked
                                for (let i = 0; i < profile.data?.preferredCategories.length; i++) {
                                    const existingCategory = profile.data?.preferredCategories[i];
                                    if (templateCategory.text === existingCategory.text) return true
                                }
                                return false
                            })
                            updateProfile.mutate({ preferredCategories: [...newCategories] })
                        }} />
                    )
                })}
            </ViewContent>
        </Stack>
    );
};

export default ViewCategories;

