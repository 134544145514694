import { css } from "@emotion/css";
import {
  DefaultButton, IBasePicker,
  IBasePickerSuggestionsProps, ITag,
  Label,
  MessageBar,
  MessageBarType,
  Modal,
  Pivot,
  PivotItem,
  Stack,
  TagPicker,
  Text,
  Toggle,
  useTheme
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { useCallback, useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import axiosJWT from "../assets/axiosjwt";
import { returnErrorsToReset, returnServerError } from "../assets/functions";
import { Icons } from "../assets/icons";
import {
  useCreateNewTag,
  useCreateProfile,
  useDeleteProfile,
  useUpdateProfile,
  useUpdateTagsByProfile
} from "../assets/mutations";
import {
  useProfile,
  useTags,
  useTagsByProfile,
  useWorkLogsByEmployee,
  useWorkLogsByEmployer
} from "../assets/queries";
import { useClientStore } from "../assets/stores";
import { myStack } from "../assets/styles";
import { returnValidationErrors } from "../assets/validations";
import { addWindow, closeWindow } from "../assets/windows";
import Create from "../components/forms/Create";
import {
  FieldDateUpdate,
  FieldDropdownUpdateEnumeration, FieldStringUpdate
} from "../components/forms/Parts";
import {
  InternalMeta,
  MutationProgressIndicator,
  ProfileImage,
  PullUp,
  PushDown,
  QueryProgressIndicator
} from "../components/misc";
import QrUploader from "../components/QrUploader";
import { queryClient } from "../imports";
import Contacts from "../lists/Contacts";
import ListJobsByEmployee from "../lists/JobsByEmployee";
import ListJobsByEmployer from "../lists/JobsByEmployer";
import ListHistory from "../lists/ListHistory";
import ListNotesByType from "../lists/ListNotesByType";
import ListWorkLogs from "../lists/ListWorkLogs";
import { AccountingEmployee, AccountingEmployer } from "./Accounting";
import { ViewAddContact } from "./Contact";
import Documents from "./Documents";
import PivotSubMenu, { ViewContent, ViewFooter } from "./Parts";
import ViewCategories from "./ViewCategories";
const { getState, setState } = useClientStore

export const ViewCreateProfile: React.FC<{
  type: string;
  windowId: string;
}> = ({ type, windowId }) => {
  const [form, setForm] = useState<any>({});
  const [formErrors, setFormErrors] = useState<any>({});
  const createProfile: any = useCreateProfile(type);
  const [title, setTitle] = useState("");
  const [requestGranted, setRequestGranted] = useState(false);
  const theme = useTheme()

  const change = (updatedFields: any, oldForm: any = form) => {
    setForm({ ...oldForm!, ...updatedFields });
    setFormErrors({
      ...formErrors,
      formErrors: {
        ...formErrors?.formErrors,
        ...returnErrorsToReset(updatedFields),
      },
    });
  };

  useEffect(() => {
    let newWindows = getState().openWindows.filter((window: any) => {
      if (window.window.id === "createProfile" + type) {
        switch (type) {
          case "publicEmployer":
            window.window.title = "Neue*r öffentlicher Auftraggeber*in"
            break;
          case "privateEmployer":
            window.window.title = "Neue*r private*r Auftraggeber*in"
            break;
          case "employee":
            window.window.title = "Neue*r Jugendliche*r"
            break;

          default:
            break;
        }
      }
      return true
    })

    setState({ openWindows: newWindows })
  }, [createProfile.isSuccess])

  useEffect(() => {
    if (createProfile.isSuccess) {
      Swal.fire({
        title: "Erfolgreich erstellt",
        text: "Was möchtest du tun?",
        cancelButtonText: "Fenster schliessen",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Dossier öffnen"
      }).then((event) => {
        if (event.isConfirmed) {
          addWindow.Profile.view(createProfile.data?.data?.id)
          closeWindow(windowId)
        } else {
          closeWindow(windowId)
        }
      })
    };
    if (createProfile.isError) {
      setFormErrors(returnServerError(createProfile.error));
      // BETA
      let requests = createProfile.error?.response?.data?.data?.requests;
      if (requests && Object.keys(requests).length > 0) {
        Object.values(createProfile.error.response.data.data.requests).forEach(
          (request: any) => {
            Swal.fire({
              buttonsStyling: false,
              confirmButtonText: "Trotzdem erstellen",
              cancelButtonText: "Abbrechen",
              focusCancel: true,
              showCancelButton: true,
              title: "Meldung",
              text: request.message,
              reverseButtons: true,
            }).then((event: any) => {
              if (event.isConfirmed) {
                setForm({ ...form, [request.key]: true });
                setRequestGranted(!requestGranted);
              }
            });
          }
        );
      }
      // END OF BETA
    }
  }, [
    createProfile.error,
    createProfile.isError,
    createProfile.isSuccess,
    windowId,
  ]);

  useEffect(() => {
    if (!form.description && !form.email) return;
    createProfile.mutate(form);
  }, [requestGranted]);

  useEffect(() => {
    switch (type) {
      case "publicEmployer":
        setTitle("Öffentliche*r Auftraggeber*in erstellen");
        break;
      case "privateEmployer":
        setTitle("Private*r Auftraggeber*in erstellen");
        break;
      case "employee":
        setTitle("Jugendliche*r erstellen");
        break;

      default:
        break;
    }
  });

  return (
    <Stack styles={{ root: { padding: 20, borderRadius: 16 } }}>
      <Stack styles={{ root: { background: theme.palette.white, padding: 20, borderRadius: 16 } }}>
        <ViewContent>
          <MutationProgressIndicator mutationQuery={createProfile} />

          <Create
            form={form}
            formErrors={formErrors?.formErrors}
            onChange={(updatedFields: Partial<any>) =>
              change(updatedFields, form)
            }
            type={type}
          />
        </ViewContent>

        <ViewFooter
          onCreate={() => {
            let errors = returnValidationErrors(type, form);
            setFormErrors({
              formErrors: errors,
            });
            if (!errors) createProfile.mutate(form);
          }}
          mutationQuery={createProfile}
        />
      </Stack>
    </Stack>
  );
};

export const ViewProfile: React.FC<{
  profileId: string;
  windowId: string;
}> = ({ profileId, windowId }) => {
  const profile = useProfile(profileId);
  const updateProfile = useUpdateProfile(profileId);
  const [hasCritical, setHasCritical] = useState(false);
  const [hasDanger, setHasDanger] = useState(false);
  const deleteProfile = useDeleteProfile(profileId, windowId);
  const theme = useTheme()
  const workLogsByEmployer = useWorkLogsByEmployer(profileId)
  const workLogsByEmployee = useWorkLogsByEmployee(profileId)

  useEffect(() => {
    let newWindows = getState().openWindows.filter((window: any) => {
      if (window.window.id === "viewProfile" + profileId) {
        window.window.title = profile.data?.description ? profile.data?.description : profile.data?.firstName + " " + profile.data?.lastName
      }
      return true
    })

    setState({ openWindows: newWindows })
  }, [profile.isSuccess])

  useEffect(() => {
    profile.data?.notes?.forEach((note: any) => {
      if (note.modelId == profileId && note.modelType === "profile") {
        if (note.type === "critical") setHasCritical(true);
        if (note.type === "danger") setHasDanger(true);
      }
    });
  }, [profile.isSuccess]);

  let actions: any = [
    {
      key: "export",
      iconProps: { iconName: Icons.Archive },
      onClick: async () => {
        const res: any = await axiosJWT.post(
          process.env.REACT_APP_SERVER + "/api/a/export",
          {
            data: {
              type: "singlePublicEmployer",
              employerId: profile.data?.id,
            },
          },
        );

        window.open(process.env.REACT_APP_SERVER + "/" + res.data);
      },
      text: "Exportieren",
    },
    {
      key: "delete",
      iconProps: { iconName: Icons.Delete },
      onClick: async () => {
        Swal.fire({
          buttonsStyling: false,
          title: "Sicher?",
          text: "Willst du dieses Profil wirklich unwiderruflich löschen?",
          showCancelButton: true,
          cancelButtonText: "Abbrechen",
          confirmButtonText: "Ja, löschen"
        }).then(async (event) => {
          if (event.isConfirmed) await deleteProfile.mutateAsync();
        })
      },
      text: "Löschen",
    },
    {
      disabled: true,
      key: "archive",
      iconProps: { iconName: Icons.Archive },
      onClick: async () => { },
      text: "Archivieren",
    },
  ]

  const [showQrUploader, setShowQrUploader] = useState(false)
  const [showAddContact, setShowAddContact] = useState(false)

  return (
    <Stack>
      <Modal isOpen={showQrUploader} onDismiss={() => setShowQrUploader(false)} styles={{ main: { borderRadius: 16, height: "35vh", width: "30vw" } }}>
        <PushDown by={20} />
        <QrUploader setShowQrUploader={setShowQrUploader} profileId={profileId} windowId={"uploadProfilePicture" + profileId} />
      </Modal>

      <Modal isOpen={showAddContact} onDismiss={() => setShowAddContact(false)} styles={{ main: { borderRadius: 16, height: "50vh", width: "50vw" } }}>
        <PushDown by={20} />
        <ViewAddContact setShowAddContact={setShowAddContact} profileId={profileId} windowId={"addContact" + profileId} />
      </Modal>

      <PullUp by={20} />

      {profile.isSuccess && (
        <Stack>
          <ViewContent>
            <MutationProgressIndicator
              mutationQuery={updateProfile}
            />

            <MutationProgressIndicator
              mutationQuery={deleteProfile}
            />

            <QueryProgressIndicator query={profile} />

            <Pivot overflowBehavior="menu" styles={{
              root: {
                background: theme.palette.white, zIndex: 10, left: 0,
                width: "100%",
                borderRadius: "16px 16px 0px 0px",
              },
            }}>
              <PivotItem headerText="Dossier" itemIcon={Icons.File}>
                <PivotSubMenu>
                  <DefaultButton
                    styles={{ root: { borderRadius: 16 } }}
                    disabled={hasDanger}
                    iconProps={{ iconName: Icons.Contact }}
                    // onClick={() => addWindow.Contact.add(profileId)}
                    onClick={() => {
                      setShowAddContact(true)
                    }}
                    text="Kontakt hinzufügen"
                  />

                  {!profile.data?.profileImage?.url && (
                    <DefaultButton
                      styles={{ root: { borderRadius: 16 } }}
                      iconProps={{ iconName: Icons.Camera }}
                      onClick={() => {
                        setShowQrUploader(true)
                        // addWindow.QrUploader.open(profileId);
                      }}
                      text="Profilbild hochladen"
                    />
                  )}

                  {profile.data?.profileImage?.url && (
                    <DefaultButton
                      styles={{ root: { borderRadius: 16 } }}
                      iconProps={{ iconName: Icons.Delete }}
                      onClick={() =>
                        Swal.fire({
                          buttonsStyling: false,
                          confirmButtonText: "Löschen",
                          focusCancel: true,
                          cancelButtonText: "Abbrechen",
                          showCancelButton: true,
                          title: "Achtung",
                          html:
                            "Das Bild kann NICHT mehr wiederhergestellt werden.",
                          reverseButtons: true,
                        }).then(async (event: any) => {
                          if (event.isConfirmed) updateProfile.mutate({ profileImage: null })
                        })
                      }
                      text="Profilbild löschen"
                    />
                  )}

                  <Stack
                    grow
                    horizontalAlign="end"
                    style={{ paddingRight: 10 }}
                  >
                    <Toggle
                      checked={profile.isSuccess && profile.data?.canUseApp}
                      disabled={hasDanger}
                      inlineLabel
                      label="App-Zugang aktivieren"
                      onClick={async () => {
                        if (!profile.data?.mobilephone) {
                          return Swal.fire({
                            buttonsStyling: false,
                            title: "Mobilnummer fehlt",
                            text: "Ohne Mobilnummer kann kein App-Zugang aktiviert werden.",
                          });
                        }
                        return await updateProfile.mutateAsync({
                          canUseApp: !profile.data?.canUseApp,
                        });
                      }}
                    />
                  </Stack>

                  <DefaultButton styles={{ root: { borderRadius: 16 } }} primary menuProps={{ items: actions }}>Aktionen</DefaultButton>
                </PivotSubMenu>

                <Profile profile={profile} />
              </PivotItem>

              {!hasDanger && profile.data?.type !== "employee" && (
                <PivotItem headerText="Jobs" itemIcon={Icons.Job}>
                  <PivotSubMenu>
                    <DefaultButton
                      styles={{ root: { borderRadius: 16 } }}
                      iconProps={{ iconName: Icons.Note }}
                      onClick={() => {
                        addWindow.Job.add(profileId);
                      }}
                      text="Job hinzufügen"
                    />
                  </PivotSubMenu>

                  <PushDown by={20} />

                  <ListJobsByEmployer employerId={profileId} />
                </PivotItem>
              )}

              {!hasDanger && profile.data?.type === "employee" && (
                <PivotItem headerText="Jobs" itemIcon={Icons.Job}>
                  <PushDown by={20} />

                  <ListJobsByEmployee employeeId={profileId} />
                </PivotItem>
              )}

              {/* {!hasDanger && profile.data?.type === "employee" && (
                <PivotItem headerText="Termine" itemIcon={Icons.Assignment}>
                  <PushDown by={20} />

                  <ListAssignments />
                </PivotItem>
              )} */}

              {!hasDanger && profile.data?.type !== "employee" && (
                <PivotItem
                  headerText="Arbeitseinsätze"
                  itemIcon={Icons.WorkLog}
                >
                  <ListWorkLogs query={workLogsByEmployer} />
                </PivotItem>
              )}

              {!hasDanger && profile.data?.type === "employee" && (
                <PivotItem
                  headerText="Arbeitseinsätze"
                  itemIcon={Icons.WorkLog}
                >
                  <ListWorkLogs query={workLogsByEmployee} />
                </PivotItem>
              )}

              {!hasDanger && profile.data?.type !== "employee" && (
                <PivotItem headerText="Rechnungen" itemIcon={Icons.Accounting}>
                  <AccountingEmployer employerId={profile.data?.id} />
                </PivotItem>
              )}

              {!hasDanger && profile.data?.type === "employee" && (
                <PivotItem headerText="Quittungen" itemIcon={Icons.Receipt}>
                  <AccountingEmployee employeeId={profile.data?.id} />
                </PivotItem>
              )}

              {!hasDanger && (
                <PivotItem headerText="Dokumente" itemIcon={Icons.Documents}>
                  <Documents modelId={profile.data?.id} modelType="profile" />
                </PivotItem>
              )}

              <PivotItem headerText="Notizen" itemIcon={Icons.Note}>
                <PivotSubMenu>
                  <DefaultButton
                    styles={{ root: { borderRadius: 16 } }}
                    iconProps={{ iconName: Icons.Note }}
                    onClick={() =>
                      addWindow.Note.add(profileId, profileId, "profile", false)
                    }
                    text="Notiz hinzufügen"
                  />
                </PivotSubMenu>

                <PushDown by={20} />

                <ListNotesByType modelId={profileId} modelType="profile" />
              </PivotItem>

              {!hasDanger && (
                <PivotItem headerText="" itemIcon={Icons.Warning}>
                  <InternalMeta query={profile} />

                  <ListHistory modelType="profile" modelId={profileId} />
                </PivotItem>
              )}
            </Pivot>
          </ViewContent>
        </Stack>
      )}
    </Stack>
  );
};

export const Profile: React.FC<{
  profile: any;
}> = ({ profile }) => {
  switch (profile.data?.type) {
    case "publicEmployer":
      return <PublicEmployer profile={profile} />;
    case "privateEmployer":
      return <PrivateEmployer profile={profile} />;
    case "employee":
      return <Employee profile={profile} />;

    default:
      return null;
  }
};

const PublicEmployer: React.FC<{ profile: any }> = ({ profile }) => {
  const updateProfile = useUpdateProfile(profile.data?.id);
  const [hasCritical, setHasCritical] = useState(false);
  const [hasDanger, setHasDanger] = useState(false);
  const theme = useTheme()

  useEffect(() => {
    profile.data?.notes?.forEach((note: any) => {
      if (note.modelId == profile.data?.id && note.modelType === "profile") {
        if (note.type === "critical") setHasCritical(true);
        if (note.type === "danger") setHasDanger(true);
      }
    });
  }, []);

  return (
    <Stack styles={{ root: { background: theme.palette.white, padding: 20, borderRadius: "0px 0px 16px 16px" } }}>
      <MutationProgressIndicator mutationQuery={updateProfile} />

      <QueryProgressIndicator query={profile} />

      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <Stack styles={{ root: { width: "55%" } }}>
          <ProfileImage profileImageUrl={profile.data?.profileImage?.url} />
        </Stack>

        <Stack styles={{ root: { width: "100%" } }}>
          <Stack tokens={{ childrenGap: 10 }}>
            {hasCritical && (
              <MessageBar messageBarType={MessageBarType.warning}>
                Achtung: kritische Notiz vorhanden
              </MessageBar>
            )}

            {hasDanger && (
              <MessageBar messageBarType={MessageBarType.blocked}>
                Achtung: alarmierende Notiz vorhanden
              </MessageBar>
            )}

            <Stack horizontal>
              <Stack grow horizontal styles={myStack}>
                <Text>Registriert in {profile.data?.registeredAt?.text}</Text>
              </Stack>
            </Stack>

            <Stack horizontal>
              <Stack grow horizontal styles={myStack}>
                <FieldStringUpdate
                  allowMutation={true}
                  field="description"
                  label="Bezeichnung"
                  mutation={(payload: any) => updateProfile.mutate(payload)}
                  query={profile}
                />
              </Stack>
            </Stack>

            <Stack horizontal>
              <Stack grow horizontal styles={myStack}>
                <FieldStringUpdate
                  allowMutation={true}
                  field="website"
                  label="Webseite"
                  mutation={(payload: any) => updateProfile.mutate(payload)}
                  query={profile}
                  type="url"
                />
              </Stack>
            </Stack>

            <Stack horizontal>
              <Stack grow styles={myStack} tokens={{ childrenGap: 10 }}>
                <Contacts contacts={profile.data?.contacts} />
              </Stack>
            </Stack>

            <Label>Hervorgehobene Notizen</Label>
            <Stack>
              {profile.data?.notes.map((note: any) => {
                if (!note.showInDossier) return null;
                return (
                  <Stack>
                    <Text>
                      <b>{note.title}</b>
                    </Text>
                    <Text>{note.text}</Text>
                    <br />
                  </Stack>
                );
              })}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

const PrivateEmployer: React.FC<{ profile: any }> = ({ profile }) => {
  const updateProfile = useUpdateProfile(profile.data?.id);
  const [hasCritical, setHasCritical] = useState(false);
  const [hasDanger, setHasDanger] = useState(false);
  const theme = useTheme()

  useEffect(() => {
    profile.data?.notes?.forEach((note: any) => {
      if (note.modelId == profile.data?.id && note.modelType === "profile") {
        if (note.type === "critical") setHasCritical(true);
        if (note.type === "danger") setHasDanger(true);
      }
    });
  }, []);

  return (
    <Stack styles={{ root: { background: theme.palette.white, padding: 20, borderRadius: "0px 0px 16px 16px" } }}>
      <QueryProgressIndicator query={profile} />
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <Stack styles={{ root: { width: "55%" } }}>
          <ProfileImage profileImageUrl={profile.data?.profileImage?.url} />
        </Stack>

        <Stack styles={{ root: { width: "100%" } }}>
          <Stack tokens={{ childrenGap: 10 }}>
            {hasCritical && (
              <MessageBar messageBarType={MessageBarType.warning}>
                Achtung: kritische Notiz vorhanden
              </MessageBar>
            )}

            {hasDanger && (
              <MessageBar messageBarType={MessageBarType.blocked}>
                Achtung: alarmierende Notiz vorhanden
              </MessageBar>
            )}

            <Stack horizontal>
              <Stack grow horizontal styles={myStack}>
                <Text>Registriert in {profile.data?.registeredAt?.text}</Text>
              </Stack>
            </Stack>

            <Stack horizontal>
              <Stack styles={myStack}>
                <FieldStringUpdate
                  allowMutation={true}
                  field="firstName"
                  label="Vorname"
                  mutation={(payload: any) => updateProfile.mutate(payload)}
                  query={profile}
                />
              </Stack>
              <Stack styles={myStack}>
                <FieldStringUpdate
                  allowMutation={true}
                  field="lastName"
                  label="Nachname"
                  mutation={(payload: any) => updateProfile.mutate(payload)}
                  query={profile}
                />
              </Stack>
            </Stack>

            <Stack horizontal>
              <Stack grow styles={myStack} tokens={{ childrenGap: 10 }}>
                <Contacts contacts={profile.data?.contacts} />
              </Stack>
            </Stack>

            <Label>Hervorgehobene Notizen</Label>
            <Stack>
              {profile.data?.notes.map((note: any) => {
                if (!note.showInDossier) return null;
                return (
                  <Stack>
                    <Text>
                      <b>{note.title}</b>
                    </Text>
                    <Text>{note.text}</Text>
                    <br />
                  </Stack>
                );
              })}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

const Employee: React.FC<{ profile: any }> = ({ profile }) => {
  const updateProfile = useUpdateProfile(profile.data?.id);
  const tags = useTags();
  const tagsByProfile = useTagsByProfile(profile.data?.id);
  const updateTags = useUpdateTagsByProfile(profile.data?.id);
  const createTag = useCreateNewTag(profile.data?.id);
  const [hasCritical, setHasCritical] = useState(false);
  const [hasDanger, setHasDanger] = useState(false);

  useEffect(() => {
    profile.data?.notes?.forEach((note: any) => {
      if (note.modelId == profile.data?.id && note.modelType === "profile") {
        if (note.type === "critical") setHasCritical(true);
        if (note.type === "danger") setHasDanger(true);
      }
    });
  }, []);

  const filterSelectedTags: any = (
    filterText: string,
    tagList: ITag[]
  ): ITag[] => {
    let results = [];

    if (filterText) {
      results = tags.data?.filter(
        (tag: any) =>
          tag.name.toLowerCase().indexOf(filterText.toLowerCase()) === 0
      );
    }

    return filterText
      ? results.length === 0
        ? [
          {
            key: "new",
            name: filterText + " erstellen",
            realName: filterText,
          },
        ]
        : tags.data?.filter(
          (tag: any) =>
            tag.name.toLowerCase().indexOf(filterText.toLowerCase()) === 0
        )
      : [];
  };

  const [showCategory, setShowCategory] = useState(false)

  const picker = useRef<IBasePicker<ITag>>(null);

  const listContainsTagList = (tag: ITag, tagList?: ITag[]) => {
    if (!tagList || !tagList.length || tagList.length === 0) {
      return false;
    }
    return tagList.some((compareTag) => compareTag.key === tag.key);
  };

  const onItemSelected: any = useCallback((item: any): ITag | null => {
    if (item.key === "new") {
      createTag.mutate({ name: item?.realName });
      queryClient.invalidateQueries("tags");
      item.name = item?.realName;
      return item;
    }

    if (picker.current && listContainsTagList(item, picker.current.items)) {
      return null;
    }
    return item;
  }, []);

  const getTextFromItem = (item: any) => item?.name;

  const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
    suggestionsHeaderText: "Vorschläge",
    noResultsFoundText: "Keine Tags gefunden",
  };

  const [tagPicker, { toggle: toggleIsTagPickerVisible }] = useBoolean(false);

  const theme = useTheme()

  return (
    <Stack styles={{ root: { background: theme.palette.white, padding: 20, borderRadius: "0px 0px 16px 16px" } }}>
      <Modal isOpen={showCategory} onDismiss={() => setShowCategory(false)} styles={{ main: { borderRadius: 16, height: "45vh", width: "50vw", padding: 20 } }}>
        <ViewCategories profileId={profile.data?.id} windowId={"editCategories" + profile.data?.id} />
      </Modal>

      <QueryProgressIndicator query={profile} />

      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <Stack styles={{ root: { width: "55%" } }}>
          <ProfileImage profileImageUrl={profile.data?.profileImage?.url} />

          <Stack styles={{ root: { paddingTop: 10 } }}>
            {profile.data?.notes.map((note: any) => {
              if (!note.showInDossier) return null;
              return (
                <Stack styles={{ root: { background: theme.palette.themePrimary, borderRadius: 16, padding: 10, marginBottom: 5 } }}>
                  <Text>
                    <b>{note.title}</b>
                  </Text>
                  <Text>{note.text}</Text>
                </Stack>
              );
            })}
          </Stack>
        </Stack>

        <Stack styles={{ root: { width: "100%" } }}>
          <Stack tokens={{ childrenGap: 10 }}>
            {hasCritical && (
              <MessageBar messageBarType={MessageBarType.warning}>
                Achtung: kritische Notiz vorhanden
              </MessageBar>
            )}

            {hasDanger && (
              <MessageBar messageBarType={MessageBarType.blocked}>
                Achtung: alarmierende Notiz vorhanden
              </MessageBar>
            )}

            <Stack horizontal>
              <Stack grow horizontal styles={myStack}>
                <Text>Registriert in {profile.data?.registeredAt?.text}</Text>
              </Stack>
            </Stack>

            <Stack horizontal>
              <Stack styles={myStack}>
                <FieldDateUpdate
                  allowMutation={true}
                  field="birthdate"
                  label="Geburtsdatum"
                  mutation={(payload: any) => updateProfile.mutate(payload)}
                  query={profile}
                />
              </Stack>

              <Stack styles={myStack}>
                <FieldDropdownUpdateEnumeration
                  field="gender"
                  label="Geschlecht"
                  mutation={(payload: any) => updateProfile.mutate(payload)}
                  options={[
                    {
                      key: "undefined",
                      text: "keine Angabe",
                    },
                    {
                      key: "other",
                      text: "anderes",
                    },
                    {
                      key: "M",
                      text: "männlich",
                    },
                    {
                      key: "F",
                      text: "weiblich",
                    },
                  ]}
                  query={profile}
                  selectedKey={profile.data?.gender}
                />
              </Stack>
            </Stack>

            <Stack horizontal>
              <Stack styles={myStack}>
                <FieldStringUpdate
                  allowMutation={true}
                  field="firstName"
                  label="Vorname"
                  mutation={(payload: any) => updateProfile.mutate(payload)}
                  query={profile}
                />
              </Stack>

              <Stack styles={myStack}>
                <FieldStringUpdate
                  allowMutation={true}
                  field="lastName"
                  label="Nachname"
                  mutation={(payload: any) => updateProfile.mutate(payload)}
                  query={profile}
                />
              </Stack>
            </Stack>

            <Stack horizontal>
              <Stack grow styles={myStack} tokens={{ childrenGap: 10 }}>
                <Contacts contacts={profile.data?.contacts} />
              </Stack>
            </Stack>

            <Stack>
              <Label>Bevorzugte Rubrik(en)</Label>
              {profile.data?.preferredCategories.length < 1 && (
                <Text style={{ color: "red" }}>mind. 1 Rubrik wählen</Text>
              )}
              {profile.data?.preferredCategories.map((category: any) => {
                <br />
              })}
              {profile.data?.preferredCategories.map((category: any) => {
                return (
                  <Stack>
                    <Text>- {category.text}</Text>
                  </Stack>
                )
              })}

              <PushDown by={10} />

              <Stack horizontal horizontalAlign="start" styles={myStack}>

                <DefaultButton
                  styles={{ root: { borderRadius: 16 } }}
                  onClick={() => setShowCategory(true)}>Anpassen</DefaultButton>
              </Stack>
            </Stack>

            <Stack horizontal>
              <Stack styles={myStack}>
                <FieldStringUpdate
                  allowMutation
                  field="iban"
                  label="IBAN"
                  mutation={(payload: any) => updateProfile.mutate(payload)}
                  query={profile}
                />
              </Stack>
            </Stack>

            <Stack style={{ paddingRight: 35 }}>
              <Label>Tags</Label>

              <Stack grow>
                {tagsByProfile.isSuccess && (
                  <TagPicker
                    className={css`
                      .ms-BasePicker-text {
                        border: 0 !important;
                      }
                      border-bottom: 3px solid lightgrey !important;
                    `}
                    componentRef={picker}
                    onResolveSuggestions={filterSelectedTags}
                    onItemSelected={onItemSelected}
                    getTextFromItem={getTextFromItem}
                    pickerSuggestionsProps={pickerSuggestionsProps}
                    disabled={tagPicker}
                    onChange={(items) => {
                      updateTags.mutateAsync({ tags: items });
                    }}
                    defaultSelectedItems={tagsByProfile.data}
                  />
                )}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
