import { DetailsList, Icon, Link, SelectionMode, Stack } from "@fluentui/react";
import Markdown from "markdown-to-jsx";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useMyTodos } from "../assets/queries";
import { addWindow } from "../assets/windows";
import {
  PushDown,
  QueryProgressIndicator
} from "../components/misc";

const ListTodos: React.FC<{ type?: "all" | "done" | "notDone" }> = ({
  type = "all",
}) => {
  const notes = useMyTodos(type);
  const [list, setList] = useState<any>({
    items: [],
    isSorted: {},
  });
  const clear = () => setList({ ...list, items: notes.data });
  const setItems = (items: Array<any>) => setList({ ...list, items: items });

  useEffect(() => {
    if (notes.isSuccess) setItems(notes.data);
    // eslint-disable-next-line
  }, [notes.isSuccess, notes.data]);

  return (
    <Stack>
      {notes.isSuccess && notes.data.length < 1 && <PushDown by={40} />}

      <QueryProgressIndicator query={notes} />

      {notes.isSuccess && notes.data.length > 0 && (
        <Stack>
          <DetailsList
            columns={[
              {
                isIconOnly: true,
                iconName: "Group",
                key: "closed",
                minWidth: 15,
                name: "",
                onRender: (item: any) => {
                  if (item?.closed)
                    return (
                      <Icon iconName="CheckMark" style={{ color: "green" }} />
                    );
                  return (
                    <Icon iconName="ChromeClose" style={{ color: "red" }} />
                  );
                },
              },
              {
                key: "text",
                minWidth: 0,
                name: "Auszug",
                onRender: (item: any) => <Markdown>{item?.text}</Markdown>,
              },
              {
                key: "updatedByProfile",
                minWidth: 0,
                name: "aktualisiert von",
                onRender: (item: any) => {
                  return (
                    <Link
                      onClick={() => {
                        Swal.fire({
                          buttonsStyling: false,
                          title: "Noch nicht verfügbar",
                          text:
                            "Diese Funktion ist in Planung." ||
                            "Unbekannter Fehler",
                        });
                      }}
                    >
                      {item?.updatedByProfile?.firstName +
                        " " +
                        item?.updatedByProfile?.lastName}
                    </Link>
                  );
                },
              },
              {
                key: "createdByProfile",
                minWidth: 0,
                name: "erstellt von",
                onRender: (item: any) => {
                  return (
                    <Link
                      onClick={() => {
                        Swal.fire({
                          buttonsStyling: false,
                          title: "Noch nicht verfügbar",
                          text:
                            "Diese Funktion ist in Planung." ||
                            "Unbekannter Fehler",
                        });
                      }}
                    >
                      {item?.createdByProfile?.firstName +
                        " " +
                        item?.createdByProfile?.lastName}
                    </Link>
                  );
                },
              },
            ]}
            isHeaderVisible={true}
            items={list.items}
            onItemInvoked={(item) => addWindow.Note.view(item?.id)}
            selectionMode={SelectionMode.none}
            styles={{ root: { cursor: "pointer", borderRadius: "0px 0px 16px 16px" } }}
          />
        </Stack>
      )}
    </Stack>
  );
};

export default ListTodos;
