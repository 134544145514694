import { IconButton, Label, Link, Stack, Text } from "@fluentui/react";
import { returnMobilephone } from "../assets/functions";
import { Icons } from "../assets/icons";
import { useDeleteEntry } from "../assets/mutations";
import { myStack, myTokens } from "../assets/styles";
import { addWindow } from "../assets/windows";
import { MutationProgressIndicator } from "../components/misc";

const Contacts: React.FC<{
  contacts: any;
  primaryOnly?: boolean;
  secondaryOnly?: boolean;
}> = ({ contacts, primaryOnly = false, secondaryOnly = false }) => {
  const isAdmin = true;
  const deleteContact = useDeleteEntry();

  return (
    <Stack tokens={myTokens}>
      <MutationProgressIndicator mutationQuery={deleteContact}  />

      {contacts?.map((contact: any) => {
        const {
          firstName,
          lastName,
          id,
          isPrimary,
          description,
          route,
          street_number,
          postal_code,
          locality,
          landline,
          mobilephone,
          email,
        } = contact;
        if (primaryOnly && !isPrimary) return null;
        if (secondaryOnly && isPrimary) return null;
        return (
          <Stack key={id}>
            <Stack horizontal>
              <Label>{isPrimary ? "Primärer Kontakt" : description}</Label>
              <Stack grow />
              {!isPrimary && (
                <IconButton
                  styles={{ root: { borderRadius: 16 } }}
                  disabled={!isAdmin}
                  iconProps={{ iconName: Icons.Delete }}
                  onClick={() =>
                    deleteContact.mutate({ id: contact.id, type: "contact" })
                  }
                />
              )}
              <IconButton
                styles={{ root: { borderRadius: 16 } }}
                disabled={!isAdmin}
                iconProps={{ iconName: Icons.Edit }}
                onClick={() => addWindow.Contact.view(id)}
              />
            </Stack>
            <Stack horizontal>
              {firstName && lastName && (
                <Stack styles={myStack}>
                  <Stack>
                    <Text variant="xSmall">Kontakt</Text>
                    <Text variant="medium">
                      {firstName} {lastName}
                    </Text>
                  </Stack>
                </Stack>
              )}
              <Stack styles={myStack}>
                {route && (
                  <Stack>
                    <Text variant="xSmall">Adresse</Text>
                    <Text variant="medium">
                      {route} {street_number}
                    </Text>
                    <Text variant="medium">
                      {postal_code} {locality}
                    </Text>
                  </Stack>
                )}
              </Stack>
              <Stack styles={myStack}>
                {email && (
                  <Stack>
                    <Text variant="xSmall">E-Mail</Text>
                    <Link href={"mailto:" + email} variant="medium">{email}</Link>
                  </Stack>
                )}
                {landline && (
                  <Stack>
                    <Text variant="xSmall">Festnetznummer</Text>
                    <Text variant="medium">{returnMobilephone(landline)}</Text>
                  </Stack>
                )}
                {mobilephone && (
                  <Stack>
                    <Text variant="xSmall">Mobilnummer</Text>
                    <Text variant="medium">{returnMobilephone(mobilephone)}</Text>
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
};

export default Contacts;
