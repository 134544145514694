import { Label, Link, Stack, Text } from "@fluentui/react";
import { useEffect, useState } from "react";
import { returnErrorsToReset, returnServerError } from "../assets/functions";
import { useUpdateWorkLog } from "../assets/mutations";
import { useWorkLog } from "../assets/queries";
import { useClientStore } from "../assets/stores";
import { myColumnRight } from "../assets/styles";
import { addWindow, closeWindow } from "../assets/windows";
import Create from "../components/forms/Create";
import { FormColumnLeft } from "../components/forms/Parts";
import { MutationProgressIndicator, PushDown } from "../components/misc";
import { ViewContent, ViewFooter } from "./Parts";
const { getState, setState } = useClientStore

const ViewUpdateWorkLog: React.FC<{
    workLogId: string;
    windowId: string;
}> = ({ workLogId, windowId }) => {
    const [form, setForm] = useState<any>({});
    const [formErrors, setFormErrors] = useState<any>({
        error: undefined,
        formErrors: undefined,
        isKnownError: false,
        isMultiline: false,
        message: "Unbekannter Fehler",
        secondaryMessage: undefined,
        type: "severeWarning",
    });
    const mutation = useUpdateWorkLog(workLogId);
    const workLog = useWorkLog(workLogId)

    useEffect(() => {
        let newWindows = getState().openWindows.filter((window: any) => {
            if (window.window.id === "updateWorkLog" + workLogId) {
                window.window.title = workLog.data?.publicKey + " bearbeiten"
            }
            return true
        })

        setState({ openWindows: newWindows })
    }, [workLog.isSuccess])

    const change = (updatedFields: any, oldForm: any = form) => {
        setForm({ ...oldForm!, ...updatedFields });
        setFormErrors({
            ...formErrors,
            formErrors: {
                ...formErrors?.formErrors,
                ...returnErrorsToReset(updatedFields),
            },
        });
    };

    useEffect(() => {
        if (mutation.isSuccess) closeWindow(windowId);
        if (mutation.isError) setFormErrors(returnServerError(mutation.error));
    }, [mutation.error, mutation.isError, mutation.isSuccess, windowId]);

    // styles
    const stackStyles: any = { root: { width: "100%" } };
    const textFieldStyle: any = { root: { width: "100%" } };

    return (
        <Stack>
            <ViewContent>
                <MutationProgressIndicator mutationQuery={mutation} />

                <Create
                    form={form}
                    formErrors={formErrors?.formErrors}
                    onChange={(updatedFields: any) => change(updatedFields, form)}
                    type="workLog"
                />

                <PushDown by={20} />

                <Stack grow horizontal styles={stackStyles}>
                    <FormColumnLeft label="Zugehörigkeit" />

                    <Stack grow horizontal styles={myColumnRight}>
                        <Text styles={textFieldStyle}>
                            <Label>Job</Label>
                            <Link onClick={() => addWindow.Job.view(workLog.data?.job.data?.id)}>
                                {workLog.data?.job.data?.title}
                            </Link>
                        </Text>

                        <Text styles={textFieldStyle}>
                            <Label>Jugendliche*r</Label>
                            <Link onClick={() => addWindow.Profile.view(workLog.data?.employee.data?.id)}>
                                {workLog.data?.employee.data?.firstName + " " + workLog.data?.employee.data?.lastName}
                            </Link>
                        </Text>
                    </Stack>
                </Stack>
            </ViewContent>

            <ViewFooter
                onCreate={() => mutation.mutate(form)}
                mutationQuery={mutation}
            />
        </Stack>
    );
};

export default ViewUpdateWorkLog;
