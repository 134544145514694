import {
  DefaultButton,
  Icon,
  Link,
  Pivot,
  PivotItem,
  Stack,
  Text,
  Toggle,
  useTheme
} from "@fluentui/react";
import { format, formatDistance } from "date-fns";
import { de } from "date-fns/locale";
import Markdown from "markdown-to-jsx";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Icons } from "../assets/icons";
import { useNoteDelete, useNoteUpdate } from "../assets/mutations";
import { useMyProfile, useNoteById } from "../assets/queries";
import { useClientStore } from "../assets/stores";
import { addWindow, closeWindow } from "../assets/windows";
import {
  FieldStringUpdate
} from "../components/forms/Parts";
import {
  InternalMeta,
  MutationProgressIndicator,
  PullUp,
  PushDown,
  QueryProgressIndicator
} from "../components/misc";
import PivotSubMenu, { ViewContent } from "./Parts";
const { getState, setState, } = useClientStore

const ViewNote: React.FC<{ noteId: string; windowId: string }> = ({
  noteId,
  windowId,
}) => {
  const deleteNote = useNoteDelete(windowId);
  const query = useNoteById(noteId);
  const updateNote = useNoteUpdate(noteId);
  const myProfile = useMyProfile();
  const [isDone, setIsDone] = useState({
    needsToBeConfirmed: false,
    isDone: false,
    date: undefined,
  });

  useEffect(() => {
    let newWindows = getState().openWindows.filter((window: any) => {
      if (window.window.id === "viewNote" + noteId) {
        window.window.title = query.data?.publicKey
      }
      return true
    })

    setState({ openWindows: newWindows })
  }, [query.isSuccess])

  useEffect(() => {
    query.data?.confirmationsNeeded?.forEach((confirmation: any) => {
      if (confirmation.profileId === myProfile.data?.id)
        setIsDone({ ...isDone, needsToBeConfirmed: true });
    });
  }, [
    query.isSuccess,
    myProfile.isSuccess,
    myProfile.isRefetching,
    query.isRefetching,
  ]);

  useEffect(() => {
    query.data?.confirmations?.forEach((confirmation: any) => {
      if (confirmation.profileId === myProfile.data?.id)
        setIsDone({ ...isDone, isDone: true, date: confirmation.date });
    });
  }, [
    query.isSuccess,
    myProfile.isSuccess,
    myProfile.isRefetching,
    query.isRefetching,
  ]);

  // styles
  const theme = useTheme();
  const pivotStyles: any = {
    root: {
      background: theme.palette.white,
      left: 0,
      // position: "fixed",
      // top: 45,
      width: "100%",
      zIndex: 10,
      borderRadius: "16px 16px 0px 0px",
    },
  };

  useEffect(() => {
    if (query.isSuccess) {
      let read = false;
      query.data?.readByProfiles.forEach((profile: any) => {
        if (profile.profileId === myProfile.data?.id) read = true;
      });
      if (!read) {
        updateNote.mutate({
          readByProfiles: [
            ...query.data?.readByProfiles,
            {
              profileId: myProfile.data?.id,
              date: new Date(),
              name: myProfile.data?.firstName + " " + myProfile.data?.lastName,
            },
          ],
        });
      }
    }
  }, [query.isSuccess, myProfile.isSuccess]);

  let actions: any = [
    {
      key: "delete",
      iconProps: { iconName: Icons.Delete },
      onClick: async () => {
        Swal.fire({
          buttonsStyling: false,
          confirmButtonText: "Löschen",
          focusCancel: true,
          cancelButtonText: "Abbrechen",
          showCancelButton: true,
          title: "Achtung",
          html:
            "Die Notiz kann NICHT mehr wiederhergestellt werden.",
          reverseButtons: true,
        }).then(async (event: any) => {
          if (event.isConfirmed) {
            closeWindow(windowId)
            await deleteNote.mutateAsync(noteId);
          }
        });
      },
      text: "Löschen",
    },
  ]

  return (
    <Stack>
      <ViewContent>
        <MutationProgressIndicator mutationQuery={updateNote}  />

        <MutationProgressIndicator mutationQuery={deleteNote}  />

        <QueryProgressIndicator  query={query} />

        <PullUp by={20} />

        <Pivot styles={pivotStyles}>
          <PivotItem headerText="Notiz" itemIcon={Icons.Note}>
            <PivotSubMenu>
              <Stack grow></Stack>
              <Stack slot="end">
                <DefaultButton styles={{ root: { borderRadius: 16 } }} primary menuProps={{ items: actions }}>Aktionen</DefaultButton>
              </Stack>

            </PivotSubMenu>

            <Stack styles={{ root: { background: theme.palette.white, padding: 20, borderRadius: "0px 0px 16px 16px" } }}>
              <Stack horizontal>
                <Stack>
                  {query.data?.concerns && (
                    <Text style={{ fontWeight: 700, marginTop: 5 }}>
                      Betrifft{" "}
                      <Link
                        onClick={() => {
                          if (query.data?.modelType === "profile") {
                            addWindow.Profile.view(query.data?.modelId);
                          }
                          if (query.data?.modelType === "job") {
                            addWindow.Job.view(query.data?.modelId);
                          }
                        }}
                      >
                        {query.data?.concerns}
                      </Link>
                    </Text>
                  )}
                </Stack>

                <Stack grow horizontalAlign="end">
                  <Toggle
                    checked={isDone.isDone}
                    disabled={!isDone.needsToBeConfirmed || isDone.isDone}
                    onClick={async () => {
                      await updateNote.mutateAsync({
                        confirmations: [
                          ...query.data?.confirmations,
                          {
                            date: new Date(),
                            name:
                              myProfile.data?.firstName +
                              " " +
                              myProfile.data?.lastName,
                            profileId: myProfile.data?.id,
                          },
                        ],
                      });
                    }}
                    inlineLabel
                    label={
                      isDone.isDone && isDone.date
                        ? "erledigt am " +
                        format(new Date(isDone.date), "dd.MM.yyy")
                        : "Als erledigt markieren"
                    }
                  />
                </Stack>
              </Stack>

              <Stack grow horizontal></Stack>

              <PushDown by={20} />

              {query.data?.text && (
                <Stack
                  style={{
                    background: theme.palette.neutralLighterAlt,
                    padding: 10,
                  }}
                >
                  <Markdown>{query.data?.text}</Markdown>
                </Stack>
              )}

              <br />

              {query.data?.document?.file?.url && (
                <Stack>
                  <Text style={{ fontWeight: 700 }}>Anhang</Text>
                  <Link href={query.data?.document?.file?.url}>{query.data?.document?.name}</Link>
                </Stack>
              )}

              <br />

              {query.data?.comments.length === 0 && (
                <Stack horizontal>
                  <Text style={{ fontWeight: 700 }}>Keine Kommentare</Text>
                </Stack>
              )}

              {query.data?.comments?.map((comment: any, index: any) => {
                return (
                  <Stack
                    style={{
                      background:
                        index % 2 === 0
                          ? "rgba(0, 0, 0, 0.025)"
                          : "rgba(0, 0, 0, 0.05)",
                      padding: 10,
                    }}
                  >
                    <Text>"{comment?.text}"</Text>
                    <Text style={{ fontSize: 12 }}>
                      {comment?.name}
                      {", "}
                      {comment.date &&
                        format(new Date(comment?.date), "dd.MM.yyy, HH:mm")}
                      {" Uhr"}
                    </Text>
                  </Stack>
                );
              })}

              <br />

              <DefaultButton
                styles={{ root: { borderRadius: 16 } }}
                onClick={() => {
                  Swal.fire({
                    buttonsStyling: false,
                    cancelButtonText: "Abbrechen",
                    title: "Kommentar",
                    input: "textarea",
                    reverseButtons: true,
                    showCancelButton: true,
                  }).then(async (v) => {
                    if (!v.value) return;
                    await updateNote.mutateAsync({
                      comments: [
                        ...query.data?.comments,
                        {
                          date: new Date(),
                          name:
                            myProfile.data?.firstName +
                            " " +
                            myProfile.data?.lastName,
                          text: v.value,
                        },
                      ],
                    });
                  });
                }}
              >
                Kommentieren
              </DefaultButton>
            </Stack>
          </PivotItem>

          {myProfile.data?.id === query.data?.createdByProfile?.id && (
            <PivotItem headerText="Bearbeiten" itemIcon={Icons.Edit}>

              <Stack styles={{ root: { background: theme.palette.white, padding: 20, borderRadius: "0px 0px 16px 16px" } }}>
                {query.data && (
                  <Stack>
                    <Stack horizontal>
                      <FieldStringUpdate
                        allowMutation
                        field="title"
                        label="Titel"
                        mutation={(payload: any) => updateNote.mutate(payload)}
                        query={query}
                      />

                      <Toggle
                        label="Hervorheben"
                        onChange={(event: any, checked: any) =>
                          updateNote.mutate({ showInDossier: checked })
                        }
                        checked={query.data?.showInDossier}
                      />
                    </Stack>

                    <FieldStringUpdate
                      allowMutation
                      field="text"
                      label="Notiz"
                      multiline
                      mutation={(payload: any) => updateNote.mutate(payload)}
                      query={query}
                      rows={25}
                    />
                  </Stack>
                )}
              </Stack>
            </PivotItem>
          )}

          <PivotItem headerText="Status" itemIcon={Icons.Read}>

            <Stack styles={{ root: { background: theme.palette.white, padding: 20, borderRadius: "0px 0px 16px 16px" } }}>

              <Text style={{ fontWeight: 700 }}>Gelesen?</Text>

              {query.data?.readByProfiles?.map((profile: any) => {
                return (
                  <Stack>
                    <Text>
                      {profile?.name}
                      {", "}
                      {profile.date &&
                        formatDistance(new Date(profile.date), new Date(), {
                          addSuffix: true,
                          locale: de,
                        })}
                    </Text>
                  </Stack>
                );
              })}

              <br />
              <br />

              {query.data?.type === "todo" && (
                <Stack>
                  <Text style={{ fontWeight: 700 }}>Erledigt?</Text>

                  {query.data?.confirmationsNeeded?.map((profile: any) => {
                    let isDone = false;
                    query.data?.confirmations.forEach((p: any) => {
                      if (profile.profileId === p.profileId) isDone = true;
                    });

                    return (
                      <Stack>
                        <Text style={{ color: isDone ? "green" : "red" }}>
                          {isDone && (
                            <Icon iconName="CheckMark" style={{ fontSize: 10 }} />
                          )}
                          {!isDone && (
                            <Icon
                              iconName="ChromeClose"
                              style={{ fontSize: 10 }}
                            />
                          )}{" "}
                          {profile?.name}
                          {isDone &&
                            profile.date &&
                            ", " +
                            format(
                              new Date(profile?.date),
                              "dd. MMMM yyy, HH:mm",
                              { locale: de }
                            )}
                        </Text>
                      </Stack>
                    );
                  })}
                </Stack>
              )}
            </Stack>
          </PivotItem>

          <PivotItem headerText="" itemIcon={Icons.Warning}>
            <InternalMeta query={query} />
          </PivotItem>
        </Pivot>
      </ViewContent>
    </Stack>
  );
};

export default ViewNote;
