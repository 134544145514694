import { DetailsList, Link, SelectionMode, Stack, Text } from '@fluentui/react';
import { format } from 'date-fns';
import { de } from 'date-fns/locale';
import React from 'react';
import { useHistory } from '../assets/queries';
import { addWindow } from '../assets/windows';

const ListHistory: React.FC<{ modelType: string, modelId: string }> = ({ modelType, modelId }) => {
    const history = useHistory(modelType, modelId)

    return (
        <Stack>
            {history.isSuccess && <DetailsList columns={[
                {
                    key: "date",
                    fieldName: "date",
                    minWidth: 30,
                    maxWidth: 125,
                    name: "Datum",
                    onRender: (item: any) => {
                        return format(new Date(item?.date), "dd. MMM yyy, hh:mm", { locale: de })
                    }
                },
                {
                    key: "mutation",
                    fieldName: "Mutation",
                    minWidth: 0,
                    name: "Mutation",
                    onRender: (item: any) => {
                        return (
                            <Stack>
                                <Text>
                                    {item?.mutation.title}
                                </Text>
                                <Text>
                                    {item?.mutation.fieldValue}
                                </Text>
                                {item?.mutation.contactId && (
                                    <Link onClick={() => addWindow.Contact.view(item?.mutation.contactId)}>{item?.mutation.fieldValue}</Link>
                                )}
                                <Text>
                                    durch <Link onClick={() => {
                                        if (item?.mutation.profileType === "account") return addWindow.Account.byId(item?.mutation.profileId)
                                        addWindow.Profile.view(item?.mutation.profileId)
                                    }
                                    }>{item?.mutation.profileDescription}</Link>
                                </Text>
                            </Stack>
                        )
                    }
                },
            ]} items={history.data} selectionMode={SelectionMode.none}
                styles={{ root: { cursor: "pointer", borderRadius: "0px 0px 16px 16px" } }}
            />}
        </Stack>
    )
}

export default ListHistory