import axios from "axios";
import jwt_decode from 'jwt-decode';
const axiosJWT = axios.create()

const getRefreshToken = async () => {
    try {
        const res = await axios.post(process.env.REACT_APP_SERVER + "/api/token/refresh", {
            refreshToken: localStorage.refreshToken
        })

        return res.data;
    } catch (err) {
        console.log(err);
    }
};

axiosJWT.interceptors.request.use(
    async (config: any) => {
        const accessToken = localStorage.jwt
        if (accessToken) {
            let currentDate = new Date();
            const decodedToken: any = jwt_decode(accessToken);
            if (decodedToken.exp * 1000 < currentDate.getTime()) {
                const data: any = await getRefreshToken();
                if (data) {
                    localStorage.jwt = data.jwt
                    config.headers["authorization"] = "Bearer " + data.jwt;
                } else {
                }
            } else {
                config.headers["authorization"] = "Bearer " + accessToken;
            }
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axiosJWT