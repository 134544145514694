import { DefaultButton, Image, Separator, Stack, Text } from "@fluentui/react";
import { useRef, useState } from "react";
import { useQueryClient } from "react-query";
import Swal from "sweetalert2";
import axiosJWT from "../assets/axiosjwt";
import { myStack, myTextCenter, myTokens } from "../assets/styles";
import { ViewContent } from "../views/Parts";

const QrUploader: React.FC<{
  profileId: string;
  windowId: string;
  setShowQrUploader: any;
}> = ({ profileId, windowId, setShowQrUploader }) => {
  const [done, setDone] = useState(false);
  const queryClient = useQueryClient();
  const uploader = useRef<any>();

  // styles
  const imageStyles: any = { root: { margin: "auto" } };

  return (
    <Stack styles={myStack}>
      <ViewContent>
        {!done && (
          <Stack styles={myTextCenter} tokens={myTokens}>
            <Stack tokens={myTokens}>
              <Text variant="medium">
                Profilbild von diesem Gerät aus hochladen:
              </Text>

              <DefaultButton primary onClick={() => uploader.current.click()} styles={{ root: { borderRadius: 16 } }}>
                Profilbild hochladen
              </DefaultButton>

              <input
                id="file"
                onChange={async (e: any) => {
                  try {
                    let form = new FormData();
                    form.append("files", e.target.files[0]);
                    form.append("ref", "api::profile.profile");
                    form.append("refId", profileId);
                    form.append("field", "profileImage");

                    const uploadRes: any = await axiosJWT.post(
                      process.env.REACT_APP_SERVER + "/api/upload", form);

                    await queryClient.refetchQueries();

                    setDone(true);
                  } catch (error) {
                    console.log(error);
                    Swal.fire({
                      buttonsStyling: false,
                      title: "Fehler",
                      text: "Wende dich an den Support.",
                    });
                  }
                }}
                ref={uploader}
                style={{ display: "none" }}
                type="file"
              />
            </Stack>

            <Separator />

            <Stack tokens={myTokens}>
              <Text variant="medium">
                Profilbild von einem anderen Gerät aus hochladen:
              </Text>

              {process.env.NODE_ENV === "development" && (
                <Image
                  src={
                    "https://api.qrserver.com/v1/create-qr-code/?size=100x100&data=" +
                    "http://192.168.0.10:3000" +
                    "/uploader/" +
                    profileId +
                    "/uploadProfileImage"
                  }
                  styles={imageStyles}
                />
              )}

              {process.env.NODE_ENV === "production" && (
                <Image
                  src={
                    "https://api.qrserver.com/v1/create-qr-code/?size=100x100&data=" +
                    "https://bern.jobfairy.ch" +
                    "/uploader/" +
                    profileId +
                    "/uploadProfileImage"
                  }
                  styles={imageStyles}
                />
              )}
            </Stack>
          </Stack>
        )}

        {done && (
          <Stack>
            <Text variant="medium" styles={{ root: { textAlign: "center" } }}>
              Profilbild erfolgreich hochgeladen.
            </Text>

            <br />

            <DefaultButton onClick={() => setShowQrUploader(false)} styles={{ root: { borderRadius: 16 } }}>Schliessen</DefaultButton>
          </Stack>
        )}
      </ViewContent>
    </Stack>
  );
};

export default QrUploader;
