import {
  DefaultButton,
  DetailsList,
  Icon,
  Link,
  Modal,
  SelectionMode,
  Spinner,
  Stack,
} from "@fluentui/react";
import { useEffect, useState } from "react";
import { Icons } from "../assets/icons";
import {
  useGenerateAuftragsbestaetigung,
  useGenerateRapportblatt,
} from "../assets/mutations";
import { useJobsByEmployee } from "../assets/queries";
import { addWindow } from "../assets/windows";
import { PullUp, QueryProgressIndicator } from "../components/misc";
import ViewCreateWorkLog from "../views/ViewCreateWorkLog";

const ListJobsByEmployee: React.FC<{
  employeeId: string;
}> = ({ employeeId }) => {
  const jobs = useJobsByEmployee(employeeId);
  const [list, setList] = useState<any>({
    items: [],
    isSorted: {},
  });
  const clear = () => setList({ ...list, items: jobs.data });
  const setItems = (items: Array<any>) => setList({ ...list, items: items });
  const setSorted = (isSorted: any) => {
    setList({ ...list, isSorted: { ...list.isSorted, ...isSorted } });
  };
  const [isLoading, setIsLoading] = useState(false);
  const generateRapportblatt = useGenerateRapportblatt();
  const generateAuftragsbestaetigung = useGenerateAuftragsbestaetigung();
  const [showCreateWorkLog, setShowCreateWorkLog] = useState(false);
  const [currentJobId, setCurrentJobId] = useState("");

  useEffect(() => {
    if (jobs.isSuccess) setItems(jobs.data);
    // eslint-disable-next-line
  }, [jobs.isSuccess, jobs.data]);

  return (
    <Stack>
      <Modal
        onDismiss={() => setShowCreateWorkLog(false)}
        isOpen={showCreateWorkLog}
        styles={{ main: { borderRadius: 16, height: "45vh", width: "50vw" } }}
      >
        <ViewCreateWorkLog
          employeeId={employeeId}
          windowId={"createWorkLog_" + employeeId}
          jobId={currentJobId}
          setShowCreateWorkLog={setShowCreateWorkLog}
        />
      </Modal>

      <QueryProgressIndicator query={jobs} />

      {jobs.isSuccess && jobs.data.length > 0 && (
        <Stack>
          {list.items.length < 10 && <PullUp by={20} />}

          <DetailsList
            columns={[
              {
                key: "title",
                minWidth: 0,
                name: "Titel",
                onRender: (item) => {
                  return item?.title;
                },
              },
              {
                fieldName: "jobfair",
                key: "jobfair",
                minWidth: 0,
                name: "zuständig",
                onRender: (item: any) => {
                  return item?.jobfairs.map((jf: any) => {
                    return jf.text + " ";
                  });
                },
              },
              {
                key: "employer",
                minWidth: 0,
                name: "Auftraggeber*in",
                onRender: (item) => {
                  if (item?.employer?.type === "publicEmployer") {
                    return (
                      <Link
                        onClick={() =>
                          addWindow.Profile.view(item?.employer?.id)
                        }
                      >
                        {item?.employer?.description}
                      </Link>
                    );
                  } else {
                    return (
                      <Link
                        onClick={() =>
                          addWindow.Profile.view(item?.employer?.id)
                        }
                      >
                        {item?.employer?.firstName +
                          " " +
                          item?.employer?.lastName}
                      </Link>
                    );
                  }
                },
              },
              {
                key: "rapportblatt",
                minWidth: 0,
                name: "Rapportblatt",
                onRender: (item: any) => {
                  return (
                    <Link
                      disabled={item.isArchived}
                      onClick={async () => {
                        setIsLoading(true);
                        await generateRapportblatt.mutateAsync({
                          jobId: item?.id,
                          employeeId: employeeId,
                        });
                        setIsLoading(false);
                      }}
                    >
                      {isLoading && <Spinner />}
                      {!isLoading && !item.isArchived && "Herunterladen"}
                    </Link>
                  );
                },
              },
              {
                key: "auftragsbestaetigung",
                minWidth: 0,
                name: "Auftragsbestätigung",
                onRender: (item: any) => {
                  return (
                    <Link
                      onClick={async () => {
                        setIsLoading(true);
                        await generateAuftragsbestaetigung.mutateAsync({
                          jobId: item?.id,
                          employeeId: employeeId,
                        });
                        setIsLoading(false);
                      }}
                    >
                      {isLoading && <Spinner />}
                      {!isLoading && "Herunterladen"}
                    </Link>
                  );
                },
              },
              {
                key: "addWorkLog",
                minWidth: 0,
                name: "Arbeitseinsatz",
                onRender: (item: any) => {
                  return (
                    // <IconButton
                    //   iconProps={{ iconName: Icons.WorkLog }}
                    //   // onClick={() => addWindow.WorkLog.add(employeeId, item?.id)}
                    //   onClick={() => {
                    //     setCurrentJobId(item.id)
                    //     setShowCreateWorkLog(true)
                    //   }}
                    //   styles={{
                    //     icon: { fontSize: 15 },
                    //     iconHovered: { color: "#FFD23F" },
                    //     root: { borderRadius: 16, height: 20, width: 20 },
                    //   }}
                    // />
                    <DefaultButton
                      disabled={item.isArchived}
                      primary
                      onClick={() => {
                        setCurrentJobId(item.id);
                        setShowCreateWorkLog(true);
                      }}
                    >
                      <Icon iconName={Icons.WorkLog} />
                    </DefaultButton>
                  );
                },
              },
              {
                fieldName: "archived",
                key: "archived",
                minWidth: 0,
                name: "archiviert",
                onRender: (item: any) => {
                  if (item.isArchived) return "ARCHIVIERT";
                  return "";
                },
              },
              // {
              //   key: "addAssignment",
              //   minWidth: 0,
              //   name: "Termin",
              //   onRender: (item: any) => {
              //     return (
              //       <IconButton
              //         iconProps={{ iconName: Icons.Assignment }}
              //         onClick={() =>
              //           addWindow.Assignment.add(employeeId, item?.id)
              //         }
              //         styles={{
              //           icon: { fontSize: 15 },
              //           iconHovered: { color: "#FFD23F" },
              //           root: { height: 20, width: 20 },
              //         }}
              //       />
              //     );
              //   },
              // },
            ]}
            isHeaderVisible={true}
            items={list.items}
            onItemInvoked={(item) => addWindow.Job.view(item!.id)}
            selectionMode={SelectionMode.none}
            styles={{
              root: { cursor: "pointer", borderRadius: "0px 0px 16px 16px" },
            }}
          />
        </Stack>
      )}
    </Stack>
  );
};

export default ListJobsByEmployee;
