import { DefaultButton, Layer, Stack, useTheme } from "@fluentui/react";
import React from "react";
import { useIsServerRunning } from "../assets/functions";
import { myButton } from "../assets/styles";
import { Logo, PushDown } from "../components/misc";

const ScreenLoadingIndicator: React.FC = ({ children }) => {
  const connection = useIsServerRunning();

  return (
    <Layer>
      {!connection.isSuccess && <NoServerConnection />}

      {connection.isSuccess && children}
    </Layer>
  );
};

export default ScreenLoadingIndicator;

export const NoServerConnection: React.FC = () => {
  const connection = useIsServerRunning();
  const theme = useTheme()

  return (
    <Stack
      styles={{ root: { background: theme.palette.themeLight, height: "100vh", zIndex: 100 } }}
    >
      <Stack styles={{ root: { margin: "auto" } }}>
        <Logo type="text" />

        <PushDown by={20} />

        <DefaultButton
          disabled={connection.failureCount < 3}
          onClick={() => window.location.reload()}
          styles={myButton}
          text={connection.failureCount < 3 ? "Verbinden ..." : "Wiederholen"}
        />
      </Stack>
    </Stack>
  );
};
