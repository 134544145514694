import { Stack } from "@fluentui/react";
import React, { useEffect } from "react";
import { Redirect } from "react-router";
import { useSetup } from "./assets/functions";
import { useMyUser } from "./assets/queries";
import { addWindow } from "./assets/windows";
import Dashboard from "./screens/Dashboard";
import Windows from "./wrappers/Windows";

const App: React.FC = () => {
  const user = useMyUser();
  useSetup();

  useEffect(() => {
    if (localStorage.windowsToBeOpened) {
      let windowsToBeOpened = JSON.parse(localStorage.windowsToBeOpened)

      windowsToBeOpened.forEach((window: any) => {
        addWindow[window.type][window.call](window.id)
      })
    }
  }, [])

  return (
    <Stack>
      {/* if user cannot possibly be logged in, redirect to /login */}
      {/* if user cannot possibly be logged in, redirect to /login */}
      {(!localStorage.userId || !localStorage.jwt) && <Redirect to="/login" />}

      {/* if fetching user fails, redirect to /login */}
      {!user.isLoading && !user.data && <Redirect to="/login" />}

      {/* else */}
      {!user.isLoading && user.data && (
        <Stack>
          <Windows />
          <Dashboard />
        </Stack>
      )}
    </Stack>
  );
};

export default App;
