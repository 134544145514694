import {
  DefaultButton,
  Label,
  Pivot,
  PivotItem,
  Slider,
  Spinner,
  Stack,
  Text, useTheme
} from "@fluentui/react";
import { useState } from "react";
import { useQueryClient } from "react-query";
import Swal from "sweetalert2";
import axiosJWT from "../assets/axiosjwt";
import { Icons } from "../assets/icons";
import { useUpdateConditions } from "../assets/mutations";
import { useConditions, useInvoicesBySmallInvoice, useReceipts } from "../assets/queries";
import { myTokens } from "../assets/styles";
import {
  MutationProgressIndicator,
  PullUp,
  PushDown,
  QueryProgressIndicator
} from "../components/misc";
import ListInvoices from "../lists/ListInvoices";
import ListReceipts from "../lists/ListReceipts";
import PivotSubMenu, { ViewContent } from "./Parts";
import ViewStats from "./ViewStats";

const ViewBundleAccounting: React.FC<{
  defaultSelectedKey: string;
  windowId: string;
}> = ({ defaultSelectedKey, windowId }) => {
  const conditions = useConditions();
  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();
  const updateConditions = useUpdateConditions();
  const theme = useTheme()
  const invoices: any = useInvoicesBySmallInvoice();
  const receipts = useReceipts(100)

  return (
    <Stack>
      <ViewContent>
        <Pivot overflowBehavior="menu" styles={{
          root: {
            background: theme.palette.white, zIndex: 10, left: 0,
            borderRadius: "16px 16px 0px 0px",
            width: "100%",
          }
        }}>
          <PivotItem headerText="Rechnungen" itemIcon={Icons.Accounting}>
            <PivotSubMenu>
              <Stack tokens={myTokens}>
                <DefaultButton
                  styles={{ root: { borderRadius: 16 } }}
                  iconProps={{ iconName: Icons.Invoice }}
                  onClick={async () => {
                    Swal.fire({
                      buttonsStyling: false,
                      confirmButtonText: "Ja",
                      cancelButtonText: "Noch nicht",
                      focusCancel: true,
                      showCancelButton: true,
                      title: "Sicher?",
                      text: "Damit löst du alle Rechnungen aus, die mindestens eine der 3 Bedingungen erfüllen.",
                      reverseButtons: true,
                    }).then(async (event: any) => {
                      if (event.isConfirmed) {
                        setIsLoading(true);
                        await axiosJWT.get(
                          process.env.REACT_APP_SERVER +
                          "/api/a/generate-all-possible-invoices",
                        );
                        await queryClient.invalidateQueries(
                          "invoices-by-smallinvoice"
                        );
                        setIsLoading(false);
                      }
                    });
                  }}
                >
                  {isLoading && <Spinner />}
                  {!isLoading && "Alle Rechnungen auslösen"}
                </DefaultButton>
              </Stack>
            </PivotSubMenu>

            <ListInvoices query={invoices} />
          </PivotItem>

          <PivotItem headerText="Quittungen" itemIcon={Icons.Receipt}>
            <PullUp by={100} />

            <ListReceipts query={receipts} />
          </PivotItem>

          <PivotItem headerText="Statistiken" itemIcon={Icons.Statistics}>
            <PullUp by={40} />

            <ViewStats />
          </PivotItem>

          <PivotItem headerText="Einstellungen" itemIcon={Icons.Settings}>
            <QueryProgressIndicator query={conditions} />

            <MutationProgressIndicator mutationQuery={updateConditions} />

            <Stack tokens={myTokens} styles={{ root: { background: theme.palette.white, borderRadius: "0px 0px 16px 16px", padding: 20 } }}>
              <Stack tokens={myTokens}>
                <Label>Bedingungen zur Rechnungs-Erstellung</Label>

                <Text variant="medium">
                  Beim (manuellen oder automatischen) Auslösen der
                  Rechnungs-Erstellung werden ausschliesslich Rechnungen
                  erstellt, die eine der folgenden Bedingungen erfüllen.
                </Text>

                <PushDown by={10} />

                <PushDown by={10} />

                <Stack horizontal tokens={myTokens}>
                  <Stack grow styles={{ root: { width: "100%" } }}>
                    <Label>Anzahl der Arbeitseinsätze</Label>
                  </Stack>

                  <Stack grow styles={{ root: { width: "100%" } }}>
                    <Slider
                      max={20}
                      min={1}
                      onChanged={(event, newValue) => {
                        updateConditions.mutate({
                          ...conditions.data,
                          afterCount: newValue,
                        });
                      }}
                      step={1}
                      defaultValue={conditions.data?.afterCount}
                    />
                  </Stack>
                </Stack>

                <Stack horizontal tokens={myTokens}>
                  <Stack grow styles={{ root: { width: "100%" } }}>
                    <Label>Höhe des Rechnungsbetrag</Label>
                  </Stack>

                  <Stack grow styles={{ root: { width: "100%" } }}>
                    <Slider
                      max={300}
                      min={15}
                      onChanged={(event, newValue) => {
                        updateConditions.mutate({
                          ...conditions.data,
                          afterAmount: newValue,
                        });
                      }}
                      step={15}
                      defaultValue={conditions.data?.afterAmount}
                    />
                  </Stack>
                </Stack>

                <Stack horizontal tokens={myTokens}>
                  <Stack grow styles={{ root: { width: "100%" } }}>
                    <Label>Tage seit letztem Einsatz</Label>
                  </Stack>

                  <Stack grow styles={{ root: { width: "100%" } }}>
                    <Slider
                      max={56}
                      min={7}
                      onChanged={(event, newValue) => {
                        updateConditions.mutate({
                          ...conditions.data,
                          afterDays: newValue,
                        });
                      }}
                      step={7}
                      defaultValue={conditions.data?.afterDays}
                    />
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </PivotItem>
        </Pivot>
      </ViewContent>
    </Stack>
  );
};

export default ViewBundleAccounting;
