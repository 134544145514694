import isElectron from "is-electron";
import Swal from "sweetalert2";
import QrUploader from "../components/QrUploader";
import AddCategories from "../views/AddCategories";
import { ViewAddContact, ViewContact } from "../views/Contact";
import { UploadDocument, ViewDocument } from "../views/Document";
import { ViewAddJob, ViewJob } from "../views/Job";
import { ViewCreateProfile, ViewProfile } from "../views/Profile";
import ViewAccount from "../views/ViewAccount";
import ViewAssignment from "../views/ViewAssignment";
import ViewBundleAccounting from "../views/ViewBundleAccounting";
import ViewCategories from "../views/ViewCategories";
import ViewCreateAssignment from "../views/ViewCreateAssignment";
import ViewCreateExport from "../views/ViewCreateExport";
import ViewCreateNote from "../views/ViewCreateNote";
import ViewCreateUser from "../views/ViewCreateUser";
import ViewCreateWorkLog from "../views/ViewCreateWorkLog";
import ViewInvoice from "../views/ViewInvoice";
import ViewLinkEmployee from "../views/ViewLinkEmployee";
import ViewMyAccount from "../views/ViewMyAccount";
import ViewNote from "../views/ViewNote";
import ViewSearch from "../views/ViewSearch";
import ViewTodos from "../views/ViewTodos";
import ViewUpdateWorkLog from "../views/ViewUpdateWorkLog";
import ViewWorkLog from "../views/ViewWorkLog";
import { setCurrentlyFocusedWindowsId } from "./functions";
import { useClientStore } from "./stores";
const { getState, setState } = useClientStore;

const open = (window: any) => {
  setState({ lastClickedWindowId: window.id, windowOrder: [...getState().windowOrder, window.id], openWindows: [...getState().openWindows, { window }] });
  setCurrentlyFocusedWindowsId(window.id);
};

const abortIfAlreadyOpen = (id: string) => {
  for (let i = 0; i < getState().openWindows.length; i++) {
    const window = getState().openWindows[i];
    if (window.window.id === id) {
      if (getState().currentlyFocusedWindowId === id) {
        Swal.fire({
          buttonsStyling: false,
          title: "Du befindest dich bereits in diesem Dossier.",
          confirmButtonText: "Alles klar!"
        })
      }
      setCurrentlyFocusedWindowsId(id);
      return true;
    }
  }

  return false;
};

const callOnReload = (id: string, type: string, call: string, windowId: string) => {
  if (!localStorage.windowsToBeOpened) localStorage.windowsToBeOpened = JSON.stringify([])

  let windowsToBeOpened = JSON.parse(localStorage.windowsToBeOpened)

  let abort = false

  windowsToBeOpened.forEach((window: any) => {
    if (window.windowId === windowId) abort = true
  })

  if (abort) return

  windowsToBeOpened = [...JSON.parse(localStorage.windowsToBeOpened), {
    id: id,
    type: type,
    call: call,
    windowId: windowId
  }]

  localStorage.windowsToBeOpened = JSON.stringify(windowsToBeOpened)
}

export const addWindow: any = {
  Category: {
    add: () => {
      let windowId = "preferredCategoriesLocalStorage";
      callOnReload("", "Category", "add", windowId)
      if (abortIfAlreadyOpen(windowId)) return;
      let content = <AddCategories windowId={windowId} />;
      if (isElectron()) {
        const electron = window.require("electron");
        electron.ipcRenderer.send("addWindow", {
          id: windowId,
          url: "preferredCategoriesLocalStorage",
        });
      } else {
        open({
          // changes to TAB-VIEW
          title: "Bevorzugte Rubrik(en)",
          content: content,
          height: 500,
          id: windowId,
          isFullscreen: false,
          width: 500,
        });
      }
    },
    edit: (profileId: string) => {
      let windowId = "preferredCategories";
      callOnReload(profileId, "Category", "edit", windowId)
      if (abortIfAlreadyOpen(windowId)) return;
      let content = <ViewCategories profileId={profileId} windowId={windowId} />;
      if (isElectron()) {
        const electron = window.require("electron");
        electron.ipcRenderer.send("addWindow", {
          id: windowId,
          url: "preferredCategories",
        });
      } else {
        open({
          // changes to TAB-VIEW
          title: "Bevorzugte Rubrik(en)",
          content: content,
          height: 500,
          id: windowId,
          isFullscreen: false,
          width: 500,
        });
      }
    }
  },
  Account: {
    me: () => {
      let windowId = "myAccount";
      callOnReload("", "Account", "me", windowId)
      if (abortIfAlreadyOpen(windowId)) return;
      let content = <ViewMyAccount windowId={windowId} />;
      if (isElectron()) {
        const electron = window.require("electron");
        electron.ipcRenderer.send("addWindow", {
          id: windowId,
          url: "myAccount",
        });
      } else {
        open({
          // changes to TAB-VIEW
          title: "Verwaltung",
          content: content,
          height: 750,
          id: windowId,
          isFullscreen: false,
          width: 750,
        });
      }
    },
    byId: (profileId: string) => {
      let windowId = "accountById" + profileId;
      callOnReload(profileId, "Account", "byId", windowId)
      if (abortIfAlreadyOpen(windowId)) return;
      let content = <ViewAccount profileId={profileId} windowId={windowId} />;
      if (isElectron()) {
        const electron = window.require("electron");
        electron.ipcRenderer.send("addWindow", {
          id: windowId,
          url: "accountById",
        });
      } else {
        open({
          // changes to TAB-VIEW
          title: "Lädt ...",
          content: content,
          height: 750,
          id: windowId,
          isFullscreen: false,
          width: 750,
        });
      }
    },
  },
  Assignment: {
    add: (employeeId: string, jobId: string) => {
      let windowId = "addAssignment" + employeeId + jobId;
      callOnReload(employeeId, "Assignment", "add", windowId)
      if (abortIfAlreadyOpen(windowId)) return;
      let content = (
        <ViewCreateAssignment
          employeeId={employeeId}
          jobId={jobId}
          windowId={windowId}
        />
      );
      open({
        // changes to TAB-VIEW
        title: "Neuer Termin",
        content: content,
        height: 400,
        id: windowId,
        isFullscreen: false,
        width: 500,
      });
    },
    view: (assignmentId: string) => {
      let windowId = "viewAssignment" + assignmentId;
      callOnReload(assignmentId, "Assignment", "view", windowId)
      if (abortIfAlreadyOpen(windowId)) return;
      let content = (
        <ViewAssignment assignmentId={assignmentId} windowId={windowId} />
      );
      open({
        // changes to TAB-VIEW
        title: "Lädt ...",
        content: content,
        height: 750,
        id: windowId,
        isFullscreen: false,
        width: 750,
      });
    },
  },
  Contact: {
    add: (profileId: string) => {
      let windowId = "addContact" + profileId;
      callOnReload(profileId, "Contact", "add", windowId)
      if (abortIfAlreadyOpen(windowId)) return;
      let content = (
        <ViewAddContact setShowAddContact={() => { }} profileId={profileId} windowId={windowId} />
      );
      if (isElectron()) {
        const electron = window.require("electron");
        electron.ipcRenderer.send("addWindow", {
          id: windowId,
          url: "addContact",
        });
      } else {
        open({
          // changes to TAB-VIEW
          title: "Neuer Kontakt",
          content: content,
          height: 500,
          id: windowId,
          isFullscreen: false,
          width: 500,
        });
      }
    },
    view: (contactId: string) => {
      let windowId = "viewContact" + contactId;
      callOnReload(contactId, "Contact", "view", windowId)
      if (abortIfAlreadyOpen(windowId)) return;
      let content = <ViewContact contactId={contactId} windowId={windowId} />;
      open({
        // changes to TAB-VIEW
        title: "Lädt ...",
        content: content,
        height: 500,
        id: windowId,
        isFullscreen: false,
        width: 500,
      });
    },
  },
  Job: {
    add: (profileId: string) => {
      let windowId = "addJob" + profileId;
      callOnReload(profileId, "Job", "add", windowId)
      if (abortIfAlreadyOpen(windowId)) return;
      let content = <ViewAddJob profileId={profileId} windowId={windowId} />;
      open({
        // changes to TAB-VIEW
        title: "Job hinzufügen",
        content: content,
        height: 750,
        id: windowId,
        isFullscreen: false,
        width: 750,
      });
    },
    view: (jobId: string) => {
      let windowId = "viewJob" + jobId;
      callOnReload(jobId, "Job", "view", windowId)
      if (abortIfAlreadyOpen(windowId)) return;
      let content = <ViewJob jobId={jobId} windowId={windowId} />;
      open({
        // changes to TAB-VIEW
        title: "Lädt ...",
        content: content,
        height: 750,
        id: windowId,
        isFullscreen: false,
        width: 750,
      });
    },
  },
  Profile: {
    create: (type: string) => {
      let windowId = "createProfile" + type;
      callOnReload(type, "Profile", "create", windowId)
      if (abortIfAlreadyOpen(windowId)) return;
      let content = <ViewCreateProfile type={type} windowId={windowId} />;
      open({
        // changes to TAB-VIEW
        title: "Neues Profil",
        content: content,
        height: 500,
        id: windowId,
        isFullscreen: false,
        width: 750,
      });
    },
    view: (profileId: string) => {
      let windowId = "viewProfile" + profileId;
      callOnReload(profileId, "Profile", "view", windowId)
      if (abortIfAlreadyOpen(windowId)) return;
      let content = <ViewProfile profileId={profileId} windowId={windowId} />;
      open({
        // changes to TAB-VIEW
        title: "Lädt ...",
        content: content,
        height: 750,
        id: windowId,
        isFullscreen: false,
        width: 750,
      });
    },
  },
  QrUploader: {
    open: (profileId: string) => {
      let windowId = "openQr" + profileId;
      callOnReload(profileId, "QrUploader", "open", windowId)
      if (abortIfAlreadyOpen(windowId)) return;
      let content = <QrUploader setShowQrUploader={() => { }} profileId={profileId} windowId={windowId} />;
      open({
        // changes to TAB-VIEW
        title: "Profilbild hochladen",
        content: content,
        height: 350,
        id: windowId,
        isFullscreen: false,
        width: 350,
      });
    },
  },
  Search: (searchString: string = "") => {
    let windowId = "search";
    if (abortIfAlreadyOpen(windowId)) return;
    let content = <ViewSearch searchString={searchString} windowId={windowId} />;
    open({
      // changes to TAB-VIEW
      title: "Suche",
      content: content,
      height: 750,
      id: windowId,
      isFullscreen: false,
      width: 750,
    });
  },
  Accounting: {
    view: (defaultSelectedKey: string) => {
      let windowId = "viewAccounting";
      callOnReload(defaultSelectedKey, "Accounting", "view", windowId)
      if (abortIfAlreadyOpen(windowId)) return;
      let content = (
        <ViewBundleAccounting
          defaultSelectedKey={defaultSelectedKey}
          windowId={windowId}
        />
      );
      open({
        // changes to TAB-VIEW
        title: "Buchhaltung",
        content: content,
        height: 750,
        id: windowId,
        isFullscreen: false,
        width: 750,
      });
    },
  },
  Employee: {
    assign: (jobId: string) => {
      let windowId = "assignEmployee" + jobId;
      callOnReload(jobId, "Employee", "assign", windowId)
      if (abortIfAlreadyOpen(windowId)) return;
      let content = <ViewLinkEmployee jobId={jobId} windowId={windowId} setShowLinkEmployee={() => { }} />;
      open({
        // changes to TAB-VIEW
        title: "Jugendliche*r zuweisen",
        content: content,
        height: 500,
        id: windowId,
        isFullscreen: false,
        width: 500,
      });
    },
  },
  Invoice: {
    view: (invoiceId: string) => {
      let windowId = "viewInvoice" + invoiceId;
      callOnReload(invoiceId, "Invoice", "view", windowId)
      if (abortIfAlreadyOpen(windowId)) return;
      let content = <ViewInvoice invoiceId={invoiceId} windowId={windowId} />;
      open({
        // changes to TAB-VIEW
        title: "Lädt ...",
        content: content,
        height: 750,
        id: windowId,
        isFullscreen: false,
        width: 750,
      });
    },
  },
  Document: {
    upload: (modelId: string, modelType: string) => {
      let windowId = "uploadDocument" + modelId + modelType;
      if (abortIfAlreadyOpen(windowId)) return;
      let content = (
        <UploadDocument
          modelId={modelId}
          modelType={modelType}
          windowId={windowId}
        />
      );
      open({
        // changes to TAB-VIEW
        title: "Dokument hochladen",
        content: content,
        height: 500,
        id: windowId,
        isFullscreen: false,
        width: 500,
      });
    },
    view: (documentId: string) => {
      let windowId = "viewDocument" + documentId;
      callOnReload(documentId, "Document", "view", windowId)
      if (abortIfAlreadyOpen(windowId)) return;
      let content = (
        <ViewDocument documentId={documentId} windowId={windowId} />
      );
      open({
        // changes to TAB-VIEW
        title: "Dokument",
        content: content,
        height: 500,
        id: windowId,
        isFullscreen: false,
        width: 500,
      });
    },
  },
  Note: {
    add: (
      profileId: string,
      modelId: string,
      modelType: string,
      independent: boolean
    ) => {
      let windowId = "addNote" + profileId + modelId + modelType;
      if (abortIfAlreadyOpen(windowId)) return;
      let content = (
        <ViewCreateNote
          profileId={profileId}
          modelId={modelId}
          modelType={modelType}
          windowId={windowId}
          independent={independent}
        />
      );
      open({
        // changes to TAB-VIEW
        title: "Neue Notiz",
        content: content,
        height: 500,
        id: windowId,
        isFullscreen: false,
        width: 500,
      });
    },
    view: (noteId: string) => {
      let windowId = "viewNote" + noteId;
      callOnReload(noteId, "Note", "view", windowId)
      if (abortIfAlreadyOpen(windowId)) return;
      let content = <ViewNote noteId={noteId} windowId={windowId} />;
      open({
        // changes to TAB-VIEW
        title: "Lädt ...",
        content: content,
        height: 500,
        id: windowId,
        isFullscreen: false,
        width: 500,
      });
    },
    viewTodos: () => {
      let windowId = "viewTodos";
      callOnReload("", "Note", "viewTodos", windowId)
      if (abortIfAlreadyOpen(windowId)) return;
      let content = <ViewTodos windowId={windowId} />;
      open({
        // changes to TAB-VIEW
        title: "Zu erledigen",
        content: content,
        height: 500,
        id: windowId,
        isFullscreen: false,
        width: 500,
      });
    },
  },
  Organisation: {
    create: {
      user: () => {
        let windowId = "createUser";
        callOnReload("", "Organisation", "create", windowId)
        if (abortIfAlreadyOpen(windowId)) return;
        let content = <ViewCreateUser windowId={windowId} />;
        open({
          // changes to TAB-VIEW
          title: "Teammitglied hinzufügen",
          content: content,
          height: 525,
          id: windowId,
          isFullscreen: false,
          width: 500,
        });
      },
    },
  },
  WorkLog: {
    add: (employeeId: string, jobId: string) => {
      let windowId = "addWorkLog" + employeeId + jobId;
      if (abortIfAlreadyOpen(windowId)) return;
      let content = (
        <ViewCreateWorkLog
          setShowCreateWorkLog={() => { }}
          employeeId={employeeId}
          jobId={jobId}
          windowId={windowId}
        />
      );
      open({
        // changes to TAB-VIEW
        title: "Neuer Arbeitseinsatz",
        content: content,
        height: 400,
        id: windowId,
        isFullscreen: false,
        width: 500,
      });
    },
    update: (workLogId: string) => {
      let windowId = "updateWorkLog" + workLogId;
      callOnReload(workLogId, "WorkLog", "update", windowId)
      if (abortIfAlreadyOpen(windowId)) return;
      let content = (
        <ViewUpdateWorkLog
          workLogId={workLogId}
          windowId={windowId}
        />
      );
      open({
        // changes to TAB-VIEW
        title: "Lädt ...",
        content: content,
        height: 400,
        id: windowId,
        isFullscreen: false,
        width: 500,
      });
    },
    view: (workLogId: string) => {
      let windowId = "viewWorkLog" + workLogId;
      callOnReload(workLogId, "WorkLog", "view", windowId)
      if (abortIfAlreadyOpen(windowId)) return;
      let content = <ViewWorkLog workLogId={workLogId} windowId={windowId} />;
      open({
        // changes to TAB-VIEW
        title: "Lädt ...",
        content: content,
        height: 550,
        id: windowId,
        isFullscreen: false,
        width: 500,
      });
    },
  },
  Export: {
    new: () => {
      let windowId = "newExport";
      if (abortIfAlreadyOpen(windowId)) return;
      let content = <ViewCreateExport windowId={windowId} />;
      open({
        // changes to TAB-VIEW
        title: "Neuer Export",
        content: content,
        height: 400,
        id: windowId,
        isFullscreen: false,
        width: 500,
      });
    },
  },
};

export const closeWindow = (windowId: string) => {
  let remainingWindows = getState().openWindows.filter(({ window }: any) => {
    return window.id !== windowId;
  });

  let windowOrder = getState().windowOrder

  windowOrder = windowOrder.filter((id) => {
    return id !== windowId
  })

  setState({
    windowOrder: windowOrder,
    openWindows: remainingWindows.length === 0 ? [] : remainingWindows,
  });

  setCurrentlyFocusedWindowsId(getState().windowOrder[getState().windowOrder.length - 1])

  if (localStorage.windowsToBeOpened) {
    let windowsToBeOpened = JSON.parse(localStorage.windowsToBeOpened)

    windowsToBeOpened = windowsToBeOpened.filter((window: any) => {
      return window.windowId !== windowId
    })

    localStorage.windowsToBeOpened = JSON.stringify(windowsToBeOpened)
  }
};

// changes to TAB-VIEW
// export const toggleMaximizeWindow = (windowId: string) => {
//   let openWindows: any = getState().openWindows;
//   openWindows = openWindows.filter(({ window }: any) => {
//     if (window.id === windowId) window.isFullscreen = !window.isFullscreen;
//     return true;
//   });
//   setState({ openWindows: openWindows });
// };
