import { DetailsList, Selection, SelectionMode, Stack } from "@fluentui/react";
import { useEffect, useState } from "react";
import { useProfiles } from "../assets/queries";
import { addWindow } from "../assets/windows";
import { QueryProgressIndicator, SearchBoxEmployees } from "../components/misc";

const ListLinkEmployeesToJob: React.FC<{
  jobId: string;
  onChange: (items: any) => void;
}> = ({ jobId, onChange }) => {
  const employees = useProfiles("employee");
  const [list, setList] = useState<any>({
    items: [],
    isSorted: {},
  });
  const clear = () => setList({ ...list, items: employees.data });
  const setItems = (items: Array<any>) => {
    items.forEach((item: any) => (item.key = item?.id));
    setList({ ...list, items: items });
  };

  const selection = new Selection({
    onSelectionChanged: () => {
      selection.getItems().forEach((item: any) => {
        let { jobsAsEmployee } = item;
        jobsAsEmployee.forEach((job: any) => {
          if (job.id === jobId) selection.setKeySelected(item?.key, true, false);
        });
      });
      let selectedItems = selection.getSelection();
      let employeeIds = selectedItems.map((item: any) => {
        return item?.id;
      });
      onChange(employeeIds);
    },
  });

  useEffect(() => {
    if (employees.isSuccess) setItems(employees.data);
    // eslint-disable-next-line
  }, [employees.isSuccess, employees.data]);

  return (
    <Stack>
      <QueryProgressIndicator  query={employees} />
      {employees.isSuccess && employees.data.length > 0 && (
        <Stack>
          <SearchBoxEmployees
            items={list.items}
            onResult={(results: Array<any>) => setItems(results)}
            onClear={clear}
          />

          <DetailsList
            columns={[
              {
                fieldName: "firstName",
                isResizable: true,
                key: "firstName",
                minWidth: 0,
                name: "Vorname",
              },
              {
                fieldName: "lastName",
                isResizable: true,
                key: "lastName",
                minWidth: 0,
                name: "Nachname",
              },
              {
                key: "isAssigned",
                minWidth: 0,
                name: "Status",
                onRender: (item) => {
                  let { jobsAsEmployee } = item;
                  let assigned = false;
                  jobsAsEmployee.forEach((job: any) => {
                    if (job.id === jobId) assigned = true;
                  });
                  if (assigned) return "zugeordnet";
                  return "nicht zugeordnet";
                },
              },
            ]}
            isHeaderVisible={true}
            items={list.items}
            onItemInvoked={(item) => addWindow.Profile.view(item!.id)}
            selection={selection}
            selectionMode={SelectionMode.multiple}
            styles={{ root: { cursor: "pointer" } }}
          />
        </Stack>
      )}
    </Stack>
  );
};

export default ListLinkEmployeesToJob;
