import { MessageBar, MessageBarType, ThemeProvider } from "@fluentui/react";
import { BrowserView, MobileView } from "react-device-detect";
import { QueryClient, QueryClientProvider } from "react-query";
import { HashRouter, Redirect, Route, Switch } from "react-router-dom";
import create from 'zustand';
import App from "./App";
import { useClientStore } from "./assets/stores";
import { dark, light } from "./assets/themes";
import QrUploaderMobile from "./components/QrUploaderMobile";
import Login from "./screens/Login";
import { ViewAddContact } from "./views/Contact";
import ViewMyAccount from "./views/ViewMyAccount";
const useStore = create(useClientStore)

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const Wrapper: React.FC = ({ children }) => {
  const darkmode = useStore((store: any) => store.darkmode)

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={darkmode ? dark : light}>{children}</ThemeProvider>
    </QueryClientProvider>
  );
};

export const MobileRoutes: React.FC = () => {
  return (
    <MobileView>
      <Switch>
        <Route exact path="/uploader/:profileId/uploadProfileImage">
          <QrUploaderMobile />
        </Route>

        <Route path="/">
          <NoMobileView />
        </Route>
      </Switch>
    </MobileView>
  );
};

export const NoMobileView: React.FC = () => {
  return (
    <MessageBar messageBarType={MessageBarType.blocked}>
      jobfairy wird auf mobilen Endgeräten nicht unterstützt
    </MessageBar>
  );
};

export const BrowserRoutes: React.FC = () => {
  return (
    <BrowserView>
      <Switch>
        <Route exact path="/login">
          <Login />
        </Route>

        <Route exact path="/app">
          <App />
        </Route>

        <Route path="/">
          <Redirect to="/login" />
        </Route>
      </Switch>
    </BrowserView>
  );
};

export const ElectronRoutes: React.FC = () => {
  return (
    <HashRouter>
      <Switch>
        <Route exact path="/login">
          <Login />
        </Route>

        <Route exact path="/app">
          <App />
        </Route>

        <Route exact path="/myAccount">
          <ViewMyAccount windowId="0" />
        </Route>

        <Route exact path="/accountById">
          {/* not used */}
        </Route>

        <Route exact path="/addContact">
          <ViewAddContact setShowAddContact={() => { }} profileId="" windowId="0" />
        </Route>

        <Route path="/">
          <Redirect to="/login" />
        </Route>
      </Switch>
    </HashRouter>
  );
};
