import { DetailsList, Link, SelectionMode, Stack, Text } from "@fluentui/react";
import Markdown from "markdown-to-jsx";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useNotesByType } from "../assets/queries";
import { addWindow } from "../assets/windows";
import {
  PullUp,
  QueryProgressIndicator,
  SearchBoxList
} from "../components/misc";

const ListNotesByType: React.FC<{
  modelId: string;
  modelType: string;
}> = ({ modelId, modelType }) => {
  const notes = useNotesByType(modelId, modelType);

  const [list, setList] = useState<any>({
    items: [],
    isSorted: {},
  });
  const clear = () => setList({ ...list, items: notes.data });
  const setItems = (items: Array<any>) => setList({ ...list, items: items });

  useEffect(() => {
    if (notes.isSuccess) setItems(notes.data);
    // eslint-disable-next-line
  }, [notes.isSuccess, notes.data]);

  return (
    <Stack>
      <QueryProgressIndicator  query={notes} />

      {notes.isSuccess && notes.data.length > 0 && (
        <Stack>
          {list.items.length < 10 && <PullUp by={20} />}

          {/* <SearchBoxList
              items={list.items}
              onResult={(results: Array<any>) => setItems(results)}
              onClear={clear}
            /> */}

          <DetailsList
            columns={[
              {
                isResizable: true,
                key: "type",
                minWidth: 0,
                name: "Typ",
                onRender: (item: any) => {
                  switch (item?.type) {
                    case "default":
                      return (
                        <Text style={{ fontSize: 12 }}>
                          normal
                        </Text>
                      );
                    case "critical":
                      return (
                        <Text style={{ color: "orange", fontSize: 12 }}>
                          kritisch
                        </Text>
                      );
                    case "danger":
                      return (
                        <Text style={{ color: "red", fontSize: 12 }}>
                          alarmierend
                        </Text>
                      );
                    case "todo":
                      return (
                        <Text style={{ color: "green", fontSize: 12 }}>
                          zu erledigen
                        </Text>
                      );
                    case "ticket":
                      return (
                        <Text style={{ color: "purple", fontSize: 12 }}>
                          Support
                        </Text>
                      );

                    default:
                      break;
                  }
                },
              },
              {
                isResizable: true,
                key: "title",
                minWidth: 0,
                name: "Titel",
                onRender: (item: any) => <Markdown>{item?.title}</Markdown>,
              },
              {
                isResizable: true,
                key: "text",
                minWidth: 300,
                name: "Auszug",
                onRender: (item: any) => <Markdown>{item?.text}</Markdown>,
              },
              {
                isResizable: true,
                key: "updatedByProfile",
                minWidth: 0,
                name: "aktualisiert von",
                onRender: (item: any) => {
                  return (
                    <Link
                      onClick={() => {
                        Swal.fire({
                          buttonsStyling: false,
                          title: "Noch nicht verfügbar",
                          text:
                            "Diese Funktion ist in Planung." ||
                            "Unbekannter Fehler",
                        });
                      }}
                    >
                      {item?.updatedByProfile?.firstName +
                        " " +
                        item?.updatedByProfile?.lastName}
                    </Link>
                  );
                },
              },
              {
                isResizable: true,
                key: "createdByProfile",
                minWidth: 0,
                name: "erstellt von",
                onRender: (item: any) => {
                  return (
                    <Link
                      onClick={() => {
                        Swal.fire({
                          buttonsStyling: false,
                          title: "Noch nicht verfügbar",
                          text:
                            "Diese Funktion ist in Planung." ||
                            "Unbekannter Fehler",
                        });
                      }}
                    >
                      {item?.createdByProfile.firstName +
                        " " +
                        item?.createdByProfile.lastName}
                    </Link>
                  );
                },
              },
            ]}
            isHeaderVisible={true}
            items={list.items}
            onItemInvoked={(item) => addWindow.Note.view(item?.id)}
            selectionMode={SelectionMode.none}
            styles={{ root: { cursor: "pointer", borderRadius: "0px 0px 16px 16px" } }}
          />
        </Stack>
      )}
    </Stack>
  );
};

export default ListNotesByType;
