import { css } from "@emotion/css";
import {
  ComboBox,
  DatePicker,
  DayOfWeek,
  Dropdown,
  IconButton,
  ITextField,
  ITextFieldStyles,
  Label,
  MaskedTextField,
  Stack,
  Text,
  TextField,
  Toggle,
  useTheme
} from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import { formatDistanceToNow, set } from "date-fns";
import { de } from "date-fns/locale";
import React, { useEffect, useRef, useState } from "react";
import usePlacesAutocomplete, { getGeocode } from "use-places-autocomplete";
import { returnMobilephone } from "../../assets/functions";
import { Icons } from "../../assets/icons";
import { dateStrings } from "../../assets/strings";
import {
  myColumnLeft,
  myColumnRight,
  myDropdown,
  myStack,
  myTextField
} from "../../assets/styles";
import { PushDown } from "../misc";

export const FieldStringCreate: React.FC<{
  autoFocus?: boolean;
  disabled?: boolean;
  errorMessage: any;
  label?: string;
  onChange?: (newValue: any) => void;
  onEnter?: () => void;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  isMobilephone?: boolean;
  type?: "default" | "link" | "password" | "url";
}> = ({
  autoFocus = false,
  disabled = false,
  errorMessage,
  isMobilephone = false,
  label,
  onChange = () => { },
  onEnter = () => { },
  placeholder,
  readOnly = false,
  required = false,
  type = "text",
}) => {
    const [value, setValue] = useState("");
    const buttonId = useId("buttonId");

    return (
      <TextField
        autoFocus={autoFocus}
        borderless
        canRevealPassword={type === "password"}
        disabled={disabled}
        errorMessage={errorMessage}
        id={buttonId}
        iconProps={{ iconName: "Loading" }}
        label={label}
        onChange={(event, newValue) => {
          setValue(newValue!);
          onChange(newValue);
        }}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            onEnter();
          }
        }}
        placeholder={placeholder}
        prefix={type === "url" ? "http://www." : undefined}
        readOnly={readOnly}
        required={required}
        spellCheck={false}
        styles={myTextField}
        type={type}
        maxLength={isMobilephone ? 10 : undefined}
        value={isMobilephone ? returnMobilephone(value) : value}
      />
    );
  };

export const FieldStringUpdate: React.FC<{
  allowMutation?: boolean;
  field: string;
  label: string;
  isPassword?: boolean;
  mask?: string;
  multiline?: boolean;
  mutation: (payload: any) => void;
  type?: "default" | "link" | "password" | "url";
  query: any;
  isMobilephone?: boolean;
  rows?: number;
}> = ({
  allowMutation = false,
  field,
  isMobilephone = false,
  label,
  isPassword = false,
  mask,
  multiline,
  mutation,
  type = "default",
  query,
  rows = 3,
}) => {
    const [value, setValue] = useState<string>("");
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const el = useRef<ITextField>(null);

    const change = (newValue: string) => setValue(newValue);

    const editSave = () => {
      if (!isEditMode) {
        el.current?.focus();
        el.current?.select();
      } else {
        let payload = {
          [field]: value,
        };
        if (value !== query.data[field]) mutation(payload);
        el.current?.blur();
      }
      setIsEditMode(!isEditMode);
      if (isPassword) return setValue("");
      return setValue(query.data[field]);
    };

    const cancel = () => {
      setValue(query.data[field]);
      setIsEditMode(false);
    };

    useEffect(() => {
      if (!query.data) return;
      query.isSuccess && setValue(query.data[field]);
      // eslint-disable-next-line
    }, [query.isSuccess, query.data, field]);

    // styles
    const theme = useTheme();
    const styles: Partial<ITextFieldStyles> = {
      field: {
        color: type !== "link" ? theme.palette.black : "#FFD23F",
        cursor: type !== "link" ? "default" : "pointer",
        padding: 0,
        borderRadius: 16
      },
      fieldGroup: {
        background: "transparent",
        border: 0,
        // borderBottom: "3px solid lightgrey",
      },
      root: {
        width: "100%",
      },
    };

    // not used
    const textFieldCSS = css`
    color: red !important;
    .ms-TextField-field {
      padding: ${!isEditMode ? "10px" : "10px !important"};
    }
    .ms-TextField-fieldGroup::after {
      border: ${!isEditMode ? 0 : 3};
    }
  `;

    return (
      <Stack grow horizontal>
        {!mask ? (
          <TextField
            autoAdjustHeight={multiline}
            canRevealPassword={isEditMode && isPassword}
            className={textFieldCSS}
            componentRef={el}
            disabled={!isEditMode}
            label={label}
            multiline={multiline}
            rows={rows}
            onChange={(event, newValue) => change(newValue!)}
            onClick={() => {
              if (!isEditMode && type === "link") window.open(value, "blank");
            }}
            onKeyDown={(event) => {
              if (event.code === "Escape") setIsEditMode(false);
              if (event.code === "Enter" && !multiline) editSave();
            }}
            placeholder={
              !isPassword
                ? query.data
                  ? query.data[field]
                  : "k.A."
                : "verschlüsselt"
            }
            prefix={type === "url" ? "http://www." : undefined}
            readOnly={!isEditMode}
            resizable={false}
            styles={styles}
            type={!isPassword ? "text" : "password"}
            value={!isPassword ? isMobilephone ? returnMobilephone(value) : value : isEditMode ? value : ""}
          />
        ) : (
          <Stack grow>
            {query.isSuccess && query.data && (
              <MaskedTextField
                autoAdjustHeight={multiline}
                className={textFieldCSS}
                componentRef={el}
                label={label}
                mask={mask}
                multiline={isEditMode && multiline}
                onChange={(event, newValue) => change(newValue!)}
                onKeyDown={(event) => {
                  if (event.code === "Escape") setIsEditMode(false);
                  if (event.code === "Enter" && !multiline) editSave();
                }}
                readOnly={!isEditMode}
                resizable={false}
                styles={styles}
                value={query.data[field] || " "}
              />
            )}
          </Stack>
        )}
        {!isEditMode && (
          <IconButton
            styles={{ root: { borderRadius: 16 } }}
            disabled={!allowMutation}
            iconProps={{ iconName: Icons.Lock }}
            onClick={editSave}
            toggle
          />
        )}
        {isEditMode && (
          <IconButton
            styles={{ root: { borderRadius: 16 } }}
            disabled={!allowMutation}
            iconProps={{ iconName: Icons.Cancel }}
            onClick={cancel}
            toggle
          />
        )}
        {isEditMode && (
          <IconButton
            styles={{ root: { borderRadius: 16 } }}
            disabled={!allowMutation}
            iconProps={{ iconName: Icons.Unlock }}
            onClick={editSave}
            toggle
          />
        )}
      </Stack>
    );
  };

export const FieldToggleUpdate: React.FC<{
  checked: any;
  disabled?: boolean;
  mutation: any;
  onChange: any;
  text?: string;
  query: any;
}> = ({ checked, disabled = false, mutation, onChange, text = "", query }) => {
  // styles
  const theme = useTheme();
  const toggleStyle = { root: {} };
  const textErrorStyle = { root: { color: theme.palette.red } };

  return (
    <Stack horizontal>
      <Stack grow>
        <Text styles={textErrorStyle} variant="small">
          {mutation.isError && "Mutationsfehler"}
        </Text>
      </Stack>

      <Stack>
        <Toggle
          label={text}
          disabled={disabled}
          checked={checked}
          onChange={onChange}
          styles={toggleStyle}
        />
      </Stack>
    </Stack>
  );
};

export const FormColumnLeft: React.FC<{
  label?: string;
}> = ({ label }) => {
  return (
    <Stack styles={myColumnLeft}>
      <Label>{label}</Label>
    </Stack>
  );
};

export const MyColumnRightLabel: React.FC<{ label: string }> = ({ label }) => {
  return (
    <Stack>
      <PushDown by={20} />

      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <FormColumnLeft />

        <Stack horizontal styles={myColumnRight} tokens={{ childrenGap: 10 }}>
          <Text variant="small">{label}</Text>
        </Stack>
      </Stack>
    </Stack>
  );
};

export const FieldDropdownCreate: React.FC<{
  defaultSelectedKey?: string;
  disabled?: boolean;
  errorMessage?: any;
  form?: any;
  label: string;
  onFieldChange: (value: any) => void;
  options: any;
  placeholder: any;
  required?: boolean;
  selectedKey?: any;
}> = ({
  defaultSelectedKey = null,
  disabled = false,
  errorMessage,
  form,
  label,
  onFieldChange,
  options,
  placeholder,
  required = false,
  selectedKey = null,
}) => {
    return (
      <Dropdown
        className={css`
        .ms-Dropdown-title {
          border: 0px;
          border-bottom: 3px solid lightgrey;
          padding-left: 5px;
        }
        .ms-Dropdown-title::after {
          border: 0px;
        }
        .ms-Dropdown::after {
          border: 0px;
        }
      `}
        defaultSelectedKey={defaultSelectedKey}
        disabled={disabled}
        errorMessage={errorMessage}
        label={label}
        onChange={(event, item) => onFieldChange(item!.key)}
        options={options}
        placeholder={placeholder}
        required={required}
        selectedKey={selectedKey}
        styles={myDropdown}
      />
    );
  };

export const FieldDropdownUpdateEnumeration: React.FC<{
  allowMutation?: boolean;
  field: string;
  label: string;
  mutation: (payload: any) => void;
  options: any;
  query: any;
  selectedKey?: any;
}> = ({
  allowMutation = true,
  field,
  label,
  mutation,
  options = [],
  query,
  selectedKey = undefined,
}) => {
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const editSave = () => setIsEditMode(!isEditMode);

    return (
      <Stack styles={myStack}>
        {query.isSuccess && (
          <Stack horizontal>
            <Dropdown
              className={css`
              .ms-Dropdown-title {
                border: 0px;
                border-bottom: 3px solid lightgrey;
                padding-left: 5px;
                &:hover {
                  border-bottom: ${!isEditMode
                  ? "3px solid lightgrey"
                  : "3px solid black"};
                }
                // color: black;
              }
              .ms-Dropdown-title::after {
                border: 0px;
              }
              .ms-Dropdown::after {
                border: 0px;
              }
            `}
              disabled={!isEditMode}
              label={label}
              onChange={(event, item: any) => {
                return mutation({ [field]: item?.key });
              }}
              options={options}
              placeholder="Auswählen"
              selectedKey={selectedKey}
              styles={myDropdown}
            />
            {!isEditMode && (
              <IconButton
                styles={{ root: { borderRadius: 16 } }}
                disabled={!allowMutation}
                iconProps={{ iconName: Icons.Lock }}
                onClick={editSave}
                toggle
              />
            )}
            {isEditMode && (
              <IconButton
                styles={{ root: { borderRadius: 16 } }}
                disabled={!allowMutation}
                iconProps={{ iconName: Icons.Unlock }}
                onClick={editSave}
                toggle
              />
            )}
          </Stack>
        )}
      </Stack>
    );
  };

export const FieldDropdownUpdate: React.FC<{
  allowMutation?: boolean;
  field: string;
  label: string;
  mutation: (payload: any) => void;
  options: any;
  optionText?: string;
  query: any;
  selectedKey?: any;
}> = ({
  allowMutation = true,
  field,
  label,
  mutation,
  options = [],
  optionText,
  query,
  selectedKey = undefined,
}) => {
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const editSave = () => setIsEditMode(!isEditMode);
    const [dropdownOptions, setDropdownOptions] = useState<any>([]);
    const theme = useTheme()

    useEffect(() => {
      if (!optionText) return setDropdownOptions(options);

      let newOptions: any = [];

      options.forEach((option: any) => {
        newOptions.push({
          key: option.id.toString(),
          text: option[optionText],
        });
      });

      setDropdownOptions(newOptions);
    }, [query.isSuccess, options]);

    return (
      <Stack styles={myStack}>
        {query.isSuccess && (
          <Stack horizontal>
            <Dropdown
              className={css`
              .ms-Dropdown-title {
                border: 0px;
                border-bottom: 3px solid lightgrey;
                padding-left: 5px;
                &:hover {
                  border-bottom: ${!isEditMode
                  ? "3px solid lightgrey"
                  : "3px solid black"};
                }
                color: ${theme.palette.black};
              }
              .ms-Dropdown-title::after {
                border: 0px;
              }
              .ms-Dropdown::after {
                border: 0px;
              }
            `}
              disabled={!isEditMode}
              label={label}
              onChange={(event, item: any) => {
                return mutation({ [field]: item?.id });
              }}
              options={dropdownOptions}
              placeholder="Auswählen"
              selectedKey={selectedKey}
              styles={myDropdown}
            />
            {!isEditMode && (
              <IconButton
                styles={{ root: { borderRadius: 16 } }}
                disabled={!allowMutation}
                iconProps={{ iconName: Icons.Lock }}
                onClick={editSave}
                toggle
              />
            )}
            {isEditMode && (
              <IconButton
                styles={{ root: { borderRadius: 16 } }}
                disabled={!allowMutation}
                iconProps={{ iconName: Icons.Unlock }}
                onClick={editSave}
                toggle
              />
            )}
          </Stack>
        )}
      </Stack>
    );
  };

export const FieldDropdownUpdateMultiSelect: React.FC<{
  allowMutation?: boolean;
  field: string;
  label: string;
  mutation: (payload: any) => void;
  options: any;
  optionText?: string;
  query: any;
  selectedKeys?: any;
}> = ({
  allowMutation = true,
  field,
  label,
  mutation,
  options = [],
  optionText,
  query,
  selectedKeys = [],
}) => {
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const editSave = () => setIsEditMode(!isEditMode);
    const [dropdownOptions, setDropdownOptions] = useState<any>([]);
    const [defaultSelectedKeys, setDefaultSelectedKeys] = useState<any>([]);

    useEffect(() => {
      if (!optionText) return setDropdownOptions(options);

      let newOptions: any = [];

      options.forEach((option: any) => {
        newOptions.push({
          key: option.id.toString(),
          text: option[optionText],
        });
      });

      setDropdownOptions(newOptions);
    }, [query.isSuccess, options]);

    useEffect(() => {
      let keys: any = [];
      selectedKeys.forEach((key: any) => {
        keys.push(key.key);
      });
      setDefaultSelectedKeys(keys);
    }, []);

    useEffect(() => {
      let keys: any = [];

      defaultSelectedKeys.forEach((key: any) => {
        options.forEach((option: any) => {
          if (key === option.key) {
            keys.push(option.id);
          }
        });
      });

      if (!keys || keys.length === 0) return;

      return mutation({ [field]: keys });
    }, [defaultSelectedKeys]);

    return (
      <Stack styles={myStack}>
        {query.isSuccess && (
          <Stack horizontal>
            <Dropdown
              className={css`
              .ms-Dropdown-title {
                border: 0px;
                border-bottom: 3px solid lightgrey;
                padding-left: 5px;
                &:hover {
                  border-bottom: ${!isEditMode
                  ? "3px solid lightgrey"
                  : "3px solid black"};
                }
                text-overflow: hidden;
                white-space: nowrap;
                width: 320px;
              }
              .ms-Dropdown-title::after {
                border: 0px;
              }
              .ms-Dropdown::after {
                border: 0px;
              }
            `}
              disabled={!isEditMode}
              label={label}
              multiSelect
              onChange={(event, item: any) => {
                if (item) {
                  return setDefaultSelectedKeys(
                    item?.selected
                      ? [...defaultSelectedKeys, item?.key]
                      : defaultSelectedKeys.filter((key: any) => key !== item?.key)
                  );
                }
              }}
              options={dropdownOptions}
              placeholder="Auswählen"
              selectedKeys={defaultSelectedKeys}
              styles={myDropdown}
            />
            {!isEditMode && (
              <IconButton
                styles={{ root: { borderRadius: 16 } }}
                disabled={!allowMutation}
                iconProps={{ iconName: Icons.Lock }}
                onClick={editSave}
                toggle
              />
            )}
            {isEditMode && (
              <IconButton
                styles={{ root: { borderRadius: 16 } }}
                disabled={!allowMutation}
                iconProps={{ iconName: Icons.Unlock }}
                onClick={editSave}
                toggle
              />
            )}
          </Stack>
        )}
      </Stack>
    );
  };

export const FieldDateCreate: React.FC<{
  disabled?: boolean;
  field: string;
  form: any;
  label: string;
  onFieldChange: (value: any) => void;
  validationErrors: any;
}> = ({
  disabled = false,
  field,
  form,
  label,
  onFieldChange,
  validationErrors,
}) => {
    // styles
    const theme = useTheme();
    const textStyles = { root: { color: theme.palette.redDark } };
    const datePickerStyles: Partial<any> = {
      root: {
        marginBottom: validationErrors?.birthdate ? 5 : 0,
        border: 0,
      },
    };

    return (
      <Stack grow styles={myStack}>
        <DatePicker
          disabled={disabled}
          allowTextInput
          className={css`
          .ms-TextField-fieldGroup {
            border: 0px;
            border-bottom: 3px solid lightgrey;
          }
          .ms-TextField-fieldGroup::after {
            border: 0px;
          }
          .ms-TextField-field {
            padding: 0px;
          }
        `}
          disableAutoFocus
          firstDayOfWeek={DayOfWeek.Monday}
          label={label}
          formatDate={(date?: Date): string => {
            return !date
              ? ""
              : date.getDate() +
              "." +
              (date.getMonth() + 1) +
              "." +
              date.getFullYear();
          }}
          onSelectDate={(date: Date | null | undefined) => onFieldChange(date)}
          initialPickerDate={new Date()}
          strings={dateStrings}
          styles={datePickerStyles}
          parseDateFromString={(dateString: string) => {
            let dateParts = dateString.split(".");
            if (dateParts.length !== 3) return null;
            let day = dateParts[0];
            let month = dateParts[1];
            let year = dateParts[2];
            return new Date(month + "/" + day + "/" + year);
          }}
          placeholder="Auswählen oder eingeben"
          value={
            form[field]
              ? set(new Date(form[field]), {
                date: new Date(form[field]).getDate() - 1,
              })
              : undefined
          }
        />
        <Text styles={textStyles} variant="small">
          {validationErrors}
        </Text>
        <small>
          {form[field]
            ? formatDistanceToNow(new Date(form[field]), {
              addSuffix: true,
              locale: de,
            })
            : ""}
        </small>
      </Stack>
    );
  };

export const FieldDateUpdate: React.FC<{
  allowMutation: boolean;
  field: string;
  label: string;
  mutation: (payload: any) => any;
  query: any;
}> = ({ allowMutation, field, label, mutation, query }) => {
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const editSave = () => setIsEditMode(!isEditMode);

  return (
    <Stack grow horizontal styles={myStack}>
      <DatePicker
        allowTextInput
        className={css`
          .ms-TextField-field {
            border: 0px;
            border-bottom: 3px solid lightgrey;
            padding-left: 5px;
            &:hover {
              border-bottom: ${!isEditMode
            ? "3px solid lightgrey"
            : "3px solid black"};
            }
            // color: black;
          }
          .ms-TextField-field::after {
            border: 0px;
          }
          .ms-TextField::after {
            border: 0px;
          }
        `}
        disabled={!isEditMode}
        firstDayOfWeek={DayOfWeek.Monday}
        label={label}
        formatDate={(date?: Date): string => {
          return !date
            ? ""
            : date.getDate() +
            "." +
            (date.getMonth() + 1) +
            "." +
            date.getFullYear();
        }}
        onSelectDate={(date: Date | null | undefined) => {
          mutation({
            [field]: set(new Date(date!), {
              date: new Date(date!).getDate() + 1,
            }),
          });
        }}
        initialPickerDate={new Date()}
        strings={dateStrings}
        parseDateFromString={(dateString: string) => {
          let dateParts = dateString.split(".");
          if (dateParts.length !== 3) return null;
          let day = dateParts[0];
          let month = dateParts[1];
          let year = dateParts[2];
          return new Date(month + "/" + day + "/" + year);
        }}
        placeholder="Auswählen oder eingeben"
        styles={{ root: { background: "transparent", width: "100%" } }}
        value={(query.data && new Date(query.data[field])) || undefined}
      />
      {!isEditMode && (
        <IconButton
          styles={{ root: { borderRadius: 16 } }}
          disabled={!allowMutation}
          iconProps={{ iconName: Icons.Lock }}
          onClick={editSave}
          toggle
        />
      )}
      {isEditMode && (
        <IconButton
          styles={{ root: { borderRadius: 16 } }}
          disabled={!allowMutation}
          iconProps={{ iconName: Icons.Unlock }}
          onClick={editSave}
          toggle
        />
      )}
    </Stack>
  );
};

export const FieldAddressCreate: React.FC<{
  onFieldChange: (payload: any) => void;
  required?: boolean;
  validationErrors: any;
}> = ({ onFieldChange, required = false, validationErrors }) => {
  const [address, setAddress] = useState<any>({});
  const comboboxRef = useRef<any>(null);
  const [options, setOptions] = useState<Array<any>>([]);

  const {
    ready,
    // value,
    suggestions: { status, data },
    setValue,
  } = usePlacesAutocomplete({
    debounce: 100,
    requestOptions: {},
  });

  const handleInput = (e: any) => {
    setValue(e.target.value);
    comboboxRef.current!.focus(true);
  };

  const handleSelect = async (index: number) => {
    let description = data[index].description;
    let geocode = await getGeocode({ address: description });

    let newAddress: any = {};
    geocode.forEach((geocode: any) => {
      const { formatted_address, place_id } = geocode;
      newAddress.place_id = place_id;
      newAddress.formatted_address = formatted_address;
      geocode.address_components.forEach((component: any) => {
        let types = component.types;
        if (types.indexOf("route") !== -1)
          newAddress.route = component.long_name;
        if (types.indexOf("street_number") !== -1)
          newAddress.street_number = component.long_name;
        if (types.indexOf("postal_code") !== -1)
          newAddress.postal_code = component.long_name;
        if (types.indexOf("locality") !== -1)
          newAddress.locality = component.long_name;
      });
    });
    setAddress(newAddress);
  };

  useEffect(() => {
    let newOptions: Array<any> = [];
    if (status === "OK") {
      data.forEach(({ place_id, description }) => {
        newOptions.push({
          key: place_id,
          text: description,
        });
      });
    }
    setOptions(newOptions);
    onFieldChange({
      place_id: address.place_id,
      formatted_address: address.formatted_address,
      route: address.route,
      street_number: address.street_number,
      postal_code: address.postal_code,
      locality: address.locality,
    });
    comboboxRef.current!.focus(true);
    // eslint-disable-next-line
  }, [status, address, data]);

  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <Stack>
        <ComboBox
          allowFreeform
          className={css`
            .ms-ComboBox {
              padding-left: 0px;
              border-bottom: 3px solid lightgrey;
            }
            .ms-ComboBox::after {
              border: 0;
            }
          `}
          componentRef={comboboxRef}
          disabled={!ready}
          errorMessage={validationErrors?.main}
          label="Adresse"
          onChange={handleInput}
          onItemClick={(event, option, index) => handleSelect(index!)}
          options={options}
          placeholder={address?.formatted_address || "Eingeben und ENTER"}
          required={required}
          useComboBoxAsMenuWidth
        />
      </Stack>
    </Stack>
  );
};

export const FieldAddressUpdate: React.FC<{
  allowMutation: boolean;
  mutation: (payload: any) => void;
  required?: boolean;
  query: any;
}> = ({ allowMutation, mutation, required, query }) => {
  // add as interface
  const [address, setAddress] = useState<any>({});
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const editSave = () => setIsEditMode(!isEditMode);
  const comboboxRef = useRef<any>(null);
  const [options, setOptions] = useState<Array<any>>([]);

  const {
    ready,
    // value,
    suggestions: { status, data },
    setValue,
  } = usePlacesAutocomplete({
    debounce: 300,
    requestOptions: {},
  });

  const handleInput = (e: any) => {
    setValue(e.target.value);
    comboboxRef.current!.focus(true);
  };

  const handleSelect = async (index: number) => {
    let description = data[index].description;
    let geocode = await getGeocode({ address: description });

    let newAddress: any = {};
    geocode.forEach((geocode: any) => {
      const { formatted_address, place_id } = geocode;
      newAddress.place_id = place_id;
      newAddress.formatted_address = formatted_address;
      geocode.address_components.forEach((component: any) => {
        let types = component.types;
        if (types.indexOf("route") !== -1)
          newAddress.route = component.long_name;
        if (types.indexOf("street_number") !== -1)
          newAddress.street_number = component.long_name;
        if (types.indexOf("postal_code") !== -1)
          newAddress.postal_code = component.long_name;
        if (types.indexOf("locality") !== -1)
          newAddress.locality = component.long_name;
      });
    });
    setAddress(newAddress);
  };

  useEffect(() => {
    let newOptions: Array<any> = [];
    if (status === "OK") {
      data.forEach(({ place_id, description }) => {
        newOptions.push({
          key: place_id,
          text: description,
        });
      });
    }
    setOptions(newOptions);
    mutation({
      place_id: address.place_id,
      formatted_address: address.formatted_address,
      route: address.route,
      street_number: address.street_number,
      postal_code: address.postal_code,
      locality: address.locality,
    });
    comboboxRef.current!.focus(true);
    // eslint-disable-next-line
  }, [status, address, query.data?.formatted_address, data]);

  // styles
  const stackTokens: any = { childrenGap: 10 };

  return (
    <Stack tokens={stackTokens}>
      <Stack horizontal>
        <ComboBox
          allowFreeform
          className={css`
            .ms-ComboBox {
              padding-left: 5px;
              // border-bottom: 3px solid lightgrey;
            }
            .ms-ComboBox::after {
              border: 0;
            }
          `}
          componentRef={comboboxRef}
          disabled={!isEditMode}
          label="Adresse"
          onChange={handleInput}
          onItemClick={(event, option, index) => handleSelect(index!)}
          options={options}
          placeholder={
            query.data?.formatted_address ||
            address?.formatted_address ||
            "Eingeben und ENTER"
          }
          required={required}
          style={{ width: "100%" }}
          useComboBoxAsMenuWidth
        />
        {!isEditMode && (
          <IconButton
            styles={{ root: { borderRadius: 16 } }}
            disabled={!allowMutation}
            iconProps={{ iconName: Icons.Lock }}
            onClick={editSave}
            toggle
          />
        )}
        {isEditMode && (
          <IconButton
            styles={{ root: { borderRadius: 16 } }}
            disabled={!allowMutation}
            iconProps={{ iconName: Icons.Unlock }}
            onClick={editSave}
            toggle
          />
        )}
      </Stack>
    </Stack>
  );
};
