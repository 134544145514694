import { Pivot, PivotItem, Stack, useTheme } from "@fluentui/react";
import { PullUp } from "../components/misc";
import ListTodos from "../lists/ListTodos";
import ListUnconfirmedRegistrations from "../lists/ListUnconfirmedRegistrations";
import { ViewContent } from "./Parts";

const ViewTodos: React.FC<{ windowId: string }> = ({ windowId }) => {
  const theme = useTheme()

  return (
    <Stack>
      <ViewContent>
        <Pivot styles={{
          root: {
            background: theme.palette.white, zIndex: 10, left: 0,
            borderRadius: "16px 16px 0px 0px",
            width: "100%",
          }
        }}>
          <PivotItem headerText="zu erledigen">
            <PullUp by={20} />

            <ListTodos type="notDone" />
          </PivotItem>

          <PivotItem headerText="erledigt">
            <PullUp by={20} />

            <ListTodos type="done" />
          </PivotItem>

          <PivotItem headerText="Alle">
            <PullUp by={20} />

            <ListTodos type="all" />
          </PivotItem>

          <PivotItem headerText="unbestätigte Registrierungen">
            <PullUp by={20} />

            <ListUnconfirmedRegistrations />
          </PivotItem>
        </Pivot>
      </ViewContent>
    </Stack>
  );
};

export default ViewTodos;
