import { css } from "@emotion/css";
import {
  ContextualMenu,
  DefaultEffects,
  IDragOptions,
  IModalStyles,
  Modal, Stack
} from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import create from "zustand";
import { setCurrentlyFocusedWindowsId } from "../assets/functions";
import { useCurrentlyFocusedWindowId } from "../assets/hooks";
import { useClientStore } from "../assets/stores";
const { setState } = useClientStore

const Windows: React.FC = () => {
  const useStore = create(useClientStore);
  const openWindows = useStore((state) => state.openWindows);

  return (
    <Stack>
      {/* {openWindows.map(({ window }: any) => {
        return <Window key={window.id} window={window} />;
      })} */}
    </Stack>
  );
};

export default Windows;

export const Window: React.FC<{ key: string; window: any }> = ({ window }) => {
  const [isFullscreen, setIsFullscreen] = useState(true);
  const currentlyFocusedWindowId = useCurrentlyFocusedWindowId();
  const [myZ, setMyZ] = useState(0);
  const dragOptions: IDragOptions = {
    closeMenuItemText: "Schliessen",
    dragHandleSelector: ".drag",
    menu: ContextualMenu,
    moveMenuItemText: "Verschieben",
  };

  useEffect(() => {
    setIsFullscreen(isMobile || window.isFullscreen);
    if (currentlyFocusedWindowId === window.id) return setMyZ(10);
    setMyZ(1);
  }, [currentlyFocusedWindowId, window.isFullscreen, window.id]);

  // styles
  const modalStyles: IModalStyles = {
    main: {
      boxShadow:
        myZ > 1 ? DefaultEffects.elevation64 : DefaultEffects.elevation16,
      minHeight: isFullscreen ? "100%" : window.height + "px",
      minWidth: isFullscreen ? "100%" : window.width + "px",
      userSelect: "none",
      zIndex: 10000 + myZ,
      borderRadius: 16,
      border: "1px solid lightgray"
    },
    layer: {},
    keyboardMoveIcon: {},
    keyboardMoveIconContainer: {},
    root: {},
    scrollableContent: {}
  };

  return (
    <Modal
      className={css`
        .ms-Dialog-main {
          transform: ${isFullscreen ? "translate(0px, 0px) !important" : ""};
          overflow: hidden !important;
        }
      `}
      dragOptions={dragOptions}
      isBlocking={false}
      isDarkOverlay={false}
      isModeless
      isOpen={true}
      styles={modalStyles}
    >
      <Stack
        onClick={(e: any) => {
          let t = e.target;
          if (
            t.className &&
            t.className.indexOf &&
            t.className.indexOf("ms-Icon") !== -1
          )
            return;
          if (
            t.className &&
            t.className.indexOf &&
            t.className.indexOf("ms-Button-label") !== -1
          )
            return;
          if (
            t.className &&
            t.className.indexOf &&
            t.className.indexOf("ms-Link") !== -1
          )
            return;
          setCurrentlyFocusedWindowsId(window.id);
        }}
        verticalFill
      >
        {window.content}
      </Stack>
    </Modal>
  );
};
