import { DefaultButton, Stack, useTheme } from "@fluentui/react";
import { useState } from "react";
import { Icons } from "../assets/icons";
import { useLinkEmployeesToJob } from "../assets/mutations";
import { MutationProgressIndicator } from "../components/misc";
import ListLinkEmployeesToJob from "../lists/ListLinkEmployeesToJob";
import PivotSubMenu, { ViewContent, ViewFooter } from "../views/Parts";

const ViewLinkEmployee: React.FC<{ jobId: string; windowId: string, setShowLinkEmployee: any }> = ({
  jobId,
  windowId,
  setShowLinkEmployee
}) => {
  const [selectedEmployeeIds, setSelectedEmployeeIds] = useState<Array<any>>(
    []
  );
  const linkEmployeesToJob = useLinkEmployeesToJob(
    selectedEmployeeIds,
    jobId,
    windowId
  );
  const theme = useTheme()

  return (
    <Stack styles={{ root: { padding: 20 } }}>
      <Stack styles={{ root: { padding: 0, background: theme.palette.white, borderRadius: 16 } }}>
        <ViewContent>
          {/* <PivotSubMenu>
            <DefaultButton
              styles={{ root: { borderRadius: 16 } }}
              iconProps={{
                iconName: Icons.Link,
              }}
              onClick={async () => {
                linkEmployeesToJob.mutate()
              }}
            >
              Jugendliche*r zuweisen
            </DefaultButton>
          </PivotSubMenu> */}

          <MutationProgressIndicator mutationQuery={linkEmployeesToJob} />

          <ListLinkEmployeesToJob
            jobId={jobId}
            onChange={(selectedEmployeeIds: any) =>
              setSelectedEmployeeIds(selectedEmployeeIds)
            }
          />
        </ViewContent>

        <ViewFooter
          custom={{
            icon: Icons.Link,
            text: "Zuweisen",
            onCustom: async () => {
              await linkEmployeesToJob.mutateAsync()
              setShowLinkEmployee(false)
            },
          }}
        />
      </Stack>
    </Stack>
  );
};

export default ViewLinkEmployee;
