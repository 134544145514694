import {
  DefaultButton,
  DetailsList,
  Link,
  SelectionMode,
  Stack
} from "@fluentui/react";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useDeleteDocument } from "../assets/mutations";
import { useDocumentsByType } from "../assets/queries";
import { addWindow } from "../assets/windows";
import {
  QueryProgressIndicator
} from "../components/misc";

const ListDocuments: React.FC<{ modelId: string; modelType: string }> = ({
  modelId,
  modelType,
}) => {
  const documents: any = useDocumentsByType(modelId, modelType);
  const [list, setList] = useState<any>({
    items: [],
    isSorted: {},
  });
  const clear = () => setList({ ...list, items: documents.data });
  const setItems = (items: Array<any>) => setList({ ...list, items: items });
  const deleteDocument = useDeleteDocument();

  useEffect(() => {
    if (documents.isSuccess) setItems(documents.data);
    // eslint-disable-next-line
  }, [documents.isSuccess, documents.data?.length]);

  return (
    <Stack>
      <QueryProgressIndicator  query={documents} />

      {documents.isSuccess && documents.data.length > 0 && (
        <Stack>
          <DetailsList
            columns={[
              {
                key: "tags",
                minWidth: 65,
                name: "Tags",
                onRender: (item: any) => {
                  let tagsAsString = "";
                  for (let i = 0; i < item?.tags.length; i++) {
                    const tag = item?.tags[i];
                    if (i === 0) {
                      tagsAsString += tag.name;
                    } else {
                      tagsAsString += ", " + tag.name;
                    }
                  }
                  return tagsAsString;
                },
              },
              {
                key: "name",
                minWidth: 65,
                name: "Name",
                onRender: (item: any) => {
                  return item?.name;
                },
              },
              {
                key: "file",
                minWidth: 0,
                name: "Datei",
                onRender: (item: any) => {
                  return (
                    <Link href={item?.file?.url} target="_blank">
                      Öffnen
                    </Link>
                  );
                },
              },
              {
                key: "delete",
                minWidth: 0,
                name: "Löschen",
                onRender: (item: any) => {
                  return (
                    <DefaultButton
                      onClick={async () => {
                        Swal.fire({
                          buttonsStyling: false,
                          confirmButtonText: "Löschen",
                          focusCancel: true,
                          cancelButtonText: "Abbrechen",
                          showCancelButton: true,
                          title: "Achtung",
                          html:
                            "Das Dokument kann NICHT mehr wiederhergestellt werden.",
                          reverseButtons: true,
                        }).then(async (event: any) => {
                          if (event.isConfirmed) await deleteDocument.mutateAsync(item?.id)
                        });
                      }}
                    >
                      Löschen
                    </DefaultButton>
                  );
                },
              },
            ]}
            isHeaderVisible={true}
            items={list.items}
            onItemInvoked={(item) => addWindow.Document.view(item?.id)}
            selectionMode={SelectionMode.none}
            styles={{ root: { cursor: "pointer", borderRadius: "0px 0px 16px 16px" } }}
          />
        </Stack>
      )
      }
    </Stack >
  );
};

export default ListDocuments;
