import { Stack, useTheme } from "@fluentui/react";
import { useEffect, useState } from "react";
import { returnErrorsToReset, returnServerError } from "../assets/functions";
import { useAddContact, useUpdateContact } from "../assets/mutations";
import { useContact } from "../assets/queries";
import { useClientStore } from "../assets/stores";
import { myTokens } from "../assets/styles";
import { closeWindow } from "../assets/windows";
import Create from "../components/forms/Create";
import {
  FieldAddressUpdate,
  FieldStringUpdate
} from "../components/forms/Parts";
import {
  MutationProgressIndicator,
  QueryProgressIndicator
} from "../components/misc";
import { ViewContent, ViewFooter } from "./Parts";
const { getState, setState } = useClientStore

export const ViewAddContact: React.FC<{
  profileId: string;
  windowId: string;
  setShowAddContact: any;
}> = ({ profileId, windowId, setShowAddContact }) => {
  const [form, setForm] = useState<any>({});
  const [formErrors, setFormErrors] = useState<any>({});
  const mutation = useAddContact(profileId);
  const theme = useTheme()

  const change = (updatedFields: any, oldForm: any = form) => {
    setForm({ ...oldForm!, ...updatedFields });
    setFormErrors({
      ...formErrors,
      formErrors: {
        ...formErrors?.formErrors,
        ...returnErrorsToReset(updatedFields),
      },
    });
  };

  useEffect(() => {
    if (mutation.isSuccess) closeWindow(windowId);
    if (mutation.isError) setFormErrors(returnServerError(mutation.error));
  }, [mutation.error, mutation.isError, mutation.isSuccess, windowId]);

  return (
    <Stack styles={{ root: { padding: "0px 20px 20px 20px" } }}>
      <Stack styles={{ root: { borderRadius: 16, background: theme.palette.white, padding: 0 } }}>
        <ViewContent>
          <MutationProgressIndicator mutationQuery={mutation} />

          <Create
            form={form}
            formErrors={formErrors?.formErrors}
            onChange={(updatedFields: any) => change(updatedFields, form)}
            type="contact"
          />
        </ViewContent>

        <ViewFooter
          onCreate={async () => {
            await mutation.mutateAsync(form)
            setShowAddContact(false)
          }}
          mutationQuery={mutation}
        />
      </Stack>
    </Stack>
  );
};

export const ViewContact: React.FC<{
  contactId: string;
  windowId: string;
}> = ({ contactId, windowId }) => {
  const contact = useContact(contactId);
  const updateContact = useUpdateContact(contactId);

  useEffect(() => {
    let newWindows = getState().openWindows.filter((window: any) => {
      if (window.window.id === "viewContact" + contactId) {
        window.window.title = contact.data?.publicKey
      }
      return true
    })

    setState({ openWindows: newWindows })
  }, [contact.isSuccess])

  return (
    <Stack>
      <ViewContent>
        {contact.data && <Contact mutation={updateContact} query={contact} />}
      </ViewContent>
    </Stack>
  );
};

const Contact: React.FC<{ mutation: any; query: any }> = ({
  mutation,
  query,
}) => {
  const theme = useTheme()

  return (
    <Stack tokens={myTokens} styles={{ root: { padding: 0 } }}>
      <Stack styles={{ root: { background: theme.palette.white, padding: 20, borderRadius: 16 } }}>
        <MutationProgressIndicator mutationQuery={mutation} />

        <QueryProgressIndicator query={query} />

        <FieldStringUpdate
          allowMutation
          label="Bezeichnung"
          field="description"
          mutation={(payload: any) => mutation.mutate(payload)}
          query={query}
        />

        <Stack horizontal>
          <FieldStringUpdate
            allowMutation
            label="Vorname"
            field="firstName"
            mutation={(payload: any) => mutation.mutate(payload)}
            query={query}
          />

          <FieldStringUpdate
            allowMutation
            label="Nachname"
            field="lastName"
            mutation={(payload: any) => mutation.mutate(payload)}
            query={query}
          />
        </Stack>

        <FieldAddressUpdate
          allowMutation
          mutation={(payload: any) => mutation.mutate(payload)}
          query={query}
        />

        <FieldStringUpdate
          allowMutation
          label="E-Mail"
          field="email"
          mutation={(payload: any) => mutation.mutate({ ...payload, ignoreEmail: true })}
          query={query}
        />

        <Stack horizontal>
          <Stack grow>
            <FieldStringUpdate
              allowMutation
              label="Festnetznummer"
              field="landline"
              isMobilephone
              mutation={(payload: any) => mutation.mutate(payload)}
              query={query}
            />
          </Stack>

          <Stack grow>
            <FieldStringUpdate
              allowMutation
              label="Mobilnummer"
              field="mobilephone"
              isMobilephone
              mutation={(payload: any) => mutation.mutate(payload)}
              query={query}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
