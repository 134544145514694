import {
  Label,
  Link,
  MessageBar,
  MessageBarType,
  Pivot,
  PivotItem,
  Stack,
  Text,
  Toggle,
  useTheme
} from "@fluentui/react";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { returnReadableDuration } from "../assets/functions";
import { Icons } from "../assets/icons";
import { useDeleteWorkLog, useUpdateWorkLog } from "../assets/mutations";
import { useWorkLog } from "../assets/queries";
import { addWindow, closeWindow } from "../assets/windows";
import {
  InternalMeta,
  MutationProgressIndicator,
  PushDown,
  QueryProgressIndicator
} from "../components/misc";
import { ViewContent } from "./Parts";

const ViewAssignment: React.FC<{ assignmentId: string; windowId: string }> = ({
  assignmentId,
  windowId,
}) => {
  const [confirmedBy, setConfirmedBy] = useState({
    jobfair: false,
    employer: false,
    employee: false,
  });
  const query = useWorkLog(assignmentId);
  const updateWorkLog = useUpdateWorkLog(assignmentId);
  const deleteWorkLog = useDeleteWorkLog(assignmentId);
  const theme = useTheme()

  // styles
  const stackStyles: any = { root: { width: "100%" } };

  useEffect(() => {
    setConfirmedBy({
      jobfair: query.data?.confirmedByJobfair,
      employer: query.data?.confirmedByEmployer,
      employee: query.data?.confirmedByEmployee,
    });
  }, [query.isSuccess, query.isRefetching]);

  useEffect(() => {
    if (deleteWorkLog.isSuccess) closeWindow(windowId);
  }, [deleteWorkLog]);

  return (
    <Stack>
      <ViewContent>
        <QueryProgressIndicator  query={query} />

        <MutationProgressIndicator  mutationQuery={deleteWorkLog} />

        <Pivot styles={{
          root: {
            background: theme.palette.white, zIndex: 10, left: 0,
            borderRadius: "16px 16px 0px 0px",
            width: "100%",
          }
        }}>
          <PivotItem headerText="Details" itemIcon={Icons.File}>
            <PushDown by={20} />

            {query.data?.invoiceId && (
              <MessageBar messageBarType={MessageBarType.success}>
                in Rechnung gestellt
              </MessageBar>
            )}

            {!query.data?.invoiceId && (
              <MessageBar messageBarType={MessageBarType.warning}>
                noch nicht in Rechnung gestellt
              </MessageBar>
            )}

            {query.data?.receipt && (
              <MessageBar messageBarType={MessageBarType.success}>
                quittiert
              </MessageBar>
            )}

            {!query.data?.receipt && (
              <MessageBar messageBarType={MessageBarType.warning}>
                noch nicht quittiert
              </MessageBar>
            )}

            <PushDown by={20} />

            <Stack horizontal>
              <Stack styles={stackStyles}>
                <Text variant="medium">
                  <Label>Rechnung</Label>
                  <Link
                    onClick={() => {
                      addWindow.Invoice.view(query.data?.invoiceId);
                    }}
                  >
                    {query.data?.invoice_publicKey}
                  </Link>
                </Text>
              </Stack>
            </Stack>

            <PushDown by={20} />

            <Stack horizontal>
              <Stack styles={stackStyles}>
                <Text variant="medium">
                  <Label>Arbeitseinsatz vom</Label>
                  {query.data?.date &&
                    format(new Date(query.data?.date), "dd.MM.yyyy")}
                </Text>
              </Stack>

              <Stack styles={stackStyles}>
                <Text variant="medium">
                  <Label>Dauer</Label>
                  {returnReadableDuration(query.data?.minutes)}
                </Text>
              </Stack>
            </Stack>

            <PushDown by={20} />

            <Stack horizontal>
              <Stack styles={stackStyles}>
                <Text variant="medium">
                  <Label>Job</Label>
                  <Link onClick={() => addWindow.Job.view(query.data?.job?.id)}>
                    {query?.data?.job?.title}
                  </Link>
                </Text>
              </Stack>

              <Stack styles={stackStyles}>
                <Text variant="medium">
                  <Label>Jugendliche*r</Label>
                  <Link
                    onClick={() =>
                      addWindow.Profile.view(query.data?.employee?.id)
                    }
                  >
                    {query?.data?.employee?.firstName}{" "}
                    {query?.data?.employee?.lastName}
                  </Link>
                </Text>
              </Stack>
            </Stack>

            <PushDown by={20} />

            <Stack horizontal>
              <Stack styles={stackStyles}>
                <Text variant="medium">
                  <Label>Bestätigungen</Label>
                </Text>

                <Stack style={{ paddingRight: 50 }}>
                  <Text
                    style={{
                      display: "block",
                      fontSize: 10,
                      textAlign: "justify",
                    }}
                  >
                    Eine Bestätigung durch die Job-Börse reicht aus, um einen
                    Arbeitseinsatz in Rechnung stellen zu können.
                  </Text>
                </Stack>
              </Stack>

              <Stack styles={stackStyles}>
                <Toggle
                  checked={confirmedBy.jobfair}
                  disabled={query.data?.invoiceId}
                  label="Jugend-Job-Börse"
                  onClick={async () =>
                    await updateWorkLog.mutateAsync({
                      confirmedByJobfair: !confirmedBy.jobfair,
                    })
                  }
                />

                <Toggle
                  checked={confirmedBy.employer}
                  disabled
                  label="Auftraggeber*in"
                />

                <Toggle
                  checked={confirmedBy.employee}
                  disabled
                  label="Jugendliche*r"
                />
              </Stack>
            </Stack>
          </PivotItem>

          <PivotItem itemIcon={Icons.Warning}>
            <PushDown by={20} />

            <InternalMeta query={query} />
          </PivotItem>
        </Pivot>
      </ViewContent>
    </Stack>
  );
};

export default ViewAssignment;
