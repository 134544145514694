import {
  DetailsList,
  Link,
  SelectionMode,
  Stack,
  Text,
  Toggle,
  useTheme,
} from "@fluentui/react";
import { format } from "date-fns";
import de from "date-fns/esm/locale/de/index.js";
import { useEffect, useState } from "react";
import axiosJWT from "../assets/axiosjwt";
import { returnReadableDuration } from "../assets/functions";
import { useUpdateWorkLogVariable } from "../assets/mutations";
import { addWindow } from "../assets/windows";
import { QueryProgressIndicator } from "../components/misc";

const ListWorkLogs: React.FC<{
  query: any;
  isArchived?: boolean;
}> = ({ query, isArchived }) => {
  const workLogs = query;
  const [list, setList] = useState<any>({
    items: [],
    isSorted: {},
  });
  const clear = () => setList({ ...list, items: workLogs.data });
  const setItems = (items: Array<any>) => setList({ ...list, items: items });
  const updateWorkLog = useUpdateWorkLogVariable();
  const theme = useTheme();
  const defaults = {
    byDurationUP: false,
    byDurationDOWN: false,
    byCreatedAtUP: false,
    byCreatedAtDOWN: false,
    byDateUP: false,
    byDateDOWN: false,
    byPublicKeyUP: false,
    byPublicKeyDOWN: false,
    byStatusUP: false,
    byEmployerUP: false,
    byEmployerDOWN: false,
    byStatusDOWN: false,
    byJobUP: false,
    byJobDOWN: false,
    byAmountUP: false,
    byAmountDOWN: false,
    byEmployeeUP: false,
    byEmployeeDOWN: false,
    byConfirmedUP: false,
    byConfirmedDOWN: false,
    byInvoiceUP: false,
    byInvoiceDOWN: false,
    byReceiptUP: false,
    byReceiptDOWN: false,
  };

  useEffect(() => {
    if (workLogs.isSuccess) setItems(workLogs.data);
    // eslint-disable-next-line
  }, [workLogs.isSuccess, workLogs.data]);

  const sortByInvoice = () => {
    let items = list.items;

    if (list.isSorted.byInvoiceUP) {
      items = items.sort((a: any, b: any) => {
        if (a.invoice_publicKey.length < 12) a.invoice_publicKey = "00000";
        if (b.invoice_publicKey < 12) b.invoice_publicKey = "00000";
        if (
          parseInt(
            a.invoice_publicKey.slice(
              a.invoice_publicKey.length - 4,
              a.invoice_publicKey.length
            )
          ) <
          parseInt(
            b.invoice_publicKey.slice(
              b.invoice_publicKey.length - 4,
              b.invoice_publicKey.length
            )
          )
        )
          return -1;
        return 1;
      });
    } else {
      items = items.sort((a: any, b: any) => {
        if (a.invoice_publicKey.length < 12) a.invoice_publicKey = "00000";
        if (b.invoice_publicKey < 12) b.invoice_publicKey = "00000";
        if (
          parseInt(
            a.invoice_publicKey.slice(
              a.invoice_publicKey.length - 4,
              a.invoice_publicKey.length
            )
          ) <
          parseInt(
            b.invoice_publicKey.slice(
              b.invoice_publicKey.length - 4,
              b.invoice_publicKey.length
            )
          )
        )
          return 1;
        return -1;
      });
    }

    setList({
      items: items,
      isSorted: {
        ...defaults,
        byInvoiceUP: !list.isSorted.byInvoiceUP,
        byInvoiceDOWN: list.isSorted.byInvoiceUP,
      },
    });
  };

  const sortByPublicKey = () => {
    let items = list.items;

    if (list.isSorted.byPublicKeyUP) {
      items = items.sort((a: any, b: any) => {
        if (
          parseInt(
            a.publicKey.slice(a.publicKey.length - 4, a.publicKey.length)
          ) <
          parseInt(
            b.publicKey.slice(b.publicKey.length - 4, b.publicKey.length)
          )
        )
          return -1;
        return 1;
      });
    } else {
      items = items.sort((a: any, b: any) => {
        if (
          parseInt(
            a.publicKey.slice(a.publicKey.length - 4, a.publicKey.length)
          ) <
          parseInt(
            b.publicKey.slice(b.publicKey.length - 4, b.publicKey.length)
          )
        )
          return 1;
        return -1;
      });
    }

    setList({
      items: items,
      isSorted: {
        ...defaults,
        byPublicKeyUP: !list.isSorted.byPublicKeyUP,
        byPublicKeyDOWN: list.isSorted.byPublicKeyUP,
      },
    });
  };

  const sortByReceipt = () => {
    let items = list.items;

    if (list.isSorted.byReceiptUP) {
      items = items.sort((a: any, b: any) => {
        if (a.receipt?.publicKey.length < 12) a.receipt.publicKey = "00000";
        if (b.receipt?.publicKey < 12) b.receipt.publicKey = "00000";
        if (
          parseInt(
            a.receipt?.publicKey.slice(
              a.receipt?.publicKey.length - 4,
              a.receipt?.publicKey.length
            )
          ) <
          parseInt(
            b.receipt?.publicKey.slice(
              b.receipt?.publicKey.length - 4,
              b.receipt?.publicKey.length
            )
          )
        )
          return -1;
        return 1;
      });
    } else {
      items = items.sort((a: any, b: any) => {
        if (a.receipt?.publicKey.length < 12) a.receipt.publicKey = "00000";
        if (b.receipt?.publicKey < 12) b.receipt.publicKey = "00000";
        if (
          parseInt(
            a.receipt?.publicKey.slice(
              a.receipt?.publicKey.length - 4,
              a.receipt?.publicKey.length
            )
          ) <
          parseInt(
            b.receipt?.publicKey.slice(
              b.receipt?.publicKey.length - 4,
              b.receipt?.publicKey.length
            )
          )
        )
          return 1;
        return -1;
      });
    }

    setList({
      items: items,
      isSorted: {
        ...defaults,
        byReceiptUP: !list.isSorted.byReceiptUP,
        byReceiptDOWN: list.isSorted.byReceiptUP,
      },
    });
  };

  const sortByEmployer = () => {
    let items = list.items;

    if (list.isSorted.byEmployerUP) {
      items = items.sort((a: any, b: any) => {
        if (
          parseInt(
            a.employer.publicKey.slice(
              a.employer.publicKey.length - 4,
              a.employer.publicKey.length
            )
          ) <
          parseInt(
            b.employer.publicKey.slice(
              b.employer.publicKey.length - 4,
              b.employer.publicKey.length
            )
          )
        )
          return -1;
        return 1;
      });
    } else {
      items = items.sort((a: any, b: any) => {
        if (
          parseInt(
            a.employer.publicKey.slice(
              a.employer.publicKey.length - 4,
              a.employer.publicKey.length
            )
          ) <
          parseInt(
            b.employer.publicKey.slice(
              b.employer.publicKey.length - 4,
              b.employer.publicKey.length
            )
          )
        )
          return 1;
        return -1;
      });
    }

    setList({
      items: items,
      isSorted: {
        ...defaults,
        byEmployerUP: !list.isSorted.byEmployerUP,
        byEmployerDOWN: list.isSorted.byEmployerUP,
      },
    });
  };

  const sortByEmployee = () => {
    let items = list.items;

    if (list.isSorted.byEmployeeUP) {
      items = items.sort((a: any, b: any) => {
        if (
          parseInt(
            a.employee.publicKey.slice(
              a.employee.publicKey.length - 4,
              a.employee.publicKey.length
            )
          ) <
          parseInt(
            b.employee.publicKey.slice(
              b.employee.publicKey.length - 4,
              b.employee.publicKey.length
            )
          )
        )
          return -1;
        return 1;
      });
    } else {
      items = items.sort((a: any, b: any) => {
        if (
          parseInt(
            a.employee.publicKey.slice(
              a.employee.publicKey.length - 4,
              a.employee.publicKey.length
            )
          ) <
          parseInt(
            b.employee.publicKey.slice(
              b.employee.publicKey.length - 4,
              b.employee.publicKey.length
            )
          )
        )
          return 1;
        return -1;
      });
    }

    setList({
      items: items,
      isSorted: {
        ...defaults,
        byEmployeeUP: !list.isSorted.byEmployeeUP,
        byEmployeeDOWN: list.isSorted.byEmployeeUP,
      },
    });
  };

  const sortByJob = () => {
    let items = list.items;

    if (list.isSorted.byJobUP) {
      items = items.sort((a: any, b: any) => {
        if (
          parseInt(
            a.job.publicKey.slice(
              a.job.publicKey.length - 4,
              a.job.publicKey.length
            )
          ) <
          parseInt(
            b.job.publicKey.slice(
              b.job.publicKey.length - 4,
              b.job.publicKey.length
            )
          )
        )
          return -1;
        return 1;
      });
    } else {
      items = items.sort((a: any, b: any) => {
        if (
          parseInt(
            a.job.publicKey.slice(
              a.job.publicKey.length - 4,
              a.job.publicKey.length
            )
          ) <
          parseInt(
            b.job.publicKey.slice(
              b.job.publicKey.length - 4,
              b.job.publicKey.length
            )
          )
        )
          return 1;
        return -1;
      });
    }

    setList({
      items: items,
      isSorted: {
        ...defaults,
        byJobUP: !list.isSorted.byJobUP,
        byJobDOWN: list.isSorted.byJobUP,
      },
    });
  };

  const sortByDate = () => {
    let items = list.items;

    if (list.isSorted.byDateUP) {
      items = items.sort((a: any, b: any) => {
        if (new Date(a.date).getTime() < new Date(b.date).getTime()) return -1;
        return 1;
      });
    } else {
      items = items.sort((a: any, b: any) => {
        if (new Date(a.date).getTime() < new Date(b.date).getTime()) return 1;
        return -1;
      });
    }

    setList({
      items: items,
      isSorted: {
        ...defaults,
        byDateUP: !list.isSorted.byDateUP,
        byDateDOWN: list.isSorted.byDateDOWN,
      },
    });
  };

  const sortByCreatedAt = () => {
    let items = list.items;

    if (list.isSorted.byCreatedAtUP) {
      items = items.sort((a: any, b: any) => {
        if (new Date(a.createdAt).getTime() < new Date(b.createdAt).getTime())
          return -1;
        return 1;
      });
    } else {
      items = items.sort((a: any, b: any) => {
        if (new Date(a.createdAt).getTime() < new Date(b.createdAt).getTime())
          return 1;
        return -1;
      });
    }

    setList({
      items: items,
      isSorted: {
        ...defaults,
        byCreatedAtUP: !list.isSorted.byCreatedAtUP,
        byCreatedAtDOWN: list.isSorted.byCreatedAtUP,
      },
    });
  };

  const sortByAmount = () => {
    let items = list.items;

    if (list.isSorted.byAmountUP) {
      items = items.sort((a: any, b: any) => {
        if (a.minutes < b.minutes) return -1;
        return 1;
      });
    } else {
      items = items.sort((a: any, b: any) => {
        if (a.minutes < b.minutes) return 1;
        return -1;
      });
    }

    setList({
      items: items,
      isSorted: {
        ...defaults,
        byAmountUP: !list.isSorted.byAmountUP,
        byAmountDOWN: list.isSorted.byAmountUP,
      },
    });
  };

  const sortByDuration = () => {
    let items = list.items;

    if (list.isSorted.byDurationUP) {
      items = items.sort((a: any, b: any) => {
        if (a.minutes < b.minutes) return -1;
        return 1;
      });
    } else {
      items = items.sort((a: any, b: any) => {
        if (a.minutes < b.minutes) return 1;
        return -1;
      });
    }

    setList({
      items: items,
      isSorted: {
        ...defaults,
        byDurationUP: !list.isSorted.byDurationUP,
        byDurationDOWN: list.isSorted.byDurationUP,
      },
    });
  };

  const sortByConfirmed = () => {
    let items = list.items;

    if (list.isSorted.byConfirmedUP) {
      items = items.sort((a: any, b: any) => {
        if (a.confirmedByJobfair) return -1;
        return 1;
      });
    } else {
      items = items.sort((a: any, b: any) => {
        if (!b.confirmedByJobfair) return 1;
        return -1;
      });
    }

    setList({
      items: items,
      isSorted: {
        ...defaults,
        byConfirmedUP: !list.isSorted.byConfirmedUP,
        byConfirmedDOWN: list.isSorted.byConfirmedUP,
      },
    });
  };

  return (
    <Stack>
      <QueryProgressIndicator query={workLogs} />

      {workLogs.isSuccess && workLogs.data.length > 0 && (
        <Stack>
          <DetailsList
            columns={[
              {
                isResizable: true,
                key: "createdAt",
                onColumnClick(ev, column) {
                  sortByCreatedAt();
                },
                minWidth: 0,
                maxWidth: 50,
                name: ((): string => {
                  if (list.isSorted.byCreatedAtUP) {
                    return "erstellt am | oben";
                  } else if (list.isSorted.byCreatedAtDOWN) {
                    return "erstellt am | unten";
                  } else {
                    return "erstellt am";
                  }
                })(),
                styles: {
                  root: {
                    borderRadius: "16px 16px 0px 0px",
                    background:
                      list.isSorted.byCreatedAtUP ||
                      list.isSorted.byCreatedAtDOWN
                        ? theme.palette.themePrimary
                        : undefined,
                  },
                },
                onRender: (item: any) => {
                  return format(new Date(item?.createdAt), "dd.MM.yy", {
                    locale: de,
                  });
                },
              },
              {
                isResizable: true,
                key: "publicKey",
                minWidth: 0,
                maxWidth: 50,
                name: ((): string => {
                  if (list.isSorted.byPublicKeyUP) {
                    return "Schlüssel | oben";
                  } else if (list.isSorted.byPublicKeyDOWN) {
                    return "Schlüssel | unten";
                  } else {
                    return "Schlüssel";
                  }
                })(),
                onColumnClick(ev, column) {
                  sortByPublicKey();
                },
                onRender: (item: any) => item?.publicKey,
                styles: {
                  root: {
                    borderRadius: "16px 16px 0px 0px",
                    background:
                      list.isSorted.byPublicKeyUP ||
                      list.isSorted.byPublicKeyDOWN
                        ? theme.palette.themePrimary
                        : undefined,
                  },
                },
              },
              {
                isResizable: true,
                key: "invoice",
                minWidth: 0,
                maxWidth: 50,
                name: ((): string => {
                  if (list.isSorted.byInvoiceUP) {
                    return "Rechnung | oben";
                  } else if (list.isSorted.byInvoiceDOWN) {
                    return "Rechnung | unten";
                  } else {
                    return "Rechnung";
                  }
                })(),
                onColumnClick(ev, column) {
                  sortByInvoice();
                },
                styles: {
                  root: {
                    borderRadius: "16px 16px 0px 0px",
                    background:
                      list.isSorted.byInvoiceUP || list.isSorted.byInvoiceDOWN
                        ? theme.palette.themePrimary
                        : undefined,
                  },
                },
                onRender: (item: any) => {
                  return (
                    <Link
                      onClick={() =>
                        addWindow.Invoice.view(item?.invoice?.invoiceId)
                      }
                    >
                      {item?.invoice?.publicKey}
                    </Link>
                  );
                },
              },
              {
                key: "employer",
                isResizable: true,
                minWidth: 0,
                maxWidth: 250,
                name: ((): string => {
                  if (list.isSorted.byEmployerUP) {
                    return "Auftraggeber*in | oben";
                  } else if (list.isSorted.byEmployerDOWN) {
                    return "Auftraggeber*in | unten";
                  } else {
                    return "Auftraggeber*in";
                  }
                })(),
                onColumnClick(ev, column) {
                  sortByEmployer();
                },
                styles: {
                  root: {
                    borderRadius: "16px 16px 0px 0px",
                    background:
                      list.isSorted.byEmployerUP || list.isSorted.byEmployerDOWN
                        ? theme.palette.themePrimary
                        : undefined,
                  },
                },
                onRender: (item: any) => {
                  if (item?.employer?.type === "publicEmployer") {
                    return (
                      <Link
                        onClick={() =>
                          addWindow.Profile.view(item?.employer?.id)
                        }
                      >
                        {item?.employer?.description}
                      </Link>
                    );
                  }
                  if (item?.employer?.type !== "publicEmployer") {
                    return (
                      <Link
                        onClick={() =>
                          addWindow.Profile.view(item?.employer?.id)
                        }
                      >
                        {item?.employer?.firstName +
                          " " +
                          item?.employer?.lastName}
                      </Link>
                    );
                  }
                },
              },
              {
                key: "job",
                minWidth: 0,
                isResizable: true,
                onColumnClick(ev, column) {
                  sortByJob();
                },
                name: ((): string => {
                  if (list.isSorted.byJobUP) {
                    return "Job | oben";
                  } else if (list.isSorted.byJobDOWN) {
                    return "Job | unten";
                  } else {
                    return "Job";
                  }
                })(),
                styles: {
                  root: {
                    borderRadius: "16px 16px 0px 0px",
                    background:
                      list.isSorted.byJobUP || list.isSorted.byJobDOWN
                        ? theme.palette.themePrimary
                        : undefined,
                  },
                },
                onRender: (item: any) => {
                  return (
                    <Link onClick={() => addWindow.Job.view(item?.job?.id)}>
                      {item?.job?.title}
                    </Link>
                  );
                },
              },
              {
                isResizable: true,
                key: "employee",
                minWidth: 0,
                maxWidth: 250,
                onColumnClick(ev, column) {
                  sortByEmployee();
                },
                name: ((): string => {
                  if (list.isSorted.byEmployeeUP) {
                    return "Jugendliche*r | oben";
                  } else if (list.isSorted.byEmployeeDOWN) {
                    return "Jugendliche*r | unten";
                  } else {
                    return "Jugendliche*r";
                  }
                })(),
                styles: {
                  root: {
                    borderRadius: "16px 16px 0px 0px",
                    background:
                      list.isSorted.byEmployeeUP || list.isSorted.byEmployeeDOWN
                        ? theme.palette.themePrimary
                        : undefined,
                  },
                },
                onRender: (item: any) => {
                  return (
                    <Link
                      onClick={() => addWindow.Profile.view(item?.employee?.id)}
                    >
                      {item?.employee?.firstName +
                        " " +
                        item?.employee?.lastName}
                    </Link>
                  );
                },
              },
              {
                isResizable: true,
                key: "date",
                onColumnClick(ev, column) {
                  sortByDate();
                },
                minWidth: 0,
                maxWidth: 50,
                name: ((): string => {
                  if (list.isSorted.byDateUP) {
                    return "Einsatz vom | oben";
                  } else if (list.isSorted.byDateDOWN) {
                    return "Einsatz vom | unten";
                  } else {
                    return "Einsatz vom";
                  }
                })(),
                styles: {
                  root: {
                    borderRadius: "16px 16px 0px 0px",
                    background:
                      list.isSorted.byDateUP || list.isSorted.byDateDOWN
                        ? theme.palette.themePrimary
                        : undefined,
                  },
                },
                onRender: (item: any) => {
                  return format(new Date(item?.date), "dd.MM.yy", {
                    locale: de,
                  });
                },
              },
              {
                isResizable: true,
                key: "duration",
                onColumnClick(ev, column) {
                  sortByDuration();
                },
                minWidth: 0,
                maxWidth: 50,
                name: ((): string => {
                  if (list.isSorted.byDurationUP) {
                    return "Dauer | oben";
                  } else if (list.isSorted.byDurationDOWN) {
                    return "Dauer | unten";
                  } else {
                    return "Dauer";
                  }
                })(),
                styles: {
                  root: {
                    borderRadius: "16px 16px 0px 0px",
                    background:
                      list.isSorted.byDurationUP || list.isSorted.byDurationDOWN
                        ? theme.palette.themePrimary
                        : undefined,
                  },
                },
                onRender: (item: any) => returnReadableDuration(item?.minutes),
              },
              {
                key: "amount",
                minWidth: 0,
                maxWidth: 50,
                name: ((): string => {
                  if (list.isSorted.byAmountUP) {
                    return "Betrag | oben";
                  } else if (list.isSorted.byAmountDOWN) {
                    return "Betrag | unten";
                  } else {
                    return "Betrag";
                  }
                })(),
                styles: {
                  root: {
                    borderRadius: "16px 16px 0px 0px",
                    background:
                      list.isSorted.byAmountUP || list.isSorted.byAmountDOWN
                        ? theme.palette.themePrimary
                        : undefined,
                  },
                },
                onColumnClick(ev, column) {
                  sortByAmount();
                },
                onRender: (item: any) => "CHF " + (item?.minutes / 60) * 13,
              },
              {
                isResizable: true,
                key: "confirmed",
                minWidth: 0,
                maxWidth: 50,
                onColumnClick(ev, column) {
                  sortByConfirmed();
                },
                name: ((): string => {
                  if (list.isSorted.byConfirmedUP) {
                    return "Bestätigt | oben";
                  } else if (list.isSorted.byConfirmedDOWN) {
                    return "Bestätigt | unten";
                  } else {
                    return "Bestätigt";
                  }
                })(),
                styles: {
                  root: {
                    borderRadius: "16px 16px 0px 0px",
                    background:
                      list.isSorted.byConfirmedUP ||
                      list.isSorted.byConfirmedDOWN
                        ? theme.palette.themePrimary
                        : undefined,
                  },
                },
                onRender: (item: any) => {
                  return (
                    <Toggle
                      checked={item?.confirmedByJobfair}
                      disabled={item?.invoiceId || isArchived || item?.job?.isArchived}
                      onClick={async () =>
                        await updateWorkLog.mutateAsync({
                          workLogId: item?.id,
                          confirmedByJobfair: !item?.confirmedByJobfair,
                        })
                      }
                    />
                  );
                },
              },
              {
                isResizable: true,
                key: "receipt",
                minWidth: 0,
                maxWidth: 50,
                name: ((): string => {
                  if (list.isSorted.byReceiptUP) {
                    return "Quittung | oben";
                  } else if (list.isSorted.byReceiptDOWN) {
                    return "Quittung | unten";
                  } else {
                    return "Quittung";
                  }
                })(),
                onColumnClick(ev, column) {
                  sortByReceipt();
                },
                onRender: (item: any) => {
                  return (
                    <Link
                      onClick={async () => {
                        let res = await axiosJWT.get(
                          process.env.REACT_APP_SERVER +
                            "/api/a/get-receipt-by-id/" +
                            item?.receipt?.id
                        );

                        const link: any = res.data;

                        window.open(link, "blank");
                      }}
                    >
                      {item?.receipt?.publicKey}
                    </Link>
                  );
                },
                styles: {
                  root: {
                    borderRadius: "16px 16px 0px 0px",
                    background:
                      list.isSorted.byReceiptUP || list.isSorted.byReceiptDOWN
                        ? theme.palette.themePrimary
                        : undefined,
                  },
                },
              },
            ]}
            isHeaderVisible={true}
            items={list.items}
            onItemInvoked={(item) => addWindow.WorkLog.view(item!.id)}
            selectionMode={SelectionMode.none}
            styles={{
              root: { cursor: "pointer", borderRadius: "0px 0px 16px 16px" },
            }}
          />
        </Stack>
      )}
    </Stack>
  );
};

export default ListWorkLogs;
