import {
    DetailsList,
    IconButton,
    Link,
    SelectionMode,
    Spinner,
    Stack, Text, useTheme
} from "@fluentui/react";
import { format } from "date-fns";
import { de } from "date-fns/locale";
import { useState } from "react";
import axiosJWT from "../assets/axiosjwt";
import { Icons } from "../assets/icons";
import { useDailyPayoutsByLocation } from "../assets/queries";
import { addWindow } from "../assets/windows";
import {
    PushDown
} from "../components/misc";

const ViewStats: React.FC = () => {
    const dailyPayouts = useDailyPayoutsByLocation()
    const [isLoading, setIsLoading] = useState(false)
    const theme = useTheme()

    const getReceiptAsPDF = async (item: any) => {
        let res = await axiosJWT.get(
            process.env.REACT_APP_SERVER +
            "/api/a/get-receipt-by-id/" +
            item?.id
        );

        const link: any = res.data;

        window.open(link, "blank");
    }

    return (
        <Stack>
            <Stack styles={{ root: { background: theme.palette.white, padding: 20, borderRadius: 16 } }}>
                <PushDown by={40} />

                <Text style={{ fontWeight: 700 }}>Auszahlungen</Text>
                <Text>{format(new Date(), "dd. MMMM yyy", { locale: de })}</Text>

                <br />

                {dailyPayouts.isSuccess && Object.keys(dailyPayouts.data).map((location: any) => {
                    return (
                        <Stack key={location}>
                            <Text style={{ fontWeight: 700 }}>{location.charAt(0).toUpperCase() + location.slice(1, location.length)}</Text>

                            <Text>Gesamt: CHF {dailyPayouts.data[location].amount / 100}</Text>

                            <DetailsList
                                columns={[
                                    {
                                        key: "invoice",
                                        minWidth: 0,
                                        name: "Rechnung",
                                        onRender: (item: any) => {
                                            return (
                                                <Link
                                                    onClick={() =>
                                                        addWindow.Invoice.view(item?.invoice?.invoiceId)
                                                    }
                                                >
                                                    {item?.invoice?.publicKey}
                                                </Link>
                                            );
                                        },
                                    },
                                    {
                                        key: "employer",
                                        minWidth: 0,
                                        name: "Auftraggeber*in",
                                        onRender: (item: any) => {
                                            if (item?.job?.employer?.type === "publicEmployer") {
                                                return (
                                                    <Link
                                                        onClick={() =>
                                                            addWindow.Profile.view(item?.job?.employer?.id)
                                                        }
                                                    >
                                                        {item?.job?.employer?.description}
                                                    </Link>
                                                );
                                            }
                                            if (item?.job?.employer?.type !== "publicEmployer") {
                                                return (
                                                    <Link
                                                        onClick={() =>
                                                            addWindow.Profile.view(item?.job?.employer?.id)
                                                        }
                                                    >
                                                        {item?.job?.employer?.firstName +
                                                            " " +
                                                            item?.job?.employer?.lastName}
                                                    </Link>
                                                );
                                            }
                                        },
                                    },
                                    {
                                        key: "employee",
                                        minWidth: 0,
                                        name: "Jugendliche*r",
                                        onRender: (item: any) => {
                                            return (
                                                <Link
                                                    onClick={() => addWindow.Profile.view(item?.employee?.id)}
                                                >
                                                    {item?.employee?.firstName + " " + item?.employee?.lastName}
                                                </Link>
                                            );
                                        },
                                    },
                                    {
                                        key: "job",
                                        minWidth: 0,
                                        name: "Job",
                                        onRender: (item: any) => {
                                            return (
                                                <Link onClick={() => addWindow.Job.view(item?.job?.id)}>
                                                    {item?.job?.title}
                                                </Link>
                                            );
                                        },
                                    },
                                    {
                                        key: "amount",
                                        minWidth: 55,
                                        name: "Betrag",
                                        onRender: (item: any) => "CHF " + item?.amount / 100,
                                    },
                                    {
                                        isIconOnly: true,
                                        key: "actions",
                                        minWidth: 0,
                                        name: "Aktionen",
                                        onRender: (item: any) => {
                                            if (!isLoading)
                                                return (
                                                    <IconButton
                                                        iconProps={{ iconName: Icons.Receipt }}
                                                        onClick={async () => {
                                                            setIsLoading(true);
                                                            let res = await axiosJWT.get(
                                                                process.env.REACT_APP_SERVER +
                                                                "/api/a/get-receipt-by-id/" +
                                                                item?.id
                                                            );

                                                            const link: any = res.data;

                                                            setIsLoading(false);

                                                            window.open(link, "blank");
                                                        }}
                                                        styles={{
                                                            icon: { fontSize: 15 },
                                                            iconHovered: { color: "#FFD23F" },
                                                            root: { borderRadius: 16, height: 20, width: 20 },
                                                        }}
                                                    />
                                                );
                                            if (isLoading)
                                                return (
                                                    <Spinner
                                                        styles={{
                                                            root: { height: 20, width: 20 },
                                                        }}
                                                    />
                                                );
                                        },
                                    },
                                ]}
                                items={dailyPayouts.data[location].receipts}
                                onItemInvoked={(item: any) => getReceiptAsPDF(item)}
                                selectionMode={SelectionMode.none}
                                styles={{ root: { cursor: "pointer" } }}
                            />

                            <br />
                        </Stack>
                    )
                })}
            </Stack>
        </Stack>
    )
};

export default ViewStats;
