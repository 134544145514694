import { formatDistance } from "date-fns";
import { de } from "date-fns/locale";
import { useQuery } from "react-query";
import axiosJWT from "./axiosjwt";
import { useMyUser } from "./queries";
import { useClientStore } from "./stores";
const { setState } = useClientStore;

export const setAccountInLocalStorage = async (
  jwt: string,
  userId: string,
  refreshToken: string
) => {
  localStorage.setItem("jwt", jwt);
  localStorage.setItem("userId", userId);
  localStorage.setItem("refreshToken", refreshToken);
};

export const redirectTo = (url: string) => {
  window.location.href = url;
};

export const useSetup = () => {
  const myUser = useMyUser();
  if (myUser.data?.setting.darkmode) {
    setState({ darkmode: true });
  }
  if (myUser.data?.setting.editMode) {
    setState({ editMode: true });
  }
  if (myUser.data?.setting?.preventUnintendedReloadByUser) {
    window.addEventListener("beforeunload", (event) => {
      event.preventDefault();
      event.returnValue = "";
    });
  }

  window.addEventListener("storage", () => {
    if (localStorage.doNotReload) return true;
    window.location.reload();
  });
  return true;
};

export const triggerSignOut = () => {
  localStorage.removeItem("jwt");
  localStorage.removeItem("userId");
  localStorage.removeItem("refreshToken");
  window.location.reload();
};

export const returnMobilephone = (mobilephone: string) => {
  if (!mobilephone) return "";

  mobilephone = mobilephone.replace(" ", "");
  mobilephone = mobilephone.replace(" ", "");
  mobilephone = mobilephone.replace(" ", "");

  if (mobilephone.length < 10) return mobilephone;

  return (
    mobilephone.slice(0, 3) +
    " " +
    mobilephone.slice(3, 6) +
    " " +
    mobilephone.slice(6, 8) +
    " " +
    mobilephone.slice(8, 10)
  );
};

export const setCurrentlyFocusedWindowsId = (windowId: string) => {
  setTimeout(() => setState({ currentlyFocusedWindowId: windowId }), 10);
};

export const getRandomId = () => {
  return Math.floor(Math.random() * 1000000000).toString();
};

export const returnServerError = (serverError: any) => {
  if (serverError?.response?.data?.error?.details?.message) {
    return {
      message: serverError?.response?.data?.error?.details?.message,
      type: "warning",
    };
  }
  if (serverError?.response?.data?.error?.details?.error?.details?.errors[0]) {
    return {
      message:
        serverError?.response?.data?.error?.details?.error?.details?.errors[0].path[0].toUpperCase() +
        ": " +
        serverError?.response?.data?.error?.details?.error?.details?.errors[0]
          .message,
      type: "warning",
    };
  }
  if (serverError?.response?.data?.error) {
    return {
      message: serverError?.response?.data?.error?.details?.message,
      type: "warning",
    };
  }

  if (!serverError?.response?.data?.data) {
    let status = serverError?.response?.status;
    switch (status) {
      case 401:
        return {
          message: "Berechtigung fehlt",
          type: "warning",
        };
      case 403:
        return {
          message: "Berechtigung fehlt",
          type: "warning",
        };
      default:
        break;
    }
  }

  if (serverError?.response?.data?.data) return serverError.response.data.data;

  if (serverError?.response?.errors)
    return serverError.response.errors[0].extensions.exception.data;

  return {};
};

export const returnReadableDuration = (data: any) => {
  let duration = parseInt(data);
  let hours = Math.floor(duration / 60);
  let minutes = (duration % 60).toString();

  if (duration % 60 < 10) minutes = "0" + minutes;

  if (hours > 0) {
    if (parseInt(minutes) > 0) {
      return hours + " Std. " + minutes + " Min.";
    } else {
      return hours + " Stunde(n)";
    }
  } else {
    return minutes + " Minuten";
  }
};

export const returnErrorsToReset = (updatedFields: any) => {
  let errorsToReset: any = {};
  for (let fieldName of Object.keys(updatedFields)) {
    errorsToReset[fieldName] = "";
  }
  return errorsToReset;
};

export const returnCreatedAt = (query: any) => {
  return (
    query.data?.createdAt &&
    "vor " +
      formatDistance(new Date(query.data?.createdAt), new Date(), {
        locale: de,
      })
  );
};

export const returnUpdatedAt = (query: any) => {
  return (
    query.data?.updatedAt &&
    "vor " +
      formatDistance(new Date(query.data?.updatedAt), new Date(), {
        locale: de,
      })
  );
};

export const returnCreatedBy = {
  fullName: (query: any) => {
    return query.data?.createdByProfile.firstName
      ? query.data?.createdByProfile?.firstName +
          " " +
          query.data?.createdByProfile?.lastName
      : query.data?.createdByProfile?.description
      ? query.data?.createdByProfile?.description
      : "Unbekannt";
  },
  username: (query: any) => {
    return query.data?.createdByProfile
      ? query.data?.createdByProfile?.username
      : "k.A.";
  },
};

export const returnUpdatedBy = {
  fullName: (query: any) => {
    return query.data?.updatedByProfile?.firstName
      ? query.data?.updatedByProfile?.firstName +
          " " +
          query.data?.updatedByProfile?.lastName
      : query.data?.updatedByProfile?.description
      ? query.data?.updatedByProfile?.description
      : "Unbekannt";
  },
  username: (query: any) => {
    return query.data?.updatedByProfile
      ? query.data?.updatedByProfile?.username
      : "k.A.";
  },
};

export const sortItems = (
  field: string,
  list: any,
  onResult: (items: Array<any>) => void
) => {
  let results = list.items.sort((a: any, b: any) => {
    if (list.isSorted[field]) return a[field] < b[field] ? 1 : -1;
    return a[field] > b[field] ? 1 : -1;
  });
  onResult(results);
};

export const searchText = (
  items: Array<any>,
  searchTerm: string,
  onResult: (results: Array<any>) => void,
  onError: (message: string) => void
) => {
  try {
    let searchTerms = searchTerm.split(" ");

    let results: any = [];

    items.forEach((item: any) => {
      let contains: any = {};

      searchTerms.forEach((term: string) => {
        term = term.toLowerCase();
        ["firstName", "lastName"].forEach((key: any) => {
          ["employer", "employee"].forEach((type) => {
            let value = item[type][key];
            if (!value) return;
            value = value.toLowerCase();
            if (value.indexOf(term) !== -1) contains[term] = true;
          });
        });
      });
      let isResult = Object.keys(contains).length === searchTerms.length;
      if (isResult) results.push(item);
    });

    if (results.length > 0) return onResult(results);

    onError("Kein Resultat");
  } catch (error) {
    console.log(error);
    onError("Unbekannter Fehler");
  }
};

export const searchInvoices = (
  items: Array<any>,
  searchTerm: string,
  onResult: (results: Array<any>) => void,
  onError: (message: string) => void
) => {
  try {
    let invoicesIndex = [];

    const createIndex = () => {
      let invoicesIndex: any = [];

      items.forEach((item: any) => {
        let string = "";

        string += item.publicKey;
        string += item.smallinvoice_invoice_data.item.total;

        switch (item.smallinvoice_invoice_data.item.status) {
          case "DR":
            string += "entwurf";
            break;
          case "S":
            string += "gesendet";
            break;
          case "C":
            string += "storniert";
            break;
          case "P":
            string += "bezahlt";
            break;

          default:
            break;
        }

        string += item.employer.description;
        string += item.employer.firstName;
        string += item.employer.lastName;
        string += item.employee.firstName;
        string += item.employee.lastName;
        string += item.job.description;

        string = string.toLowerCase();

        invoicesIndex.push({
          id: item.id,
          string: string,
          original: item,
        });
      });

      return invoicesIndex;
    };

    invoicesIndex = createIndex();

    // if (localStorage.invoicesIndex && localStorage.invoicesLastIndexed) {
    //   let quarterHourInMs = 15 * 60 * 1000
    //   if (new Date(localStorage.invoicesLastIndexed).getTime() < new Date().getTime() - quarterHourInMs) {
    //     invoicesIndex = createIndex()
    //     localStorage.invoicesIndex = JSON.stringify(invoicesIndex)
    //   } else {
    //     invoicesIndex = JSON.parse(localStorage.invoicesIndex)
    //   }
    // } else {
    //   invoicesIndex = createIndex()
    //   localStorage.invoicesLastIndexed = new Date()
    //   localStorage.invoicesIndex = JSON.stringify(invoicesIndex)
    // }

    let searchTerms = searchTerm.split(" ");

    let results: any = [];

    invoicesIndex.forEach((item: any) => {
      let contains: any = {};

      searchTerms.forEach((term: string) => {
        term = term.toLowerCase();
        if (item.string.indexOf(term) !== -1) {
          contains[term] = true;
        }
      });

      let isResult = Object.keys(contains).length === searchTerms.length;
      if (isResult) results.push(item.original);
    });

    if (results.length > 0) return onResult(results);

    return onResult([]);
  } catch (error) {
    console.log(error);
    onError("Unbekannter Fehler");
  }
};

export const searchEmployees = (
  items: Array<any>,
  searchTerm: string,
  onResult: (results: Array<any>) => void,
  onError: (message: string) => void
) => {
  try {
    let employeesIndex = [];

    const createIndex = () => {
      let employeesIndex: any = [];

      items.forEach((item: any) => {
        let string = "";

        string += item.publicKey;
        string += item.firstName;
        string += item.lastName;

        string = string.toLowerCase();

        employeesIndex.push({
          id: item.id,
          string: string,
          original: item,
        });
      });

      return employeesIndex;
    };

    employeesIndex = createIndex();

    // if (localStorage.employeesIndex && localStorage.invoicesLastIndexed) {
    //   let quarterHourInMs = 15 * 60 * 1000
    //   if (new Date(localStorage.invoicesLastIndexed).getTime() < new Date().getTime() - quarterHourInMs) {
    //     employeesIndex = createIndex()
    //     localStorage.employeesIndex = JSON.stringify(employeesIndex)
    //   } else {
    //     employeesIndex = JSON.parse(localStorage.employeesIndex)
    //   }
    // } else {
    //   employeesIndex = createIndex()
    //   localStorage.invoicesLastIndexed = new Date()
    //   localStorage.employeesIndex = JSON.stringify(employeesIndex)
    // }

    let searchTerms = searchTerm.split(" ");

    let results: any = [];

    employeesIndex.forEach((item: any) => {
      let contains: any = {};

      searchTerms.forEach((term: string) => {
        term = term.toLowerCase();
        if (item.string.indexOf(term) !== -1) {
          contains[term] = true;
        }
      });

      let isResult = Object.keys(contains).length === searchTerms.length;
      if (isResult) results.push(item.original);
    });

    if (results.length > 0) return onResult(results);

    return onResult([]);
  } catch (error) {
    console.log(error);
    onError("Unbekannter Fehler");
  }
};

export const returnPartOfString = (
  part: number,
  string: string,
  lowerCase: boolean = true
) => {
  string = string.split(" ")[part - 1];
  if (lowerCase) return string.toLowerCase();

  return string;
};

export const changeForm = (updatedFields: any, form: any, setForm: any) => {
  setForm({
    ...form,
    ...updatedFields,
    errors: {
      ...form?.errors,
      ...returnErrorsToReset(updatedFields),
    },
  });
};

export const useIsServerRunning = () => {
  return useQuery(
    "isServerRunning",
    async () => await axiosJWT.get(process.env.REACT_APP_SERVER + "/"),
    { retry: 2 }
  );
};
