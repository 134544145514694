import {
  DefaultEffects,
  IconButton,
  Image,
  ImageFit,
  IStackStyles,
  Label,
  MessageBar,
  MessageBarType,
  Persona,
  PersonaPresence,
  PersonaSize,
  SearchBox,
  Separator,
  Stack,
  Text,
  useTheme,
} from "@fluentui/react";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import {
  returnCreatedAt,
  returnCreatedBy,
  returnServerError,
  returnUpdatedAt,
  returnUpdatedBy,
  searchEmployees,
  searchInvoices,
  searchText,
} from "../assets/functions";
import { Icons } from "../assets/icons";

export const Logo: React.FC<{ type?: "full" | "text" | "image" }> = ({
  type = "text",
}) => {
  if (type === "full")
    return (
      <Stack styles={{ root: { alignSelf: "center" } }}>
        <Image src="https://i.ibb.co/RN91MLT/logo.png" height="100px" />
        <Text variant="xLarge">jobfairy</Text>
      </Stack>
    );
  if (type === "text")
    return (
      <Stack styles={{ root: { alignSelf: "center" } }}>
        <Text variant="xLarge">jobfairy</Text>
      </Stack>
    );
  if (type === "image")
    return (
      <Stack styles={{ root: { alignSelf: "start" } }}>
        <Image src="https://i.ibb.co/RN91MLT/logo.png" height="100px" />
      </Stack>
    );
  return null;
};

export const PullUp: React.FC<{ by?: number }> = ({ by = 10 }) => {
  // styles
  const stackStyles: any = { root: { marginBottom: -by } };

  return <Stack styles={stackStyles}></Stack>;
};

export const PushDown: React.FC<{ by?: number }> = ({ by = 10 }) => {
  // styles
  const stackStyles: any = { root: { marginBottom: by } };

  return <Stack styles={stackStyles}></Stack>;
};

export const QueryProgressIndicator: React.FC<{
  query: any;
}> = ({ query }) => {
  const [errors, setErrors] = useState<any>({});
  const theme = useTheme();
  useEffect(
    () => setErrors(returnServerError(query?.error)),
    // eslint-disable-next-line
    [query?.error]
  );

  return (
    <Stack
      styles={{
        root: {
          bottom: 0,
          left: 0,
          width: "100%",
          zIndex: 1,
          marginBottom: 10,
        },
      }}
    >
      {query.isFetching && (
        <Stack tokens={{ childrenGap: 10 }}>
          <MessageBar
            isMultiline={false}
            messageBarType={MessageBarType.info}
            styles={{ root: { borderRadius: 16 } }}
          >
            Lade ...
          </MessageBar>
        </Stack>
      )}
      {!query.isFetching && query.isError && (
        <Stack horizontal>
          <MessageBar
            styles={{ root: { borderRadius: 16 } }}
            actions={
              <Stack>
                <IconButton
                  disabled
                  iconProps={{ iconName: Icons.Bug }}
                  // onClick={() => addWindow.Json.view(query.error.response)}
                />
              </Stack>
            }
            isMultiline={errors?.isMultiline || false}
            // messageBarType={MessageBarType[errors?.type || "severeWarning"]}
          >
            {errors?.message || "Unbekannter Fehler"}
          </MessageBar>
        </Stack>
      )}
      {/* possible confusion as data?.length doesn't work with single entries */}
      {!query.isFetching && query.isSuccess && query.data?.length < 1 && (
        <MessageBar
          messageBarType={MessageBarType.warning}
          styles={{ root: { background: "transparent", borderRadius: 16 } }}
        >
          <Text>Kein Eintrag</Text>
        </MessageBar>
      )}
    </Stack>
  );
};

export const MutationProgressIndicator: React.FC<{
  mutationQuery: any;
}> = ({ mutationQuery }) => {
  const [errors, setErrors] = useState<any>({});

  const success = () => {
    Swal.fire({
      timer: 1000,
      position: "top-end",
      title: "Erfolgreich",
      toast: true,
      color: "success",
      showConfirmButton: false,
    });
  };

  const error = (error: string) => {
    Swal.fire({
      buttonsStyling: false,
      title: "Fehlgeschlagen",
      text: errors?.message,
      toast: true,
      color: "danger",
      showConfirmButton: true,
    });
  };

  useEffect(() => {
    if (mutationQuery.isSuccess) {
      success();
    }
  }, [mutationQuery.isSuccess]);

  useEffect(() => {
    setErrors(returnServerError(mutationQuery.error));
  }, [mutationQuery.isError, mutationQuery.error]);

  useEffect(() => {
    if (mutationQuery.isError) {
      error(mutationQuery.error);
    }
  }, [errors]);

  return (
    <Stack
      styles={{
        root: {
          bottom: 0,
          left: 0,
          width: "100%",
          zIndex: 1,
          marginBottom: 10,
        },
      }}
    >
      {mutationQuery.isLoading && (
        <MessageBar
          styles={{ root: { borderRadius: 16 } }}
          messageBarType={MessageBarType.info}
          isMultiline={false}
        >
          Aktualisiere ...
        </MessageBar>
      )}
      {mutationQuery.isError && (
        <MessageBar
          styles={{ root: { borderRadius: 16 } }}
          actions={
            <Stack>
              <IconButton
                disabled
                iconProps={{ iconName: Icons.Bug }}
                // onClick={() =>
                //   addWindow.Json.view(mutationQuery.error.response)
                // }
              />
            </Stack>
          }
          isMultiline={errors?.isMultiline || false}
          // messageBarType={MessageBarType[errors?.type || "severeWarning"]}
        >
          {errors?.message || "Unbekannter Fehler"}
          <br />
          {errors?.secondaryMessage}
        </MessageBar>
      )}
      {mutationQuery.isSuccess && (
        <MessageBar
          messageBarType={MessageBarType.success}
          isMultiline={false}
          styles={{ root: { borderRadius: 16 } }}
        >
          Aktualisierung erfolgreich
        </MessageBar>
      )}
    </Stack>
  );
};

export const SeparatorTransparent: React.FC<{
  alignContent?: "start" | "end" | "center";
}> = ({ alignContent = "start", children }) => {
  return (
    <Separator
      alignContent={alignContent}
      styles={{ content: { background: "transparent" } }}
    >
      {children}
    </Separator>
  );
};

export const InternalMeta: React.FC<{ query: any }> = ({ query }) => {
  const createdAt = returnCreatedAt(query);
  const updatedAt = returnUpdatedAt(query);
  const createdBy = returnCreatedBy.fullName(query);
  const updatedBy = returnUpdatedBy.fullName(query);

  const personaCreated = {
    imageInitials:
      createdBy.charAt(0).toUpperCase() + createdBy.charAt(1).toUpperCase(),
    text:
      query.data?.createdByProfile?.firstName &&
      query.data?.createdByProfile?.lastName
        ? query.data?.createdByProfile?.firstName +
          " " +
          query.data?.createdByProfile?.lastName
        : query.data?.createdByProfile?.description
        ? query.data?.createdByProfile?.description
        : "Unbekannt",
  };
  const personaUpdated = {
    imageInitials:
      updatedBy.charAt(0).toUpperCase() + updatedBy.charAt(1).toUpperCase(),
    text:
      query.data?.updatedByProfile?.firstName &&
      query.data?.updatedByProfile?.lastName
        ? query.data?.updatedByProfile?.firstName +
          " " +
          query.data?.updatedByProfile?.lastName
        : query.data?.createdByProfile?.description
        ? query.data?.createdByProfile?.description
        : "Unbekannt",
  };

  // styles
  const stackTokens: any = { childrenGap: 10 };
  const stackStyles: any = { root: { width: "100%" } };
  const theme = useTheme();

  return (
    <Stack
      tokens={stackTokens}
      styles={{ root: { background: theme.palette.white, padding: 10 } }}
    >
      <Stack horizontal>
        <Stack grow styles={stackStyles} tokens={stackTokens}>
          <Label>erstellt von</Label>
          <Persona
            {...personaCreated}
            size={PersonaSize.size48}
            presence={PersonaPresence.none}
            secondaryText={createdAt}
          />
        </Stack>
        <Stack grow styles={stackStyles} tokens={stackTokens}>
          <Label>zuletzt aktualisiert von</Label>
          <Persona
            {...personaUpdated}
            size={PersonaSize.size48}
            presence={PersonaPresence.none}
            secondaryText={updatedAt}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export const DangerZone: React.FC<{
  payload: { id: string; type: string };
  phrase?: string;
  windowId: string;
}> = ({}) => {
  // styles
  const stackTokens: any = { childrenGap: 10 };

  return (
    <Stack tokens={stackTokens}>
      <Stack horizontal tokens={stackTokens}></Stack>
    </Stack>
  );
};

export const ProfileImage: React.FC<{ profileImageUrl: string }> = ({
  profileImageUrl,
}) => {
  // styles
  const imageStyles = {
    root: { borderRadius: 16, boxShadow: DefaultEffects.elevation4 },
  };

  return (
    <Image
      height={150}
      imageFit={ImageFit.centerCover}
      shouldFadeIn={false}
      src={
        profileImageUrl
          ? profileImageUrl
          : process.env.REACT_APP_SERVER + "/uploads/logo.svg"
      }
      styles={imageStyles}
      width={150}
    />
  );
};

export const SearchBoxList: React.FC<{
  items: any;
  onResult: (results: Array<any>) => void;
  onClear: () => void;
}> = ({ items, onClear, onResult }) => {
  const checkValue = (newValue?: string) => {
    if (!newValue) return onClear();
    searchText(items, newValue, onResult, onError);
  };
  const [error, setError] = useState<{
    isError: boolean;
    message: string | undefined;
  }>({
    isError: false,
    message: undefined,
  });

  const onError = (message: string) =>
    setError({ isError: true, message: message });

  // styles
  const theme = useTheme();
  const stackStyles: IStackStyles = {
    root: {
      background: theme.palette.white,
      left: 0,
      padding: "20px 20px 0px 20px",
      // position: "absolute",
      top: 60,
      width: "100%",
      zIndex: 1,
    },
  };

  return (
    <Stack styles={stackStyles}>
      <SearchBox
        onClear={onClear}
        onChange={(event, newValue) => {
          setError({ isError: false, message: "" });
          checkValue(newValue);
        }}
        placeholder="Jugendliche*r suchen"
      />
      {error.isError && error.message !== "Kein Resultat" && (
        <MessageBar messageBarType={MessageBarType.error}>
          {error.message || "Unbekannter Fehler"}
        </MessageBar>
      )}
      {error.isError && error.message === "Kein Resultat" && (
        <MessageBar messageBarType={MessageBarType.info}>
          {error.message || "Unbekannter Fehler"}
        </MessageBar>
      )}
    </Stack>
  );
};

export const SearchBoxInvoices: React.FC<{
  items: any;
  onResult: (results: Array<any>) => void;
  onClear: () => void;
}> = ({ items, onClear, onResult }) => {
  const checkValue = (newValue?: string) => {
    if (!newValue) return onClear();
    searchInvoices(items, newValue, onResult, onError);
  };
  const [error, setError] = useState<{
    isError: boolean;
    message: string | undefined;
  }>({
    isError: false,
    message: undefined,
  });

  const onError = (message: string) =>
    setError({ isError: true, message: message });

  // styles
  const theme = useTheme();
  const stackStyles: IStackStyles = {
    root: {
      background: theme.palette.white,
      left: 0,
      padding: "20px 20px 0px 20px",
      // position: "absolute",
      top: 60,
      width: "100%",
      zIndex: 1,
    },
  };

  return (
    <Stack styles={stackStyles}>
      <SearchBox
        onClear={onClear}
        onChange={(event, newValue) => {
          setError({ isError: false, message: "" });
          checkValue(newValue);
        }}
        placeholder="Suchen"
      />
      {error.isError && error.message !== "Kein Resultat" && (
        <MessageBar messageBarType={MessageBarType.error}>
          {error.message || "Unbekannter Fehler"}
        </MessageBar>
      )}
      {error.isError && error.message === "Kein Resultat" && (
        <MessageBar messageBarType={MessageBarType.info}>
          {error.message || "Unbekannter Fehler"}
        </MessageBar>
      )}
    </Stack>
  );
};

export const SearchBoxEmployees: React.FC<{
  items: any;
  onResult: (results: Array<any>) => void;
  onClear: () => void;
}> = ({ items, onClear, onResult }) => {
  const checkValue = (newValue?: string) => {
    if (!newValue) return onClear();
    searchEmployees(items, newValue, onResult, onError);
  };
  const [error, setError] = useState<{
    isError: boolean;
    message: string | undefined;
  }>({
    isError: false,
    message: undefined,
  });

  const onError = (message: string) =>
    setError({ isError: true, message: message });

  // styles
  const theme = useTheme();
  const stackStyles: IStackStyles = {
    root: {
      background: theme.palette.white,
      left: 0,
      padding: "20px 20px 0px 20px",
      // position: "absolute",
      top: 60,
      width: "100%",
      zIndex: 1,
    },
  };

  return (
    <Stack styles={stackStyles}>
      <SearchBox
        onClear={onClear}
        onChange={(event, newValue) => {
          setError({ isError: false, message: "" });
          checkValue(newValue);
        }}
        placeholder="Suchen"
      />
      {error.isError && error.message !== "Kein Resultat" && (
        <MessageBar messageBarType={MessageBarType.error}>
          {error.message || "Unbekannter Fehler"}
        </MessageBar>
      )}
      {error.isError && error.message === "Kein Resultat" && (
        <MessageBar messageBarType={MessageBarType.info}>
          {error.message || "Unbekannter Fehler"}
        </MessageBar>
      )}
    </Stack>
  );
};
