import { Stack } from "@fluentui/react";
import { useState } from "react";
import { useJobfairs } from "../../assets/queries";
import { myColumnRight, myTokens } from "../../assets/styles";
import {
  FieldDropdownCreate,
  FieldStringCreate,
  FormColumnLeft,
  MyColumnRightLabel,
} from "./Parts";

const FormCreateUser: React.FC<{
  form: any;
  onChange: (updatedFields: any) => void;
}> = ({ form, onChange }) => {
  const jobfairs = useJobfairs();
  const [jobfairId, setJobfairId] = useState();

  return (
    <Stack tokens={myTokens}>
      <Stack horizontal tokens={myTokens}>
        <FormColumnLeft label="Teammitglied" />

        <Stack horizontal styles={myColumnRight} tokens={myTokens}>
          <FieldStringCreate
            errorMessage={form?.errors?.firstName}
            label="Vorname"
            onChange={(newValue) => onChange({ firstName: newValue })}
            required
          />

          <FieldStringCreate
            errorMessage={form?.errors?.lastName}
            label="Nachname"
            onChange={(newValue) => onChange({ lastName: newValue })}
            required
          />
        </Stack>
      </Stack>

      <MyColumnRightLabel label="Zugehörigkeit" />

      <Stack horizontal tokens={myTokens}>
        <FormColumnLeft />

        <Stack horizontal styles={myColumnRight} tokens={myTokens}>
          <FieldStringCreate
            errorMessage={form?.errors?.position}
            label="Funktion"
            onChange={(newValue) => onChange({ position: newValue })}
            placeholder="z.B. Praktikant*in"
            required
          />

          <FieldDropdownCreate
            errorMessage={form?.errors?.jobfairId}
            form={form}
            disabled={
              !jobfairs.isSuccess ||
              (jobfairs.isSuccess && jobfairs.data.length < 1)
            }
            label="Jobbörse"
            onFieldChange={(option: any) => {
              onChange({ jobfairId: option });
              setJobfairId(option);
            }}
            options={jobfairs.data}
            placeholder={
              jobfairs.isSuccess
                ? jobfairs.data.length > 0
                  ? ""
                  : "Keine Einträge gefunden"
                : "Fehler beim Laden"
            }
            selectedKey={jobfairId}
          />
        </Stack>
      </Stack>

      <MyColumnRightLabel
        label={"Zugangsdaten für demo.jobfairy.ch (generiert)"}
      />

      <Stack horizontal tokens={myTokens}>
        <FormColumnLeft />

        <Stack horizontal styles={myColumnRight} tokens={myTokens}>
          <FieldStringCreate
            disabled
            errorMessage={form?.errors?.username}
            label="Benutzername"
            placeholder={form?.username}
            readOnly
          />

          <FieldStringCreate
            disabled
            errorMessage={form?.errors?.email}
            label="E-Mail"
            placeholder={form?.email}
            readOnly
          />
        </Stack>
      </Stack>

      <Stack horizontal tokens={myTokens}>
        <FormColumnLeft />

        <Stack horizontal styles={myColumnRight} tokens={myTokens}>
          <FieldStringCreate
            disabled
            errorMessage={form?.errors?.password}
            label="Passwort"
            placeholder="jobfairy"
            readOnly
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default FormCreateUser;
