import {
  DefaultButton,
  DetailsList,
  Icon,
  Link,
  Modal,
  SelectionMode,
  Spinner,
  Stack,
} from "@fluentui/react";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Icons } from "../assets/icons";
import {
  useGenerateAuftragsbestaetigung,
  useGenerateRapportblatt,
  useUnlinkEmployee,
} from "../assets/mutations";
import { useEmployeesByJob } from "../assets/queries";
import { addWindow } from "../assets/windows";
import {
  MutationProgressIndicator,
  QueryProgressIndicator,
} from "../components/misc";
import ViewCreateWorkLog from "../views/ViewCreateWorkLog";

const ListEmployeesByJob: React.FC<{
  jobId: string;
  isArchived?: boolean;
}> = ({ jobId, isArchived }) => {
  const employees = useEmployeesByJob(jobId);
  const [list, setList] = useState<any>({
    items: [],
    isSorted: {},
  });
  const clear = () => setList({ ...list, items: employees.data });
  const setItems = (items: Array<any>) => setList({ ...list, items: items });
  const generateRapportblatt = useGenerateRapportblatt();
  const generateAuftragsbestaetigung = useGenerateAuftragsbestaetigung();
  const [isLoading, setIsLoading] = useState(false);
  const unlinkEmployee = useUnlinkEmployee();
  const [showCreateWorkLog, setShowCreateWorkLog] = useState(false);
  const [currentEmployeeId, setCurrentEmployeeId] = useState("");

  useEffect(() => {
    if (employees.isSuccess) setItems(employees.data);
    // eslint-disable-next-line
  }, [employees.isSuccess, employees.data]);

  return (
    <Stack>
      <Modal
        onDismiss={() => setShowCreateWorkLog(false)}
        isOpen={showCreateWorkLog}
        styles={{ main: { borderRadius: 16, height: "50vh", width: "50vw" } }}
      >
        <ViewCreateWorkLog
          employeeId={currentEmployeeId}
          windowId={"createWorkLog_" + currentEmployeeId}
          jobId={jobId}
          setShowCreateWorkLog={setShowCreateWorkLog}
        />
      </Modal>

      <QueryProgressIndicator query={employees} />

      {employees.isSuccess && employees.data.length > 0 && (
        <Stack>
          <DetailsList
            columns={[
              {
                fieldName: "firstName",
                isResizable: true,
                key: "firstName",
                minWidth: 0,
                name: "Vorname",
              },
              {
                fieldName: "lastName",
                isResizable: true,
                key: "lastName",
                minWidth: 0,
                name: "Nachname",
              },
              {
                key: "rapportblatt",
                minWidth: 0,
                name: "Rapportblatt",
                onRender: (item: any) => {
                  return (
                    <Link
                      disabled={isArchived}
                      onClick={async () => {
                        setIsLoading(true);
                        await generateRapportblatt.mutateAsync({
                          jobId: jobId,
                          employeeId: item?.id,
                        });
                        setIsLoading(false);
                      }}
                    >
                      {isLoading && <Spinner />}
                      {!isLoading && !isArchived && "Herunterladen"}
                    </Link>
                  );
                },
              },
              {
                key: "auftragsbestaetigung",
                minWidth: 0,
                name: "Auftragsbestätigung",
                onRender: (item: any) => {
                  return (
                    <Link
                      onClick={async () => {
                        setIsLoading(true);
                        await generateAuftragsbestaetigung.mutateAsync({
                          jobId: jobId,
                          employeeId: item?.id,
                        });
                        setIsLoading(false);
                      }}
                    >
                      {isLoading && <Spinner />}
                      {!isLoading && "Herunterladen"}
                    </Link>
                  );
                },
              },
              {
                key: "addWorkLog",
                minWidth: 0,
                name: "Arbeitseinsatz",
                onRender: (item: any) => {
                  return (
                    // <IconButton
                    //   iconProps={{ iconName: Icons.WorkLog }}
                    //   onClick={() => addWindow.WorkLog.add(item?.id, jobId)}
                    //   styles={{
                    //     icon: { fontSize: 15 },
                    //     iconHovered: { color: "#FFD23F" },
                    //     root: { borderRadius: 16, height: 20, width: 20 },
                    //   }}
                    // />
                    <DefaultButton
                      disabled={isArchived}
                      primary
                      onClick={() => {
                        setCurrentEmployeeId(item.id);
                        setShowCreateWorkLog(true);
                      }}
                    >
                      <Icon iconName={Icons.WorkLog} />
                    </DefaultButton>
                  );
                },
              },
              // {
              //   key: "addAssignment",
              //   minWidth: 0,
              //   name: "Termin",
              //   onRender: (item: any) => {
              //     return (
              //       <IconButton
              //         iconProps={{ iconName: Icons.Assignment }}
              //         onClick={() =>
              //           addWindow.Assignment.add(item?.id, jobId)
              //         }
              //         styles={{
              //           icon: { fontSize: 15 },
              //           iconHovered: { color: "#FFD23F" },
              //           root: { height: 20, width: 20 },
              //         }}
              //       />
              //     );
              //   },
              // },
              {
                fieldName: "unlink",
                isResizable: true,
                key: "unlink",
                minWidth: 0,
                name: "Entfernen",
                onRender: (item: any) => {
                  return (
                    // <IconButton onClick={() => {
                    //   unlinkEmployee.mutate({ jobId: jobId, employeeId: item?.id })
                    // }} iconProps={{ iconName: Icons.Delete }}
                    //   styles={{
                    //     icon: { fontSize: 15 },
                    //     iconHovered: { color: "#FFD23F" },
                    //     root: { height: 20, width: 20 },
                    //   }}
                    // />
                    <DefaultButton
                      disabled={isArchived}
                      color="danger"
                      onClick={() => {
                        Swal.fire({
                          buttonsStyling: false,
                          title: "Sicher?",
                          text: "Willst du diese Zuweisung wirklich entfernen?",
                          showCancelButton: true,
                          confirmButtonText: "Ja, Zuweisung entfernen",
                          cancelButtonText: "Abbrechen",
                        }).then((event: any) => {
                          if (event.isConfirmed)
                            unlinkEmployee.mutate({
                              jobId: jobId,
                              employeeId: item?.id,
                            });
                        });
                      }}
                    >
                      <Icon iconName={Icons.Delete} />
                    </DefaultButton>
                  );
                },
              },
            ]}
            isHeaderVisible={true}
            items={list.items}
            onItemInvoked={(item) => addWindow.Profile.view(item!.id)}
            selectionMode={SelectionMode.none}
            styles={{
              root: { cursor: "pointer", borderRadius: "0px 0px 16px 16px" },
            }}
          />
        </Stack>
      )}

      <MutationProgressIndicator mutationQuery={unlinkEmployee} />
    </Stack>
  );
};

export default ListEmployeesByJob;
