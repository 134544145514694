import {
  DefaultButton,
  Label,
  Spinner,
  Stack,
  Text,
  useTheme
} from "@fluentui/react";
import Swal from "sweetalert2";
import { Icons } from "../assets/icons";
import {
  useGenerateInvoicesByEmployer,
  useGenerateReceipts
} from "../assets/mutations";
import {
  useCreditByEmployee,
  useInvoicesByEmployerSmallInvoice,
  usePossibleDebtByEmployer,
  useReceiptsByEmployee
} from "../assets/queries";
import { PullUp, PushDown } from "../components/misc";
import ListInvoices from "../lists/ListInvoices";
import ListReceipts from "../lists/ListReceipts";
import PivotSubMenu from "./Parts";

export const AccountingEmployer: React.FC<{ employerId: string }> = ({
  employerId,
}) => {
  const debt = usePossibleDebtByEmployer(employerId);
  const generateInvoices = useGenerateInvoicesByEmployer(employerId);
  const invoices = useInvoicesByEmployerSmallInvoice(employerId)

  const theme = useTheme();
  const stackTokens: any = { childrenGap: 10 };

  return (
    <Stack styles={{ root: { background: theme.palette.white, borderRadius: "0px 0px 16px 16px" } }}>
      <PivotSubMenu>
        <DefaultButton
          disabled={debt.data?.future_debt < 1}
          iconProps={{
            iconName: !generateInvoices.isLoading ? Icons.Invoice : "",
          }}
          styles={{ root: { borderRadius: 16 } }}
          onClick={async () => {
            Swal.fire({
              buttonsStyling: false,
              confirmButtonText: "Ja",
              cancelButtonText: "Noch nicht",
              focusCancel: true,
              showCancelButton: true,
              title: "Sicher?",
              text: "Damit löst du Verrechnung ALLER verfügbaren Arbeitseinsätze der/des AG's aus.",
              reverseButtons: true,
            }).then(async (event: any) => {
              if (event.isConfirmed) {
                await generateInvoices.mutateAsync();
              }
            });
          }}
        >
          {!generateInvoices.isLoading && "Rechnungen auslösen"}
          {generateInvoices.isLoading && <Spinner />}
        </DefaultButton>
      </PivotSubMenu>

      <PushDown by={20} />

      <Stack grow horizontal styles={{ root: { padding: 20 } }}>
        <Stack tokens={stackTokens} styles={{ root: { width: "100%" } }}>
          <Text variant="medium">
            <small>noch nicht in Rechnung gestellt</small>

            <Label>voraussichtlicher Rechnungsbetrag</Label>

            <b style={{ color: theme.palette.orangeLighter }}>
              CHF {debt.data?.future_debt}
            </b>
          </Text>
        </Stack>

        <Stack tokens={stackTokens} styles={{ root: { width: "100%" } }}>
          <Text variant="medium">
            <small>in Rechnung gestellt</small>
            <Label>Rechnungsbetrag</Label>
            <b style={{ color: theme.palette.green }}>
              CHF {debt.data?.current_debt}
            </b>
          </Text>
        </Stack>
      </Stack>

      <PullUp by={100} />

      <ListInvoices query={invoices} />
    </Stack>
  );
};

export const AccountingEmployee: React.FC<{ employeeId: string }> = ({
  employeeId,
}) => {
  const credit = useCreditByEmployee(employeeId);
  const generateReceipts = useGenerateReceipts(employeeId);
  const receipts = useReceiptsByEmployee(employeeId)

  const theme = useTheme();
  const stackTokens: any = { childrenGap: 10 };

  return (
    <Stack styles={{ root: { background: theme.palette.white, borderRadius: "0px 0px 16px 16px" } }}>
      <PivotSubMenu>
        <DefaultButton
          disabled={credit.data?.current_credit < 1}
          iconProps={{
            iconName: !generateReceipts.isLoading ? Icons.Receipt : "",
          }}
          styles={{ root: { borderRadius: 16 } }}
          onClick={() => {
            generateReceipts.mutate();
          }}
        >
          {!generateReceipts.isLoading && "Quittungen erstellen"}
          {generateReceipts.isLoading && <Spinner />}
        </DefaultButton>
      </PivotSubMenu>

      <PushDown by={20} />

      <Stack grow horizontal styles={{ root: { padding: 20 } }}>
        <Stack tokens={stackTokens} styles={{ root: { width: "100%" } }}>
          <Text variant="medium">
            <small>noch nicht in Rechnung gestellt</small>

            <Label>voraussichtliches Guthaben</Label>

            <b style={{ color: theme.palette.orangeLighter }}>
              CHF {credit.data?.future_credit}
            </b>
          </Text>
        </Stack>

        <Stack tokens={stackTokens} styles={{ root: { width: "100%" } }}>
          <Text variant="medium">
            <small>in Rechnung gestellt</small>
            <Label>auszahlbares Guthaben</Label>
            <b style={{ color: theme.palette.green }}>
              CHF {credit.data?.current_credit}
            </b>
          </Text>
        </Stack>
      </Stack>

      <PullUp by={100} />

      <ListReceipts query={receipts} />
    </Stack>
  );
};
