import { DetailsList, SelectionMode, Stack, useTheme } from "@fluentui/react";
import { useEffect, useState } from "react";
import { useStaff } from "../assets/queries";
import { addWindow } from "../assets/windows";
import {
  PullUp,
  QueryProgressIndicator,
  SearchBoxList,
} from "../components/misc";

const ListStaff: React.FC = () => {
  const staff = useStaff();
  const [list, setList] = useState<any>({});
  const clear = () => setList({ ...list, items: staff.data });
  const setItems = (items: Array<any>) => setList({ ...list, items: items });

  useEffect(() => {
    if (staff.isSuccess) setItems(staff.data);
    // eslint-disable-next-line
  }, [staff.isSuccess, staff.data]);

  const theme = useTheme()

  return (
    <Stack>
      <QueryProgressIndicator  query={staff} />

      {staff.isSuccess && staff.data?.length > 0 && (
        <Stack tokens={{ childrenGap: 10 }} styles={{ root: { background: theme.palette.white, padding: 20, borderRadius: "0px 0px 16px 16px" } }}>
          <DetailsList
            columns={[
              {
                fieldName: "firstName",
                key: "firstName",
                minWidth: 0,
                name: "Vorname",
              },
              {
                fieldName: "lastName",
                key: "lastName",
                minWidth: 0,
                name: "Nachname",
              },
              {
                fieldName: "position",
                key: "position",
                minWidth: 0,
                name: "Funktion",
              },
              {
                key: "jobfair",
                minWidth: 0,
                name: "Jobbörse",
                onRender: (item) => {
                  if (!item?.jobfair) return "k.A.";
                  if (item?.jobfair) return item?.jobfair;
                  return "k.A.";
                },
              },
            ]}
            isHeaderVisible={true}
            items={staff.data}
            onItemInvoked={(item) => addWindow.Account.byId(item?.id)}
            selectionMode={SelectionMode.none}
            styles={{ root: { cursor: "pointer", staffelect: "none" } }}
          />
        </Stack>
      )}
    </Stack>
  );
};

export default ListStaff;
