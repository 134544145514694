import {
  DefaultButton,
  Label,
  Link,
  MessageBar,
  MessageBarType,
  Pivot,
  PivotItem,
  Stack,
  Text,
  Toggle,
  useTheme,
} from "@fluentui/react";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import axiosJWT from "../assets/axiosjwt";
import { returnReadableDuration } from "../assets/functions";
import { Icons } from "../assets/icons";
import { useDeleteWorkLog, useUpdateWorkLog } from "../assets/mutations";
import { useJobById, useWorkLog } from "../assets/queries";
import { useClientStore } from "../assets/stores";
import { addWindow, closeWindow } from "../assets/windows";
import {
  InternalMeta,
  MutationProgressIndicator,
  PushDown,
  QueryProgressIndicator,
} from "../components/misc";
import PivotSubMenu, { ViewContent } from "./Parts";
const { getState, setState } = useClientStore;

const ViewWorkLog: React.FC<{ workLogId: string; windowId: string }> = ({
  workLogId,
  windowId,
}) => {
  const [confirmedBy, setConfirmedBy] = useState({
    jobfair: false,
    employer: false,
    employee: false,
  });
  const query = useWorkLog(workLogId);
  const updateWorkLog = useUpdateWorkLog(workLogId);
  const deleteWorkLog = useDeleteWorkLog(workLogId);
  const theme = useTheme();
  const job = useJobById(query.data?.job.id);

  useEffect(() => {
    let newWindows = getState().openWindows.filter((window: any) => {
      if (window.window.id === "viewWorkLog" + workLogId) {
        window.window.title = query.data?.publicKey;
      }
      return true;
    });

    setState({ openWindows: newWindows });
  }, [query.isSuccess]);

  // styles
  const stackStyles: any = { root: { width: "100%" } };

  useEffect(() => {
    setConfirmedBy({
      jobfair: query.data?.confirmedByJobfair,
      employer: query.data?.confirmedByEmployer,
      employee: query.data?.confirmedByEmployee,
    });
  }, [query.isSuccess, query.isRefetching]);

  useEffect(() => {
    if (deleteWorkLog.isSuccess) closeWindow(windowId);
  }, [deleteWorkLog]);

  let actions: any = [
    {
      disabled:
        query.data?.invoiceId ||
        query.data?.confirmedByEmployer ||
        query.data?.confirmedByEmployee ||
        job.data?.isArchived,
      key: "edit",
      iconProps: {
        iconName:
          query.data?.confirmedByEmployer || query.data?.confirmedByEmployee
            ? Icons.Cancel
            : Icons.Edit,
      },
      onClick: async () => {
        if (!query.data?.invoiceId) {
          addWindow.WorkLog.update(workLogId);
        }
      },
      text:
        query.data?.invoiceId ||
          query.data?.confirmedByEmployer ||
          query.data?.confirmedByEmployee
          ? "Bereits bestätigt"
          : "Bearbeiten",
    },
    {
      key: "delete",
      disabled: job.data?.isArchived || query.data?.invoiceId,
      iconProps: { iconName: Icons.Delete },
      onClick: async () => {
        Swal.fire({
          buttonsStyling: false,
          confirmButtonText: "Löschen",
          cancelButtonText: "Abbrechen",
          focusCancel: true,
          showCancelButton: true,
          title: "Achtung",
          text: "Dieser Arbeitseinsatz ist eventuell bereits bestätigt worden: willst du ihn wirklich unwiderruflich löschen?",
          reverseButtons: true,
        }).then(async (event: any) => {
          if (event.isConfirmed) {
            try {
              await deleteWorkLog.mutateAsync();
              closeWindow(windowId);
            } catch (error) {
              Swal.fire({
                icon: "error",
                title: "Fehler",
                text: "Konnte Arbeitseinsatz nicht löschen",
              });
            }
          }
        });
      },
      text: "Löschen",
    },
  ];

  return (
    <Stack>
      <ViewContent>
        <QueryProgressIndicator query={query} />

        <MutationProgressIndicator mutationQuery={deleteWorkLog} />

        <Pivot
          styles={{
            root: {
              background: theme.palette.white,
              zIndex: 10,
              left: 0,
              borderRadius: "16px 16px 0px 0px",
              width: "100%",
            },
          }}
        >
          <PivotItem headerText="Details" itemIcon={Icons.File}>
            <PivotSubMenu>
              <Stack grow></Stack>
              <Stack slot="end">
                <DefaultButton
                  styles={{ root: { borderRadius: 16 } }}
                  primary
                  menuProps={{ items: actions }}
                >
                  Aktionen
                </DefaultButton>
              </Stack>
            </PivotSubMenu>

            <Stack
              styles={{
                root: {
                  background: theme.palette.white,
                  padding: 20,
                  borderRadius: "0px 0px 16px 16px",
                },
              }}
            >
              {query.data?.invoiceId && (
                <MessageBar messageBarType={MessageBarType.success}>
                  in Rechnung gestellt
                </MessageBar>
              )}

              {!query.data?.invoiceId && (
                <MessageBar messageBarType={MessageBarType.warning}>
                  noch nicht in Rechnung gestellt
                </MessageBar>
              )}

              {query.data?.receipt && (
                <MessageBar messageBarType={MessageBarType.success}>
                  quittiert
                </MessageBar>
              )}

              {!query.data?.receipt && (
                <MessageBar messageBarType={MessageBarType.warning}>
                  noch nicht quittiert
                </MessageBar>
              )}

              <PushDown by={20} />

              <Stack horizontal>
                <Stack styles={stackStyles}>
                  <Text variant="medium">
                    <Label>Rechnung</Label>
                    <Link
                      onClick={() => {
                        addWindow.Invoice.view(query.data?.invoiceId);
                      }}
                    >
                      {query.data?.invoice_publicKey}
                    </Link>
                  </Text>
                </Stack>
              </Stack>

              <Stack horizontal>
                <Stack styles={stackStyles}>
                  <Text variant="medium">
                    <Label>Quittung</Label>
                    <Link
                      onClick={async () => {
                        let res = await axiosJWT.get(
                          process.env.REACT_APP_SERVER +
                          "/api/a/get-receipt-by-id/" +
                          query.data?.receipt.id
                        );

                        const link: any = res.data;

                        window.open(link, "blank");
                      }}
                    >
                      {query.data?.receipt?.publicKey}
                    </Link>
                  </Text>
                </Stack>
              </Stack>

              <PushDown by={20} />

              <Stack horizontal>
                <Stack styles={stackStyles}>
                  <Text variant="medium">
                    <Label>Arbeitseinsatz vom</Label>
                    {query.data?.date &&
                      format(new Date(query.data?.date), "dd.MM.yyyy")}
                  </Text>
                </Stack>

                <Stack styles={stackStyles}>
                  <Text variant="medium">
                    <Label>Dauer</Label>
                    {returnReadableDuration(query.data?.minutes)}
                  </Text>
                </Stack>
              </Stack>

              <PushDown by={20} />

              <Stack horizontal>
                <Stack styles={stackStyles}>
                  <Text variant="medium">
                    <Label>Job</Label>
                    <Link
                      onClick={() => addWindow.Job.view(query.data?.job?.id)}
                    >
                      {query?.data?.job?.title}
                    </Link>
                  </Text>
                </Stack>

                <Stack styles={stackStyles}>
                  <Text variant="medium">
                    <Label>Jugendliche*r</Label>
                    <Link
                      onClick={() =>
                        addWindow.Profile.view(query.data?.employee?.id)
                      }
                    >
                      {query?.data?.employee?.firstName}{" "}
                      {query?.data?.employee?.lastName}
                    </Link>
                  </Text>
                </Stack>
              </Stack>

              <PushDown by={20} />

              <Stack horizontal>
                <Stack styles={stackStyles}>
                  <Text variant="medium">
                    <Label>Bestätigungen</Label>
                  </Text>

                  <Stack style={{ paddingRight: 50 }}>
                    <Text
                      style={{
                        display: "block",
                        fontSize: 10,
                        textAlign: "justify",
                      }}
                    >
                      Eine Bestätigung durch die Job-Börse reicht aus, um einen
                      Arbeitseinsatz in Rechnung stellen zu können.
                    </Text>
                  </Stack>
                </Stack>

                <Stack styles={stackStyles}>
                  <Toggle
                    checked={confirmedBy.jobfair}
                    disabled={query.data?.invoiceId || job.data?.isArchived}
                    label="Jugend-Job-Börse"
                    onClick={async () =>
                      await updateWorkLog.mutateAsync({
                        confirmedByJobfair: !confirmedBy.jobfair,
                      })
                    }
                  />

                  <Toggle
                    checked={confirmedBy.employer}
                    disabled
                    label="Auftraggeber*in"
                  />

                  <Toggle
                    checked={confirmedBy.employee}
                    disabled
                    label="Jugendliche*r"
                  />
                </Stack>
              </Stack>
            </Stack>
          </PivotItem>

          <PivotItem itemIcon={Icons.Warning}>
            <InternalMeta query={query} />
          </PivotItem>
        </Pivot>
      </ViewContent>
    </Stack>
  );
};

export default ViewWorkLog;
