import { DefaultButton, Stack, Text } from "@fluentui/react";
import { useRef, useState } from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router";
import Swal from "sweetalert2";
import axiosJWT from "../assets/axiosjwt";
import { myTokens } from "../assets/styles";

const QrUploaderMobile: React.FC = () => {
  const [done, setDone] = useState(false);
  const { profileId }: any = useParams();
  const queryClient = useQueryClient();
  const uploader = useRef<any>();

  // styles
  const container = { root: { height: "100vh", textAlign: "center" } };
  const content = { root: { margin: "auto" } };

  return (
    <Stack styles={container}>
      <Stack styles={content} tokens={myTokens}>
        {!done && (
          <DefaultButton onClick={() => uploader.current.click()}>Profilbild hochladen</DefaultButton>
        )}

        {!done && (
          <input
            id="file"
            onChange={async (e: any) => {
              try {
                let form = new FormData();
                form.append("files", e.target.files[0]);
                form.append("ref", "api::profile.profile");
                form.append("refId", profileId);
                form.append("field", "profileImage");

                await axiosJWT.post(
                  process.env.REACT_APP_SERVER + "/api/upload",
                  form
                );

                await queryClient.refetchQueries();

                setDone(true);
              } catch (error) {
                console.log(error);
                Swal.fire({
                  buttonsStyling: false,
                  title: "Fehler",
                  text: "Wende dich an den Support.",
                });
              }
            }}
            ref={uploader}
            style={{ display: "none" }}
            type="file"
          />
        )}

        {done && (
          <Text variant="medium">
            Du kannst dieses Fenster jetzt schliessen.
          </Text>
        )}
      </Stack>
    </Stack>
  );
};

export default QrUploaderMobile;
