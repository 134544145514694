import {
  DefaultButton,
  DirectionalHint,
  IconButton,
  IStackStyles,
  Spinner,
  SpinnerSize,
  Stack, Text, useTheme
} from "@fluentui/react";
import { Icons } from "../assets/icons";
import {
  myTokens,
  myViewFooterContainer,
  myViewFooterContent,
  myViewHeaderContainer
} from "../assets/styles";

export const ViewHeader: React.FC<{
  actions?: any;
  isLoading?: boolean;
  status?: any;
  title: string | undefined;
  queries?: any;
  windowId: string;
}> = ({
  actions,
  isLoading = false,
  status = undefined,
  title,
  queries,
  windowId,
}) => {
    // styles
    const theme = useTheme();

    return (
      <Stack
        horizontal
        styles={{
          root: {
            background: theme.palette.white,
            width: "100%",
            zIndex: 1000,
            ...myViewHeaderContainer,
          },
        }}
        tokens={myTokens}
      >
        {!isLoading && (
          <Text
            nowrap
            styles={{
              root: { color: theme.palette.themePrimary },
            }}
            variant="xLarge"
          >
            {title}
          </Text>
        )}

        <Stack>{status}</Stack>

        {/* check again */}
        {/* still neccessary? */}
        {actions?.length > 0 && (
          <IconButton
            styles={{ root: { borderRadius: 16, } }}
            iconProps={{ iconName: Icons.Actions }}
            menuProps={{
              directionalHint: DirectionalHint.bottomRightEdge,
              items: actions,
            }}
          />
        )}
      </Stack>
    );
  };

export const ViewContent: React.FC<{ padding?: boolean }> = ({
  children,
  padding = true,
}) => {
  // styles
  const theme = useTheme();
  const stackStyles: IStackStyles = {
    root: {
      padding: 10
    },
  };

  return (
    <Stack grow>
      <Stack styles={stackStyles} tokens={myTokens}>
        {children}
      </Stack>
    </Stack>
  );
};

export const ViewFooter: React.FC<{
  custom?: {
    icon: any;
    text: string;
    onCustom: (payload: any) => void;
  };
  onCancel?: () => void;
  onConfirm?: () => void;
  onCreate?: (payload: any) => void;
  mutationQuery?: any;
}> = ({ custom, onCancel, onConfirm, onCreate, mutationQuery }) => {
  // styles
  const theme = useTheme();

  return (
    <Stack
      styles={{
        root: { background: theme.palette.white, ...myViewFooterContainer },
      }}
    >
      <Stack
        horizontal
        styles={{ root: { ...myViewFooterContent } }}
        tokens={myTokens}
      >
        <Stack grow />

        {onCancel && <DefaultButton onClick={onCancel} text="Abbrechen" />}

        {onConfirm && (
          <DefaultButton
            styles={{ root: { borderRadius: 16 } }}
            iconProps={{ iconName: Icons.Check }}
            onClick={onConfirm}
            primary
            text="OK"
          />
        )}

        {onCreate && (
          <DefaultButton
            styles={{ root: { borderRadius: 16 } }}
            disabled={mutationQuery && mutationQuery.isLoading}
            iconProps={{
              iconName: !mutationQuery.isLoading ? Icons.Create : "",
            }}
            onClick={onCreate}
            primary
          >
            {mutationQuery ? (
              mutationQuery.isLoading ? (
                <Spinner size={SpinnerSize.medium} />
              ) : (
                "Erstellen"
              )
            ) : (
              "Erstellen"
            )}
          </DefaultButton>
        )}

        {custom?.onCustom && (
          <DefaultButton
            styles={{ root: { borderRadius: 16 } }}
            iconProps={{ iconName: custom.icon }}
            onClick={custom.onCustom}
            primary
            text={custom.text}
          />
        )}
      </Stack>
    </Stack>
  );
};

const PivotSubMenu: React.FC = ({ children }) => {
  // styles
  const theme = useTheme();
  const stackStyles: IStackStyles = {
    root: {
      background: theme.palette.white,
      left: 0,
      padding: 7,
      paddingLeft: 20,
      top: 90,
      width: "100%",
      zIndex: 10,
    },
  };
  const stackTokens: any = { childrenGap: 10 };

  return (
    <Stack horizontal styles={stackStyles} tokens={stackTokens}>
      {children}
    </Stack>
  );
};

export default PivotSubMenu;
