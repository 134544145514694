import {
  DefaultButton,
  Dropdown,
  Label,
  Modal,
  Position,
  SpinButton,
  Stack, TextField,
  Toggle
} from "@fluentui/react";
import { set } from "date-fns";
import { useEffect, useState } from "react";
import {
  useCategories,
  useFrequencies,
  useJobfairs
} from "../../assets/queries";
import { myColumnRight, myStack, myTokens } from "../../assets/styles";
import AddCategories from "../../views/AddCategories";
import ViewCategories from "../../views/ViewCategories";
import { PushDown } from "../misc";
import { CreateTitle } from "./Fields";
import { FieldDateCreate, FormColumnLeft } from "./Parts";

const Add: React.FC<{
  employer: any;
  form: any;
  formErrors: any;
  onChange: (updatedFields: any) => void;
  type: string;
}> = ({ employer, form, formErrors, onChange, type }) => {

  switch (type) {
    case "job":
      return (
        <Job
          employer={employer}
          form={form}
          formErrors={formErrors}
          onChange={onChange}
        />
      );

    default:
      return <Stack></Stack>;
  }
};

export default Add;

const Job: React.FC<{
  employer: any;
  form: any;
  formErrors: any;
  onChange: (updatedFields: any) => void;
}> = ({ employer, form, formErrors, onChange }) => {
  const categories = useCategories();
  const frequencies = useFrequencies();
  const [selectedCategories, setSelectedCategories] = useState<any>([]);
  const [selectedJobfairs, setSelectedJobfairs] = useState<any>([]);
  const jobfairs = useJobfairs();
  const [provideEstimatedInfos, setProvideEstimatedInfos] = useState(false);

  useEffect(() => {
    localStorage.doNotReload = true
    localStorage.preferredCategories = JSON.stringify([])
  }, [])

  const [showCategory, setShowCategory] = useState(false)

  useEffect(() => {
    let ids: any = [];

    selectedCategories.forEach((selected: any) => {
      categories.data?.forEach((category: any) => {
        if (category.key === selected) {
          ids.push(category.id);
        }
      });
    });

    onChange({ categories: ids });
    // eslint-disable-next-line
  }, [selectedCategories]);

  useEffect(() => {
    let ids: any = [];

    selectedJobfairs.forEach((selected: any) => {
      jobfairs.data?.forEach((category: any) => {
        if (category.key === selected) {
          ids.push(category.id);
        }
      });
    });

    onChange({ jobfairs: ids });
    // eslint-disable-next-line
  }, [selectedJobfairs]);


  return (
    <Stack tokens={{ childrenGap: 20 }}>
      <Modal isOpen={showCategory} onDismiss={() => setShowCategory(false)} styles={{ main: { borderRadius: 16, height: "45vh", width: "50vw", padding: 20 } }}>
        <AddCategories windowId={"addCategories" + Math.floor(Math.random())} />
      </Modal>

      <Stack horizontal tokens={myTokens}>
        <FormColumnLeft label="Auftraggeber*in" />

        <Stack styles={myColumnRight}>
          <Label>
            {employer.data?.description ||
              employer.data?.firstName + " " + employer.data?.lastName}
          </Label>
        </Stack>
      </Stack>

      <Stack horizontal tokens={myTokens}>
        <FormColumnLeft label="Details" />

        <Stack styles={myColumnRight}>
          <CreateTitle formErrors={formErrors} onChange={onChange} />
        </Stack>
      </Stack>

      <Stack horizontal tokens={myTokens}>
        <Stack style={{ width: "30%" }}>
          <Label required>Job-Börse(n)</Label>
        </Stack>

        <Stack grow horizontal>
          <Dropdown
            errorMessage={formErrors?.jobfairs}
            multiSelect
            onChange={(event, item: any) => {
              if (item) {
                setSelectedJobfairs(
                  item?.selected
                    ? [...selectedJobfairs, item?.key]
                    : selectedJobfairs.filter((key: any) => key !== item?.key)
                );
              }
            }}
            options={jobfairs?.data}
            placeholder="Bitte auswählen"
            selectedKeys={selectedJobfairs}
            styles={{
              dropdown: {
                border: "3px solid lightgrey",
              },
              title: {
                borderWidth: "0px",
              },
              root: { width: "100%" },
            }}
          />
        </Stack>
      </Stack>

      <Stack horizontal tokens={myTokens}>
        <FormColumnLeft />

        <Stack styles={myColumnRight}>
          <Label required>Mindestalter</Label>

          <PushDown by={10} />

          <Stack horizontal tokens={{ childrenGap: 1 }}>
            {[13, 14, 15, 16, 17, 18].map((age: any) => {
              return (
                <DefaultButton
                  key={age}
                  primary={form?.minAge === age}
                  onClick={() => onChange({ minAge: age })}
                  styles={{
                    root: { border: "3px solid lightgrey", padding: 10 },
                  }}
                  text={age}
                />
              );
            })}
          </Stack>
        </Stack>
      </Stack>

      <Stack horizontal tokens={myTokens}>
        <Stack style={{ width: "30%" }}>
          <Label>Einsatzzeiten anzeigen</Label>
          <Toggle
            checked={provideEstimatedInfos}
            onClick={() => {
              setProvideEstimatedInfos(!provideEstimatedInfos);
              onChange({ provideEstimatedInfos: !provideEstimatedInfos });
            }}
          />
        </Stack>

        <Stack grow horizontal styles={{ root: { width: "33%" } }}>
          <SpinButton
            disabled={!provideEstimatedInfos}
            label="Einsätze"
            labelPosition={Position.top}
            min={1}
            onChange={(event, value: any) =>
              onChange({ amountOfAssignments: value })
            }
            styles={{
              spinButtonWrapper: {
                border: "3px solid lightgrey",
                ":after": {
                  borderWidth: "0px",
                },
              },
            }}
          />
        </Stack>

        <Stack grow horizontal styles={{ root: { width: "33%" } }}>
          <SpinButton
            disabled={!provideEstimatedInfos}
            label="à Stunden"
            labelPosition={Position.top}
            max={8}
            min={1}
            onChange={(event, value: any) =>
              onChange({ amountOfHoursPerAssignment: value })
            }
            styles={{
              spinButtonWrapper: {
                border: "3px solid lightgrey",
                ":after": {
                  borderWidth: "0px",
                },
              },
            }}
          />
        </Stack>
      </Stack>

      <Stack horizontal tokens={myTokens}>
        <Stack style={{ width: "30%" }}></Stack>
        <Stack grow horizontal tokens={{ childrenGap: 10 }}>
          <FieldDateCreate
            disabled={!provideEstimatedInfos}
            field="startDate"
            form={form}
            label="erster Einsatz"
            onFieldChange={(value: any) =>
              onChange({
                startDate: set(new Date(value!), {
                  date: new Date(value!).getDate() + 1,
                }),
              })
            }
            validationErrors={formErrors?.startDate}
          />

          <FieldDateCreate
            disabled={!provideEstimatedInfos}
            field="endDate"
            form={form}
            label="letzter Einsatz"
            onFieldChange={(value: any) =>
              onChange({
                endDate: set(new Date(value!), {
                  date: new Date(value!).getDate() + 1,
                }),
              })
            }
            validationErrors={formErrors?.startDate}
          />
        </Stack>
      </Stack>

      <Stack horizontal tokens={myTokens}>
        <Stack style={{ width: "30%" }}>
          <Label required>Regelmässigkeit</Label>
        </Stack>

        <Stack grow horizontal>
          <Dropdown
            errorMessage={formErrors?.frequencies}
            onChange={(event, item: any) => onChange({ frequency: item?.id })}
            options={frequencies?.data}
            placeholder="Bitte auswählen"
            required
            styles={{
              dropdown: {
                border: "3px solid lightgrey",
              },
              title: {
                borderWidth: "0px",
              },
              root: {
                width: "100%"
              },
            }}
          />
        </Stack>
      </Stack>

      <Stack horizontal tokens={myTokens}>
        <Stack style={{ width: "30%" }}>
          <Label required>Rubrik(en)</Label>
        </Stack>

        <Stack>
          <Stack horizontal horizontalAlign="start" styles={myStack}>
            <DefaultButton onClick={() => {
              setShowCategory(true)
            }}>Anpassen</DefaultButton>
          </Stack>
          <small>Klick auf Anpassen, um die gewählten Kategorien zu sehen.</small>
        </Stack>
      </Stack>

      <Stack horizontal tokens={myTokens}>
        <Stack style={{ width: "30%" }}>
          <Label required>Ausschreibung</Label>
        </Stack>

        <Stack grow tokens={myTokens}>
          <TextField
            borderless
            errorMessage={formErrors?.description}
            multiline
            rows={10}
            onChange={(event, newValue) => onChange({ description: newValue! })}
            styles={{
              fieldGroup: {
                border: "3px solid lightgrey",
              },
              field: {},
              root: {
                width: "100%",
              },
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
