import { Link, Stack, Text, useTheme } from "@fluentui/react";
import { useEffect, useState } from "react";
import { returnErrorsToReset, returnServerError } from "../assets/functions";
import { useNoteCreate } from "../assets/mutations";
import { useProfile } from "../assets/queries";
import { addWindow, closeWindow } from "../assets/windows";
import Create from "../components/forms/Create";
import { MutationProgressIndicator } from "../components/misc";
import { ViewContent, ViewFooter } from "./Parts";

const ViewCreateNote: React.FC<{
  profileId: string;
  modelId: string;
  modelType: string;
  windowId: string;
  independent: boolean;
}> = ({ profileId, modelId, modelType, windowId, independent = false }) => {
  const [form, setForm] = useState<any>({});
  const [formErrors, setFormErrors] = useState<any>({
    error: undefined,
    formErrors: undefined,
    isKnownError: false,
    isMultiline: false,
    message: "Unbekannter Fehler",
    secondaryMessage: undefined,
    type: "severeWarning",
  });
  const createNote = useNoteCreate(profileId, modelId, modelType);
  const profile = useProfile(modelId);
  const theme = useTheme()

  const change = (updatedFields: any, oldForm: any = form) => {
    setForm({ ...oldForm!, ...updatedFields });
    setFormErrors({
      ...formErrors,
      formErrors: {
        ...formErrors?.formErrors,
        ...returnErrorsToReset(updatedFields),
      },
    });
  };

  useEffect(() => {
    if (createNote.isSuccess) {
      closeWindow(windowId);
    }
    if (createNote.isError) setFormErrors(returnServerError(createNote.error));
  }, [createNote.error, createNote.isError, createNote.isSuccess, windowId]);

  return (
    <Stack styles={{ root: { padding: 20, borderRadius: 16 } }}>
      <Stack styles={{ root: { background: theme.palette.white, padding: 20, borderRadius: 16 } }}>
        <ViewContent>
          <MutationProgressIndicator mutationQuery={createNote} />

          {profile.isSuccess && profile.data?.type === "publicEmployer" && (
            <Text>
              Notiz für{" "}
              <Link onClick={() => addWindow.Profile.view(profile.data?.id)}>
                {profile.data?.description}
              </Link>
            </Text>
          )}

          {profile.isSuccess && profile.data?.type !== "publicEmployer" && (
            <Text>
              Notiz für{" "}
              <Link onClick={() => addWindow.Profile.view(profile.data?.id)}>
                {profile.data?.firstName + " " + profile.data?.lastName}
              </Link>
            </Text>
          )}

          <Create
            form={form}
            formErrors={formErrors?.formErrors}
            onChange={(updatedFields: any) => change(updatedFields, form)}
            type="note"
            independent={independent}
          />
        </ViewContent>

        <ViewFooter
          onCreate={() => createNote.mutate(form)}
          mutationQuery={createNote}
        />
      </Stack>
    </Stack>
  );
};

export default ViewCreateNote;
