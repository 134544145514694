import {
  DefaultButton,
  DetailsList,
  DetailsListLayoutMode,
  DetailsRow,
  DocumentCardActions,
  IconButton,
  IDetailsListProps,
  IDetailsRowStyles,
  SelectionMode,
  Stack,
  Text,
  useTheme
} from "@fluentui/react";
import { useState } from "react";
import { TwitterPicker } from 'react-color';
import create from 'zustand';
import { Icons } from "../assets/icons";
import { useUpdateMyUser } from "../assets/mutations";
import { useClientStore } from "../assets/stores";
import { PushDown, QueryProgressIndicator } from "../components/misc";

export const Tile: React.FC<{
  actions?: any;
  columns?: any;
  content?: any;
  hasList?: boolean;
  isMockUp?: boolean;
  location?: string;
  onItemInvoked?: (selectedItem: any) => void;
  title: any;
  query?: any;
  whoAmI?: any;
}> = ({
  actions = [],
  columns,
  content,
  hasList = true,
  isMockUp = false,
  location = hasList ? "Zuletzt erstellt" : "",
  onItemInvoked = () => { },
  title,
  query,
  whoAmI,
}) => {
    const updateMyUser = useUpdateMyUser();
    const [chosenColor, setChosenColor] = useState("")
    const [showPicker, setShowPicker] = useState(false)
    const useStore = create(useClientStore)
    const editMode = useStore((store) => store.editMode)

    // styles
    const theme = useTheme();

    const _onRenderRow: IDetailsListProps['onRenderRow'] = props => {
      const customStyles: Partial<IDetailsRowStyles> = {};
      if (props) {
        if (props.itemIndex % 2 === 0) {
          customStyles.root = { backgroundColor: theme.palette.whiteTranslucent40 };
        } else {
          customStyles.root = { backgroundColor: theme.palette.whiteTranslucent40 };
        }
        customStyles.root = { backgroundColor: "transparent" };

        return <DetailsRow {...props} styles={customStyles} />;
      }
      return null;
    };

    return (
      <Stack
        grow
        styles={{
          root: {
            background: theme.palette.white,
            borderRadius: 16,
            // border: "1px dotted " + (whoAmI?.self?.background ? whoAmI?.self?.background : "black"),
            boxShadow: "0px 4px 3px rgba(0, 0, 0, 0.15)",
            margin: 0,
            maxWidth: 100 / whoAmI?.self.size + "vw"
          },
        }}
      >
        <Stack
          horizontal
          styles={{
            root: {
              background: "rgba(" + whoAmI?.self?.background + ")",
              borderRadius: "16px 16px 0px 0px",
              padding: 10,
            },
          }}
        >
          {title !== "zuletzt aufgerufen" && <Stack grow>
            {/* <Text style={{ color: whoAmI?.self?.background }} variant="large">{location}</Text> */}
            <Text style={{ color: whoAmI?.self?.background }} variant="xLargePlus">{title}</Text>
          </Stack>}

          {showPicker && (
            <Stack styles={{ root: { position: "absolute", zIndex: 100000 } }}>
              <TwitterPicker color={chosenColor} onChangeComplete={(color: any) => {
                setChosenColor(color.rgba)
                setShowPicker(false)
                let newLayout = whoAmI.layout;
                newLayout.rows[whoAmI.row].columns[whoAmI.column] = {
                  tile: newLayout.rows[whoAmI.row].columns[whoAmI.column].tile,
                  size: newLayout.rows[whoAmI.row].columns[whoAmI.column].size,
                  background: color.hex
                };
                if (showPicker) updateMyUser.mutate({ layout: newLayout });
              }} />
            </Stack>
          )}

          {title !== "zuletzt aufgerufen" && editMode && <IconButton styles={{ root: { borderRadius: 16, } }} iconProps={{
            iconName: "Color", style: { color: whoAmI?.self?.background },
          }}
            onClick={() => {
              if (isMockUp) return;
              setShowPicker(!showPicker)
            }}
          />}

          {title !== "zuletzt aufgerufen" && editMode && <IconButton
            styles={{ root: { borderRadius: 16, } }}
            iconProps={{
              iconName: Icons.Cancel,
              style: { color: whoAmI?.self?.background },
            }}
            onClick={async () => {
              if (isMockUp) return;
              let newLayout = whoAmI.layout;
              newLayout.rows[whoAmI.row].columns[whoAmI.column] = {
                tile: "empty",
                size: newLayout.rows[whoAmI.row].columns[whoAmI.column].size,
              };
              newLayout.rows[whoAmI.row].columns = newLayout.rows[
                whoAmI.row
              ].columns.filter((column: any) => {
                return column !== null;
              });
              if (newLayout.rows[whoAmI.row].columns.length < 1) {
                newLayout.rows[whoAmI.row].columns = newLayout.rows[
                  whoAmI.row
                ].columns = [
                    {
                      tile: "empty",
                      size: 3,
                    },
                  ];
              }
              updateMyUser.mutate({ layout: newLayout });
            }}
          />}
        </Stack>

        {title === "zuletzt aufgerufen" && (
          <Stack styles={{ root: { padding: 60 } }}>
            <Text style={{ color: "red", textAlign: "center" }}>NICHT MEHR VERFÜGBAR</Text>

            <PushDown by={20} />

            <DefaultButton style={{ borderRadius: 16 }} onClick={() => {
              if (isMockUp) return;
              let newLayout = whoAmI.layout;
              newLayout.rows[whoAmI.row].columns[whoAmI.column] = {
                tile: "empty",
                size: newLayout.rows[whoAmI.row].columns[whoAmI.column].size,
              };
              newLayout.rows[whoAmI.row].columns = newLayout.rows[
                whoAmI.row
              ].columns.filter((column: any) => {
                return column !== null;
              });
              if (newLayout.rows[whoAmI.row].columns.length < 1) {
                newLayout.rows[whoAmI.row].columns = newLayout.rows[
                  whoAmI.row
                ].columns = [
                    {
                      tile: "empty",
                      size: 3,
                    },
                  ];
              }
              updateMyUser.mutate({ layout: newLayout });
            }}>
              Kachel entfernen
            </DefaultButton>
          </Stack>
        )}

        {title !== "zuletzt aufgerufen" && hasList && (
          <Stack grow>
            {!isMockUp && <QueryProgressIndicator query={query} />}
            {!isMockUp &&
              !query.isFetching &&
              query.isSuccess &&
              query.data?.length > 0 && (
                <DetailsList
                  layoutMode={DetailsListLayoutMode.fixedColumns}
                  styles={{
                    contentWrapper: {
                      background: theme.palette.white,
                    },
                    headerWrapper: {
                      background: theme.palette.white,
                    },
                    root: {
                      background: theme.palette.white,
                      cursor: "pointer",
                      userSelect: "none",
                      maxHeight: 300,
                    },
                  }}
                  onRenderRow={_onRenderRow}
                  columns={columns}
                  isHeaderVisible={false}
                  items={query.data}
                  onItemInvoked={onItemInvoked}
                  selectionMode={SelectionMode.none}
                />
              )}
          </Stack>
        )}

        {title !== "zuletzt aufgerufen" && !hasList && <Stack grow>{content()}</Stack>}

        {title !== "zuletzt aufgerufen" && <Stack>
          <DocumentCardActions
            actions={[
              hasList && {
                iconProps: { iconName: Icons.Reload },
                onClick: () => !isMockUp && query.refetch(),
              },
              ...actions,
            ]}
          />
        </Stack>}
      </Stack>
    );
  };

export const Present: React.FC<{
  mockUpTile: any;
  explanation: any;
  onAdd: () => void;
  tile: string;
}> = ({ mockUpTile, explanation, onAdd, tile }) => {
  return (
    <Stack
      horizontal
      tokens={{ childrenGap: 10 }}
      styles={{ root: { padding: 20 } }}
    >
      <Stack
        styles={{
          root: {
            marginLeft: 10,
            boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.05)",
            width: "100%",
          },
        }}
      >
        {mockUpTile}
      </Stack>
      <Stack
        styles={{
          root: {
            padding: 10,
            alignItems: "flex-end",
            width: "100%",
          },
        }}
      >
        <Stack
          styles={{
            root: {
              width: "100%",
            },
          }}
        >
          {explanation()}
        </Stack>
        <Stack grow styles={{ root: { justifyContent: "flex-end" } }}>
          <DefaultButton
            styles={{ root: { borderRadius: 16 } }}
            onClick={async () => {
              onAdd();
            }}
            primary
            text="Hinzufügen"
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Present;
