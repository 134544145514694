import { Stack, Toggle } from "@fluentui/react";
import { set } from "date-fns";
import { useLanguages } from "../../assets/queries";
import {
  FieldAddressCreate,
  FieldDateCreate,
  FieldDropdownCreate,
  FieldStringCreate
} from "./Parts";

export const CreateDescription: React.FC<{
  formErrors: any;
  onChange: (payload: any) => void;
  placeholder?: string;
}> = ({ formErrors, onChange, placeholder }) => {
  return (
    <FieldStringCreate
      errorMessage={formErrors?.description}
      label="Bezeichnung"
      onChange={(newValue) => {
        onChange({ description: newValue });
      }}
      placeholder={placeholder}
      required
    />
  );
};

export const CreateWebsite: React.FC<{
  formErrors: any;
  onChange: (payload: any) => void;
  placeholder?: string;
}> = ({ formErrors, onChange, placeholder }) => {
  return (
    <FieldStringCreate
      errorMessage={formErrors?.website}
      label="Webseite"
      onChange={(newValue) => onChange({ website: newValue })}
      placeholder={placeholder}
      type="url"
    />
  );
};

export const CreateFirstName: React.FC<{
  formErrors: any;
  onChange: (payload: any) => void;
}> = ({ formErrors, onChange }) => {
  return (
    <FieldStringCreate
      errorMessage={formErrors?.firstName}
      label="Vorname"
      onChange={(newValue) => onChange({ firstName: newValue })}
      required
    />
  );
};

export const CreateLastName: React.FC<{
  formErrors: any;
  onChange: (payload: any) => void;
}> = ({ formErrors, onChange }) => {
  return (
    <FieldStringCreate
      errorMessage={formErrors?.lastName}
      label="Nachname"
      onChange={(newValue) => onChange({ lastName: newValue })}
      required
    />
  );
};

export const CreateAddress: React.FC<{
  formErrors: any;
  onChange: (payload: any) => void;
}> = ({ formErrors, onChange }) => {
  return (
    <FieldAddressCreate
      onFieldChange={(payload: any) => onChange({ address: payload })}
      required
      validationErrors={formErrors?.address}
    />
  );
};

export const CreateEmail: React.FC<{
  formErrors: any;
  onChange: (payload: any) => void;
}> = ({ formErrors, onChange }) => {
  return (
    <Stack>
      <Stack>
        <FieldStringCreate
          errorMessage={formErrors?.email}
          label="E-Mail"
          onChange={(newValue) => onChange({ email: newValue })}
        />
      </Stack>

      <Stack horizontalAlign="end">
        <Toggle
          inlineLabel
          label="nicht validieren"
          onClick={() => onChange({ ignoreEmail: true })}
        />
      </Stack>
    </Stack>
  );
};

export const CreateLandline: React.FC<{
  formErrors: any;
  onChange: (payload: any) => void;
}> = ({ formErrors, onChange }) => {
  return (
    <FieldStringCreate
      errorMessage={formErrors?.landline}
      label="Festnetznummer"
      onChange={(newValue) => onChange({ landline: newValue })}
      isMobilephone
    />
  );
};

export const CreateMobile: React.FC<{
  isPrimary?: boolean;
  formErrors: any;
  onChange: (payload: any) => void;
}> = ({ formErrors, isPrimary = false, onChange }) => {
  return (
    <FieldStringCreate
      errorMessage={formErrors?.mobilephone}
      label={isPrimary ? "Mobilnummer (App Zugang)" : "Mobilnummer"}
      onChange={(newValue) => onChange({ mobilephone: newValue })}
      isMobilephone
    />
  );
};

export const CreateBirthdate: React.FC<{
  form: any;
  formErrors: any;
  onChange: (payload: any) => void;
}> = ({ form, formErrors, onChange }) => {
  return (
    <FieldDateCreate
      field="birthdate"
      form={form}
      label="Geburtsdatum"
      onFieldChange={(value: any) =>
        onChange({
          birthdate: set(new Date(value!), {
            date: new Date(value!).getDate() + 1,
          }),
        })
      }
      validationErrors={formErrors?.birthdate}
    />
  );
};

export const CreateLanguages: React.FC<{
  form: any;
  formErrors: any;
  onChange: (payload: any) => void;
}> = ({ form, formErrors, onChange }) => {
  const languages = useLanguages();

  return (
    <FieldDropdownCreate
      defaultSelectedKey={form?.languages}
      errorMessage={formErrors?.languages}
      form={form}
      label="Sprache(n)"
      onFieldChange={(value: any) => onChange({ languages: value })}
      options={languages.data}
      placeholder={form ? undefined : "Auswählen"}
      selectedKey={form?.languages}
    />
  );
};

export const CreateGender: React.FC<{
  form: any;
  formErrors: any;
  onChange: (payload: any) => void;
}> = ({ form, formErrors, onChange }) => {
  return (
    <FieldDropdownCreate
      defaultSelectedKey={form?.gender}
      errorMessage={formErrors?.gender}
      form={form}
      label="Geschlecht"
      onFieldChange={(value: any) => onChange({ gender: value })}
      options={[
        {
          key: "undefined",
          text: "keine Angabe",
        },
        {
          key: "other",
          text: "anderes",
        },
        {
          key: "M",
          text: "männlich",
        },
        {
          key: "F",
          text: "weiblich",
        },
      ]}
      placeholder={form ? undefined : "Auswählen"}
      selectedKey={form?.gender}
    />
  );
};

export const CreateTitle: React.FC<{
  formErrors: any;
  onChange: (payload: any) => void;
  placeholder?: string;
}> = ({ formErrors, onChange, placeholder }) => {
  return (
    <FieldStringCreate
      errorMessage={formErrors?.description}
      label="Titel"
      onChange={(newValue) => onChange({ title: newValue })}
      placeholder={placeholder}
      required
    />
  );
};
