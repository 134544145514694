import { css } from "@emotion/css";
import {
  DefaultButton,
  DefaultEffects,
  DetailsList,
  IBasePicker,
  IBasePickerSuggestionsProps,
  Icon,
  ITag,
  MessageBar,
  MessageBarType,
  SelectionMode,
  Stack,
  TagPicker,
  useTheme
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { formatDistance } from "date-fns";
import { de } from "date-fns/locale";
import { useEffect, useRef, useState } from "react";
import create from "zustand";
import axiosJWT from "../assets/axiosjwt";
import { Icons } from "../assets/icons";
import { returnSearchResult } from "../assets/requests";
import { useClientStore } from "../assets/stores";
import { addWindow } from "../assets/windows";
import { PushDown } from "./misc";
const store = create(useClientStore)

const SearchServer: React.FC<{ searchString: string, isTile: boolean }> = ({ searchString, isTile = false }) => {
  const [searchResult, setSearchResult] = useState<any>({
    searchResults: [],
    meta: {},
  });
  const [currentItems, setCurrentItems] = useState<any>([]);
  const [searchState, setSearchState] = useState<
    "idle" | "searching" | "done"
  >();
  const searchField = useRef<any>()
  const [groups, setGroups] = useState([]);
  const searchIsActiveTab = store(state => state.searchIsActiveTab)

  const triggerSearch = async () => {
    if (searchString !== "") {
      let cItem = [{ key: "contains" + searchString, name: "enthält " + searchString }]
      setCurrentItems(cItem);
      let usedFilterTextStrings = "";
      cItem?.forEach((item) => {
        usedFilterTextStrings += item?.name;
      });
      setUsedFilterText(usedFilterTextStrings);
      setSearchState("searching");
      let sr = await returnSearchResult(cItem, false);
      setSearchResult(sr);
      setSearchState("done");
    }
  }

  useEffect(() => {
    triggerSearch()
  }, [])

  useEffect(() => {
    let g: any = [];

    if (!searchResult.meta) return;

    if (searchResult?.meta["public-employer"]?.["public-employer"].count > 0) {
      g.push({
        isCollapsed: searchString ? false : true,
        name: "Öffentliche Auftraggeber*innen",
        key: "publicEmployers",
        count:
          searchResult.meta?.["public-employer"]?.["public-employer"]?.count,
        startIndex:
          searchResult.meta?.["public-employer"]?.["public-employer"]
            .startIndex,
      });
    }
    if (
      searchResult?.meta["private-employer"]?.["private-employer"].count > 0
    ) {
      g.push({
        isCollapsed: searchString ? false : true,
        name: "Private Auftraggeber*innen",
        key: "privateEmployers",
        count:
          searchResult.meta?.["private-employer"]?.["private-employer"].count,
        startIndex:
          searchResult.meta?.["private-employer"]?.["private-employer"]
            .startIndex,
      });
    }
    if (searchResult?.meta.employee?.employee.count > 0) {
      g.push({
        isCollapsed: searchString ? false : true,
        name: "Jugendliche",
        key: "employees",
        count: searchResult.meta?.employee?.employee?.count,
        startIndex: searchResult.meta?.employee?.employee?.startIndex,
      });
    }
    if (searchResult?.meta.job?.job.count > 0) {
      g.push({
        isCollapsed: searchString ? false : true,
        name: "Jobs",
        key: "jobs",
        count: searchResult.meta?.job?.job?.count,
        startIndex: searchResult.meta?.job?.job?.startIndex,
      });
    }
    if (searchResult?.meta.workLog?.workLog.count > 0) {
      g.push({
        isCollapsed: searchString ? false : true,
        name: "Arbeitseinsätze",
        key: "workLogs",
        count: searchResult.meta?.workLog?.workLog?.count,
        startIndex: searchResult.meta?.workLog?.workLog?.startIndex,
      });
    }
    if (searchResult?.meta.invoice?.invoice.count > 0) {
      g.push({
        isCollapsed: searchString ? false : true,
        name: "Rechnungen",
        key: "invoices",
        count: searchResult.meta?.invoice?.invoice?.count,
        startIndex: searchResult.meta?.invoice?.invoice?.startIndex,
      });
    }
    if (searchResult?.meta.receipt?.receipt.count > 0) {
      g.push({
        isCollapsed: searchString ? false : true,
        name: "Quittungen",
        key: "receipts",
        count: searchResult.meta?.receipt?.receipt?.count,
        startIndex: searchResult.meta?.receipt?.receipt?.startIndex,
      });
    }
    if (searchResult?.meta.note?.note.count > 0) {
      g.push({
        isCollapsed: searchString ? false : true,
        name: "Notizen",
        key: "notes",
        count: searchResult.meta?.note?.note?.count,
        startIndex: searchResult.meta?.note?.note?.startIndex,
      });
    }

    setGroups(g);
    // eslint-disable-next-line
  }, [searchResult]);

  useEffect(() => {
    setTimeout(() => {
      if (searchField.current) searchField.current.focus()
    }, 100);

  }, [searchIsActiveTab])

  const searchTags = [
    {
      key: "isEmployer",
      name: "ist Auftraggeber*in",
    },
    {
      key: "isEmployee",
      name: "ist Jugendliche*r",
    },
    {
      key: "over13",
      name: "ist Jugendliche*r (über 13)",
    },
    {
      key: "over14",
      name: "ist Jugendliche*r (über 14)",
    },
    {
      key: "over15",
      name: "ist Jugendliche*r (über 15)",
    },
    {
      key: "over16",
      name: "ist Jugendliche*r (über 16)",
    },
    {
      key: "over17",
      name: "ist Jugendliche*r (über 17)",
    },
    {
      key: "under14",
      name: "ist Jugendliche*r (unter 14)",
    },
    {
      key: "under15",
      name: "ist Jugendliche*r (unter 15)",
    },
    {
      key: "under16",
      name: "ist Jugendliche*r (unter 16)",
    },
    {
      key: "under17",
      name: "ist Jugendliche*r (unter 17)",
    },
    {
      key: "under18",
      name: "ist Jugendliche*r (unter 18)",
    },
    {
      key: "over18",
      name: "ist Jugendliche*r (über 18)",
    },
    {
      key: "isJob",
      name: "ist Job",
    },
    {
      key: "isAssignedJob",
      name: "ist Job (vermittelt)",
    },
    {
      key: "isUnassignedJob",
      name: "ist Job (unvermittelt)",
    },
    {
      key: "isWorkLog",
      name: "ist Arbeitseinsatz",
    },
    {
      key: "isInvoice",
      name: "ist Rechnung",
    },
    {
      key: "isReceipt",
      name: "ist Quittung",
    },
    {
      key: "isNote",
      name: "ist Notiz",
    },
    {
      key: "isPaid",
      name: "ist bezahlt",
    },
    {
      key: "isUnpaid",
      name: "ist nicht bezahlt",
    },
    {
      key: "isDraft",
      name: "ist Entwurf",
    },
    {
      key: "entryCreatedToday",
      name: "heute erstellt",
    },
    {
      key: "createdInBern",
      name: "erfasst in Standort Bern",
    },
    {
      key: "createdInKoeniz",
      name: "erfasst in Standort Köniz",
    },
    {
      key: "livesInBern",
      name: "wohnt im Raum Bern",
    },
    {
      key: "livesInKoeniz",
      name: "wohnt im Raum Köniz",
    },
    {
      key: "hasAccessToApp",
      name: "App-Zugang aktiviert",
    },
    {
      key: "hasNoAccessToApp",
      name: "App-Zugang NICHT aktiviert",
    },
    // BETA
    // {
    //   input: true,
    //   key: "olderThan",
    //   name: "über . Jahre",
    //   position: 5,
    //   value: "",
    // },
  ];

  const [shadowSearchTags, setShadowSearchTags] = useState(searchTags);
  const [usedFilterText, setUsedFilterText] = useState("");

  const filterSelectedTags: any = (
    filterText: any,
    tagList: ITag[]
  ): ITag[] => {
    let results = [];

    if (filterText) {
      results = searchTags.filter(
        (tag: any) =>
          tag.name.toLowerCase().indexOf(filterText.toLowerCase()) !== -1
      );
    }

    return filterText
      ? results.length === 0
        ? usedFilterText.indexOf(filterText) === -1
          ? [
            {
              key: "contains" + filterText,
              name: "enthält " + filterText,
            },
          ]
          : []
        : searchTags?.filter((tag: any) => {
          for (let i = 0; i < tagList.length; i++) {
            const item = tagList[i];
            if (item.key === tag.key) return false;
          }

          let nst: any = [];
          searchTags.forEach((t: any) => {
            if (tag.input) {
              if (t.key === tag.key) {
                t.value = filterText;
              }
            }
            nst.push(t);
          });
          setShadowSearchTags(nst);

          if (tag.input) {
            let s = filterText;
            if (s.length > 4)
              s = s.substring(0, 5) + "." + s.substring(5 + 1);

            if (tag.name.toLowerCase().indexOf(s.toLowerCase()) !== -1) {
              return true;
            }
          }

          if (
            tag.name.toLowerCase().indexOf(filterText.toLowerCase()) !== -1
          ) {
            return true;
          }
        })
      : [];
  };

  const picker = useRef<IBasePicker<ITag>>(null);

  useEffect(() => {
    if (picker?.current) picker?.current!.focus()
  }, [picker.current])

  const listContainsTagList = (tag: ITag, tagList?: ITag[]) => {
    if (!tagList || !tagList.length || tagList.length === 0) {
      return false;
    }
    return tagList.some((compareTag) => compareTag.key === tag.key);
  };

  const onItemSelected: any = async (item: any) => {
    if (picker.current && listContainsTagList(item, picker.current.items)) {
      return null;
    }

    let newItem;

    for (let i = 0; i < shadowSearchTags.length; i++) {
      const t: any = shadowSearchTags[i];
      if (t.input) {
        if (t.key === item?.key) {
          newItem = t;
          newItem.name = t.value;
        }
      }
    }

    setUsedFilterText(usedFilterText + item?.name);

    if (newItem) return newItem;
    return item;
  };

  const getTextFromItem = (item: any) => item?.name;

  const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
    suggestionsHeaderText: "Filter-Tags",
    noResultsFoundText: "Keinen Filter-Tag verfügbar",
  };

  const [tagPicker, { toggle: toggleIsTagPickerVisible }] = useBoolean(false);
  const theme = useTheme()

  return (
    <Stack styles={{ root: { maxWidth: "100%", background: theme.palette.white, borderRadius: 16 } }}>
      {searchState === "searching" && (
        <MessageBar
          messageBarType={MessageBarType.warning}
        >
          Suche läuft ...
        </MessageBar>
      )}

      {searchState === "done" && searchResult?.searchResults?.length > 0 && (
        <MessageBar
          messageBarType={MessageBarType.success}
        >
          {searchResult?.searchResults?.length} Suchergebnisse
        </MessageBar>
      )}

      {searchState === "done" && searchResult?.searchResults?.length === 0 && (
        <MessageBar
          messageBarType={MessageBarType.info}
        >
          Keine Suchergebnisse
        </MessageBar>
      )}

      {!searchString && <PushDown by={40} />}

      {!searchString && <Stack styles={{ root: { margin: "5px 25px" } }}>
        <TagPicker
          ref={searchField}
          className={css`
            .ms-BasePicker-text {
              border: 0 !important;
            }
            border-bottom: 3px solid lightgrey !important;
          `}
          componentRef={picker}
          onResolveSuggestions={filterSelectedTags}
          onItemSelected={onItemSelected}
          getTextFromItem={getTextFromItem}
          pickerSuggestionsProps={pickerSuggestionsProps}
          disabled={tagPicker}
          onChange={async (items) => {
            setCurrentItems(items);
            let usedFilterTextStrings = "";
            items?.forEach((item) => {
              usedFilterTextStrings += item?.name;
            });
            setUsedFilterText(usedFilterTextStrings);
            setSearchState("searching");
            let sr = await returnSearchResult(items, false);
            setSearchResult(sr);
            setSearchState("done");
          }}
        />
      </Stack>
      }

      {!searchString && <Stack
        horizontal
        horizontalAlign="end"
        styles={{ root: { margin: "5px 25px" } }}
      >
        <DefaultButton
          disabled={currentItems.length === 0}
          onClick={async () => {
            await returnSearchResult(currentItems, true);
          }}
        >
          Resultat exportieren
        </DefaultButton>
      </Stack>}

      {searchResult.searchResults?.length > 0 && (
        <DetailsList
          columns={[
            {
              key: "icon",
              minWidth: 15,
              maxWidth: 15,
              name: "Typ",
              onRender: (item) => {
                switch (item?.type) {
                  case "public-employer":
                    return <Icon iconName={Icons.PublicEmployer} />;
                  case "private-employer":
                    return <Icon iconName={Icons.PrivateEmployer} />;
                  case "employee":
                    return <Icon iconName={Icons.Employee} />;
                  case "job":
                    return <Icon iconName={Icons.Job} />;
                  case "workLog":
                    return <Icon iconName={Icons.WorkLog} />;
                  case "invoice":
                    return <Icon iconName={Icons.Invoice} />;
                  case "receipt":
                    return <Icon iconName={Icons.Receipt} />;
                  case "note":
                    return <Icon iconName={Icons.Note} />;
                  case "contact":
                    return <Icon iconName={Icons.Contact} />;

                  default:
                    break;
                }
              },
            },
            {
              fieldName: "publicKey",
              iconName: "Permissions",
              isIconOnly: true,
              key: "publicKey",
              onRender: (item: any) => {
                return item.publicKey
              },
              minWidth: 100,
              maxWidth: 100,
              name: "Schlüssel",
            },
            {
              fieldName: "preview",
              key: "preview",
              minWidth: 0,
              name: "Vorschau",
            },
            {
              fieldName: "jobfair",
              key: "jobfair",
              minWidth: 0,
              name: "zuständig",
              onRender: (item: any) => {
                if (item?.type !== "job") return null
                return item?.original.jobfairs.map((jf: any) => {
                  return jf.text + " "
                })
              }
            },
            {
              fieldName: "createdAt",
              key: "createdAt",
              minWidth: 0,
              name: "erstellt am",
              onRender: (item: any) => {
                if (!item?.original.createdAt)
                  return "Erstellungsdatum unbekannt";
                return formatDistance(
                  new Date(item?.original?.createdAt),
                  new Date(),
                  { locale: de, addSuffix: true }
                );
              },
            },
          ]}
          isHeaderVisible={false}
          items={searchResult.searchResults}
          groups={groups}
          selectionMode={SelectionMode.none}
          onItemInvoked={(item: any) => {
            switch (item?.type) {
              case "public-employer":
                return addWindow.Profile.view(item?.id);
              case "private-employer":
                return addWindow.Profile.view(item?.id);
              case "job":
                return addWindow.Job.view(item?.id);
              case "employee":
                return addWindow.Profile.view(item?.id);
              case "work-log":
                return addWindow.WorkLog.view(item?.id);
              case "invoice":
                return addWindow.Invoice.view(item?.original.invoiceId);
              case "receipt":
                (async () => {
                  let res = await axiosJWT.get(
                    process.env.REACT_APP_SERVER +
                    "/api/a/get-receipt-by-id/" +
                    item?.id);

                  const link: any = res.data;

                  return window.open(link, "blank");
                })();
                break;
              case "contact":
                return addWindow.Contact.view(item?.id);
              case "note":
                return addWindow.Note.view(item?.id);
              default:
                break;
            }
          }}
          styles={{
            root: [
              {
                cursor: "pointer",
                userSelect: "none",
                zIndex: 1,
                maxWidth: "50vw",
                background: theme.palette.white
              },
              isTile
                ? {
                  boxShadow: DefaultEffects.elevation8,
                  position: "absolute",
                  marginLeft: 30,
                  maxWidth: 345,
                }
                : { marginLeft: 10, marginRight: 10, marginTop: 25 },
            ],
          }}
        />
      )}
    </Stack>
  );
};

export default SearchServer;
