import {
  DefaultButton, Image, Link,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
  useTheme
} from "@fluentui/react";
import { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { redirectTo, setAccountInLocalStorage } from "../assets/functions";
import {
  authCheckPassword,
  authCheckToken,
  authSendToken
} from "../assets/requests";
import { myButton, myTextLeft, myTextRight } from "../assets/styles";
import { FieldStringCreate } from "../components/forms/Parts";
import { PushDown } from "../components/misc";

const Login: React.FC = () => {
  const [loginMethod, setLoginMethod] = useState<any>();

  useEffect(() => {
    console.log("Willkommen auf jobfairy.ch")
    console.log(process.env.NODE_ENV);
  }, []);

  return (
    <Stack styles={{ root: { height: "100vh" } }}>
      {/* if user is already logged in, redirect to /app */}
      {localStorage.jwt && localStorage.userId && <Redirect to="/app" />}

      <Header />

      <Stack horizontal>
        <Stack
          styles={{
            root: {
              backgroundImage: "url('https://i.ibb.co/BgpSvtJ/background2.png')",
              // backgroundImage: "url('https://i.ibb.co/0j9VkQz/background6.png')",
              // backgroundImage: "url('https://i.ibb.co/d2xZMsx/background.png')",
              backgroundSize: "cover",
              // minHeight: "100vh",
              width: "50%",
            },
          }}
        />

        <Stack grow styles={{
          root: {
            // background: "rgba(0, 0, 0, 0.95)",
            // boxShadow: "-5px 0px black",
            backgroundSize: "cover",
            padding: "25vh 15vw",
            height: "100vh",
          }
        }}>
          <Image src="https://i.ibb.co/RN91MLT/logo.png"
            style={{ height: 200 }}
          />

          <Text variant="superLarge"
            style={{ color: "#FFD23F" }}
          >jobFAIRy.ch</Text>

          <Text
            style={{ color: "white" }}
            variant="xLargePlus">Anmelden</Text>

          <PushDown by={30} />

          <Stack styles={{ root: { width: 300 } }} tokens={{ childrenGap: 10 }}>
            {loginMethod === undefined && (
              <Stack tokens={{ childrenGap: 10 }}>
                <DefaultButton
                  onClick={() => setLoginMethod("password")}
                  // primary
                  styles={{
                    root: {
                      border: 0,
                      borderRadius: 16,
                      height: 50,
                      boxShadow: "0px 4px 10px rgba(0,0,0,0.05)",
                    },
                    rootHovered: { border: 0 },
                  }}
                  text="Benutzername/E-Mail & Passwort"
                />
                <DefaultButton
                  disabled
                  styles={{
                    root: {
                      border: 0,
                      borderRadius: 16,
                      height: 50,
                      boxShadow: "0px 4px 10px rgba(0,0,0,0.05)",
                    },
                    rootHovered: { border: 0 },
                  }}
                  onClick={() => setLoginMethod("token")}
                  text="Mobilnummer & SMS-Token"
                />
              </Stack>
            )}

            {loginMethod === "password" && <FormLoginPassword />}

            {loginMethod === "token" && <FormLoginToken />}

            {loginMethod && (
              <Link onClick={() => setLoginMethod(undefined)}>
                Anders authentifizieren
              </Link>
            )}
          </Stack>
        </Stack>
      </Stack>

      <Footer />
    </Stack>
  );
};

export default Login;

const Header: React.FC = () => {
  const theme = useTheme()

  return (
    <Stack
      horizontal
      styles={{
        root: {
          // background: theme.palette.white,
          // borderBottom: "1px solid " + theme.palette.white,
          top: 0,
          height: 65,
          justifyContent: "space-between",
          padding: "10px 25px",
          position: "fixed",
          width: "100vw",
        },
      }}
    >
      {/* <Logo type="text" /> */}
      <Stack styles={{ root: { alignSelf: "center" } }}>
        <Text variant="xLarge" style={{ color: "white" }}>jobFAIRy</Text>
      </Stack>
      <Text styles={{ root: { alignSelf: "center" } }} variant="large">
        Die Verwaltungsplattform für Jugend-Job-Börsen
      </Text>
    </Stack>
  );
};

const Footer: React.FC = () => {
  const theme = useTheme()

  return (
    <Stack
      horizontal
      styles={{
        root: {
          // background: theme.palette.white,
          // borderTop: "1px solid " + theme.palette.white,
          bottom: 0,
          height: 65,
          justifyContent: "space-between",
          padding: "10px 25px",
          position: "fixed",
          width: "100vw",
        },
      }}
    >
      <Stack styles={myTextLeft}>
        <Text style={{ color: "white" }} variant="large">jobfairy.ch</Text>
        <Text style={{ color: "white" }} variant="small">
          Die Verwaltungsplattform für Jugend-Job-Börsen
        </Text>
      </Stack>
      <Stack styles={myTextRight}>
        <Text variant="large">Neu bei jobfairy.ch?</Text>
        <Text variant="small">Alle Informationen unter jobfairy.ch</Text>
      </Stack>
    </Stack>
  );
};

export const FormLoginPassword: React.FC = () => {
  const [credentials, setCredentials] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const change = (field: string, value: string) => {
    setIsError(false);
    setCredentials({ ...credentials, [field]: value });
  };

  const login = async () => {
    setIsLoading(true);
    let { jwt, userId, refreshToken } = await authCheckPassword(credentials);
    if (!jwt || !userId || !refreshToken) {
      setIsError(true);
      setIsLoading(false);
      return;
    }
    setAccountInLocalStorage(jwt, userId, refreshToken);
    redirectTo("/app");
  };

  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <FieldStringCreate
        autoFocus
        errorMessage={!isError ? "" : "Benutzername falsch"}
        label="Benutzername/E-Mail"
        onChange={(newValue) => change("identifier", newValue)}
      />

      <FieldStringCreate
        errorMessage={!isError ? "" : "Passwort falsch"}
        label="Passwort"
        onChange={(newValue) => change("password", newValue)}
        onEnter={login}
        type="password"
      />

      <DefaultButton
        disabled={!credentials?.identifier || !credentials?.password}
        onClick={login}
        primary
        styles={myButton}
      >
        {!isLoading ? (
          "Authentifizieren"
        ) : (
          <Spinner size={SpinnerSize.medium} />
        )}
      </DefaultButton>
    </Stack>
  );
};

export const FormLoginToken: React.FC = () => {
  const [initial, setInitial] = useState(true);
  const [credentials, setCredentials] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const change = (field: any, value: string) => {
    setIsError(false);
    setCredentials({ ...credentials, [field]: value });
  };

  const sendToken = async () => {
    setIsError(false);
    let tokenSent = await authSendToken(credentials);
    if (tokenSent) {
      setInitial(false);
    } else {
      setIsError(true);
      setInitial(true);
    }
    setIsLoading(false);
  };

  const login = async () => {
    setIsLoading(true);
    let { jwt, userId, refreshToken } = await authCheckToken(credentials);
    if (!jwt || !userId || !refreshToken) {
      setIsError(true);
      setIsLoading(false);
      return;
    }
    setAccountInLocalStorage(jwt, userId, refreshToken);
    redirectTo("/app");
  };

  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <FieldStringCreate
        autoFocus
        disabled={!initial}
        label="Mobilnummer"
        errorMessage={!isError ? "" : "Mobilnummer ungültig"}
        onChange={(newValue) => change("identifier", newValue)}
        onEnter={initial ? sendToken : login}
      />
      <small>CH: +41</small>

      {!initial && (
        <FieldStringCreate
          autoFocus
          label="SMS-Token"
          errorMessage={!isError ? "" : "SMS-Token ungültig"}
          onChange={(newValue) => change("password", newValue)}
          onEnter={initial ? sendToken : login}
        />
      )}

      <DefaultButton
        disabled={!credentials?.identifier}
        onClick={initial ? sendToken : login}
        primary
        text={
          initial ? "SMS-Token senden" : !isLoading ? "Authentifizieren" : ""
        }
        styles={myButton}
      >
        {isLoading && <Spinner size={SpinnerSize.medium} />}
      </DefaultButton>
    </Stack>
  );
};
