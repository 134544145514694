import { Stack } from "@fluentui/react";
import SearchServer from "../components/SearchServer";
import { ViewContent } from "./Parts";

const ViewSearch: React.FC<{ searchString: string, windowId: string }> = ({ searchString, windowId }) => {
  return (
    <Stack>
      <ViewContent padding={false}>
        <SearchServer searchString={searchString} isTile={false} />
      </ViewContent>
    </Stack>
  );
};

export default ViewSearch;
