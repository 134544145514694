export const returnValidationErrors = (type: string, form: any) => {
  switch (type) {
    case "publicEmployer":
      return checkPublicEmployerForm(form);
    case "privateEmployer":
      return checkPrivateEmployer(form);
    case "employee":
      return checkEmployee(form);

    default:
      return false;
  }
};

const checkPublicEmployerForm = (form: any) => {
  let validationErrors: any = {};
  validationErrors.address = {};

  if (!form.description) validationErrors.description = "Beschreibung fehlt";

  // if (!form.website) validationErrors.website = "Webseite fehlt";

  if (
    !form.address.route ||
    !form.address.street_number ||
    !form.address.postal_code ||
    !form.address.locality
  )
    validationErrors.address.main = "Adresse unvollständig";

  // if (!form.email && !form.mobilephone) {
  //   validationErrors.email = "E-Mail oder Mobilnummer benötigt";
  //   validationErrors.mobilephone = "E-Mail oder Mobilnummer benötigt";
  // }

  if (
    Object.keys(validationErrors).length < 2 &&
    !validationErrors.address.main
  )
    return null;
  return validationErrors;
};

const checkPrivateEmployer = (form: any) => {
  let validationErrors: any = {};
  validationErrors.address = {};

  if (!form.firstName) validationErrors.firstName = "Vorname fehlt";

  if (!form.lastName) validationErrors.lastName = "Nachname fehlt";

  if (
    !form.address.route ||
    !form.address.street_number ||
    !form.address.postal_code ||
    !form.address.locality
  )
    validationErrors.address.main = "Adresse unvollständig";

  // if (!form.email && !form.mobilephone) {
  //   validationErrors.email = "E-Mail oder Mobilnummer benötigt";
  //   validationErrors.mobilephone = "E-Mail oder Mobilnummer benötigt";
  // }

  if (
    Object.keys(validationErrors).length < 2 &&
    !validationErrors.address.main
  )
    return null;
  return validationErrors;
};

const checkEmployee = (form: any) => {
  let validationErrors: any = {};
  validationErrors.address = {};

  if (!form.birthdate) validationErrors.birthdate = "Geburtsdatum fehlt";

  if (!form.gender) validationErrors.gender = "Geschlecht fehlt";

  if (!form.firstName) validationErrors.firstName = "Vorname fehlt";

  if (!form.lastName) validationErrors.lastName = "Nachname fehlt";

  if (
    !form.address.route ||
    !form.address.street_number ||
    !form.address.postal_code ||
    !form.address.locality
  )
    validationErrors.address.main = "Adresse unvollständig";

  // if (!form.email && !form.mobilephone) {
  //   validationErrors.email = "E-Mail oder Mobilnummer benötigt";
  //   validationErrors.mobilephone = "E-Mail oder Mobilnummer benötigt";
  // }

  if (
    Object.keys(validationErrors).length < 2 &&
    !validationErrors.address.main
  )
    return null;
  return validationErrors;
};
