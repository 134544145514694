import { DefaultButton, Modal, Stack } from "@fluentui/react";
import { useState } from "react";
import { Icons } from "../assets/icons";
import ListDocuments from "../lists/ListDocuments";
import { UploadDocument } from "./Document";
import PivotSubMenu from "./Parts";

const Documents: React.FC<{ modelId: string; modelType: string }> = ({
  modelId,
  modelType,
}) => {
  const [showUploadDocument, setShowUploadDocument] = useState(false)

  return (
    <Stack>
      <Modal isOpen={showUploadDocument} onDismiss={() => setShowUploadDocument(false)} styles={{ main: { borderRadius: 16, height: "45vh", width: "50vw" } }}>
        <UploadDocument modelId={modelId} modelType={modelType} windowId={"uploadDocument_" + modelId} />
      </Modal>
      <PivotSubMenu>
        <DefaultButton
          styles={{ root: { borderRadius: 16 } }}
          iconProps={{
            iconName: Icons.Documents,
          }}
          // onClick={async () => addWindow.Document.upload(modelId, modelType)}
          onClick={async () => setShowUploadDocument(true)}
        >
          Dokument hochladen
        </DefaultButton>
      </PivotSubMenu>

      <ListDocuments modelId={modelId} modelType={modelType} />
    </Stack>
  );
};

export default Documents;
