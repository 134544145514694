import { DetailsList, Link, SelectionMode, Stack, Text, useTheme } from "@fluentui/react";
import { format } from "date-fns";
import { de } from "date-fns/locale";
import { useEffect, useState } from "react";
import { addWindow } from "../assets/windows";
import {
  QueryProgressIndicator, SearchBoxInvoices
} from "../components/misc";

const ListInvoices: React.FC<{ query: any }> = ({ query }) => {
  const invoices = query
  const [list, setList] = useState<any>({
    items: [],
    isSorted: {},
  });
  const clear = () => setList({ ...list, items: invoices?.data });
  const setItems = (items: Array<any>) => setList({ ...list, items: items });
  const theme = useTheme()

  useEffect(() => {
    if (invoices?.isSuccess) setItems(invoices?.data);
    // eslint-disable-next-line
  }, [invoices?.isSuccess, invoices?.data?.length]);

  const sortByPublicKey = () => {
    let items = list.items

    if (list.isSorted.byPublicKeyUP) {
      items = items.sort((a: any, b: any) => {
        if (parseInt(a.publicKey.slice(a.publicKey.length - 4, a.publicKey.length))
          <
          parseInt(b.publicKey.slice(b.publicKey.length - 4, b.publicKey.length))) return -1
        return 1
      })
    } else {
      items = items.sort((a: any, b: any) => {
        if (parseInt(a.publicKey.slice(a.publicKey.length - 4, a.publicKey.length))
          <
          parseInt(b.publicKey.slice(b.publicKey.length - 4, b.publicKey.length))) return 1
        return -1
      })
    }

    setList({
      items: items, isSorted: {
        byCreatedAtUP: false,
        byCreatedAtDOWN: false,
        byPublicKeyUP: !list.isSorted.byPublicKeyUP,
        byPublicKeyDOWN: list.isSorted.byPublicKeyUP,
        byStatusUP: false,
        byStatusDOWN: false,
        byEmployerUP: false,
        byEmployerDOWN: false,
        byJobUP: false,
        byJobDOWN: false,
        byAmountUP: false,
        byAmountDOWN: false,
        byEmployeeUP: false,
        byEmployeeDOWN: false,
      }
    })
  }

  const sortByEmployer = () => {
    let items = list.items

    if (list.isSorted.byEmployerUP) {
      items = items.sort((a: any, b: any) => {
        if (parseInt(a.employer.publicKey.slice(a.employer.publicKey.length - 4, a.employer.publicKey.length))
          <
          parseInt(b.employer.publicKey.slice(b.employer.publicKey.length - 4, b.employer.publicKey.length))) return -1
        return 1
      })
    } else {
      items = items.sort((a: any, b: any) => {
        if (parseInt(a.employer.publicKey.slice(a.employer.publicKey.length - 4, a.employer.publicKey.length))
          <
          parseInt(b.employer.publicKey.slice(b.employer.publicKey.length - 4, b.employer.publicKey.length))) return 1
        return -1
      })
    }

    setList({
      items: items, isSorted: {
        byCreatedAtUP: false,
        byCreatedAtDOWN: false,
        byPublicKeyUP: false,
        byPublicKeyDOWN: false,
        byEmployerUP: !list.isSorted.byEmployerUP,
        byEmployerDOWN: list.isSorted.byEmployerUP,
        byStatusUP: false,
        byStatusDOWN: false,
        byJobUP: false,
        byJobDOWN: false,
        byAmountUP: false,
        byAmountDOWN: false,
        byEmployeeUP: false,
        byEmployeeDOWN: false,
      }
    })
  }

  const sortByEmployee = () => {
    let items = list.items

    if (list.isSorted.byEmployeeUP) {
      items = items.sort((a: any, b: any) => {
        if (parseInt(a.employee.publicKey.slice(a.employee.publicKey.length - 4, a.employee.publicKey.length))
          <
          parseInt(b.employee.publicKey.slice(b.employee.publicKey.length - 4, b.employee.publicKey.length))) return -1
        return 1
      })
    } else {
      items = items.sort((a: any, b: any) => {
        if (parseInt(a.employee.publicKey.slice(a.employee.publicKey.length - 4, a.employee.publicKey.length))
          <
          parseInt(b.employee.publicKey.slice(b.employee.publicKey.length - 4, b.employee.publicKey.length))) return 1
        return -1
      })
    }

    setList({
      items: items, isSorted: {
        byCreatedAtUP: false,
        byCreatedAtDOWN: false,
        byPublicKeyUP: false,
        byPublicKeyDOWN: false,
        byEmployerUP: false,
        byEmployerDOWN: false,
        byEmployeeUP: !list.isSorted.byEmployeeUP,
        byEmployeeDOWN: list.isSorted.byEmployeeUP,
        byStatusUP: false,
        byStatusDOWN: false,
        byJobUP: false,
        byJobDOWN: false,
        byAmountUP: false,
        byAmountDOWN: false,
      }
    })
  }

  const sortByJob = () => {
    let items = list.items

    if (list.isSorted.byJobUP) {
      items = items.sort((a: any, b: any) => {
        if (parseInt(a.job.publicKey.slice(a.job.publicKey.length - 4, a.job.publicKey.length))
          <
          parseInt(b.job.publicKey.slice(b.job.publicKey.length - 4, b.job.publicKey.length))) return -1
        return 1
      })
    } else {
      items = items.sort((a: any, b: any) => {
        if (parseInt(a.job.publicKey.slice(a.job.publicKey.length - 4, a.job.publicKey.length))
          <
          parseInt(b.job.publicKey.slice(b.job.publicKey.length - 4, b.job.publicKey.length))) return 1
        return -1
      })
    }

    setList({
      items: items, isSorted: {
        byCreatedAtUP: false,
        byCreatedAtDOWN: false,
        byPublicKeyUP: false,
        byPublicKeyDOWN: false,
        byJobUP: !list.isSorted.byJobUP,
        byJobDOWN: list.isSorted.byJobUP,
        byEmployerUP: false,
        byEmployerDOWN: false,
        byStatusUP: false,
        byStatusDOWN: false,
        byAmountUP: false,
        byAmountDOWN: false,
        byEmployeeUP: false,
        byEmployeeDOWN: false,
      }
    })
  }

  const sortByCreatedAt = () => {
    let items = list.items

    if (list.isSorted.byCreatedAtUP) {
      items = items.sort((a: any, b: any) => {
        if (new Date(a.createdAt).getTime() < new Date(b.createdAt).getTime()) return -1
        return 1
      })
    } else {
      items = items.sort((a: any, b: any) => {
        if (new Date(a.createdAt).getTime() < new Date(b.createdAt).getTime()) return 1
        return -1
      })
    }

    setList({
      items: items, isSorted: {
        byCreatedAtUP: !list.isSorted.byCreatedAtUP,
        byCreatedAtDOWN: list.isSorted.byCreatedAtUP,
        byPublicKey: false,
        byStatusUP: false,
        byEmployerUP: false,
        byEmployerDOWN: false,
        byStatusDOWN: false,
        byJobUP: false,
        byJobDOWN: false,
        byAmountUP: false,
        byAmountDOWN: false,
        byEmployeeUP: false,
        byEmployeeDOWN: false,
      }
    })
  }

  const sortByStatus = () => {
    let items = list.items

    if (list.isSorted.byStatusUP) {
      items = items.sort((a: any, b: any) => {
        if (a.smallinvoice_invoice_data.item.status < b.smallinvoice_invoice_data.item.status) return -1
        return 1
      })
    } else {
      items = items.sort((a: any, b: any) => {
        if (a.smallinvoice_invoice_data.item.status < b.smallinvoice_invoice_data.item.status) return 1
        return -1
      })
    }

    setList({
      items: items, isSorted: {
        byCreatedAtUP: false,
        byCreatedAtDOWN: false,
        byEmployerUP: false,
        byEmployerDOWN: false,
        byAmountUP: false,
        byAmountDOWN: false,
        byPublicKeyUP: false,
        byPublicKeyDOWN: false,
        byStatusUP: !list.isSorted.byStatusUP,
        byStatusDOWN: list.isSorted.byStatusUP,
        byJobUP: false,
        byJobDOWN: false,
        byEmployeeUP: false,
        byEmployeeDOWN: false,
      }
    })
  }

  const sortByAmount = () => {
    let items = list.items

    if (list.isSorted.byAmountUP) {
      items = items.sort((a: any, b: any) => {
        if (a.smallinvoice_invoice_data.item.total < b.smallinvoice_invoice_data.item.total) return -1
        return 1
      })
    } else {
      items = items.sort((a: any, b: any) => {
        if (a.smallinvoice_invoice_data.item.total < b.smallinvoice_invoice_data.item.total) return 1
        return -1
      })
    }

    setList({
      items: items, isSorted: {
        byCreatedAtUP: false,
        byCreatedAtDOWN: false,
        byAmountUP: !list.isSorted.byAmountUP,
        byAmountDOWN: list.isSorted.byAmountUP,
        byPublicKeyUP: false,
        byPublicKeyDOWN: false,
        byStatusUP: false,
        byStatusDOWN: false,
        byEmployerUP: false,
        byEmployerDOWN: false,
        byJobUP: false,
        byJobDOWN: false,
        byEmployeeUP: false,
        byEmployeeDOWN: false,
      }
    })
  }

  return (
    <Stack>
      <QueryProgressIndicator  query={invoices} />

      {invoices?.isSuccess && invoices?.data.length > 0 && (
        <Stack>
          <SearchBoxInvoices
            items={invoices?.data}
            onResult={(results: Array<any>) => setItems(results)}
            onClear={clear}
          />

          <DetailsList
            columns={[
              {
                isResizable: true,
                key: "date",
                onColumnClick(ev, column) {
                  sortByCreatedAt()
                },
                minWidth: 0,
                maxWidth: 50,
                name: ((): string => {
                  if (list.isSorted.byCreatedAtUP) {
                    return "erstellt am | oben"
                  } else if (list.isSorted.byCreatedAtDOWN) {
                    return "erstellt am | unten"
                  } else {
                    return "erstellt am"
                  }
                })(),
                styles: { root: { borderRadius: "16px 16px 0px 0px", background: list.isSorted.byCreatedAtUP || list.isSorted.byCreatedAtDOWN ? theme.palette.themePrimary : undefined } },
                onRender: (item: any) => {
                  return (
                    format(new Date(item?.createdAt), "dd.MM.yy", { locale: de })
                  );
                },
              },
              {
                isResizable: true,
                key: "publicKey",
                minWidth: 0,
                maxWidth: 50,
                name: ((): string => {
                  if (list.isSorted.byPublicKeyUP) {
                    return "Schlüssel | oben"
                  } else if (list.isSorted.byPublicKeyDOWN) {
                    return "Schlüssel | unten"
                  } else {
                    return "Schlüssel"
                  }
                })(),
                onColumnClick(ev, column) {
                  sortByPublicKey()
                },
                onRender: (item: any) => item?.publicKey,
                styles: { root: { borderRadius: "16px 16px 0px 0px", background: list.isSorted.byPublicKeyUP || list.isSorted.byPublicKeyDOWN ? theme.palette.themePrimary : undefined } }
              },
              {
                key: "employer",
                isResizable: true,
                minWidth: 0,
                maxWidth: 250,
                name: ((): string => {
                  if (list.isSorted.byEmployerUP) {
                    return "Auftraggeber*in | oben"
                  } else if (list.isSorted.byEmployerDOWN) {
                    return "Auftraggeber*in | unten"
                  } else {
                    return "Auftraggeber*in"
                  }
                })(),
                onColumnClick(ev, column) {
                  sortByEmployer()
                },
                styles: { root: { borderRadius: "16px 16px 0px 0px", background: list.isSorted.byEmployerUP || list.isSorted.byEmployerDOWN ? theme.palette.themePrimary : undefined } },
                onRender: (item: any) => {
                  if (item?.employer?.type === "publicEmployer") {
                    return (
                      <Link
                        onClick={() =>
                          addWindow.Profile.view(item?.job?.employer?.id)
                        }
                      >
                        {item?.employer?.description}
                      </Link>
                    );
                  }
                  if (item?.employer?.type !== "publicEmployer") {
                    return (
                      <Link
                        onClick={() =>
                          addWindow.Profile.view(item?.job?.employer?.id)
                        }
                      >
                        {item?.employer?.firstName +
                          " " +
                          item?.employer?.lastName}
                      </Link>
                    );
                  }
                },
              },
              {
                isResizable: true,
                key: "employee",
                minWidth: 0,
                maxWidth: 250,
                onColumnClick(ev, column) {
                  sortByEmployee()
                },
                name: ((): string => {
                  if (list.isSorted.byEmployeeUP) {
                    return "Jugendliche*r | oben"
                  } else if (list.isSorted.byEmployeeDOWN) {
                    return "Jugendliche*r | unten"
                  } else {
                    return "Jugendliche*r"
                  }
                })(),
                styles: { root: { borderRadius: "16px 16px 0px 0px", background: list.isSorted.byEmployeeUP || list.isSorted.byEmployeeDOWN ? theme.palette.themePrimary : undefined } },
                onRender: (item: any) => {
                  return (
                    <Link
                      onClick={() => addWindow.Profile.view(item?.employee?.id)}
                    >
                      {item?.employee?.firstName + " " + item?.employee?.lastName}
                    </Link>
                  );
                },
              },
              {
                key: "job",
                minWidth: 0,
                isResizable: true,
                onColumnClick(ev, column) {
                  sortByJob()
                },
                name: ((): string => {
                  if (list.isSorted.byJobUP) {
                    return "Job | oben"
                  } else if (list.isSorted.byJobDOWN) {
                    return "Job | unten"
                  } else {
                    return "Job"
                  }
                })(),
                styles: { root: { borderRadius: "16px 16px 0px 0px", background: list.isSorted.byJobUP || list.isSorted.byJobDOWN ? theme.palette.themePrimary : undefined } },
                onRender: (item: any) => {
                  return (
                    <Link onClick={() => addWindow.Job.view(item?.job?.id)}>
                      {item?.job?.title}
                    </Link>
                  );
                },
              },
              {
                key: "amount",
                minWidth: 0,
                maxWidth: 50,
                name: ((): string => {
                  if (list.isSorted.byAmountUP) {
                    return "Betrag | oben"
                  } else if (list.isSorted.byAmountDOWN) {
                    return "Betrag | unten"
                  } else {
                    return "Betrag"
                  }
                })(),
                styles: { root: { borderRadius: "16px 16px 0px 0px", background: list.isSorted.byAmountUP || list.isSorted.byAmountDOWN ? theme.palette.themePrimary : undefined } },
                onColumnClick(ev, column) {
                  sortByAmount()
                },
                onRender: (item: any) => "CHF " + item?.smallinvoice_invoice_data?.item?.total,
              },
              {
                isResizable: true,
                styles: { root: { borderRadius: "16px 16px 0px 0px", background: list.isSorted.byStatusUP || list.isSorted.byStatusDOWN ? theme.palette.themePrimary : undefined } },
                key: "status",
                minWidth: 0,
                name: ((): string => {
                  if (list.isSorted.byStatusUP) {
                    return "Status | oben"
                  } else if (list.isSorted.byStatusDOWN) {
                    return "Status | unten"
                  } else {
                    return "Status"
                  }
                })(),
                onColumnClick(ev, column) {
                  sortByStatus()
                },
                onRender: (item: any) => {
                  if (item?.smallinvoice_invoice_data?.item?.status === "P")
                    return (
                      <Text style={{ color: "green", fontSize: 12 }}>
                        bezahlt
                      </Text>
                    );
                  if (item?.smallinvoice_invoice_data?.item?.status === "C")
                    return (
                      <Text style={{ color: "red", fontSize: 12 }}>
                        storniert
                      </Text>
                    );
                  if (item?.smallinvoice_invoice_data?.item?.status === "S")
                    return (
                      <Text style={{ color: "orange", fontSize: 12 }}>
                        gesendet
                      </Text>
                    );
                  if (item?.smallinvoice_invoice_data?.item?.status === "D")
                    return (
                      <Text style={{ color: "red", fontSize: 12 }}>
                        gelöscht
                      </Text>
                    );
                  if (item?.smallinvoice_invoice_data?.item?.status === "DR")
                    return (
                      <Text style={{ fontSize: 12 }}>
                        Entwurf
                      </Text>
                    );
                  return (
                    <Text style={{ color: "orange", fontSize: 12 }}>
                      Status unklar
                    </Text>
                  );
                },
              },
            ]}
            isHeaderVisible={true}
            items={list.items}
            onItemInvoked={(item) => addWindow.Invoice.view(item?.invoiceId)}
            selectionMode={SelectionMode.none}
            styles={{ root: { cursor: "pointer", borderRadius: "0px 0px 16px 16px" } }}
          />
        </Stack>
      )}
    </Stack>
  );
};

export default ListInvoices;
