import {
  DefaultButton,
  Label,
  Link,
  MessageBar,
  MessageBarType,
  Pivot,
  PivotItem,
  Stack,
  Text,
  Toggle,
  useTheme
} from "@fluentui/react";
import { add, format } from "date-fns";
import { de } from "date-fns/locale";
import { useEffect } from "react";
import Swal from "sweetalert2";
import axiosJWT from "../assets/axiosjwt";
import { Icons } from "../assets/icons";
import {
  useDeleteInvoice,
  useMarkInvoiceAsPaidInCash,
  useSendByEmail,
  useSendByPost,
  useUpdateInvoice,
  useUpdateInvoiceDate
} from "../assets/mutations";
import { useInvoiceBySmallInvoice, useReceiptsByInvoice, useWorkLogsByInvoice } from "../assets/queries";
import { useClientStore } from "../assets/stores";
import { addWindow } from "../assets/windows";
import {
  InternalMeta,
  MutationProgressIndicator,
  PullUp, QueryProgressIndicator,
  SeparatorTransparent
} from "../components/misc";
import ListReceipts from "../lists/ListReceipts";
import ListWorkLogs from "../lists/ListWorkLogs";
import PivotSubMenu, { ViewContent } from "./Parts";
const { getState, setState } = useClientStore

const ViewInvoice: React.FC<{ invoiceId: string; windowId: string }> = ({
  invoiceId,
  windowId,
}) => {
  const query: any = useInvoiceBySmallInvoice(invoiceId);
  const updateInvoice = useUpdateInvoice(invoiceId);
  const updateInvoiceDate = useUpdateInvoiceDate(invoiceId);
  const deleteInvoice = useDeleteInvoice(invoiceId);
  const markInvoiceAsPaidInCash = useMarkInvoiceAsPaidInCash(invoiceId)
  const sendByPost = useSendByPost(invoiceId)
  const sendByEmail = useSendByEmail(invoiceId)
  const receipts = useReceiptsByInvoice(invoiceId)
  const workLogs = useWorkLogsByInvoice(invoiceId)

  useEffect(() => {
    let newWindows = getState().openWindows.filter((window: any) => {
      if (window.window.id === "viewInvoice" + invoiceId) {
        window.window.title = query.data?.number
      }
      return true
    })

    setState({ openWindows: newWindows })
  }, [query.isSuccess])

  const returnStatus = () => {
    switch (query.data?.status) {
      case "DR":
        return (
          <MessageBar messageBarType={MessageBarType.warning}>
            Entwurf
          </MessageBar>
        );
      case "S":
        return (
          <MessageBar messageBarType={MessageBarType.info}>gesendet</MessageBar>
        );
      case "D":
        return (
          <MessageBar messageBarType={MessageBarType.blocked}>
            gelöscht
          </MessageBar>
        );
      case "P":
        return (
          <MessageBar messageBarType={MessageBarType.success}>
            bezahlt
          </MessageBar>
        );
      case "C":
        return (
          <MessageBar messageBarType={MessageBarType.severeWarning}>
            storniert
          </MessageBar>
        );
      default:
        break;
    }
  };

  const isPastDue = () => {
    const now = new Date().getMilliseconds();
    const due = new Date(query.data?.due).getMilliseconds();
    return due - now > 0;
  };

  const getInvoice = async () => {
    const res = await axiosJWT.get(
      process.env.REACT_APP_SERVER +
      "/api/a/invoice-preview-by-smallinvoice/" +
      invoiceId,
    );

    return process.env.REACT_APP_SERVER + "/" + res.data + ".pdf";
  };

  // styles
  const theme = useTheme();
  const pivotStyles: any = {
    root: {
      background: theme.palette.white,
      left: 0,
      width: "100%",
      borderRadius: "16px 16px 0px 0px",
      zIndex: 10,
    },
  };

  let actions: any = [
    {
      disabled: query.data?.status === "C",
      key: "delete",
      iconProps: { iconName: Icons.Delete },
      onClick: async () => {
        await deleteInvoice.mutateAsync();
      },
      text: "Stornieren",
    },
  ]

  return (
    <Stack>
      <ViewContent>
        <MutationProgressIndicator mutationQuery={updateInvoice}  />

        <MutationProgressIndicator mutationQuery={sendByPost}  />

        <MutationProgressIndicator mutationQuery={sendByEmail}  />

        <MutationProgressIndicator
          mutationQuery={updateInvoiceDate}
          
        />

        <MutationProgressIndicator mutationQuery={deleteInvoice}  />

        <QueryProgressIndicator  query={query} />

        <PullUp by={20} />

        {query.data && (
          <Pivot overflowBehavior="menu" styles={pivotStyles}>
            <PivotItem headerText="Rechnung" itemIcon={Icons.File}>
              <PivotSubMenu>
                <DefaultButton
                  styles={{ root: { borderRadius: 16 } }}
                  disabled={
                    query.data?.status === "P" || query.data?.status === "C"
                  }
                  onClick={async () => {
                    Swal.fire({
                      buttonsStyling: false,
                      confirmButtonText: "Ja, anpassen",
                      focusCancel: true,
                      cancelButtonText: "Abbrechen",
                      showCancelButton: true,
                      title: "Achtung",
                      html:
                        "Willst du das Rechnungsdatum wirklich anpassen?",
                      reverseButtons: true,
                    }).then(async (event: any) => {
                      if (event.isConfirmed) {
                        await updateInvoiceDate.mutateAsync({
                          date: new Date(),
                          due: add(new Date(), { months: 1 }),
                        });
                      }
                    });
                  }}
                  iconProps={{ iconName: "Calendar" }}
                  text="Frist"
                />

                <DefaultButton
                  styles={{ root: { borderRadius: 16 } }}
                  disabled={query.data?.status === "C"}
                  onClick={async () => {
                    if (!query.data?.isSent && query.data?.status !== "P" && query.data?.status !== "S") {
                      Swal.fire({
                        buttonsStyling: false,
                        confirmButtonText: "Verstanden",
                        focusCancel: true,
                        title: "Rechnung kann noch erweitert werden",
                        text: "Verschicke diese Rechnung NICHT, wenn du sie nicht als versandt markiert hast.",
                        reverseButtons: true,
                      }).then(async (event: any) => {
                        const link: any = await getInvoice();
                        window.open(link);
                      });
                    } else if (query.data?.status === "P") {
                      Swal.fire({
                        buttonsStyling: false,
                        confirmButtonText: "Verstanden",
                        focusCancel: true,
                        title: "Achtung",
                        html:
                          "Diese Rechnung ist am " +
                          format(
                            new Date(query.data?.paid_date),
                            "dd. MMMM yyy",
                            { locale: de }
                          ) +
                          " bezahlt worden!",
                        reverseButtons: true,
                      }).then(async (event: any) => {
                        const link: any = await getInvoice();
                        window.open(link);
                      });
                    } else {
                      const link: any = await getInvoice();
                      window.open(link);
                    }
                  }}
                  iconProps={{ iconName: "PDF" }}
                  text="PDF"
                />

                <DefaultButton
                  styles={{ root: { borderRadius: 16 } }}
                  disabled={
                    query.data?.status === "P" || query.data?.status === "C"
                  }
                  onClick={async () => {
                    Swal.fire({
                      buttonsStyling: false,
                      confirmButtonText: "Ja, BAR bezahlt",
                      focusCancel: true,
                      cancelButtonText: "Abbrechen",
                      showCancelButton: true,
                      title: "Achtung",
                      html:
                        "Willst du diese Rechnung als vollständig in BAR bezahlt markieren?",
                      reverseButtons: true,
                    }).then(async (event: any) => {
                      if (event.isConfirmed) {
                        await markInvoiceAsPaidInCash.mutateAsync({
                          date: new Date(),
                          amount: query.data.total
                        });
                      }
                    });
                  }}
                  iconProps={{ iconName: "Money" }}
                  text="BAR"
                />

                <DefaultButton
                  styles={{ root: { borderRadius: 16 } }}
                  disabled={
                    query.data?.status === "P" || query.data?.status === "C" || query.data?.isSent === true
                  }
                  onClick={async () => {
                    Swal.fire({
                      buttonsStyling: false,
                      confirmButtonText: "Brief",
                      focusCancel: true,
                      showDenyButton: true,
                      denyButtonText: "E-Mail",
                      cancelButtonText: "Abbrechen",
                      showCancelButton: true,
                      title: "E-Mail oder Brief?",
                      html:
                        "Wie soll die Rechnung versandt werden?",
                      reverseButtons: true,
                    }).then(async (event: any) => {
                      // email
                      if (event.isDenied) {
                        await sendByEmail.mutateAsync()
                      }
                      // letter
                      if (event.isConfirmed) {
                        await sendByPost.mutateAsync();
                      }
                    });
                  }}
                  iconProps={{ iconName: "Mail" }}
                  text={query.data?.isSentByPost ? "per Brief" : query.data?.isSentByEmail ? "E-Mail" : "E-Mail/Brief"}
                />

                <Stack grow horizontalAlign="end" style={{ paddingRight: 10 }}>
                  <Toggle
                    checked={query.data?.isSent}
                    disabled={
                      query.data?.status === "P" || query.data?.status === "C" || query.data?.isSent === true
                    }
                    inlineLabel
                    label={
                      query.data?.isSent
                        ? "versandt am " +
                        format(new Date(query.data?.sentAt), "dd.MM.yyy")
                        : "als versandt markieren"
                    }
                    onClick={async () =>
                      await updateInvoice.mutateAsync({
                        isSent: !query.data?.isSent,
                        sentAt: query.data?.isSent ? null : new Date(),
                      })
                    }
                  />
                </Stack>

                <DefaultButton styles={{ root: { borderRadius: 16 } }} primary menuProps={{ items: actions }}>Aktionen</DefaultButton>
              </PivotSubMenu>

              <Stack tokens={{ childrenGap: 10 }} styles={{ root: { background: theme.palette.white, padding: 20, borderRadius: "0px 0px 16px 16px" } }}>
                <Stack horizontal>
                  <Stack styles={{ root: { width: "100%" } }}>
                    <Label>Auftraggeber*in</Label>

                    {query.data?.employer?.type === "publicEmployer" && (
                      <Link
                        onClick={() =>
                          addWindow.Profile.view(query.data?.employer?.id)
                        }
                      >
                        {query.data?.employer?.description}
                      </Link>
                    )}

                    {query.data?.employer?.type !== "publicEmployer" && (
                      <Link
                        onClick={() =>
                          addWindow.Profile.view(query.data?.employer?.id)
                        }
                      >
                        {query.data?.employer?.firstName +
                          " " +
                          query.data?.employer.lastName}
                      </Link>
                    )}
                  </Stack>

                  <Stack styles={{ root: { width: "100%" } }}>
                    <Label>Jugendliche*r</Label>

                    <Link
                      onClick={() =>
                        addWindow.Profile.view(query.data?.employee?.id)
                      }
                    >
                      {query.data?.employee?.firstName +
                        " " +
                        query.data?.employee.lastName}
                    </Link>
                  </Stack>
                </Stack>

                <Stack styles={{ root: { width: "100%" } }}>
                  <Label>Job</Label>

                  <Link
                    onClick={() => addWindow.Job.view(query.data?.job?.id)}
                  >
                    {query.data?.job?.title}
                  </Link>
                </Stack>

                <SeparatorTransparent alignContent="end"></SeparatorTransparent>

                <Stack horizontal>
                  <Stack styles={{ root: { width: "100%" } }}>
                    <Label>Schlüssel</Label>

                    <Text variant="medium">{query.data?.number}</Text>
                  </Stack>

                  <Stack styles={{ root: { width: "100%" } }}>
                    <Label>Rechnungsdatum</Label>
                    <Text variant="medium">
                      {format(new Date(query.data?.date), "dd.MM.yyyy")}
                    </Text>
                  </Stack>
                </Stack>

                <Stack horizontal>
                  <Stack styles={{ root: { width: "100%" } }}>
                    <Label>Zahlungsfrist</Label>
                    <Text
                      variant="medium"
                      styles={{
                        root: {
                          color: isPastDue()
                            ? theme.palette.red
                            : theme.palette.green,
                        },
                      }}
                    >
                      {format(new Date(query.data?.due), "dd.MM.yyyy")}
                    </Text>
                  </Stack>

                  <Stack styles={{ root: { width: "100%" } }}>
                    <Label>bezahlt am</Label>
                    {query.data?.paid_date && (
                      <Text variant="medium">
                        {format(new Date(query.data?.paid_date), "dd.MM.yyyy")}
                      </Text>
                    )}
                  </Stack>
                </Stack>

                <Stack horizontal>
                  <Stack styles={{ root: { width: "100%" } }}>
                    <Label>Offen</Label>
                    <Text variant="medium">
                      {query.data?.total - query.data?.total_paid} CHF/
                      {query.data?.total} CHF
                    </Text>
                  </Stack>

                  <Stack styles={{ root: { width: "100%" } }}>
                    <Label>Betrag</Label>
                    <Text variant="medium">{query.data?.total} CHF</Text>
                  </Stack>
                </Stack>

                <SeparatorTransparent alignContent="end"></SeparatorTransparent>

                <small>Diese Rechnung wird von SmallInvoice verwaltet.</small>
              </Stack>
            </PivotItem>

            {query.data?.status !== "C" && (
              <PivotItem headerText="Arbeitseinsätze" itemIcon={Icons.WorkLog}>
                <ListWorkLogs query={workLogs} />
              </PivotItem>
            )}

            <PivotItem headerText="Quittungen" itemIcon={Icons.Receipt}>
              <PullUp by={120} />

              <ListReceipts query={receipts} />
            </PivotItem>

            <PivotItem headerText="" itemIcon={Icons.Warning}>
              <InternalMeta query={query} />
            </PivotItem>
          </Pivot>
        )}
      </ViewContent>
    </Stack>
  );
};

export default ViewInvoice;
