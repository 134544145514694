import { useMutation, useQueryClient } from "react-query";
import Swal from "sweetalert2";
import axiosJWT from './axiosjwt';
import { returnServerError } from "./functions";
import { useClientStore } from "./stores";
import { closeWindow } from "./windows";
const server = process.env.REACT_APP_SERVER;
// const jwt = localStorage.jwt;

// const success = () => {
//   Swal.fire({
//     timer: 1000,
//     position: "top-end",
//     title: "Erfolgreich",
//     toast: true,
//     color: "success",
//     showConfirmButton: false,
//   })
// }

// const error = (error: string) => {
//   Swal.fire({
//     timer: 1000,
//     position: "top-end",
//     title: "Fehlgeschlagen",
//     text: error,
//     toast: true,
//     color: "danger",
//     showConfirmButton: false,
//   })
// }

export const useUpdateMyUser = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: any) => {
      return await axiosJWT.put(
        server + "/api/a/user/my",
        { data: payload });
    },
    {
      onError: (error: any) => error,
      onSuccess: async () => {
        await queryClient.invalidateQueries("myUser");
      },
    }
  );
};

export const useUpdateUser = (profileId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: any) => {
      return await axiosJWT.post(
        server + "/api/a/update-user",
        { data: { ...payload, profileId: profileId } });
    },
    {
      onError: (error: any) => error,
      onSuccess: async () => {
        await queryClient.invalidateQueries(["user", { profileId: profileId }]);
      },
    }
  );
};

export const useCreateProfile = (type: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: any) =>
      await axiosJWT.post(
        server + "/api/a/profile",
        { data: { ...payload, type: type } }),
    {
      onError: (error: any) => error,
      onSuccess: async (data) => {
        await queryClient.invalidateQueries();
        return data
      },
    }
  );
};

export const useUpdateMyProfile = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: any) =>
      await axiosJWT.post(
        server + "/api/a/update-my-profile/",
        { data: payload }),
    {
      onError: (error: any) => error,
      onSuccess: async () => {
        await queryClient.invalidateQueries("myProfile");
      },
    }
  );
};

export const useUpdateProfile = (profileId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: any) =>
      await axiosJWT.post(
        server + "/api/a/update-profile-by-id/",
        { data: { ...payload, profileId: profileId } }),
    {
      onError: (error: any) => error,
      onSuccess: async () => {
        await queryClient.invalidateQueries("profiles");
      },
    }
  );
};

export const useUpdateProfileImage = (profileId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: any) =>
      await axiosJWT.post(server + "/api/a/update-profile-image-by-id/", {
        data: { ...payload, profileId: profileId },
      }),
    {
      onError: (error: any) => error,
      onSuccess: async () => {
        await queryClient.invalidateQueries("profiles");
      },
    }
  );
};

export const useAddContact = (profileId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: any) =>
      await axiosJWT.post(
        server + "/api/a/add-contact",
        { data: { ...payload, profileId: profileId } }),
    {
      onError: (error: any) => error,
      onSuccess: async () => {
        await queryClient.invalidateQueries("contacts");
        await queryClient.invalidateQueries("profiles");
      },
    }
  );
};

export const useUpdateContact = (contactId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: any) =>
      await axiosJWT.post(
        server + "/api/a/update-contact/",
        { data: { ...payload, contactId: contactId } }),
    {
      onError: (error: any) => error,
      onSuccess: async () => {
        await queryClient.invalidateQueries("contacts");
      },
    }
  );
};

export const useAddJob = (profileId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: any) =>
      await axiosJWT.post(
        server + "/api/a/create-job",
        { data: { ...payload, employer: profileId } }),
    {
      onError: (error: any) => {
        console.log(error.response);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries("jobs");
      },
    }
  );
};

export const useCreateWorkLog = (employeeId: string, jobId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: any) => {
      return await axiosJWT.post(
        server + "/api/a/create-work-log",
        { data: { ...payload, employeeId: employeeId, jobId: jobId } });
    },
    {
      onError: (error: any) => {
        console.log(error.response);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries("workLogs");
      },
    }
  );
};

export const useCreateAssignment = (employeeId: string, jobId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: any) => {
      return await axiosJWT.post(
        server + "/api/a/create-assignment",
        { data: { ...payload, employeeId: employeeId, jobId: jobId } });
    },
    {
      onError: (error: any) => {
        console.log(error.response);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries("assignments");
      },
    }
  );
};

export const useUpdateJob = (jobId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: any) =>
      await axiosJWT.post(
        server + "/api/a/update-job/",
        { data: { ...payload, jobId: jobId } }),
    {
      onError: (error: any) => error,
      onSuccess: async () => {
        await queryClient.invalidateQueries("jobs");
      },
    }
  );
};

export const useDeleteJob = (jobId: string, windowId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    async () =>
      await axiosJWT.post(
        server + "/api/a/delete-job-by-id/",
        { data: { jobId: jobId } }),
    {
      onError: (error: any) => error,
      onSuccess: async () => {
        closeWindow(windowId)
        await queryClient.invalidateQueries("jobs");
      },
    }
  );
};

export const useArchiveJob = (jobId: string, windowId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    async () =>
      await axiosJWT.post(
        server + "/api/a/archive-job-by-id/",
        { data: { jobId: jobId } }),
    {
      onError: (error: any) => error,
      onSuccess: async () => {
        closeWindow(windowId)
        await queryClient.invalidateQueries("jobs");
      },
    }
  );
};

export const useDeleteEntry = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: any) =>
      await axiosJWT.post(
        server + "/api/a/delete-entry",
        { data: payload }),
    {
      onError: (error: any) => error,
      onSuccess: async () => {
        await queryClient.invalidateQueries();
      },
    }
  );
};

export const useLinkEmployeesToJob = (
  employeeIds: any,
  jobId: string,
  windowId: string
) => {
  const queryClient = useQueryClient();

  return useMutation(
    async () =>
      await axiosJWT.post(
        server + "/api/a/link-employee-to-job",
        { data: { employeeIds: employeeIds, jobId: jobId } }),
    {
      onError: (error: any) => error,
      onSuccess: async () => {
        await queryClient.invalidateQueries("jobs");
        await queryClient.invalidateQueries("employees");
        closeWindow(windowId);
      },
    }
  );
};

export const useGenerateReceipts = (employeeId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    async () =>
      await axiosJWT.post(
        server + "/api/a/generate-receipts-by-employee",
        { data: { employeeId: employeeId } }),
    {
      onError: (error: any) => error,
      onSuccess: async (data: any) => {
        await queryClient.invalidateQueries();
        return data;
      },
    }
  );
};

export const useGenerateInvoicesByEmployer = (employerId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    async () =>
      await axiosJWT.post(
        server + "/api/a/generate-invoices-by-employer",
        { data: { employerId: employerId } }),
    {
      onError: (error: any) => error,
      onSuccess: async (data: any) => {
        await queryClient.invalidateQueries();
        return data;
      },
    }
  );
};

export const useUpdateConditions = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (form: any) => {
      return await axiosJWT.post(
        server + "/api/a/update-conditions",
        { data: { ...form } });
    },
    {
      onError: (error: any) => console.log(error.response),
      onSuccess: async () => {
        await queryClient.invalidateQueries("conditions");
      },
    }
  );
};

export const useNoteCreate = (
  profileId: string,
  modelId: string,
  modelType: string
) => {
  const queryClient = useQueryClient();
  const { getState } = useClientStore;

  return useMutation(
    (form: any) => {
      return axiosJWT.post(
        server + "/api/a/create-note",
        {
          data: {
            ...form,
            profileId: profileId,
            modelId: modelId,
            modelType: modelType,
          },
        });
    },
    {
      onError: (error: any) => { },
      onSuccess: async (data: any, variables: any) => {
        if (variables.type === "danger") {
          Swal.fire({
            buttonsStyling: false,
            confirmButtonText: "OK",
            focusCancel: true,
            title: "Alarmierende Notiz erstellt",
            text: "Es werden nun alle offenen Fenster geschlossen.",
            reverseButtons: true,
          }).then(() => {
            let windows = getState().openWindows;

            windows.forEach((window: any) => {
              closeWindow(window.window.id);
            });
          });
        }
        await queryClient.invalidateQueries("notes");
        await queryClient.invalidateQueries("myNotes");
        queryClient.removeQueries("profiles");
      },
    }
  );
};

export const useNoteDelete = (windowId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    (noteId: string) => {
      return axiosJWT.post(
        server + "/api/a/delete-note/",
        { data: { noteId: noteId } });
    },
    {
      onError: (error: any) => { },
      onSuccess: async () => {
        closeWindow(windowId);
        await queryClient.invalidateQueries("notes");
        await queryClient.invalidateQueries("myNotes");
        await queryClient.invalidateQueries("profiles");
      },
    }
  );
};

export const useNoteUpdate = (noteId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    (form: any) => {
      return axiosJWT.post(
        server + "/api/a/update-note/",
        { data: { ...form, noteId: noteId } });
    },
    {
      onError: (error: any) => { },
      onSuccess: async () => {
        await queryClient.refetchQueries("notes");
        await queryClient.invalidateQueries("myNotes");
        await queryClient.invalidateQueries("myTodos");
        await queryClient.invalidateQueries("profiles");
        await queryClient.invalidateQueries("myProfile");
      },
    }
  );
};

export const useUpdateWorkLog = (workLogId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: any) => {
      return axiosJWT.post(
        server + "/api/a/update-worklog/",
        { data: { ...payload, workLogId: workLogId } });
    },
    {
      onError: (error: any) => { },
      onSuccess: async () => {
        await queryClient.invalidateQueries("workLogs");
      },
    }
  );
};

export const useUpdateWorkLogVariable = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: any) => {
      return axiosJWT.post(
        server + "/api/a/update-worklog/",
        { data: { ...payload, workLogId: payload.workLogId } });
    },
    {
      onError: (error: any) => { },
      onSuccess: async () => {
        await queryClient.invalidateQueries("workLogs");
      },
    }
  );
};

export const useDeleteWorkLog = (workLogId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    () => {
      return axiosJWT.post(
        server + "/api/a/delete-worklog/",
        { data: { workLogId: workLogId } });
    },
    {
      onError: (error: any) => {
        throw error;
       },
      onSuccess: async () => {
        await queryClient.invalidateQueries("workLogs");
      },
    }
  );
};

export const useDeleteProfile = (profileId: string, windowId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    () => {
      return axiosJWT.post(
        server + "/api/a/delete-profile/",
        { data: { profileId: profileId } });
    },
    {
      onError: (error: any) => { },
      onSuccess: async () => {
        closeWindow(windowId);
        await queryClient.invalidateQueries("profiles");
      },
    }
  );
};

export const useDeleteDocument = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (documentId: any) => {
      return axiosJWT.post(
        server + "/api/a/delete-document/",
        { data: { documentId: documentId } });
    },
    {
      onError: (error: any) => { },
      onSuccess: async () => {
        await queryClient.invalidateQueries("documents");
        await queryClient.invalidateQueries("internalDocuments")
      },
    }
  );
};

export const useGenerateRapportblatt = () => {
  return useMutation(
    (payload: any) => {
      return axiosJWT.post(
        server + "/api/a/generate-rapportblatt/",
        { data: { jobId: payload.jobId, employeeId: payload.employeeId } });
    },
    {
      onError: (error: any) => { },
      onSuccess: async (data: any) => {
        window.open(data.data);
      },
    }
  );
};

export const useGenerateAuftragsbestaetigung = () => {
  return useMutation(
    (payload: any) => {
      return axiosJWT.post(
        server + "/api/a/generate-auftragsbestaetigung/",
        { data: { jobId: payload.jobId, employeeId: payload.employeeId } });
    },
    {
      onError: (error: any) => { },
      onSuccess: async (data: any) => {
        window.open(data.data);
      },
    }
  );
};

export const useUnlinkEmployee = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: any) => {
      return axiosJWT.post(
        server + "/api/a/unlink-employee",
        { data: { jobId: payload.jobId, employeeId: payload.employeeId } });
    },
    {
      onError: (error: any) => { },
      onSuccess: async (data: any) => {
        await queryClient.invalidateQueries("jobs");
        await queryClient.invalidateQueries("employees");
      }
    }
  );
};

export const useUpdateDocument = (documentId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: any) => {
      return axiosJWT.post(
        server + "/api/a/update-document",
        { data: { ...payload, documentId: documentId } });
    },
    {
      onError: (error: any) => { },
      onSuccess: async (data: any) => {
        await queryClient.invalidateQueries("documents");
      },
    }
  );
};

export const useUpdateInvoiceDate = (invoiceId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: any) => {
      return axiosJWT.post(
        server + "/api/a/update-invoice-date",
        { data: { ...payload, invoiceId: invoiceId } });
    },
    {
      onError: (error: any) => { },
      onSuccess: async (data: any) => {
        await queryClient.invalidateQueries("invoices-by-smallinvoice");
      },
    }
  );
};

export const useMarkInvoiceAsPaidInCash = (invoiceId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: any) => {
      return axiosJWT.post(
        server + "/api/a/markInvoiceAsPaidInCash",
        { data: { ...payload, invoiceId: invoiceId } });
    },
    {
      onError: (error: any) => { },
      onSuccess: async (data: any) => {
        await queryClient.invalidateQueries("invoices-by-smallinvoice");
      },
    }
  );
};

export const useSendByPost = (invoiceId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    () => {
      return axiosJWT.post(
        server + "/api/a/sendByPost",
        { data: { invoiceId: invoiceId } });
    },
    {
      onError: (error: any) => { },
      onSuccess: async (data: any) => {
        await queryClient.invalidateQueries("invoices-by-smallinvoice");
      },
    }
  );
};

export const useSendByEmail = (invoiceId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    () => {
      return axiosJWT.post(
        server + "/api/a/sendByEmail",
        { data: { invoiceId: invoiceId } });
    },
    {
      onError: (error: any) => { },
      onSuccess: async (data: any) => {
        await queryClient.invalidateQueries("invoices-by-smallinvoice");
      },
    }
  );
};

export const useDeleteInvoice = (invoiceId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    () => {
      return axiosJWT.post(
        server + "/api/a/delete-invoice",
        { data: { invoiceId: invoiceId } });
    },
    {
      onError: (error: any) => { },
      onSuccess: async (data: any) => {
        await queryClient.invalidateQueries("invoices-by-smallinvoice");
      },
    }
  );
};

export const useUpdateInvoice = (invoiceId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: any) => {
      return axiosJWT.post(
        server + "/api/a/update-invoice",
        { data: { ...payload, invoiceId: invoiceId } });
    },
    {
      onError: (error: any) => { },
      onSuccess: async (data: any) => {
        await queryClient.invalidateQueries("invoices-by-smallinvoice");
      },
    }
  );
};

export const useUpdateTagsByProfile = (profileId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: any) => {
      return axiosJWT.post(
        server + "/api/a/update-tags-by-profile",
        { data: { ...payload, profileId: profileId } });
    },
    {
      onError: (error: any) => { },
      onSuccess: async (data: any) => {
        await queryClient.invalidateQueries("tags");
      },
    }
  );
};

export const useUpdateTagsByDocument = (documentId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: any) => {
      return axiosJWT.post(
        server + "/api/a/update-tags-by-document",
        { data: { ...payload, documentId: documentId } });
    },
    {
      onError: (error: any) => { },
      onSuccess: async (data: any) => {
        await queryClient.invalidateQueries("tags");
      },
    }
  );
};

export const useCreateNewTag = (profileId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: any) => {
      return axiosJWT.post(
        server + "/api/a/create-new-tag",
        { data: { ...payload, profileId: profileId } });
    },
    {
      onError: (error: any) => { },
      onSuccess: async (data: any) => {
        await queryClient.invalidateQueries("tags");
      },
    }
  );
};

export const useCreateNewTagNote = (noteId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: any) => {
      return axiosJWT.post(
        server + "/api/a/create-new-tag-note",
        { data: { ...payload, noteId: noteId } });
    },
    {
      onError: (error: any) => { },
      onSuccess: async (data: any) => {
        await queryClient.invalidateQueries("tags");
      },
    }
  );
};

export const useCreateNewTagDocument = (documentId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: any) => {
      return axiosJWT.post(
        server + "/api/a/create-new-document-tag",
        { data: { ...payload, documentId: documentId } });
    },
    {
      onError: (error: any) => { },
      onSuccess: async (data: any) => {
        await queryClient.invalidateQueries("tags");
        await queryClient.invalidateQueries("documents");
      },
    }
  );
};

export const useUserCreate = (windowId?: string, form?: any, setForm?: any) => {
  const queryClient = useQueryClient();

  return useMutation(
    (form: any) => {
      return axiosJWT.post(
        server + "/api/a/create-user",
        { data: form });
    },
    {
      onError: (error: any) => {
        if (form && setForm) {
          setForm({
            ...form,
            errors: returnServerError(error).formErrors,
          });
        }
      },
      onSuccess: () => {
        if (windowId) closeWindow(windowId);
        queryClient.invalidateQueries("staff");
      },
    }
  );
};
