import {
  CommandBar,
  DefaultButton, IconButton,
  IPivotItemProps,
  IStackStyles,
  ITextStyles, Modal, Pivot, PivotItem, Stack,
  Text,
  useTheme
} from "@fluentui/react";
import Markdown from "markdown-to-jsx";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Toastify from 'toastify-js';
import "toastify-js/src/toastify.css";
import create from 'zustand';
import { triggerSignOut } from "../assets/functions";
import { Icons } from "../assets/icons";
import { useUpdateMyProfile } from "../assets/mutations";
import { useMyProfile, useMyTodos, useMyUser, usePlatformState, useUpdate } from "../assets/queries";
import { useClientStore } from "../assets/stores";
import { addWindow, closeWindow } from "../assets/windows";
import { Logo, PushDown } from "../components/misc";
import { UploadDocument } from "../views/Document";
import Tiles from "../wrappers/Tiles";
const { getState, setState } = useClientStore;
const p = require("../../package.json")

const Dashboard: React.FC = () => {
  return (
    <Stack>
      <Stack verticalFill styles={{ root: { minHeight: "100vh" } }}>
        <Header />
        <Content />
        <Footer />
      </Stack>
    </Stack>
  );
};

export default Dashboard;

const Header: React.FC = () => {
  const myProfile = useMyProfile()
  const [showUpdate, setShowUpdate] = useState(false)
  const updateMyProfile = useUpdateMyProfile()
  const theme = useTheme()
  const update = useUpdate()

  useEffect(() => {
    if (myProfile.isSuccess && update.data?.data?.attributes?.version && myProfile.data?.versionLastUsed !== update.data?.data?.attributes?.version) {
      setShowUpdate(true)
    } else {
      setShowUpdate(false)
    }
  }, [myProfile.isSuccess, update.isRefetching])

  return (
    <Stack>
      <Modal isOpen={showUpdate} styles={{ main: { borderRadius: 16, height: "50vh", width: "50vw" } }}>
        <Stack style={{ padding: 30 }}>
          <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 10 }}>
            <DefaultButton styles={{ root: { borderRadius: 16 } }} primary onClick={() => {
              setShowUpdate(false)
              Swal.fire({
                buttonsStyling: false,
                title: "Sicher?",
                text: "Du verlierst alle nicht gespeicherten Änderungen.",
                confirmButtonText: "Ja, jetzt neu laden",
                cancelButtonText: "Abbrechen",
                showCancelButton: true,
              }).then(async (event: any) => {
                if (event.isConfirmed) {
                  await updateMyProfile.mutateAsync({ versionLastUsed: update.data?.data?.attributes?.version })
                  window.location.reload()
                }
              })
            }}>Jetzt aktualisieren</DefaultButton>

            <DefaultButton styles={{ root: { borderRadius: 16 } }} onClick={() => {
              updateMyProfile.mutate({ versionLastUsed: update.data?.data?.attributes?.version })
              setShowUpdate(false)
            }}>Später aktualisieren</DefaultButton>
          </Stack>

          <Text style={{ fontSize: 20, fontWeight: 500 }}>Neu in Version {update.data?.data?.attributes?.version}</Text>

          <br />

          <Markdown>{update.data?.data?.attributes?.content}</Markdown>

        </Stack>
      </Modal>

      <Stack
        grow
        horizontal
        styles={{
          root: {
            background: theme.palette.themePrimary,
            borderBottom: theme.palette.themeLighterAlt,
            padding: "0px 15px",
            position: "fixed",
            width: "100%",
            zIndex: 100,
          },
        }}
        tokens={{ childrenGap: 10 }}
      >
        <Logo type="text" />
        <Menu />
      </Stack>
    </Stack>
  );
};

const Menu: React.FC = () => {
  const myProfile = useMyProfile();
  const myTodos = useMyTodos("notDone");
  const state = usePlatformState()

  useEffect(() => {
    if (!state.data?.pingenIsWorking && localStorage.shownPingenNotWorking === "false") {
      Toastify({
        text: "Postversand mit pingen.com meldet: Kein Guthaben mehr!",
        duration: 3000,
        destination: "https://pingen.com",
        newWindow: true,
        close: true,
        gravity: "top",
        position: "center",
        stopOnFocus: true,
        onClick: function () {
          localStorage.shownPingenNotWorking = "true"
        }
      }).showToast();
    }
    if (myTodos.data?.length > 0) {
      Swal.fire({
        buttonsStyling: false,
        showConfirmButton: true,
        text: "Du hast unerledigte Notizen.",
        title: "Zu erledigen",
      }).then(() => {
        addWindow.Note.viewTodos();
      });
    }
  }, [myTodos.isSuccess]);

  const items = () => {
    return [
      {
        buttonStyles: { root: { background: theme.palette.themePrimary, color: theme.palette.black } },
        iconProps: { iconName: Icons.Search, style: { color: theme.palette.black } },
        key: "search",
        onClick: () => addWindow.Search(),
        text: "Suche",
      },
      {
        buttonStyles: {
          root: { background: theme.palette.themePrimary, color: theme.palette.black }
        },
        iconProps: { iconName: Icons.Create, style: { color: theme.palette.black } },
        key: "create",
        text: "Neu",
        subMenuProps: {
          styles: { root: { background: "transparent" } },
          items: [
            {
              iconProps: { iconName: Icons.Note },
              key: "note",
              text: "Notiz",
              onClick: () =>
                addWindow.Note.add(
                  myProfile.data?.id,
                  myProfile.data?.id,
                  "profile",
                  true
                ),
            },
            {
              iconProps: { iconName: Icons.Documents },
              key: "document",
              text: "Dokument",
              onClick: () => {
                setShowUploadDocument(true)
                // addWindow.Document.upload(myProfile.data?.id, "internal"),
              }
            },
            {
              iconProps: { iconName: Icons.PublicEmployer },
              key: "publicEmployer",
              text: "Auftraggeber*in (öffentlich)",
              onClick: () => addWindow.Profile.create("publicEmployer"),
            },
            {
              iconProps: { iconName: Icons.PrivateEmployer },
              key: "privateEmployer",
              text: "Auftraggeber*in (privat)",
              onClick: () => addWindow.Profile.create("privateEmployer"),
            },
            {
              iconProps: { iconName: Icons.Employee },
              key: "employee",
              text: "Jugendliche*r",
              onClick: () => addWindow.Profile.create("employee"),
            },
          ],
        },
      },
      {
        buttonStyles: { root: { background: theme.palette.themePrimary, color: theme.palette.black } },
        iconProps: { iconName: Icons.Accounting, style: { color: theme.palette.black } },
        key: "accounting",
        onClick: () => addWindow.Accounting.view(""),
        text: "Buchhaltung",
      },
      {
        buttonStyles: { root: { background: theme.palette.themePrimary, color: theme.palette.black } },
        iconProps: { iconName: Icons.Export, style: { color: theme.palette.black } },
        key: "export",
        onClick: () => {
          addWindow.Export.new();
        },
        text: "Export",
      },
      {
        buttonStyles: { root: { background: theme.palette.themePrimary, color: theme.palette.black } },
        iconProps: { iconName: Icons.Cancel, style: { color: theme.palette.black } },
        key: "closeAll",
        onClick: () => {
          let windows = getState().openWindows;

          windows.forEach((window: any) => {
            closeWindow(window.window.id);
          });
        },
        text: "Tabs schliessen",
      },
    ];
  };

  const theme = useTheme()
  const [showUploadDocument, setShowUploadDocument] = useState(false)

  return (
    <Stack grow horizontal>
      <Stack grow>
        <Modal isOpen={showUploadDocument} onDismiss={() => setShowUploadDocument(false)} styles={{ main: { borderRadius: 16, height: "45vh", width: "50vw" } }}>
          <UploadDocument modelId={myProfile.data?.id} modelType="internal" windowId={"uploadDocument_" + myProfile.data?.id} />
        </Modal>

        <CommandBar
          styles={{ root: { background: theme.palette.themePrimary } }}
          items={items()}
          farItems={[
            {
              buttonStyles: { root: { background: theme.palette.themePrimary, color: theme.palette.black } },
              iconProps: { iconName: Icons.Organisation, style: { color: theme.palette.black } },
              key: "todos",
              onClick: () => addWindow.Note.viewTodos(),
              text: "Zu erledigen (" + myTodos.data?.length + ")",
            },
            {
              iconProps: { iconName: Icons.Organisation, style: { color: theme.palette.black } },
              key: "manage",
              onClick: () => addWindow.Account.me(),
              text: "Verwaltung",
              buttonStyles: { root: { color: theme.palette.black, background: theme.palette.themePrimary } }
            },
            {
              iconProps: { iconName: Icons.SignOut, style: { color: "red" } },
              buttonStyles: { root: { background: theme.palette.themePrimary } },
              key: "signOut",
              onClick: () => {
                Swal.fire({
                  buttonsStyling: false,
                  confirmButtonText: "Abmelden",
                  cancelButtonText: "Abbrechen",
                  focusCancel: true,
                  showCancelButton: true,
                  title: "Meldung",
                  text: "Willst du dich wirklich abmelden?",
                  reverseButtons: true,
                }).then((event: any) => {
                  if (event.isConfirmed) {
                    triggerSignOut();
                  }
                });
              },
            },
          ]}
        />
      </Stack>
    </Stack>
  );
};

const Content: React.FC = () => {
  // styles
  const theme = useTheme();
  const useStore = create(useClientStore)
  const darkmode = useStore((store: any) => store.darkmode)
  const openWindows = useStore((state) => state.openWindows);
  const currentlyFocusedWindowId = useStore((state) => state.currentlyFocusedWindowId)

  function _customRenderer(
    link?: IPivotItemProps,
    defaultRenderer?: (link?: IPivotItemProps) => JSX.Element | null,
  ): JSX.Element | null {
    if (!link || !defaultRenderer) {
      return null;
    }

    return (
      <span style={{ flex: '0 1 100%' }}>
        {defaultRenderer({ ...link, itemIcon: undefined })}
        <IconButton styles={{ root: { borderRadius: 16, } }} iconProps={{ iconName: "ChromeClose" }} onClick={() => {
          closeWindow(link?.itemKey!)
        }} style={{ marginTop: 7, marginLeft: 10 }} />
      </span>
    );
  }

  function _customRenderer2(
    link?: IPivotItemProps,
    defaultRenderer?: (link?: IPivotItemProps) => JSX.Element | null,
  ): JSX.Element | null {
    if (!link || !defaultRenderer) {
      return null;
    }

    return (
      <span style={{ flex: '0 1 100%' }}>
        {defaultRenderer({ ...link, itemIcon: undefined })}
        <IconButton styles={{ root: { borderRadius: 16 } }} iconProps={{ iconName: "CheckMark" }} onClick={() => { closeWindow(link?.itemKey!) }}
          style={{ marginTop: 7, marginLeft: 10 }} />
      </span>
    );
  }

  return (
    <Stack
      grow
      styles={{
        root: {
          background: darkmode ? theme.palette.blackTranslucent40 : theme.palette.neutralLighter,
          minHeight: "100vh",
          paddingBottom: 100,
        },
      }}
      tokens={{ childrenGap: 10 }}
      wrap
    >
      <Stack>
        <Pivot
          overflowBehavior="menu"
          selectedKey={currentlyFocusedWindowId}
          onLinkClick={(item: any) => {
            if (item.props.itemKey === "search") {
              setState({ searchIsActiveTab: true })
            } else {
              setState({ searchIsActiveTab: false })
            }

            let windowOrder = getState().windowOrder

            windowOrder = windowOrder.filter((id: any) => {
              return id !== getState().lastClickedWindowId
            })

            if (windowOrder.indexOf(getState().lastClickedWindowId) === -1) {
              windowOrder.push(getState().lastClickedWindowId)
            }

            setState({
              windowOrder: windowOrder,
              currentlyFocusedWindowId: item?.props.itemKey,
              lastClickedWindowId: item?.props.itemKey
            })
          }}
          styles={{
            root: {
              background: theme.palette.neutralLighter,
              position: "fixed",
              top: 44,
              zIndex: 100000,
              width: "100%"
            },
          }}
        >
          <PivotItem headerText="Dashboard" itemKey="tiles" onRenderItemLink={_customRenderer2}>
            <PushDown by={70} />
            <Stack styles={{ root: { maxWidth: "100vw", padding: 10 } }}>
              <Tiles />
            </Stack>
          </PivotItem>

          {openWindows.map(({ window }: any) => {
            return <PivotItem key={window.id} alwaysRender headerText={window.title} itemKey={window.id} onRenderItemLink={_customRenderer}>
              <PushDown by={100} />
              {window.content}
            </PivotItem>
          })}
        </Pivot>
      </Stack>
    </Stack >
  );
};

const Footer: React.FC = () => {
  const myUser: any = useMyUser();
  const myProfile: any = useMyProfile();

  const loggedInAs = () => {
    return (
      (myProfile.data?.firstName ? myProfile.data?.firstName + " " : "") +
      (myProfile.data?.lastName ? myProfile.data?.lastName + ", " : "") +
      myUser.data?.role?.description +
      ", Standort " +
      (myProfile.data?.jobfair?.text
        ? myProfile.data?.jobfair?.text
        : "nicht zugeordnet")
    );
  };

  // styles
  const theme = useTheme();
  const footer: IStackStyles = {
    root: {
      bottom: 0,
      position: "fixed",
      userSelect: "none",
      padding: 20,
      width: "100%",
      zIndex: 100,
    },
  };
  const text: ITextStyles = {
    root: { color: theme.palette.neutralDark },
  };

  return (
    <Stack horizontal styles={footer}>
      <Stack grow>
        <Text styles={text} variant="medium">
          jobfairy.ch
        </Text>
      </Stack>
      {myProfile.isSuccess && <Stack grow>
        <Text>{loggedInAs()}</Text>
      </Stack>}
    </Stack>
  );
};
