import { Label, Link, Stack, Text, useTheme } from "@fluentui/react";
import { useEffect, useState } from "react";
import { returnErrorsToReset, returnServerError } from "../assets/functions";
import { useCreateWorkLog } from "../assets/mutations";
import { useEmployeeById, useJobById } from "../assets/queries";
import { myColumnRight } from "../assets/styles";
import { addWindow, closeWindow } from "../assets/windows";
import Create from "../components/forms/Create";
import { FormColumnLeft } from "../components/forms/Parts";
import { MutationProgressIndicator, PushDown } from "../components/misc";
import { ViewContent, ViewFooter } from "./Parts";

const ViewCreateWorkLog: React.FC<{
  employeeId: string;
  jobId: string;
  setShowCreateWorkLog: any;
  windowId: string;
}> = ({ employeeId, jobId, windowId, setShowCreateWorkLog }) => {
  const job = useJobById(jobId);
  const employee = useEmployeeById(employeeId);
  const [form, setForm] = useState<any>({});
  const [formErrors, setFormErrors] = useState<any>({
    error: undefined,
    formErrors: undefined,
    isKnownError: false,
    isMultiline: false,
    message: "Unbekannter Fehler",
    secondaryMessage: undefined,
    type: "severeWarning",
  });
  const mutation = useCreateWorkLog(employeeId, jobId);
  const theme = useTheme()

  const change = (updatedFields: any, oldForm: any = form) => {
    setForm({ ...oldForm!, ...updatedFields });
    setFormErrors({
      ...formErrors,
      formErrors: {
        ...formErrors?.formErrors,
        ...returnErrorsToReset(updatedFields),
      },
    });
  };

  useEffect(() => {
    if (mutation.isSuccess) closeWindow(windowId);
    if (mutation.isError) setFormErrors(returnServerError(mutation.error));
  }, [mutation.error, mutation.isError, mutation.isSuccess, windowId]);

  // styles
  const stackStyles: any = { root: { width: "100%" } };
  const textFieldStyle: any = { root: { width: "100%" } };

  return (
    <Stack styles={{ root: { padding: 10, borderRadius: 16 } }}>
      <Stack styles={{ root: { background: theme.palette.white, borderRadius: 16, padding: 20 } }}>
        <ViewContent>
          <MutationProgressIndicator mutationQuery={mutation} />

          <Create
            form={form}
            formErrors={formErrors?.formErrors}
            onChange={(updatedFields: any) => change(updatedFields, form)}
            type="workLog"
          />

          <PushDown by={20} />

          <Stack grow horizontal styles={stackStyles}>
            <FormColumnLeft label="Zugehörigkeit" />

            <Stack grow horizontal styles={myColumnRight}>
              <Text styles={textFieldStyle}>
                <Label>Job</Label>
                <Link onClick={() => addWindow.Job.view(job.data?.id)}>
                  {job.data?.title}
                </Link>
              </Text>

              <Text styles={textFieldStyle}>
                <Label>Jugendliche*r</Label>
                <Link onClick={() => addWindow.Profile.view(employee.data?.id)}>
                  {employee.data?.firstName + " " + employee.data?.lastName}
                </Link>
              </Text>
            </Stack>
          </Stack>
        </ViewContent>

        <ViewFooter
          onCreate={async () => {
            await mutation.mutateAsync(form)
            setShowCreateWorkLog(false)
          }}
          mutationQuery={mutation}
        />
      </Stack>
    </Stack>
  );
};

export default ViewCreateWorkLog;
