import axios from "axios";
import { useQuery } from "react-query";
import axiosJWT from "./axiosjwt";
import { triggerSignOut } from "./functions";
const server = process.env.REACT_APP_SERVER;
const jwt = localStorage.jwt;

export const useCategories = () => {
  return useQuery(
    "categories",
    async () => {
      const res: any = await axiosJWT.get(server + "/api/a/get-categories/");

      let sorted = res.data.sort((a: any, b: any) => {
        if (a.text.toLowerCase() < b.text.toLowerCase()) return -1;
        if (a.text.toLowerCase() > b.text.toLowerCase()) return 1;
        return 0;
      });

      return sorted;
    },
    {
      onError: (error: any) => error,
      onSuccess: (data: any) => data,
    }
  );
};

export const useContact = (contactId: string) => {
  return useQuery(
    ["contacts", { contactId: contactId }],
    async () => {
      const res: any = await axiosJWT.post(
        server + "/api/a/get-contact-by-id/",
        { data: { contactId: contactId } });

      return res.data;
    },
    {
      onError: (error: any) => error,
      onSuccess: (data: any) => data,
    }
  );
};

export const useFrequencies = () => {
  return useQuery(
    "frequencies",
    async () => {
      const res: any = await axiosJWT.get(server + "/api/a/get-frequencies/");

      let sorted = res.data.sort((a: any, b: any) => {
        if (a.text.toLowerCase() < b.text.toLowerCase()) return -1;
        if (a.text.toLowerCase() > b.text.toLowerCase()) return 1;
        return 0;
      });

      return sorted;
    },
    {
      onError: (error: any) => error,
      onSuccess: (data: any) => data,
    }
  );
};

export const useJobById = (jobId: string) => {
  return useQuery(
    ["jobs", { jobId: jobId }],
    async () => {
      const res: any = await axiosJWT.post(
        server + "/api/a/get-job-by-id/",
        { data: { jobId: jobId } });

      return res.data;
    },
    {
      onError: (error: any) => error,
      onSuccess: (data: any) => data,
    }
  );
};

export const useJobs = (limit?: number) => {
  return useQuery(
    "jobs",
    async () => {
      const res: any = await axiosJWT.post(
        server + "/api/a/get-jobs",
        { data: { limit: limit } });

      return res.data;
    },
    {
      onError: (error: any) => error,
      onSuccess: (data: any) => data,
    }
  );
};

export const useEmployeeById = (employeeId: string) => {
  return useQuery(
    ["employees", { employeeId: employeeId }],
    async () => {
      const res: any = await axiosJWT.post(
        server + "/api/a/get-employee-by-id",
        { data: { employeeId: employeeId } });

      return res.data;
    },
    {
      onError: (error: any) => error,
      onSuccess: (data: any) => data,
    }
  );
};

export const useWorkLog = (workLogId: string) => {
  return useQuery(
    ["workLogs", { workLogId: workLogId }],
    async () => {
      const res: any = await axiosJWT.post(
        server + "/api/a/get-work-log-by-id",
        { data: { workLogId: workLogId } });

      return res.data;
    },
    {
      onError: (error: any) => error,
      onSuccess: (data: any) => data,
    }
  );
};

export const useJobsByEmployer = (employerId: string) => {
  return useQuery(
    ["jobs", { employerId: employerId }],
    async () => {
      const res: any = await axiosJWT.post(
        server + "/api/a/get-jobs-by-employer",
        { data: { employerId: employerId } });

      return res.data;
    },
    {
      onError: (error: any) => error,
      onSuccess: (data: any) => data,
    }
  );
};

export const useJobsByEmployee = (employeeId: string) => {
  return useQuery(
    ["jobs", { employeeId: employeeId }],
    async () => {
      const res: any = await axiosJWT.post(
        server + "/api/a/get-jobs-by-employee",
        { data: { employeeId: employeeId } });

      return res.data;
    },
    {
      onError: (error: any) => error,
      onSuccess: (data: any) => data,
    }
  );
};

export const useJobfairs = () => {
  return useQuery(
    "jobfairs",
    async () => {
      const res: any = await axiosJWT.get(server + "/api/a/get-jobfairs")

      return res.data;
    },
    {
      onError: (error: any) => error,
      onSuccess: (data: any) => data,
    }
  );
};

export const useLanguages = () => {
  return useQuery(
    "languages",
    async () => {
      const res: any = await axiosJWT.get(server + "/api/a/get-languages")

      return res.data;
    },
    {
      onError: (error: any) => error,
      onSuccess: (data: any) => data,
    }
  );
};

export const useMyUser = () => {
  return useQuery(
    "myUser",
    async () => {
      const res = await axiosJWT.get(server + "/api/a/user/my");

      return res.data;
    },
    {
      onError: (error: any) => triggerSignOut(),
      onSuccess: (data: any) => {
        if (data.setting.logMeOut) return triggerSignOut();
        return data;
      },
      refetchInterval: 60000,
    }
  );
};

export const useUser = (profileId: string) => {
  return useQuery(
    ["user", { profileId: profileId }],
    async () => {
      const res = await axiosJWT.post(
        server + "/api/a/get-user",
        { data: { profileId: profileId } }
      );

      return res.data;
    },
    {
      onError: (error: any) => console.log(error),
      onSuccess: (data: any) => data,
      refetchInterval: 60000,
    }
  );
};

export const useMyProfile = () => {
  return useQuery(
    "myProfile",
    async () => {
      const res: any = await axiosJWT.get(server + "/api/a/profile/my")

      return res.data;
    },
    {
      onError: (error: any) => error,
      onSuccess: (data: any) => data,
    }
  );
};

export const useAssignments = () => {
  return useQuery(
    "assignments",
    async () => {
      const res: any = await axiosJWT.get(server + "/api/a/get-assignments");

      return res.data;
    },
    {
      onError: (error: any) => error,
      onSuccess: (data: any) => data,
    }
  );
};

export const useProfile = (profileId: string) => {
  return useQuery(
    ["profiles", { profileId: profileId }],
    async () => {
      const res: any = await axiosJWT.post(
        server + "/api/a/get-profile-by-id/",
        { data: { profileId: profileId } });

      return res.data;
    },
    {
      onError: (error: any) => error,
      onSuccess: (data: any) => data,
    }
  );
};

export const useProfiles = (type: string, limit?: number) => {
  return useQuery(
    ["profiles", { type: type }],
    async () => {
      const res: any = await axiosJWT.post(
        server + "/api/a/get-profiles",
        { data: { type: type, limit: limit } });

      return res.data;
    },
    {
      onError: (error: any) => error,
      onSuccess: (data: any) => { },
    }
  );
};

export const useWorkLogs = (limit?: number) => {
  return useQuery(
    "workLogs",
    async () => {
      const res: any = await axiosJWT.post(
        server + "/api/a/get-work-logs/",
        { data: { limit: limit } });

      return res.data;
    },
    {
      onError: (error: any) => error,
      onSuccess: (data: any) => data,
    }
  );
};

export const useEmployeesByJob = (jobId: string) => {
  return useQuery(
    ["employees", { jobId: jobId }],
    async () => {
      const res: any = await axiosJWT.post(
        server + "/api/a/get-employees-by-job/",
        { data: { jobId: jobId } });

      return res.data;
    },
    {
      onError: (error: any) => error,
      onSuccess: (data: any) => data,
    }
  );
};

export const useTags = (excludeLanguages?: boolean) => {
  return useQuery(
    ["tags", { excludeLanguages: excludeLanguages }],
    async () => {
      const res: any = await axiosJWT.get(server + "/api/a/get-tags/")

      if (excludeLanguages) {
        let tags = []
        tags = res.data.filter((tag: any) => {
          return !tag.keep
        })

        return tags
      }

      return res.data;
    },
    {
      onError: (error: any) => error,
      onSuccess: (data: any) => data,
    }
  );
};

export const useLastOpened = () => {
  return useQuery(
    ["lastOpened"],
    () => {
      if (!localStorage.lastOpened) {
        localStorage.lastOpened = JSON.stringify([])
      }

      return JSON.parse(localStorage.lastOpened)
    },
    {
      onError: (error: any) => error,
      onSuccess: (data: any) => data,
    }
  );
};

export const useDailyPayoutsByLocation = () => {
  return useQuery(
    ["dailyPayouts"],
    async () => {
      const res: any = await axiosJWT.get(
        server + "/api/a/getDailyPayoutsByLocation/")

      return res.data;
    },
    {
      onError: (error: any) => error,
      onSuccess: (data: any) => data,
    }
  );
};

export const useTagsByProfile = (profileId: string) => {
  return useQuery(
    ["tags", { profileId: profileId }],
    async () => {
      const res: any = await axiosJWT.post(
        server + "/api/a/get-tags-by-profile/",
        { data: { profileId: profileId } });

      return res.data;
    },
    {
      onError: (error: any) => error,
      onSuccess: (data: any) => data,
    }
  );
};

export const useTagsByNote = (noteId: string) => {
  return useQuery(
    ["tags", { noteId: noteId }],
    async () => {
      const res: any = await axiosJWT.post(
        server + "/api/a/get-tags-by-note/",
        { data: { noteId: noteId } });

      return res.data;
    },
    {
      onError: (error: any) => error,
      onSuccess: (data: any) => data,
    }
  );
};

export const useTagsByDocument = (documentId: string) => {
  return useQuery(
    ["tags", { documentId: documentId }],
    async () => {
      const res: any = await axiosJWT.post(
        server + "/api/a/get-tags-by-document/",
        { data: { documentId: documentId } });

      return res.data;
    },
    {
      onError: (error: any) => error,
      onSuccess: (data: any) => data,
    }
  );
};

export const useWorkLogsByJob = (jobId: string) => {
  return useQuery(
    ["workLogs", { jobId: jobId }],
    async () => {
      const res: any = await axiosJWT.post(
        server + "/api/a/get-work-logs-by-job/",
        { data: { jobId: jobId } });

      return res.data;
    },
    {
      onError: (error: any) => error,
      onSuccess: (data: any) => data,
    }
  );
};

export const useWorkLogsByInvoice = (invoiceId: string) => {
  return useQuery(
    ["workLogs", { invoiceId: invoiceId }],
    async () => {
      const res: any = await axiosJWT.post(
        server + "/api/a/get-work-logs-by-invoice/",
        { data: { invoiceId: invoiceId } });

      return res.data;
    },
    {
      onError: (error: any) => error,
      onSuccess: (data: any) => data,
    }
  );
};

export const useCreditByEmployee = (employeeId: string) => {
  return useQuery(
    ["credit", { employeeId: employeeId }],
    async () => {
      const res = await axiosJWT.post(
        server + "/api/a/get-credits-by-employee",
        { data: { employeeId: employeeId } });

      return res.data;
    },
    {
      onError: (error: any) => error,
      onSuccess: async (data: any) => data,
    }
  );
};

export const useReceiptsByEmployee = (employeeId: string) => {
  return useQuery(
    ["receipts", { employeeId: employeeId }],
    async () => {
      const res = await axiosJWT.post(
        server + "/api/a/get-receipts-by-employee",
        { data: { employeeId: employeeId } });

      return res.data;
    },
    {
      onError: (error: any) => error,
      onSuccess: async (data: any) => data,
    }
  );
};

export const useReceiptsByJob = (jobId: string) => {
  return useQuery(
    ["receipts", { jobId: jobId }],
    async () => {
      const res = await axiosJWT.post(
        server + "/api/a/getReceiptsByJob",
        { data: { jobId: jobId } });

      return res.data;
    },
    {
      onError: (error: any) => error,
      onSuccess: async (data: any) => data,
    }
  );
};

export const useReceiptsByInvoice = (invoiceId: string) => {
  return useQuery(
    ["receipts", { invoiceId: invoiceId }],
    async () => {
      const res = await axiosJWT.post(
        server + "/api/a/getReceiptsByInvoice",
        { data: { invoiceId: invoiceId } });

      return res.data;
    },
    {
      onError: (error: any) => error,
      onSuccess: async (data: any) => data,
    }
  );
};

export const useReceipts = (limit: number) => {
  return useQuery(
    "receipts",
    async () => {
      const res = await axiosJWT.post(server + "/api/a/get-all-receipts",
        { data: { limit: limit } });

      return res.data;
    },
    {
      onError: (error: any) => error,
      onSuccess: async (data: any) => data,
    }
  );
};

export const usePossibleDebtByEmployer = (employerId: string) => {
  return useQuery(
    ["possible-debt", { employerId: employerId }],
    async () => {
      const res = await axiosJWT.post(
        server + "/api/a/get-possible-debt-by-employer",
        { data: { employerId: employerId } });

      return res.data;
    },
    {
      onError: (error: any) => error,
      onSuccess: async (data: any) => data,
    }
  );
};

export const useInvoiceBySmallInvoice = (invoiceId: string) => {
  return useQuery(
    ["invoices-by-smallinvoice", { invoiceId: invoiceId }],
    async () => {
      const res = await axiosJWT.get(
        server + "/api/a/invoices-by-smallinvoice/" + invoiceId);

      let invoice = res.data;

      return invoice;
    },
    {
      onError: (error: any) => console.log(error),
      onSuccess: (data) => data,
    }
  );
};

export const useConditions = () => {
  return useQuery(
    "conditions",
    async () => {
      const res = await axiosJWT.get(server + "/api/a/get-conditions");

      const conditions = res.data;

      return conditions;
    },
    {
      onError: (error: any) => console.log(error),
      onSuccess: (data: any) => data,
    }
  );
};

export const useInvoicesBySmallInvoice = () => {
  return useQuery(
    "invoices-by-smallinvoice",
    async () => {
      const res = await axiosJWT.get(server + "/api/a/invoices-by-smallinvoice")

      return res.data
    },
    {
      onError: (error: any) => console.log(error),
      onSuccess: (data) => data,
    }
  );
};

export const useUpdate = () => {
  return useQuery("update", async () => {
    const res: any = await axios.get(process.env.REACT_APP_SERVER + "/api/update")

    return res.data
  }, {
    onError: (error) => console.log(error),
    onSuccess: (data) => data,
    refetchOnWindowFocus: true
  })
}

export const useInvoicesByEmployerSmallInvoice = (employerId: string) => {
  return useQuery(
    ["invoices-by-smallinvoice", { employerId: employerId }],
    async () => {
      const res = await axiosJWT.post(
        server + "/api/a/invoices-by-smallinvoice-by-employer",
        { data: { employerId: employerId } });

      return res.data;
    },
    {
      onError: (error: any) => console.log(error),
      onSuccess: (data) => data,
    }
  );
};

export const useInvoicesByJob = (jobId: string) => {
  return useQuery(
    ["invoices-by-smallinvoice", { jobId: jobId }],
    async () => {
      const res = await axiosJWT.post(
        server + "/api/a/invoicesBySmallInvoiceByJob",
        { data: { jobId: jobId } });

      return res.data;
    },
    {
      onError: (error: any) => console.log(error),
      onSuccess: (data) => data,
    }
  );
};

export const useNoteById = (noteId: string) => {
  return useQuery(
    ["notes", { noteId: noteId }],
    async () => {
      const res = await axiosJWT.post(
        server + "/api/a/get-note-by-id/",
        { data: { noteId: noteId } });
      const note = res.data;
      return note;
    },
    {
      onError: (error: any) => console.log(error),
      onSuccess: (data: any) => data,
    }
  );
};

export const useNotesByType = (modelId: string, modelType: string) => {
  return useQuery(
    ["notes", { modelId: modelId }],
    async () => {
      const res = await axiosJWT.post(
        server + "/api/a/get-notes-by-type",
        { data: { modelId: modelId, modelType: modelType } });

      const notes = res.data;

      return notes;
    },
    {
      onError: (error: any) => console.log(error),
      onSuccess: (data: any) => data,
    }
  );
};

export const useMyNotes = (limit?: number) => {
  return useQuery(
    "myNotes",
    async () => {
      const res = await axiosJWT.post(
        server + "/api/a/get-my-notes",
        { data: { limit: limit } });

      const notes = res.data;

      return notes;
    },
    {
      onError: (error: any) => console.log(error),
      onSuccess: (data: any) => data,
    }
  );
};

export const useMyTodos = (
  type?: "all" | "done" | "notDone" | "unconfirmedRegistrations",
  limit?: number
) => {
  return useQuery(
    ["myTodos", { type: type }],
    async () => {
      const res = await axiosJWT.post(
        server + "/api/a/get-my-todos",
        { data: { limit: limit } });

      const notes: any = res.data;

      let doneTodos: any = [];
      let todos: any = [];
      let all: any = [];

      notes.forEach((note: any) => {
        if (note.type === "done") {
          doneTodos.push(note);
          all.push(note);
        } else if (note.type === "notDone") {
          todos.push(note);
          all.push(note);
        } else {
          all.push(note);
        }
      });

      if (type === "done") return doneTodos;
      if (type === "notDone") return todos;
      return all;
    },
    {
      onError: (error: any) => console.log(error),
      onSuccess: (data: any) => data,
    }
  );
};

export const useUnconfirmedRegistrations = (
) => {
  return useQuery(
    ["unconfirmedRegistrations"],
    async () => {
      const res = await axiosJWT.get(
        server + "/api/a/getUnconfirmedRegistrations")

      const unconfirmedRegistrations: any = res.data;

      return unconfirmedRegistrations
    },
    {
      onError: (error: any) => console.log(error),
      onSuccess: (data: any) => data,
    }
  );
};

export const useInternalDocuments = (limit?: number) => {
  return useQuery(
    "internalDocuments",
    async () => {
      const res = await axiosJWT.post(
        server + "/api/a/get-internal-documents",
        { data: { limit: limit } });

      return res.data;
    },
    {
      onError: (error: any) => console.log(error),
      onSuccess: (data: any) => data,
    }
  );
};

export const useHistory = (modelType: string, modelId: string) => {
  return useQuery(
    ["history", { modelType: modelType, modelId: modelId }],
    async () => {
      const res = await axiosJWT.post(
        server + "/api/a/getHistory",
        { data: { modelType: modelType, modelId: modelId } });

      return res.data;
    },
    {
      onError: (error: any) => console.log(error),
      onSuccess: (data: any) => data,
    }
  );
};

export const usePlatformState = () => {
  return useQuery(
    "state",
    async () => {
      const res = await axiosJWT.get(
        server + "/api/state")

      return res.data;
    },
    {
      onError: (error: any) => console.log(error),
      onSuccess: (data: any) => data,
    }
  );
};

export const useUnassignedJobs = (limit?: number) => {
  return useQuery(
    ["jobs", { type: "unassigned" }],
    async () => {
      const res: any = await axiosJWT.post(
        server + "/api/a/get-jobs",
        { data: { limit: limit } });

      let jobs = res.data;

      let sanitizedJobs: any = [];

      jobs.forEach((job: any) => {
        if (job?.employees?.length === 0) sanitizedJobs.push(job);
      });

      sanitizedJobs = sanitizedJobs.sort((a: any, b: any) => {
        return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      })

      return sanitizedJobs;
    },
    {
      onError: (error: any) => error,
      onSuccess: (data: any) => data,
    }
  );
};

export const useWorkLogsByEmployee = (employeeId: string) => {
  return useQuery(
    ["workLogs", { employeeId: employeeId }],
    async () => {
      const res = await axiosJWT.post(
        server + "/api/a/get-worklogs-by-employee",
        { data: { employeeId: employeeId } });

      return res.data;
    },
    {
      onError: (error: any) => console.log(error),
      onSuccess: (data: any) => data,
    }
  );
};

export const useWorkLogsByEmployer = (employerId: string) => {
  return useQuery(
    ["workLogs", { employerId: employerId }],
    async () => {
      const res = await axiosJWT.post(
        server + "/api/a/get-worklogs-by-employer",
        { data: { employerId: employerId } });

      return res.data;
    },
    {
      onError: (error: any) => console.log(error),
      onSuccess: (data: any) => data,
    }
  );
};

export const useDocumentsByType = (modelId: string, modelType: string) => {
  return useQuery(
    ["documents", { modelId: modelId }],
    async () => {
      const res = await axiosJWT.post(
        server + "/api/a/get-documents-by-type",
        { data: { modelId: modelId, modelType: modelType } });

      return res.data;
    },
    {
      onError: (error: any) => console.log(error),
      onSuccess: (data: any) => data,
    }
  );
};

export const useDocumentById = (documentId: string) => {
  return useQuery(
    ["documents", { documentId: documentId }],
    async () => {
      const res = await axiosJWT.post(
        server + "/api/a/get-document-by-id",
        { data: { documentId: documentId } });

      return res.data;
    },
    {
      onError: (error: any) => console.log(error),
      onSuccess: (data: any) => data,
    }
  );
};

export const useStaff = () => {
  return useQuery(
    "staff",
    async () => {
      const res = await axiosJWT.get(server + "/api/a/get-staff");

      const staff = res.data;

      return staff;
    },
    {
      onError: (error: any) => console.log(error),
      onSuccess: (data: any) => data,
    }
  );
};
