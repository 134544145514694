import { css } from "@emotion/css";
import {
  Pivot,
  PivotItem,
  SpinButton,
  Stack,
  Text,
  Toggle,
  useTheme
} from "@fluentui/react";
import { useEffect, useState } from "react";
import create from "zustand";
import { Icons } from "../assets/icons";
import { useUpdateProfile, useUpdateUser } from "../assets/mutations";
import { useJobfairs, useProfile, useUser } from "../assets/queries";
import { useClientStore } from "../assets/stores";
import {
  FieldDropdownUpdate,
  FieldStringUpdate,
  FieldToggleUpdate
} from "../components/forms/Parts";
import {
  MutationProgressIndicator,
  PushDown,
  QueryProgressIndicator
} from "../components/misc";
import { ViewContent } from "./Parts";

const ViewAccount: React.FC<{
  profileId: string;
  windowId: string;
}> = ({ profileId, windowId }) => {
  const theme = useTheme()

  return (
    <Stack>
      <ViewContent>
        <Pivot overflowBehavior="menu" styles={{
          root: {
            background: theme.palette.white, zIndex: 10, left: 0,
            // position: "fixed",
            // top: 45,
            borderRadius: "16px 16px 0px 0px",
            width: "100%",
          }
        }}>
          <PivotItem
            headerText="Profil"
            itemIcon={Icons.Profile}
            itemKey="profile"
          >
            <PushDown by={40} />

            <Profile profileId={profileId} />
          </PivotItem>

          <PivotItem
            headerText="Einstellungen"
            itemIcon={Icons.Settings}
            itemKey="setting"
          >
            <PushDown by={60} />

            <Setting profileId={profileId} />
          </PivotItem>
        </Pivot>
      </ViewContent>
    </Stack>
  );
};

export default ViewAccount;

const Profile: React.FC<{ profileId: string }> = ({ profileId }) => {
  const user = useUser(profileId);
  const updateUser = useUpdateUser(profileId);
  const profile = useProfile(profileId);
  const updateProfile = useUpdateProfile(profileId);
  const jobfairs = useJobfairs();
  const { getState, setState } = useClientStore

  useEffect(() => {
    let newWindows = getState().openWindows.filter((window: any) => {
      if (window.window.id === "accountById" + profileId) {
        window.window.title = profile.data?.description ? profile.data?.description : profile.data?.firstName + " " + profile.data?.lastName
      }
      return true
    })

    setState({ openWindows: newWindows })
  }, [])

  // styles
  const stackStyles = { root: { maxWidth: "50%", width: "100%" } };

  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <MutationProgressIndicator mutationQuery={updateUser}  />

      <MutationProgressIndicator mutationQuery={updateProfile}  />

      <QueryProgressIndicator  query={user} />

      <Stack horizontal>
        <Stack grow styles={stackStyles}>
          <FieldStringUpdate
            allowMutation
            field="firstName"
            label="Vorname"
            mutation={(payload: any) => updateProfile.mutate(payload)}
            query={profile}
          />
        </Stack>

        <Stack grow styles={stackStyles}>
          <FieldStringUpdate
            allowMutation
            field="lastName"
            label="Nachname"
            mutation={(payload: any) => updateProfile.mutate(payload)}
            query={profile}
          />
        </Stack>
      </Stack>

      <Stack horizontal>
        <Stack styles={stackStyles}>
          <FieldStringUpdate
            allowMutation
            field="position"
            label="Position"
            mutation={(payload: any) => updateProfile.mutate(payload)}
            query={profile}
          />
        </Stack>

        <Stack styles={stackStyles}>
          {jobfairs.data && (
            <FieldDropdownUpdate
              allowMutation
              field="jobfair"
              label="Jobbörse"
              mutation={(payload: any) => {
                updateProfile.mutate(payload);
              }}
              options={jobfairs.data}
              query={jobfairs}
              selectedKey={profile.data?.jobfair?.key}
            />
          )}
        </Stack>
      </Stack>

      <Stack horizontal>
        <Stack grow styles={stackStyles}>
          <FieldStringUpdate
            allowMutation
            field="username"
            label="Benutzername"
            mutation={(payload: any) => updateUser.mutate(payload)}
            query={user}
          />
        </Stack>

        <Stack grow styles={stackStyles}>
          <FieldStringUpdate
            allowMutation
            field="email"
            label="E-Mail"
            mutation={(payload: any) => updateUser.mutate({ ...payload, ignoreEmail: true })}
            query={user}
          />
        </Stack>
      </Stack>

      <Stack horizontal>
        <Stack grow styles={stackStyles}>
          <FieldStringUpdate
            allowMutation
            field="mobilephone"
            label="Mobilnummer"
            isMobilephone
            mutation={(payload: any) => updateUser.mutate(payload)}
            query={user}
          />
        </Stack>

        <Stack grow styles={stackStyles}>
          <FieldStringUpdate
            allowMutation
            field="password"
            isPassword
            label="Passwort"
            mutation={(payload: any) => updateUser.mutate(payload)}
            query={user}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

const Setting: React.FC<{ profileId: string }> = ({ profileId }) => {
  const user = useUser(profileId);
  const updateUser = useUpdateUser(profileId);
  const { getState, setState } = useClientStore;
  const useStore = create(useClientStore);
  const editMode = useStore((store: any) => store.editMode);

  const [spinButtonClass, setSpinButtonClass] = useState("");
  useEffect(() => {
    const inputs = document.getElementsByTagName("input");
    if (inputs.length < 1) return;
    setSpinButtonClass(inputs[0].parentElement?.classList[0]!);
  });

  return (
    <Stack>
      <MutationProgressIndicator mutationQuery={updateUser}  />

      <QueryProgressIndicator  query={user} />

      {user.isSuccess && (
        <Stack tokens={{ childrenGap: 10 }}>
          <Stack horizontal>
            <Stack grow>
              <Text variant="medium">Abmeldung nach Minuten</Text>
            </Stack>

            <Stack horizontal>
              <SpinButton
                className={css`
                  .${spinButtonClass} {
                    border-bottom: 3px solid lightgrey;
                  }
                  .${spinButtonClass}::after {
                    border: 0 !important;
                  }
                `}
                max={24 * 60}
                min={1}
                onChange={async (event: any, newValue: any) => {
                  updateUser.mutate({
                    setting: {
                      ...user.data?.setting,
                      logMeOutAfterMinutes: parseInt(newValue),
                    },
                  });
                }}
                value={user?.data?.setting?.logMeOutAfterMinutes}
              />
            </Stack>
          </Stack>

          <small style={{ color: "lightgrey" }}>
            Erst nach erneuter Anmeldung aktiv
          </small>

          <PushDown by={10} />

          <Stack horizontal>
            <Stack grow>
              <Text variant="medium">Layout anpassen</Text>
            </Stack>

            <Stack horizontal>
              <Toggle
                checked={editMode}
                onClick={() =>
                  setState({
                    ...getState(),
                    editMode: !getState().editMode,
                  })
                }
              />
            </Stack>
          </Stack>

          <PushDown by={10} />

          <Stack horizontal>
            <Stack grow>
              <Text variant="medium">Vor Schliessen warnen</Text>
            </Stack>

            <FieldToggleUpdate
              checked={user.data?.setting?.preventUnintendedReloadByUser}
              mutation={updateUser}
              onChange={(event: any, checked: any) => {
                updateUser.mutate({
                  setting: {
                    ...user.data?.setting,
                    preventUnintendedReloadByUser: checked,
                  },
                });
              }}
              query={user}
            />
          </Stack>

          <small style={{ color: "lightgrey" }}>
            Erst nach erneutem Laden der Plattform aktiv
          </small>
        </Stack>
      )}
    </Stack>
  );
};
