import { DefaultButton, IconButton, Modal, Stack, Text } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import create from "zustand";
import { useUpdateMyUser } from "../assets/mutations";
import {
  useInternalDocuments,
  useInvoicesBySmallInvoice,
  useJobs,
  useLastOpened,
  useMyNotes,
  useMyTodos,
  useMyUser,
  useProfiles,
  useReceipts,
  useTags,
  useUnassignedJobs,
  useWorkLogs
} from "../assets/queries";
import { useClientStore } from "../assets/stores";
import { PushDown } from "../components/misc";
import Present from "../tiles/Parts";
import Recent from "../tiles/Recent";

const Tiles: React.FC = () => {
  const useStore = create(useClientStore);
  const editMode = useStore((store: any) => store.editMode);
  const myUser = useMyUser();
  const [showTileSelection, setShowTileSelection] = useState({
    show: false,
    rowIndex: 0,
    columnIndex: 0,
  });
  const [showDivisionSelection, setShowDivisionSelection] = useState({
    show: false,
    rowIndex: 0,
    columnIndex: 0,
  });
  const updateMyUser = useUpdateMyUser();
  const publicEmployers = useProfiles("publicEmployer", 30);
  const privateEmployers = useProfiles("privateEmployer", 30);
  const jobs = useJobs(30);
  const employees = useProfiles("employee", 30);
  const workLogs = useWorkLogs(30);
  const invoices = useInvoicesBySmallInvoice();
  const receipts = useReceipts(30)
  const personalNotes = useMyNotes(10);
  const todoNotes = useMyTodos("all", 30);
  const internalDocuments = useInternalDocuments(30);
  const unassignedJobs = useUnassignedJobs(30);
  const tags = useTags(true)
  const lastOpened = useLastOpened()

  const addTile = async (tile: string) => {
    let newLayout = myUser.data?.layout;
    newLayout.rows[showTileSelection.rowIndex].columns[
      showTileSelection.columnIndex
    ] = {
      tile: tile,
      size: newLayout.rows[showTileSelection.rowIndex].columns[
        showTileSelection.columnIndex
      ].size,
    };

    updateMyUser.mutate({ layout: newLayout });
    setShowTileSelection({ ...showTileSelection, show: false });
  };

  const changeColumnDivision = async (division: string) => {
    let newLayout = myUser.data?.layout;

    switch (division) {
      case "SingleColumn":
        newLayout.rows[showDivisionSelection.rowIndex].columns = [
          {
            tile: "empty",
            size: 1,
          },
        ];
        break;
      case "DoubleColumn":
        newLayout.rows[showDivisionSelection.rowIndex].columns = [
          {
            tile: "empty",
            size: 1.5,
          },
          {
            tile: "empty",
            size: 1.5,
          },
        ];
        break;
      case "ColumnRightTwoThirds":
        newLayout.rows[showDivisionSelection.rowIndex].columns = [
          {
            tile: "empty",
            size: 2,
          },
          {
            tile: "empty",
            size: 1,
          },
        ];
        break;
      case "ColumnLeftTwoThirds":
        newLayout.rows[showDivisionSelection.rowIndex].columns = [
          {
            tile: "empty",
            size: 1,
          },
          {
            tile: "empty",
            size: 2,
          },
        ];
        break;
      case "TripleColumn":
        newLayout.rows[showDivisionSelection.rowIndex].columns = [
          {
            tile: "empty",
            size: 3,
          },
          {
            tile: "empty",
            size: 3,
          },
          {
            tile: "empty",
            size: 3,
          },
        ];
        break;
      case "TripleColumnEdit":
        let division = prompt("Aufteilung?");
        if (!division) return;
        newLayout.rows[showDivisionSelection.rowIndex].columns = [];
        division.split(",").forEach((size: any) => {
          newLayout.rows[showDivisionSelection.rowIndex].columns.push({
            tile: "empty",
            size: size,
          });
        });
        break;
      case "add":
        newLayout.rows.push({
          columns: [
            {
              tile: "empty",
              size: 1,
            },
          ],
        });
        break;
      case "delete":
        if (newLayout.rows.length > 1)
          newLayout.rows = newLayout.rows.filter((row: any, index: any) => {
            return index !== showDivisionSelection.rowIndex;
          });
        break;

      default:
        break;
    }
    updateMyUser.mutate({ layout: newLayout });
    setShowDivisionSelection({ ...showTileSelection, show: false });
  };

  return (
    <Stack grow>
      <ChangeLayout
        changeColumnDivision={changeColumnDivision}
        setShowDivisionSelection={setShowDivisionSelection}
        showDivisionSelection={showDivisionSelection}
      />

      <AddTile
        addTile={addTile}
        setShowTileSelection={setShowTileSelection}
        showTileSelection={showTileSelection}
      />

      <PushDown by={20} />

      {myUser.data?.layout.rows.map((row: any, rowIndex: any) => {
        return (
          <Stack
            key={Math.random() * 100000}
            grow
            horizontal
            styles={{
              root: {
                maxHeight: 500,
                minHeight: 300,
                padding: 2.5,
              },
            }}
            tokens={{ childrenGap: 10 }}
          >
            {row.columns.map((column: any, columnIndex: any) => {
              let me = {
                column: columnIndex,
                layout: myUser.data?.layout,
                row: rowIndex,
                self: myUser.data?.layout.rows[rowIndex].columns[columnIndex],
                layout_id: myUser.data?.layout?.id,
                showDivisionSelection: () =>
                  setShowDivisionSelection({
                    show: true,
                    rowIndex: rowIndex,
                    columnIndex: columnIndex,
                  }),
              };

              return (
                <Stack
                  key={Math.random() * 100000}
                  styles={{
                    root: {
                      width: Math.floor(100 / column.size) + "%",
                    },
                  }}
                >
                  <PushDown by={5} />

                  {column.tile === "recentPublicEmployers" && (
                    <Recent
                      query={publicEmployers}
                      type="publicEmployer"
                      whoAmI={me}
                    />
                  )}

                  {column.tile === "recentPrivateEmployers" && (
                    <Recent
                      query={privateEmployers}
                      type="privateEmployer"
                      whoAmI={me}
                    />
                  )}

                  {column.tile === "recentJobs" && (
                    <Recent query={jobs} type="job" whoAmI={me} />
                  )}

                  {column.tile === "recentEmployees" && (
                    <Recent query={employees} type="employee" whoAmI={me} />
                  )}

                  {column.tile === "recentWorkLogs" && (
                    <Recent query={workLogs} type="workLog" whoAmI={me} />
                  )}

                  {column.tile === "recentInvoices" && (
                    <Recent query={invoices} type="invoice" whoAmI={me} />
                  )}

                  {column.tile === "recentReceipts" && (
                    <Recent query={receipts} type="receipt" whoAmI={me} />
                  )}

                  {column.tile === "recentPersonalNotes" && (
                    <Recent
                      query={personalNotes}
                      type="personalNote"
                      whoAmI={me}
                    />
                  )}

                  {column.tile === "todoNotes" && (
                    <Recent query={todoNotes} type="todoNotes" whoAmI={me} />
                  )}

                  {column.tile === "internalDocuments" && (
                    <Recent
                      query={internalDocuments}
                      type="internalDocuments"
                      whoAmI={me}
                    />
                  )}

                  {column.tile === "tags" && (
                    <Recent
                      query={tags}
                      type="tags"
                      whoAmI={me}
                    />
                  )}

                  {column.tile === "unassignedJobs" && (
                    <Recent
                      query={unassignedJobs}
                      type="unassignedJobs"
                      whoAmI={me}
                    />
                  )}

                  {column.tile === "lastOpened" && (
                    <Recent
                      query={lastOpened}
                      type="lastOpened"
                      whoAmI={me}
                    />
                  )}

                  {column.tile === "empty" && editMode && (
                    <Empty
                      columnIndex={columnIndex}
                      rowIndex={rowIndex}
                      setShowDivisionSelection={setShowDivisionSelection}
                      setShowTileSelection={setShowTileSelection}
                    />
                  )}
                </Stack>
              );
            })}
          </Stack>
        );
      })}

      {editMode && (
        <Stack styles={{ root: { alignItems: "center", paddingTop: 20 } }}>
          <IconButton
            styles={{ root: { borderRadius: 16, } }}
            iconProps={{ iconName: "Add" }}
            style={{ color: "lightgrey" }}
            onClick={() => changeColumnDivision("add")}
          />
        </Stack>
      )}
    </Stack>
  );
};

export default Tiles;

const ChangeLayout: React.FC<{
  changeColumnDivision: any;
  setShowDivisionSelection: any;
  showDivisionSelection: any;
}> = ({
  changeColumnDivision,
  setShowDivisionSelection,
  showDivisionSelection,
}) => {
    const myUser = useMyUser();
    const buttonStyle: any = {
      root: { borderRadius: 16, border: 0, height: 100, margin: 10, width: 100 },
    };

    return (
      <Modal
        onDismiss={() =>
          setShowDivisionSelection({
            // ...showDivisionSelection,
            show: false,
          })
        }
        isOpen={showDivisionSelection.show}
        styles={{
          main: { borderRadius: 16 },
          scrollableContent: {
            height: 500,
            width: 750,
          },
        }}
      >
        <Stack
          styles={{
            root: {
              alignItems: "center",
              justifyContent: "center",
              minHeight: "100%",
            },
          }}
        >
          <Text variant="xLarge">Wähle ein Layout für diese Zeile aus</Text>

          <PushDown by={30} />

          <Stack
            horizontal
            styles={{
              root: {
                alignItems: "center",
                justifyContent: "center",
              },
            }}
          >
            {[
              "SingleColumn",
              "DoubleColumn",
              "ColumnRightTwoThirds",
              "ColumnLeftTwoThirds",
              "TripleColumn",
              "TripleColumnEdit",
            ].map((item: any) => {
              return (
                <DefaultButton
                  key={Math.random() * 100000}
                  disabled={item === "TripleColumnEdit" ? true : false}
                  iconProps={{
                    iconName: item,
                    style: { fontSize: 50 },
                  }}
                  onClick={async () => changeColumnDivision(item)}
                  styles={buttonStyle}
                />
              );
            })}
          </Stack>
          <Stack horizontal>
            <DefaultButton
              text="Reihe hinzufügen"
              onClick={async () => changeColumnDivision("add")}
              styles={buttonStyle}
            />
            {myUser.data?.layout.rows.length > 1 && (
              <DefaultButton
                text="Reihe entfernen"
                onClick={async () => changeColumnDivision("delete")}
                styles={buttonStyle}
              />
            )}
          </Stack>

          <PushDown by={30} />

          <Stack horizontal>
            <DefaultButton
              primary
              styles={{ root: { borderRadius: 16 } }}
              text="Abbrechen"
              onClick={() =>
                setShowDivisionSelection({
                  ...showDivisionSelection,
                  show: false,
                })
              }
            />
          </Stack>
        </Stack>
      </Modal>
    );
  };

const AddTile: React.FC<{
  addTile: any;
  setShowTileSelection: any;
  showTileSelection: any;
}> = ({ addTile, setShowTileSelection, showTileSelection }) => {
  const [addedTiles, setAddedTiles] = useState<any>([]);
  const myUser = useMyUser();

  useEffect(() => {
    let tiles: any = [];

    myUser.data?.layout?.rows?.forEach((row: any) => {
      Object.values(row).forEach((column: any) => {
        column.forEach((column: any) => {
          tiles.push(column.tile);
        });
      });
    });

    setAddedTiles(tiles);
  }, [myUser.data]);

  return (
    <Modal
      onDismiss={() =>
        setShowTileSelection({
          ...showTileSelection,
          show: false,
        })
      }
      isOpen={showTileSelection.show}
      styles={{
        main: { borderRadius: 16 },
        scrollableContent: {
          height: 750,
          width: 750,
        },
      }}
    >
      <PushDown by={20} />

      <Stack styles={{ root: { alignItems: "center" } }}>
        <Text variant="xLarge">Wähle eine Kachel für diese Spalte aus</Text>
      </Stack>
      <Stack>
        <PushDown by={20} />

        {addedTiles.indexOf("lastOpened") === -1 && (
          <Present
            mockUpTile={<Recent isMockUp type="lastOpened" />}
            explanation={() => {
              return (
                <div>
                  <strong style={{ color: "red" }}>NICHT MEHR VERFÜGBAR</strong>

                  <p>Zeigt kürzlich aufgerufene Einträge an.</p>
                  <ul>
                    <li>Daten aktualisieren</li>
                  </ul>
                </div>
              );
            }}
            onAdd={() => {
              setShowTileSelection({ ...showTileSelection, show: false });
              Swal.fire({
                buttonsStyling: false,
                title: "Hinweis",
                text: "Diese Kachel ist nicht mehr verfügbar."
              })
            }}
            tile="lastOpened"
          />
        )}

        {addedTiles.indexOf("recentPublicEmployers") === -1 && (
          <Present
            mockUpTile={<Recent isMockUp type="publicEmployer" />}
            explanation={() => {
              return (
                <div>
                  <p>Zeigt kürzlich erstellte Auftraggeber*innen an.</p>
                  <ul>
                    <li>Daten aktualisieren</li>
                    <li>AG's (öffentlich) erstellen</li>
                  </ul>
                </div>
              );
            }}
            onAdd={() => addTile("recentPublicEmployers")}
            tile="recentPublicEmployers"
          />
        )}

        {addedTiles.indexOf("recentPrivateEmployers") === -1 && (
          <Present
            mockUpTile={<Recent isMockUp type="privateEmployer" />}
            explanation={() => {
              return (
                <div>
                  <p>Zeigt kürzlich erstellte Auftraggeber*innen an.</p>
                  <ul>
                    <li>Daten aktualisieren</li>
                    <li>AG's (privat) erstellen</li>
                  </ul>
                </div>
              );
            }}
            onAdd={() => addTile("recentPrivateEmployers")}
            tile="recentPrivateEmployers"
          />
        )}

        {addedTiles.indexOf("recentJobs") === -1 && (
          <Present
            mockUpTile={<Recent isMockUp type="job" />}
            explanation={() => {
              return (
                <div>
                  <p>Zeigt kürzlich erstellte Jobs an.</p>
                  <ul>
                    <li>Daten aktualisieren</li>
                  </ul>
                </div>
              );
            }}
            onAdd={() => addTile("recentJobs")}
            tile="recentJobs"
          />
        )}

        {addedTiles.indexOf("unassignedJobs") === -1 && (
          <Present
            mockUpTile={<Recent isMockUp type="unassignedJobs" />}
            explanation={() => {
              return (
                <div>
                  <p>Zeigt nicht zugeteilte Jobs an.</p>
                  <ul>
                    <li>Daten aktualisieren</li>
                  </ul>
                </div>
              );
            }}
            onAdd={() => addTile("unassignedJobs")}
            tile="unassignedJobs"
          />
        )}

        {addedTiles.indexOf("recentEmployees") === -1 && (
          <Present
            mockUpTile={<Recent isMockUp type="employee" />}
            explanation={() => {
              return (
                <div>
                  <p>Zeigt kürzlich erstellte Jugendliche an.</p>
                  <ul>
                    <li>Daten aktualisieren</li>
                    <li>AG's (privat) erstellen</li>
                  </ul>
                </div>
              );
            }}
            onAdd={() => addTile("recentEmployees")}
            tile="recentEmployees"
          />
        )}

        {addedTiles.indexOf("recentWorkLogs") === -1 && (
          <Present
            mockUpTile={<Recent isMockUp type="workLog" />}
            explanation={() => {
              return (
                <div>
                  <p>Zeigt kürzlich erstellte Arbeitseinsätze an.</p>
                  <ul>
                    <li>Daten aktualisieren</li>
                  </ul>
                </div>
              );
            }}
            onAdd={() => addTile("recentWorkLogs")}
            tile="recentWorkLogs"
          />
        )}

        {addedTiles.indexOf("recentInvoices") === -1 && (
          <Present
            mockUpTile={<Recent isMockUp type="invoice" />}
            explanation={() => {
              return (
                <div>
                  <p>Zeigt kürzlich erstellte Rechnungen an.</p>
                  <ul>
                    <li>Daten aktualisieren</li>
                  </ul>
                </div>
              );
            }}
            onAdd={() => addTile("recentInvoices")}
            tile="recentInvoices"
          />
        )}

        {addedTiles.indexOf("recentReceipts") === -1 && (
          <Present
            mockUpTile={<Recent isMockUp type="receipt" />}
            explanation={() => {
              return (
                <div>
                  <p>Zeigt kürzlich erstellte Quittungen an.</p>
                  <ul>
                    <li>Daten aktualisieren</li>
                  </ul>
                </div>
              );
            }}
            onAdd={() => addTile("recentReceipts")}
            tile="recentReceipts"
          />
        )}

        {addedTiles.indexOf("recentPersonalNotes") === -1 && (
          <Present
            mockUpTile={<Recent isMockUp type="personalNote" />}
            explanation={() => {
              return (
                <div>
                  <p>Zeigt kürzlich erstellte eigene Notizen an.</p>
                  <ul>
                    <li>Daten aktualisieren</li>
                  </ul>
                </div>
              );
            }}
            onAdd={() => addTile("recentPersonalNotes")}
            tile="recentPersonalNotes"
          />
        )}

        {addedTiles.indexOf("todoNotes") === -1 && (
          <Present
            mockUpTile={<Recent isMockUp type="todoNotes" />}
            explanation={() => {
              return (
                <div>
                  <p>Zeigt alle zu erledigenden Notizen an.</p>
                  <ul>
                    <li>Daten aktualisieren</li>
                  </ul>
                </div>
              );
            }}
            onAdd={() => addTile("todoNotes")}
            tile="todoNotes"
          />
        )}

        {addedTiles.indexOf("internalDocuments") === -1 && (
          <Present
            mockUpTile={<Recent isMockUp type="internalDocuments" />}
            explanation={() => {
              return (
                <div>
                  <p>Zeigt alle internen Dokumente an.</p>
                  <ul>
                    <li>Daten aktualisieren</li>
                  </ul>
                </div>
              );
            }}
            onAdd={() => addTile("internalDocuments")}
            tile="internalDocuments"
          />
        )}

        {addedTiles.indexOf("tags") === -1 && (
          <Present
            mockUpTile={<Recent isMockUp type="tags" />}
            explanation={() => {
              return (
                <div>
                  <p>Zeigt alle benutzten Tags an.</p>
                  <ul>
                    <li>Daten aktualisieren</li>
                  </ul>
                </div>
              );
            }}
            onAdd={() => addTile("tags")}
            tile="tags"
          />
        )}
      </Stack>
    </Modal>
  );
};

const Empty: React.FC<{
  columnIndex: any;
  rowIndex: any;
  setShowDivisionSelection: any;
  setShowTileSelection: any;
}> = ({
  columnIndex,
  rowIndex,
  setShowDivisionSelection,
  setShowTileSelection,
}) => {
    return (
      <Stack
        grow
        horizontal
        styles={{
          root: {
            alignItems: "center",
            border: "1px dotted lightgrey",
            justifyContent: "center",
            marginTop: 10,
            height: 150,
            borderRadius: 16,
          },
        }}
      >
        <IconButton
          styles={{ root: { borderRadius: 16, } }}
          iconProps={{
            iconName: "ColumnLeftTwoThirdsEdit",
            style: { color: "lightgrey" },
          }}
          onClick={async () => {
            setShowDivisionSelection({
              rowIndex: rowIndex,
              columnIndex: columnIndex,
              show: true,
            });
          }}
        />
        <IconButton
          styles={{ root: { borderRadius: 16, } }}
          iconProps={{
            iconName: "Add",
            style: { color: "lightgrey" },
          }}
          onClick={async () => {
            setShowTileSelection({
              rowIndex: rowIndex,
              columnIndex: columnIndex,
              show: true,
            });
          }}
        />
      </Stack>
    );
  };
