import {
  IButtonStyles,
  ILabelStyles,
  IPivotStyles,
  IStackStyles,
  IStackTokens,
  ITextFieldStyles,
  ITextStyles,
} from "@fluentui/react";

// not used anymore because of theme.palette-use
export const myPivot: Partial<IPivotStyles> = {
  root: {
    left: 0,
    position: "fixed",
    top: 45,
    width: "100%",
    zIndex: 10,
  },
};

export const myStack: IStackStyles = { root: { width: "100%" } };

export const myTokens: IStackTokens = { childrenGap: 10 };

export const myColumnLeft: IStackStyles = { root: { width: "30%" } };
export const myColumnRight: IStackStyles = { root: { width: "70%" } };

export const myLabelBold: ILabelStyles = { root: { fontWeight: 700 } };
export const myLabelefault: ILabelStyles = { root: { fontWeight: 400 } };

export const myAlignRight: IStackStyles = {
  root: { alignItems: "flex-end", justifyContent: "flex-end" },
};

export const myTextField: Partial<ITextFieldStyles> = {
  field: {
    padding: 5,
  },
  fieldGroup: {
    background: "transparent",
    border: 0,
    borderBottom: "3px solid lightgrey",
  },
  root: {
    width: "100%",
  },
};

export const myDropdown: Partial<ITextFieldStyles> = {
  field: {
    padding: 0,
  },
  fieldGroup: {
    background: "transparent",
    border: 0,
    borderBottom: "3px solid lightgrey",
  },
  root: {
    width: "100%",
  },
};

export const myButton: IButtonStyles = {
  root: { border: 0, borderRadius: 16 },
  rootHovered: { border: 0 },
};

export const myButtonDelete: IButtonStyles = {
  root: { border: 0, borderRadius: 16 },
  rootHovered: { border: 0 },
};

export const myTextLeft: ITextStyles = {
  root: { textAlign: "left" },
};

export const myTextCenter: ITextStyles = {
  root: { textAlign: "center" },
};

export const myTextRight: ITextStyles = {
  root: { textAlign: "right" },
};

// ViewHeader

export const myViewHeaderContainer: any = {
  // must be set inside ViewHeader itself
  padding: 10,
  userSelect: "none",

  // some of that may be needed if it's an electron window ...?!
  // position: "fixed",
  // top: 0,
  // width: "100%",
  // zIndex: 10,
};

// ViewContent

// ...

// ViewFooter

export const myViewFooterContainer: any = {
  // must be set inside ViewFooter itself
  // bottom: 0,
  paddingRight: 10,
  // changes to TAB-VIEW
  // position: "fixed",
  right: 0,
  width: "100%",
  zIndex: 10,
};

export const myViewFooterContent: any = {
  padding: "10px 0px",
};

export const myDetailsList = { root: { cursor: "pointer" } };
