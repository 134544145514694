import { initializeIcons } from "@fluentui/react";
import isElectron from "is-electron";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import {
  BrowserRoutes,
  ElectronRoutes,
  MobileRoutes,
  Wrapper,
} from "./imports";
import ScreenLoadingIndicator from "./screens/ScreenLoadingIndicator";

initializeIcons();

ReactDOM.render(
  <React.StrictMode>
    <Wrapper>
      {/* incl. QueryClientProvider and ThemeProvider */}

      <ScreenLoadingIndicator />
      {/* ScreenLoadingIndicator is blocking when NoServerConnection */}

      <BrowserRouter>
        {!isElectron() && (
          <React.Fragment>
            <BrowserRoutes />
            {/* main */}

            <MobileRoutes />
            {/* incl. QrUploaderMobile only */}
          </React.Fragment>
        )}

        {isElectron() && (
          <React.Fragment>
            <ElectronRoutes />
          </React.Fragment>
        )}
      </BrowserRouter>
    </Wrapper>
  </React.StrictMode>,
  document.getElementById("root")
);
