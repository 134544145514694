import {
    Checkbox,
    Stack
} from "@fluentui/react";
import { useState } from "react";
import { useCategories } from "../assets/queries";
import { ViewContent } from "./Parts";

const AddCategories: React.FC<{
    windowId: string;
}> = ({ windowId }) => {
    const categories = useCategories()
    const [selectedCategories, setSelectedCategories] = useState([...JSON.parse(localStorage.preferredCategories)])

    return (
        <Stack>
            <ViewContent>
                {categories.data?.map((category: any) => {
                    let checked = false
                    selectedCategories.forEach((c: any) => {
                        if (c.text === category.text) checked = true
                    })
                    return (
                        <Checkbox key={category.text} label={category.text} checked={checked} onChange={(event: any, checked: any) => {
                            let newCategories = categories.data?.filter((templateCategory: any) => {
                                if (templateCategory.text === category.text) return checked
                                for (let i = 0; i < selectedCategories.length; i++) {
                                    const existingCategory = selectedCategories[i];
                                    if (templateCategory.text === existingCategory.text) return true
                                }
                                return false
                            })
                            setSelectedCategories([...newCategories])
                            localStorage.preferredCategories = JSON.stringify([...newCategories])
                        }} />
                    )
                })}
            </ViewContent>
        </Stack>
    );
};

export default AddCategories;

